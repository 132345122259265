import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IScreenResponse } from 'src/app/api/modules/core/network/IScreenResponse';
import { ApplicationService } from '../application/application.service';
import { DigitaServiceAPIService } from './digitaservice-api.service';

/**
 * Resolves data for a route.
 */
export const digitaServiceRouteResolver: ResolveFn<IScreenResponse> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const apiService = inject(DigitaServiceAPIService);
  const appService = inject(ApplicationService);

  // show the global loading display
  appService.setLoadingIndicator(true);

  // make the request
  return apiService.route<IScreenResponse>(state.url).pipe(
    // remove the loading system and dismiss any snackbars
    map((screenResponse) => {
      appService.setLoadingIndicator(false);
      return screenResponse;
    }),
    take(1),
    catchError((error) => {
      appService.registerError(error);
      return EMPTY;
    })
  );
};
