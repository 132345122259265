import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMediaLock } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { mediaLockButtonAnimation } from './media-lock.animations';
import { MediaLockQuery } from './media-lock.query';
import { MediaLockService } from './media-lock.service';
import { MediaLockStore } from './media-lock.store';

/**
 * The Media Lock Component is used to Force a user to watch Media.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-media-lock',
  templateUrl: './media-lock.component.html',
  styleUrls: ['./media-lock.component.scss'],
  providers: [MediaLockService, MediaLockQuery, MediaLockStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [mediaLockButtonAnimation],
})
export class MediaLockComponent {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IMediaLock;
  @Input() set config(configuration: IMediaLock) {
    this._config = configuration;
    this.mediaLockService.applyConfiguration(configuration);
  }
  get config(): IMediaLock {
    return this._config;
  }

  /**
   * Constructor
   *
   * @param mediaLockService - a reference to the Media Lock Service.
   * @param mediaLockQuery - a reference to the Media Lock Query.
   */
  constructor(private readonly mediaLockService: MediaLockService, public readonly mediaLockQuery: MediaLockQuery) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs from the Media Lock when the Media is Unlocked.
   */
  mediaLockUnlocked() {
    this.mediaLockService.applyUnlock();
  }

  /**
   * Occurs from the Media Lock when the Media has experienced an error.
   */
  mediaLockError() {
    this.mediaLockService.applyError();
  }
}
