// pro - fas - regular
import {
  IconDefinition,
  faArrowDown,
  faArrowLeft,
  faCalendarXmark,
  faCamera,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleHalfStroke,
  faCircleMinus,
  faCirclePause,
  faCirclePlay,
  faCircleXmark,
  faClock,
  faCloudArrowUp,
  faCode,
  faCommentSms,
  faCopy,
  faEnvelope,
  faHeart,
  faHouse,
  faLink,
  faLock,
  faMinus,
  faOctagonXmark,
  faPaperPlane,
  faPlay,
  faPlus,
  faRepeat,
  faRotate,
  faRotateRight,
  faShare,
  faSkullCrossbones,
  faStar,
  faThumbsUp,
  faUpload,
  faUser,
  faVideo,
  faWaveform,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

/**
 * The Supported Icon Names used via JSON.
 */
export const FasList = [
  'fas:arrow-left',
  'fas:arrow-down',
  'fas:calendar-xmark',
  'fas:camera',
  'fas:check',
  'fas:chevron-left',
  'fas:chevron-right',
  'fas:circle',
  'fas:circle-check',
  'fas:circle-minus',
  'fas:circle-pause',
  'fas:circle-play',
  'fas:circle-xmark',
  'fas:clock',
  'fas:cloud-arrow-up',
  'fas:comment-sms',
  'fas:copy',
  'fas:envelope',
  'fas:heart',
  'fas:house',
  'fas:link',
  'fas:lock',
  'fas:minus',
  'fas:octagon-xmark',
  'fas:paper-plane',
  'fas:play',
  'fas:plus',
  'fas:repeat',
  'fas:rotate',
  'fas:rotate-right',
  'fas:share',
  'fas:skull-crossbones',
  'fas:star',
  'fas:thumbs-up',
  'fas:upload',
  'fas:user',
  'fas:video',
  'fas:waveform',
  'fas:xmark',
  'fas:code',
  'fas:circle-half-stroke',
] as const;

/**
 * Generated type from the SupportedIconNames.
 */
export type FasIcons = (typeof FasList)[number];

/**
 * All FontAwesome Solid Icons available for the system
 * to use with Material Design are defined here.
 */
export const FasIconDefinitions: IconDefinition[] = [
  faArrowLeft,
  faArrowDown,
  faCalendarXmark,
  faCamera,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleMinus,
  faCirclePause,
  faCirclePlay,
  faCircleXmark,
  faClock,
  faCloudArrowUp,
  faCommentSms,
  faCopy,
  faEnvelope,
  faHeart,
  faHouse,
  faLink,
  faLock,
  faMinus,
  faOctagonXmark,
  faPaperPlane,
  faPlay,
  faPlus,
  faRepeat,
  faRotate,
  faRotateRight,
  faShare,
  faSkullCrossbones,
  faStar,
  faThumbsUp,
  faUpload,
  faUser,
  faVideo,
  faWaveform,
  faXmark,
  faCode,
  faCircleHalfStroke,
];
