import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IMediaLockable } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { MediaLockableQuery } from './media-lockable.query';
import { MediaLockableService } from './media-lockable.service';
import { MediaLockableStore } from './media-lockable.store';

/**
 * The Media Lockable Component is used to Force a user to watch Media.
 *
 * This component is not intended to be used directly, but by the {@link MediaLockComponent} and {@link MediaLock}
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-media-lockable',
  templateUrl: './media-lockable.component.html',
  styleUrls: ['./media-lockable.component.scss'],
  providers: [MediaLockableService, MediaLockableQuery, MediaLockableStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaLockableComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // @OUTPUTS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media player ended?
   */
  @Output() unlocked = new EventEmitter<void>();

  /**
   * Occurs when the Media has experienced an error.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error = new EventEmitter<void>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IMediaLockable;
  @Input() set config(configuration: IMediaLockable) {
    this._config = configuration;
    this.mediaLockableService.applyConfiguration(configuration);
  }
  get config(): IMediaLockable {
    return this._config;
  }

  /**
   * All Players must be configured initially before they can be used.
   */
  private configurationSubscription?: Subscription;

  /**
   * Holds all subscriptions for teardown.
   */
  private subscriptions?: Subscription;

  /**
   * Constructor
   *
   * @param mediaLockableService - a reference to the Media Lock Service.
   * @param mediaLockableQuery - a reference to the Media Lock Query.
   */
  constructor(private readonly mediaLockableService: MediaLockableService, public readonly mediaLockableQuery: MediaLockableQuery) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle Hook
   */
  ngOnInit() {
    // prepare the subscriptions
    this.subscriptions = new Subscription();

    // listen out for if the media throws an error
    const error = this.mediaLockableQuery.error$.subscribe((hasError) => {
      if (hasError === true) {
        this.subscriptions.remove(error);
        this.error.emit();
        this.error.complete();
      }
    });
    this.subscriptions.add(error);

    // listen out for when the system is unlocked
    const unlocked = this.mediaLockableQuery.unlocked$.subscribe((isUnlocked) => {
      if (isUnlocked === true) {
        this.subscriptions.remove(unlocked);
        this.unlocked.emit();
        this.unlocked.complete();
      }
    });
    this.subscriptions.add(unlocked);
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();
  }

  /**
   * Occurs when the Media is Playing.
   */
  onMediaPlaying() {
    this.mediaLockableService.applyInitialize();
  }

  /**
   * Occurs when the Media time update occurs.
   */
  onMediaCurrentTime(currentTime = 0) {
    this.mediaLockableService.applyCurrentTime(currentTime);
  }

  /**
   * Occurs when the Media Duration is known.
   */
  onMediaDuration(duration = 0) {
    this.mediaLockableService.applyDuration(duration);
  }

  /**
   * Occurs when the Media throws an error.
   */
  onMediaError() {
    this.mediaLockableService.applyError();
  }

  /**
   * Occurs if the media is in portrait mode or not.
   *
   * @param isPortrait - whether the media is in portrait mode.
   */
  onMediaIsPortrait(isPortrait: boolean) {
    this.mediaLockableService.applyMediaIsPortrait(isPortrait);
  }

  /**
   * Occurs when the media is ready.
   */
  onMediaReady() {
    this.mediaLockableService.applyMediaIsReady();
  }
}
