import { IFrameEventCreateDialogMedia } from '@digitaservice/utils/@types';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';

/**
 * Generates a JSON Object that can be used to construct a new Media Component
 *
 * This is used primarily within Plugins to convert a IFrame Message into an Angular
 * Component.
 *
 * @param config - The Partial Configuration for a Media
 */
export const GenerateMedia = (config: IFrameEventCreateDialogMedia): IMedia => {
  const defaults: IMedia = {
    selector: 'app-media',
  };

  return {
    ...defaults,
    ...config,
  };
};
