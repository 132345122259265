import { Component } from '@angular/core';
import { ITable } from 'src/app/api/modules/core/dynamic/components/ITable';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  /**
   * The configuration for this component.
   */
  private _config?: ITable;

  /**
   * Get the configuration for this component.
   */
  get config() {
    return this._config;
  }

  /**
   * Set the configuration for this component.
   */
  set config(config: ITable) {
    this._config = config;
  }
}
