import { Injectable } from '@angular/core';
import { CredentialStore } from './credential.store';

/**
 * The CredentialService is used to publically update the {@link CredentialStore}.
 *
 * This object is controlled by the server. By design the front end doesn't touch it, inspect it, or modify it.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialService {
  /**
   * Constructor
   */
  constructor(private readonly credentialStore: CredentialStore) {}

  /**
   * Updates the server credential.
   */
  applyCredential(credential: Record<string, unknown>) {
    this.credentialStore.applyCredential(credential);
  }
}
