import { ICoreComponent } from '../../ICoreComponent';

/**
 * The Countdown Timer displays a stylized segmented timer.
 *
 * It belongs to the {@link CoreModule}
 */
export interface ICountdown extends ICoreComponent {
  /**
   * The selector for this component.
   *
   * This is required.
   */
  selector: 'app-countdown';

  /**
   * What is the format of the countdown?
   *
   * It can be:
   *
   *   - days
   *   - hours
   *   - minutes
   *   - seconds
   *
   * This is required.
   */
  format: CountdownFormat;

  /**
   * What are the labels of the units of time?
   *
   * For example:
   *
   * ____________________________________________
   * |          |          |          |         |
   * |    02    |    02    |    02    |   02    |
   * |          |          |          |         |
   * --------------------------------------------
   * |   Days   |  Hours   | Minutes  | Seconds |
   * --------------------------------------------
   *
   * The labels have singular and plural forms. "Day" and "Days" for example.
   *
   * Labels are optional but if you provide them, you must provide all of them.
   *
   * The default is undefined.
   *
   * This is optional.
   */
  labels?: ICountdownLabels;

  /**
   * This is the Unix Time as an integer of the server local time. This is the value
   * where the timer will start counting down from once the component loads.
   *
   * This is required.
   */
  startTime: number;

  /**
   * This is the Unix Time as an integer of the target time. This is the value where
   * the timer will end and stop counting down.
   *
   * This is required.
   */
  endTime: number;

  /**
   * // TODO more detail
   * Once the countdown is complete, an API call will be made to this endpoint.
   *
   * This is optional.
   */
  onCompleteAPI?: string;
}

/**
 * Used with {@link ICountdown} to specify the format of the countdown.
 */
export enum CountdownFormat {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

/**
 * Used with {@link ICountdown} to specify translation for labels.
 */
export interface ICountdownLabels {
  /**
   * The singular translation for "day".
   */
  day: string;

  /**
   * The translation for "days".
   */
  days: string;

  /**
   * The singular translation for "hour".
   */
  hour: string;

  /**
   * The translation for "hours".
   */
  hours: string;

  /**
   * The singular translation for "minute".
   */
  minute: string;

  /**
   * The translation for "minutes".
   */
  minutes: string;

  /**
   * The singular translation for "second".
   */
  second: string;

  /**
   * The translation for "seconds".
   */
  seconds: string;
}
