import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { IDialogBase } from 'src/app/api/modules/core/components/dialog/IDialogBase';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { DialogContentComponent } from '../dialogs/dialog-content/dialog-content.component';
import { DialogMediaLockComponent } from '../dialogs/dialog-media-lock/dialog-media-lock.component';

/**
 * Gives the ability for the application to create dialogs
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /**
   * Constructor
   */
  constructor(private readonly matDialog: MatDialog, private readonly loggerService: LoggerService) {}

  /**
   * Opens a Dialog. Note that any content provided is a dynamic component
   * and must be known in advance.
   *
   * @param config - the configuration for the dialog
   */
  openDialog(config: IDialogBase) {
    const resultant = cloneDeep(config);
    if (!resultant.settings) {
      resultant.settings = {};
    }
    resultant.settings.data = cloneDeep(config);
    switch (resultant.selector) {
      default:
        this.loggerService.log('[DialogService]::openDialog - do not understand the selector provided', config.selector);
        break;
      case 'app-dialog-content':
        return this.openPrimitiveContentDialog(resultant as IDialogContent);
      case 'app-dialog-media-lock':
        resultant.settings.disableClose = true;
        return this.openDialogMediaLock(resultant as IDialogMediaLock);
    }
    return undefined;
  }

  /**
   * Opens a Dialog
   *
   * @param config - the configuration
   */
  private openPrimitiveContentDialog<Config extends IDialogContent>(config: Config) {
    return this.matDialog.open<DialogContentComponent, IDialogContent, boolean | undefined>(DialogContentComponent, config.settings);
  }

  /**
   * Opens a {@link DialogMediaLockComponent}.
   *
   * @param config - the configuration
   */
  private openDialogMediaLock<Config extends IDialogMediaLock>(config: Config) {
    return this.matDialog.open<DialogMediaLockComponent, IDialogMediaLock, boolean | undefined>(DialogMediaLockComponent, config.settings);
  }

  /**
   * Dismiss any snackbar currently being shown
   */
  dismiss() {
    this.matDialog.closeAll();
  }
}
