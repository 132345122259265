import { Injectable } from '@angular/core';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { PluginTextEntityStore } from './plugin-text-entity.store';

/**
 * Service responsible for managing the state and behavior of text entities within a plugin.
 * It provides a high-level API for initializing and updating the text entity state.
 */
@Injectable()
export class PluginTextEntityService {
  /**
   * Creates an instance of PluginTextEntityService.
   * @param store An instance of PluginTextEntityStore for state management of the plugin text entity.
   */
  constructor(private readonly store: PluginTextEntityStore) {}

  /**
   * Initializes the text entity's state with the provided configuration.
   * If a configuration is provided, it will be used to set up the initial state.
   * If no configuration is provided, the state will be initialized with default values.
   * @param configuration An optional IPluginTextEntity configuration object to initialize the text entity.
   */
  initialize(configuration?: IPluginTextEntity) {
    this.store.applyInitialize(configuration);
  }

  /**
   * Marks the text animation as complete. This method updates the state of the text entity
   * to reflect that its associated animation has finished.
   */
  applyComplete() {
    this.store.applyComplete();
  }
}
