import { ComponentRef, ViewContainerRef } from '@angular/core';
import { DataboxContainerSupportedTypes } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { IDataboxAttemptsIcon } from 'src/app/api/modules/core/dynamic/databoxes/attempts-icon/IDataboxAttemptsIcon';
import { IDataboxHighScore } from 'src/app/api/modules/core/dynamic/databoxes/highscore/IDataboxHighScore';
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { IDataboxProgressionIcon } from 'src/app/api/modules/core/dynamic/databoxes/progression-icon/IDataboxProgressionIcon';
import { IDataboxProgressionNumeric } from 'src/app/api/modules/core/dynamic/databoxes/progression-numeric/IDataboxProgressionNumeric';
import { IDataboxScore } from 'src/app/api/modules/core/dynamic/databoxes/score/IDataboxScore';
import { IDataboxTimer } from 'src/app/api/modules/core/dynamic/databoxes/timer/IDataboxTimer';
import { createDigitaServiceError } from 'src/app/app-error';
import { DataboxAttemptsIconComponent } from '../databox-attempts-icon/databox-attempts-icon.component';
import { DataboxHighScoreComponent } from '../databox-highscore/databox-highscore.component';
import { DataboxLivesIconComponent } from '../databox-lives-icon/databox-lives-icon.component';
import { DataboxProgressionIconComponent } from '../databox-progression-icon/databox-progression-icon.component';
import { DataboxProgressionNumericComponent } from '../databox-progression-numeric/databox-progression-numeric.component';
import { DataboxScoreComponent } from '../databox-score/databox-score.component';
import { DataboxTimerComponent } from '../databox-timer/databox-timer.component';

/**
 * A union type representing the set of all possible ComponentRef instances for Databox components.
 * This type is used to define a reference to a component instance that has been dynamically created,
 * which could be any of the Databox components listed below. It is typically used in contexts where
 * a component reference is needed for interaction with the component instance, such as accessing
 * instance properties or invoking methods.
 *
 * The Databox components represented by this type are used to display various kinds of data-related
 * UI elements, such as icons for attempts or lives, numeric progression, scores, and timers.
 */
export type DataboxContainerSupportedComponentReferences =
  | ComponentRef<DataboxAttemptsIconComponent>
  | ComponentRef<DataboxHighScoreComponent>
  | ComponentRef<DataboxLivesIconComponent>
  | ComponentRef<DataboxProgressionIconComponent>
  | ComponentRef<DataboxProgressionNumericComponent>
  | ComponentRef<DataboxScoreComponent>
  | ComponentRef<DataboxTimerComponent>;

/**
 * Dynamically creates and inserts an array of Databox components into a specified ViewContainerRef.
 *
 * This function iterates over a given array of component configurations, instantiates each one,
 * and injects them into the provided ViewContainerRef. It is a higher-level factory function
 * that leverages DataboxContainerNextFactoryItem for the creation of individual components.
 *
 * @param viewContainerRef - The Angular container where the dynamic components are to be
 * injected. This is typically a reference to a container in the template where the
 * components should be rendered.
 * @param components - An array of component configurations, each conforming to one of the
 * supported Databox component types. These configurations determine the type of component
 * to create and any data or settings that the component requires.
 *
 * @returns An array of ComponentRef instances, each corresponding to a dynamically created
 * Databox component. These references can be used to interact with the components, such as
 * changing inputs or subscribing to outputs.
 */
export const DataboxContainerFactoryArray = (viewContainerRef: ViewContainerRef, components: DataboxContainerSupportedTypes[]) => {
  if (!components || !viewContainerRef) {
    throw createDigitaServiceError(
      `DataboxContainer`,
      `Factory`,
      `The databox container factory was called with invalid parameters`,
      `config`
    );
  }

  const componentRefs: ComponentRef<any>[] = [];

  components.forEach((componentConfig) => {
    const componentRef = DataboxContainerNextFactoryItem(viewContainerRef, componentConfig);
    componentRefs.push(componentRef);
  });

  return componentRefs;
};

/**
 * Factory function for creating Databox components within a DataboxContainer.
 * It takes a configuration object that specifies the type of Databox component to create
 * and uses the Angular ViewContainerRef to instantiate the component.
 *
 * @param viewContainerRef - The Angular ViewContainerRef that serves as the container in which the component will be created.
 * @param config - An object conforming to one of the supported Databox component types, containing the necessary configuration for the component.
 * @returns A reference to the newly created Databox component instance.
 *
 * @throws Throws an error if the provided selector is not valid or undefined.
 */
const DataboxContainerNextFactoryItem = (viewContainerRef: ViewContainerRef, config: DataboxContainerSupportedTypes) => {
  switch (config.selector) {
    default: {
      throw createDigitaServiceError(
        `DataboxContainer`,
        `Factory`,
        `The databox container contained an item with a selector that is not a supported databox component`,
        `config`
      );
    }
    case 'app-databox-score': {
      const componentReference = viewContainerRef.createComponent(DataboxScoreComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxScore;
      return componentReference;
    }
    case 'app-databox-highscore': {
      const componentReference = viewContainerRef.createComponent(DataboxHighScoreComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxHighScore;
      return componentReference;
    }
    case 'app-databox-timer': {
      const componentReference = viewContainerRef.createComponent(DataboxTimerComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxTimer;
      return componentReference;
    }
    case 'app-databox-progression-numeric': {
      const componentReference = viewContainerRef.createComponent(DataboxProgressionNumericComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxProgressionNumeric;
      return componentReference;
    }
    case 'app-databox-progression-icon': {
      const componentReference = viewContainerRef.createComponent(DataboxProgressionIconComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxProgressionIcon;
      return componentReference;
    }
    case 'app-databox-lives-icon': {
      const componentReference = viewContainerRef.createComponent(DataboxLivesIconComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxLivesIcon;
      return componentReference;
    }
    case 'app-databox-attempts-icon': {
      const componentReference = viewContainerRef.createComponent(DataboxAttemptsIconComponent);
      const component = componentReference.instance;
      component.config = config as IDataboxAttemptsIcon;
      return componentReference;
    }
  }
};
