import { Injectable } from '@angular/core';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { TextRichStore } from './text-rich.store';

/**
 * The Text Rich Service is responsible for managing the state of the {@link TextRichComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class TextRichService {
  /**
   * Constructor
   */
  constructor(private readonly store: TextRichStore) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: ITextRich) {
    this.store.applyConfiguration(configuration);
  }
}
