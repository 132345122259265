import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IDataboxAttemptsIcon } from 'src/app/api/modules/core/dynamic/databoxes/attempts-icon/IDataboxAttemptsIcon';
import { GenerateFaIcon } from 'src/app/factories/generators/icon-fa.generator';
import { GenerateIconState } from 'src/app/factories/generators/icon-state.generator';
import { DataboxAttemptsIconModel, DataboxAttemptsIconStateModel } from './databox-attempts-icon.model';

/**
 * A Built in Default State
 */
const DEFAULT_STATE = GenerateFaIcon({
  name: 'far:circle',
  state: 'default',
});

/**
 * A Built in Positive State
 */
const POSITIVE_STATE = GenerateFaIcon({
  name: 'fas:circle-check',
  state: 'positive',
});

/**
 * A Built in Negative State
 */
const NEGATIVE_STATE = GenerateFaIcon({
  name: 'fas:circle-xmark',
  state: 'negative',
});

/**
 * The default final state which will be duplicated
 */
const defaultState = GenerateIconState({
  states: [DEFAULT_STATE, POSITIVE_STATE, NEGATIVE_STATE],
});

export function createInitialState(): DataboxAttemptsIconModel {
  return {
    selector: 'app-databox-attempts-icon',
    state: defaultState,
    states: [],
  };
}
/**
 * Manages the State of a {@link DataboxAttemptsIconComponent}.
 */
@Injectable()
export class DataboxAttemptsIconStore extends Store<DataboxAttemptsIconModel> {
  /**
   * Constructor
   */
  constructor(private readonly logger: LoggerService) {
    super(createInitialState(), {
      producerFn: produce,
      name: `databox-attempts-icon-${guid()}`,
    });
  }

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxAttemptsIcon>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // state
    let state = defaultState;
    if (configuration.state?.states) {
      let hasDefault = true;

      // ensure one of the states is default.
      for (let i = 0; i < configuration.state.states.length; i++) {
        const targetState = configuration.state.states[i];
        if (targetState.state === 'default') {
          hasDefault = true;
        }
      }

      // if there is no default then that is a problem.
      if (!hasDefault) {
        this.logger.warn(
          `[DataboxAttemptsIcon] applyInitialize - you must supply a state with the name of "default" to one of your icons.`
        );
      }

      // if there is only a single state then that is useless
      if (configuration.state.states.length === 1) {
        this.logger.warn(`[DataboxAttemptsIcon] applyInitialize - you only have provided one state... kind of useless`);
      }

      if (hasDefault) {
        state = cloneDeep(configuration.state);
      }
    }

    // starting states
    const states: DataboxAttemptsIconStateModel[] = [];
    if (configuration.startingState) {
      for (let i = 0; i < configuration.startingState.length; i++) {
        const targetState = configuration.startingState[i];
        states.push({
          ...cloneDeep(state),
          stateName: targetState,
        });
      }
    }

    // update the store
    this.update((draft) => {
      draft.state = state;
      draft.states = states;
    });
  }

  /**
   * Updates the component with the users attempts.
   *
   * @param stateList - The new state.
   */
  applyUpdate(stateList: string[]): void {
    // get the configured data
    const { state, states } = this.getValue();

    console.log(states);

    // if there is no state list then that is an issue.
    if (!stateList) {
      return;
    }

    // if there is an update total then the state is being regenerated
    let newStates: DataboxAttemptsIconStateModel[];

    // if the incoming state list is the same length as the configured states then we can just update the states
    if (states.length === stateList.length) {
      // then states can be cloned
      newStates = cloneDeep(states);
    } else {
      // otherwise states need recreated
      newStates = [];
      for (let i = 0; i < stateList.length; i++) {
        const targetState = stateList[i];
        newStates.push({
          ...cloneDeep(state),
          stateName: targetState,
        });
      }
    }

    // finally the states can be updated according to the incoming stateList
    for (let i = 0; i < stateList.length; i++) {
      const targetState = newStates[i];
      const targetStateName = stateList[i];
      targetState.stateName = targetStateName;
    }

    // the store can be updated
    this.update((draft) => {
      draft.states = newStates;
    });
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    const { states } = this.getValue();

    const newStates = cloneDeep(states);

    for (let i = 0; i < newStates.length; i++) {
      newStates[i].stateName = 'default';
    }

    this.update((draft) => {
      draft.states = newStates;
    });
  }
}
