import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-dev-panel',
  templateUrl: './dev-panel.component.html',
  styleUrls: ['./dev-panel.component.scss'],
})
/**
 * The Dev Panel is just a throw away test component utility.
 */
export class DevPanelComponent {
  private isOpenSource = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSource.asObservable();

  protected darkModeToggleButton: IButton = {
    selector: 'app-button',
    type: 'button',
    style: 'mat-mini-fab',
    color: 'primary',
    icon: {
      color: 'accent',
      name: 'fas:circle-half-stroke',
    },
  };

  protected panelButton: IButton = {
    selector: 'app-button',
    type: 'button',
    style: 'mat-mini-fab',
    color: 'primary',
    icon: {
      color: 'accent',
      name: 'fas:code',
    },
  };

  constructor(private readonly themeService: ThemeService) {}

  togglePanel() {
    this.isOpenSource.next(!this.isOpenSource.value);
  }

  protected onDevToggleDarkMode() {
    this.themeService.debugToolToggleDarkModel();
  }
}
