<!-- If a configuration exists-->
<div *ngIf="dialogContentQuery.configured$ | async">
  <!-- Set the title -->
  <ng-container *ngIf="dialogContentQuery.header$ | async as header">
    <h2 mat-dialog-title *ngIf="header">{{ header }}</h2>
  </ng-container>

  <!-- Set the content -->
  <ng-container *ngIf="dialogContentQuery.content$ | async as content">
    <mat-dialog-content *ngIf="content">
      <!-- The content is an app container similar to a screen -->
      <app-container [config]="content"></app-container>
    </mat-dialog-content>
  </ng-container>

  <!-- Set the Actions -->
  <ng-container *ngIf="dialogContentQuery.footer$ | async as footer">
    <mat-dialog-actions
      *ngIf="footer"
      [fxLayout]="footer.layout || 'row-reverse'"
      [fxLayoutAlign]="footer.layoutAlign || 'space-between center'"
    >
      <!-- The Positive Button -->
      <app-button *ngIf="footer.positiveButton" [config]="footer.positiveButton" (click)="onPositiveClick($event)"> </app-button>

      <!-- The Negative Button -->
      <app-button *ngIf="footer.negativeButton" [config]="footer.negativeButton" (click)="onNegativeClick($event)"> </app-button>
    </mat-dialog-actions>
  </ng-container>
</div>
