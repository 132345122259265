import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { PluginTextModel } from './plugin-text.model';

/**
 * Creates the initial state for the PluginText with default settings.
 *
 * @returns A PluginTextModel with default properties.
 */
function createInitialState(): PluginTextModel {
  return {
    entities: {},
  };
}

/**
 * The `PluginTextStore` manages the state of a text entity within a plugin.
 */
@Injectable()
export class PluginTextStore extends Store<PluginTextModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `plugin-text-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Adds a new entity to the store or updates an existing one.
   * @param id The unique identifier for the entity.
   * @param config The configuration object for the entity, conforming to IPluginTextEntity.
   */
  add(id: string, config: IPluginTextEntity) {
    if (!id || !config) {
      return;
    }

    // Update the store with the new entities object
    this.update((draft) => {
      draft.entities[id] = config;
    });
  }

  /**
   * Removes an entity from the store.
   * @param id The unique identifier for the entity to be removed.
   */
  remove(id?: string) {
    if (!id) {
      return;
    }

    // Update the store with the new entities object, reflecting the removal
    this.update((draft) => {
      delete draft.entities[id];
    });
  }

  /**
   * Clears all entities from the store.
   */
  clear() {
    // Update the store with an empty entities object
    this.update((draft) => {
      draft.entities = {};
    });
  }
}
