import { ViewContainerRef } from '@angular/core';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ButtonComponent } from './button.component';

/**
 * Dynamically creates a `ButtonComponent` instance
 * @param viewContainerRef the container into which we want to inject the component
 * @param config the configuration required to construct a validcomponent
 */
export const createButtonComponent = (viewContainerRef: ViewContainerRef, config: IButton) => {
  const componentReference = viewContainerRef.createComponent(ButtonComponent);
  const component = componentReference.instance;
  component.config = config;
  return componentReference;
};
