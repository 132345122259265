import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FooterModel } from './footer.model';
import { FooterStore } from './footer.store';

/**
 * Used to probe the {@link FooterStore}.
 */
@Injectable({
  providedIn: 'root',
})
export class FooterQuery extends Query<FooterModel> {
  /**
   * Does the footer exist? (has it been configured?).
   */
  exists$ = this.select((state) => state.exists);

  /**
   * What layout class should be used?
   */
  appLayoutClass$ = this.select((state) => state.appLayoutClass);

  /**
   * The Footer Sharing Object
   */
  sharing$ = this.select((state) => state.configure?.sharing);

  /**
   * The Footer Author Object
   */
  author$ = this.select((state) => state.configure?.author);

  /**
   * The Footer Branding Object
   */
  branding$ = this.select((state) => state.configure?.branding);

  /**
   * The Footer Terms and Conditions Object.
   */
  termsConditions$ = this.select((state) => state.configure?.termsConditions);

  /**
   * Is the Footer Hidden?
   */
  isFooterHidden$ = this.select((state) => state.hidden).pipe(
    switchMap((hidden) => {
      if (hidden) {
        if (hidden === true) {
          return of(true);
        } else {
          return of(false);
        }
      } else {
        return of(false);
      }
    })
  );

  /**
   * Is the Author Hidden?
   */
  isAuthorHidden$ = this.select((state) => state.hidden).pipe(
    switchMap((hidden) => {
      if (hidden) {
        if (hidden === true) {
          return of(true);
        } else {
          if (hidden.author === true) {
            return of(true);
          } else {
            return of(false);
          }
        }
      } else {
        return of(false);
      }
    })
  );

  /**
   * Is the Footer Branding Hidden?
   */
  isBrandingHidden$ = this.select((state) => state.hidden).pipe(
    switchMap((hidden) => {
      if (hidden) {
        if (hidden === true) {
          return of(true);
        } else {
          if (hidden.branding === true) {
            return of(true);
          } else {
            return of(false);
          }
        }
      } else {
        return of(false);
      }
    })
  );

  /**
   * Is the Footer Sharing Hidden?
   */
  isSharingHidden$ = this.select((state) => state.hidden).pipe(
    switchMap((hidden) => {
      if (hidden) {
        if (hidden === true) {
          return of(true);
        } else {
          if (hidden.sharing === true) {
            return of(true);
          } else {
            return of(false);
          }
        }
      } else {
        return of(false);
      }
    })
  );

  /**
   * Is the Footer Terms and Conditions hidden?
   */
  isTnCHidden$ = this.select((state) => state.hidden).pipe(
    switchMap((hidden) => {
      if (hidden) {
        if (hidden === true) {
          return of(true);
        } else {
          if (hidden.termsConditions === true) {
            return of(true);
          } else {
            return of(false);
          }
        }
      } else {
        return of(false);
      }
    })
  );

  /**
   * Constructor
   */
  constructor(protected override readonly store: FooterStore) {
    super(store);
  }
}
