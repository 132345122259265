import { animate, keyframes, style, transition, trigger } from '@angular/animations';

/**
 * Used with the {@link CountDownSectionComponent} to animate the countdown.
 */
export const countdownFlipTopAnimation = trigger('countdownFlipTopAnimation', [
  transition(':enter', [
    animate(
      '0.5s ease-out',
      keyframes([
        style({ transform: 'rotateX(0deg)', offset: 0 }),
        style({ transform: 'rotateX(-90deg)', offset: 0.5 }),
        style({ transform: 'rotateX(-90deg)', offset: 1 }),
      ])
    ),
  ]),
]);

/**
 * Used with the {@link CountDownSectionComponent} to animate the countdown.
 */
export const countdownFlipBottomAnimation = trigger('countdownFlipBottomAnimation', [
  transition(':enter', [
    animate(
      '0.5s ease-out',
      keyframes([
        style({ transform: 'rotateX(90deg)', offset: 0 }),
        style({ transform: 'rotateX(90deg)', offset: 0.5 }),
        style({ transform: 'rotateX(0deg)', offset: 1 }),
      ])
    ),
  ]),
]);
