import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DialogContentModel } from './dialog-content.model';
import { DialogContentStore } from './dialog-content.store';

/**
 * The Query used for a {@link DialogContentComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogContentQuery extends Query<DialogContentModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.select((state) => state.configured);

  /**
   * The Header Configuration.
   */
  header$ = this.select((state) => state.header);

  /**
   * The Content Configuration.
   */
  content$ = this.select((state) => state.content);

  /**
   * The Footer Configuration.
   */
  footer$ = this.select((state) => state.footer);

  /**
   * Constructor.
   */
  constructor(protected override readonly store: DialogContentStore) {
    super(store);
  }
}
