/* eslint-disable @angular-eslint/component-selector */
import { animate, AnimationBuilder, AnimationPlayer, sequence, style } from '@angular/animations';
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ILeaderboardEntry } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardEntry';

@Component({
  selector: 'tr[app-leaderboard-entry]',
  templateUrl: './leaderboard-entry.component.html',
  styleUrls: ['./leaderboard-entry.component.scss'],
  animations: [],
})
export class LeaderboardEntryComponent implements OnChanges, OnDestroy {
  /**
   * The Config as provided to the element
   */
  @Input() config?: ILeaderboardEntry;

  /**
   * When data changes within an entry, it will flash the following color
   */
  @Input() changeHighlightColor?: string;

  // To avoid flashing new entries, this will turn true after entering the DOM
  private hasInit = false;

  // holds the animation player
  private highlightPlayer: AnimationPlayer;

  /**
   * Constructor
   */
  constructor(private readonly animationBuilder: AnimationBuilder, private readonly elementRef: ElementRef) {}

  /**
   * Lifecycle
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.hasInit) {
      if (changes['config'] && changes['config'].previousValue) {
        const currentConfig: ILeaderboardEntry = changes['config'].currentValue;

        let hasImportantChange = false;

        if (!currentConfig.belongsToUser) {
          if (currentConfig.changed) {
            hasImportantChange = true;
          }
        }

        if (hasImportantChange) {
          this.doHighlightAnimation();
        }
      }
    } else {
      this.hasInit = true;
    }
  }

  private doHighlightAnimation() {
    // create the new animation player from animation builder
    const statsAnimation = this.animationBuilder.build([
      sequence([
        animate(
          `250ms ease-out`,
          style({
            backgroundColor: `${this.changeHighlightColor}`,
          })
        ),
        animate(
          `250ms ease-out`,
          style({
            backgroundColor: `*`,
          })
        ),
      ]),
    ]);
    this.highlightPlayer = statsAnimation.create(this.elementRef.nativeElement);
    this.highlightPlayer.play();
  }

  /**
   * Trackby avoids repainting the whole list for any change.
   *
   * @param index - the index of the item in the list.
   * @param item - the item itself containing an id.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  trackBy(index: number, item?: any) {
    return index;
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    if (this.highlightPlayer) {
      this.highlightPlayer.destroy();
    }
  }
}
