import { animate, query, style, transition, trigger } from '@angular/animations';

// the route only cares about entering components
// export const routeAnimations = trigger('routeAnimations', [
//   transition('* <=> *', [
//     query(':enter', [style({ transform: 'translateY(50%)', opacity: 0 })], {
//       optional: true,
//     }),
//     query(':leave', animateChild(), {
//       optional: true,
//     }),
//     group([
//       query(':leave', [animate('500ms ease-out', style({ transform: 'translateY(-50%)', opacity: 0 }))], {
//         optional: true,
//       }),
//       query(':enter', [animate('500ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))], {
//         optional: true,
//       }),
//       query('@*', animateChild(), {
//         optional: true,
//       }),
//     ]),
//   ]),
// ]);

// the route only cares about entering components
export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ transform: 'translateX(25%)', opacity: 0 }),
        animate('500ms 500ms cubic-bezier(0.645, 0.045, 0.355, 1)', style({ transform: 'translateX(0)', opacity: 1 })),
      ],
      {
        optional: true,
      }
    ),
  ]),
]);
