<!-- If the component is configured -->
<div *ngIf="countdownQuery.configured$ | async" class="countdown" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
  <!-- days (optional) -->
  <ng-container *ngIf="countdownQuery.days$ | async as days">
    <app-countdown-section *ngIf="days.enabled" [config]="days"></app-countdown-section>
  </ng-container>

  <!-- hours (optional) -->
  <ng-container *ngIf="countdownQuery.hours$ | async as hours">
    <app-countdown-section *ngIf="hours.enabled" [config]="hours"></app-countdown-section>
  </ng-container>

  <!-- minutes (optional) -->
  <ng-container *ngIf="countdownQuery.minutes$ | async as minutes">
    <app-countdown-section *ngIf="minutes.enabled" [config]="minutes"></app-countdown-section>
  </ng-container>

  <!-- seconds (required) -->
  <ng-container *ngIf="countdownQuery.seconds$ | async as seconds">
    <app-countdown-section [config]="seconds"></app-countdown-section>
  </ng-container>
</div>
