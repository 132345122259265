import { Component, Input } from '@angular/core';
import { IFooterAuthor } from 'src/app/api/modules/core/components/static/IFooterAuthor';

@Component({
  selector: 'app-footer-author',
  templateUrl: './footer-author.component.html',
  styleUrls: ['./footer-author.component.scss']
})
/**
 * The Footer Author component exists within a Footer.
 * It's purpose is to display who made the app. "Made by Clark" with an image for example.
 */
export class FooterAuthorComponent {
  /**
   * The Configuration required for this component to function
   */
  @Input()
  config: IFooterAuthor;
}
