import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DigitaServiceError } from '@digitaservice/utils';
import { produce } from 'immer';
import { IShellLayout } from 'src/app/api/modules/core/components/abstract/IShellLayout';
import { DEFAULT_SHELL_LAYOUT } from 'src/app/app-constants';
import { AppModel } from './application.model';

/**
 * Creates the initial state of this store.
 */
function createInitialState(): AppModel {
  return {
    viewportWidth: 0,
    viewportHeight: 0,
    preventWindowUnloadDialog: false,
    isInWidget: false,
    widgetType: null,
    hasBeenDeclaredComplete: false,
    hasBeenDeclaredReady: false,
    hasFatalError: false,
    hasFirstInteraction: false,
    hasInitialized: false,
    hasBeenDeclaredClosed: false,
    fatalErrorData: undefined,
    amp: false,
    layout: DEFAULT_SHELL_LAYOUT,
    loadingIndicator: true,
    orientation: 'landscape',
    disableOutletGrow: false,
  };
}

/**
 * The App Store contains high level information.
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // SYSTEM
  ////////////////////////////////////////////////////////////////////

  applySystemConfiguration(amp: boolean, preventWindowUnloadDialog: boolean) {
    this.update((draft) => {
      draft.amp = amp;
      draft.preventWindowUnloadDialog = preventWindowUnloadDialog;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // WIDGET
  ////////////////////////////////////////////////////////////////////

  applyWidgetConfiguration(isInWidget: boolean, widgetType: 'fixed' | 'auto' | null) {
    this.update((draft) => {
      draft.isInWidget = isInWidget;
      draft.widgetType = widgetType;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////

  applyFirstInteraction() {
    this.update((draft) => {
      draft.hasFirstInteraction = true;
    });
  }

  applyReady() {
    this.update((draft) => {
      draft.hasBeenDeclaredReady = true;
    });
  }

  applyComplete() {
    this.update((draft) => {
      draft.hasBeenDeclaredComplete = true;
    });
  }

  applyClose() {
    this.update((draft) => {
      draft.hasBeenDeclaredClosed = true;
    });
  }

  applyInitialized() {
    this.update((draft) => {
      draft.hasInitialized = true;
    });
  }

  applyError(fatalErrorData: DigitaServiceError) {
    this.update((draft) => {
      draft.hasFatalError = true;
      draft.fatalErrorData = fatalErrorData;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // VIEWPORT RESIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Store the Viewport Size.
   *
   * @param width - the width of the viewport
   * @param height - the height of the viewport
   */
  applyViewportResize(width: number, height: number) {
    this.update((draft) => {
      draft.viewportWidth = width;
      draft.viewportHeight = height;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // ORIENTATION
  ////////////////////////////////////////////////////////////////////

  /**
   * The Applications Orientiation.
   *
   * @param orientation - the orientiation
   */
  applyOrientation(orientation: 'landscape' | 'portrait') {
    this.update((draft) => {
      draft.orientation = orientation;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // SHELL LOADER
  ////////////////////////////////////////////////////////////////////

  /**
   * Show the Shell Loader or not.
   *
   * @param active - show the loader if true or hide if false
   */
  applyShellLoader(active: boolean) {
    this.update((draft) => {
      draft.loadingIndicator = active;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // SHELL LAYOUT
  ////////////////////////////////////////////////////////////////////

  /**
   * Set the layout of the application.
   *
   * @param layout - the shell layout
   * @param disableOutletGrow - disable the outlet grow
   */
  applyShellLayout(layout: Required<IShellLayout>, disableOutletGrow = false) {
    this.update((draft) => {
      draft.layout = layout;
      draft.disableOutletGrow = disableOutletGrow;
    });
  }
}
