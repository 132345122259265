<!-- Show the loading indicator -->
<div class="loading-container" *ngIf="leaderboardQuery.showLoadingIndicator$ | async" fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
</div>

<!-- Show the actual leaderboard -->
<div class="mat-mdc-table-scroller" *ngIf="leaderboardQuery.showLeaderboard$ | async">
  <!-- The title is optional-->
  <app-text-rich *ngIf="leaderboardQuery.hasTitle$ | async" [config]="leaderboardQuery.title$ | async"></app-text-rich>

  <!-- Adds Material Select for optional Filters -->
  <form [formGroup]="form" *ngIf="form" fxLayout.xs="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between center">
    <mat-form-field *ngFor="let filter of leaderboardQuery.filters$ | async" class="field">
      <mat-label *ngIf="filter.label">{{ filter.label }}</mat-label>
      <mat-select [formControlName]="filter.key" [compareWith]="compareWithFn">
        <mat-option *ngFor="let option of filter.options" [value]="option.value">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <!-- The leaderboard itself -->
  <table class="mat-mdc-table">
    <thead>
      <tr>
        <th *ngFor="let item of leaderboardQuery.headers$ | async">
          <div class="cell-flex">
            <app-fa-icon *ngIf="item?.icon" [config]="item.icon"></app-fa-icon>
            <span *ngIf="item?.text" class="mat-body-2"> {{ item.text }}</span>
          </div>
        </th>
      </tr>
    </thead>

    <tbody
      [@leaderboardEntryLifecycleAnimation]="leaderboardQuery.entries$ | async"
      (@leaderboardEntryLifecycleAnimation.start)="startedAnimation()"
      (@leaderboardEntryLifecycleAnimation.done)="endedAnimation()"
    >
      <tr
        app-leaderboard-entry
        *ngFor="let item of leaderboardQuery.entries$ | async; trackBy: trackBy"
        [@leaderboardEntryBelongsToAnimation]="{
          value: item.belongsToUser,
          params: {
            backgroundColor: (leaderboardQuery.belongsToUserColor$ | async) || 'rgba(0, 0, 255, 0.5)'
          }
        }"
        [changeHighlightColor]="(leaderboardQuery.changeHighlightColor$ | async) || 'rgba(255, 255, 255, 0.5)'"
        [config]="item"
      ></tr>
    </tbody>
  </table>
</div>
