import { IFrameEventCreateSnackbarData } from '@digitaservice/utils';
import { GenerateSnackbar } from '../generators/snackbar.generator';

/**
 * The Inner IFrame Page of a Plugin may emit a FrameSnackbarEvent,
 * that is a simpler API which is then converted into a real dialog
 *
 * @param incoming - the data dispatched from the inner plugin iframe page.
 */
export const ConvertPluginToSnackbar = (incoming: IFrameEventCreateSnackbarData) => {
  return GenerateSnackbar({
    message: incoming.text,
  });
};
