import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { EMPTY, of, switchMap } from 'rxjs';
import { ButtonModel } from './button.model';
import { ButtonStore } from './button.store';

/**
 * Qureries the State of an instance of {@link ButtonStore}.
 */
@Injectable()
export class ButtonQuery extends Query<ButtonModel> {
  /**
   * Constructor
   */
  constructor(protected override readonly store: ButtonStore) {
    super(store);
  }

  /**
   * Does the Button have a label?
   */
  hasLabel$ = this.select((state) => state.hasLabel);

  /**
   * If `hasLabel` then what is the configuration of the label?
   */
  label$ = this.select((state) => state.label);

  /**
   * What is the type of the button?
   */
  type$ = this.select((state) => state.type);

  /**
   * What is the style of the button?
   */
  style$ = this.select((state) => state.style);

  /**
   * What is the color of the button?
   */
  color$ = this.select((state) => state.color);

  /**
   * Does the Button have an icon?
   */
  private _hasIcon$ = this.select((state) => state.hasIcon);

  /**
   * If `hasIcon` what is the configuration of that icon?
   */
  private _icon$ = this.select((state) => state.icon);

  /**
   * Does the Button have a left aligned icon?
   */
  hasLeftAlignedIcon$ = combineQueries([this._hasIcon$, this._icon$]).pipe(
    switchMap(([hasIcon, icon]) => {
      if (hasIcon && icon.align !== 'right') {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Does the Button have a right aligned icon?
   */
  hasRightAlignedIcon$ = combineQueries([this._hasIcon$, this._icon$]).pipe(
    switchMap(([hasIcon, icon]) => {
      if (hasIcon && icon.align === 'right') {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Has an Icon Color been defined?
   */
  iconColor$ = combineQueries([this._hasIcon$, this._icon$]).pipe(
    switchMap(([hasIcon, icon]) => {
      if (hasIcon && icon.color) {
        return of(icon.color);
      } else {
        return EMPTY;
      }
    })
  );

  /**
   * Has an Icon Name been defined?
   */
  iconName$ = combineQueries([this._hasIcon$, this._icon$]).pipe(
    switchMap(([hasIcon, icon]) => {
      if (hasIcon && icon.name) {
        return of(icon.name);
      } else {
        return EMPTY;
      }
    })
  );

  /**
   * If `hasLink` what is the data for that link?
   */
  link$ = this.select((state) => state.link);

  /**
   * Does the button contain a clickToClose feature?
   */
  private _hasClickToCloseFeature$ = this.select((state) => state.hasClickToCloseFeature);

  /**
   * Does the button contain a print feature?
   */
  private _hasPrintFeature$ = this.select((state) => state.hasPrintFeature);

  /**
   * Does the button contain a copy to clipboard feature?
   */
  private _hasCopyToClipboardFeature$ = this.select((state) => state.hasCopyToClipboardFeature);

  /**
   * If `hasCopyToClipboardFeature` then what is the configure to use?
   */
  copyToClipboard$ = this.select((state) => state.copyToClipboard);

  /**
   * If any feature is true then this will be true otherwise false.
   */
  private hasFeature$ = combineQueries([this._hasClickToCloseFeature$, this._hasCopyToClipboardFeature$, this._hasPrintFeature$]).pipe(
    switchMap(([hasClickToCloseFeature, hasCopyToClipboardFeature, hasPrintFeature]) => {
      if (hasClickToCloseFeature || hasCopyToClipboardFeature || hasPrintFeature) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Is the Button Textured?
   */
  isTextured$ = this.select((state) => state.isTextured);

  /**
   * Does the Button contain a Dialog?
   */
  private hasDialog$ = this.select((state) => state.hasDialog);

  /**
   * If `hasDialog` then what is the configured dialog data?
   */
  dialog$ = this.select((state) => state.dialog);

  /**
   * Is the Button Disabled?
   */
  disabled$ = this.select((state) => state.disabled);

  /**
   * Does the button belong to a form?
   */
  belongsToForm$ = this.select((state) => state.belongsToForm);

  /**
   * If `belongsToForm` then what is the name of the form?
   */
  form$ = this.select((state) => state.form);

  /**
   * Is the Button Extended? (applies to mat-fab buttons only)
   */
  extended$ = this.select((state) => state.extended);

  /**
   * The tabindex of the Button. (optional)
   */
  tabIndex$ = this.select((state) => state.tabIndex);

  /**
   * The ClickAction of the Button gathers information about the configuration in order to
   * determine what should happen when the button is clicked.
   */
  clickAction$ = combineQueries([
    this.type$,
    this.hasFeature$,
    this.hasDialog$,
    this.disabled$,
    this._hasClickToCloseFeature$,
    this._hasCopyToClipboardFeature$,
    this._hasPrintFeature$,
  ]).pipe(
    switchMap(([type, hasFeature, hasDialog, isDisabled, hasClickToCloseFeature, hasCopyToClipboardFeature, hasPrintFeature]) => {
      return of({
        type,
        hasFeature,
        hasDialog,
        isDisabled,
        hasClickToCloseFeature,
        hasCopyToClipboardFeature,
        hasPrintFeature,
      });
    })
  );
}
