import { DEFAULT_SHELL_LAYOUT } from 'src/app/app-constants';
import { IShell } from '../abstract/IShell';
import { IShellEffects } from '../effects/IShellEffects';

/**
 * Screen's may configure the outer application shell as part of
 * each Responses `environment` property.
 */
export interface IShellSettings extends IShell {
  /**
   * By default, the application will scroll to the top each time you navigate to a new Screen Route.
   *
   * To prevent this, you must set this to true.
   *
   * The default is `false`
   */
  disableScrollToTop?: boolean;

  /**
   * You can pass an array of effects to be applied.
   */
  effects?: IShellEffects;
}

/**
 * The default state patched each route
 */
export const ShellSettingsDefaults: IShellSettings = {
  disableScrollToTop: false,
  disableOutletGrow: false,
  effects: undefined,
  layout: DEFAULT_SHELL_LAYOUT,
};
