import { IIconState } from 'src/app/api/modules/icons/IIconState';

/**
 * Generates an Icon JSON Object
 *
 * @param config - the partial configuration
 */
export const GenerateIconState = (config: Partial<IIconState> = {}): IIconState => {
  const defaults: IIconState = {
    states: [],
  };

  return {
    ...defaults,
    ...config,
  };
};
