import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';

/**
 * Generates a JSON Object that can be used to construct a new DialogContent Component
 *
 * This is used primarily within Plugins to convert a IFrame Message into an Angular
 * Component.
 *
 * @param config - The Partial Configuration for a DialogContent
 */
export const GenerateDialogContent = (config: Partial<IDialogContent> = {}): IDialogContent => {
  const defaults: IDialogContent = {
    selector: 'app-dialog-content',
    header: undefined,
    content: undefined,
    footer: undefined,
  };

  return {
    ...defaults,
    ...config,
  };
};
