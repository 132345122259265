import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { RELOAD_PAGE, SOCIAL_SHARING_FEATURES } from 'src/app/app-constants';
import { environment } from 'src/environments/environment';

/**
 * The Link Service processes DigitaService ILink Objects.
 *
 * It is intended to be used by the Window Service.
 */
@Injectable({
  providedIn: 'root',
})
export class LinkService {
  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService, private readonly router: Router) {}

  /**
   * Open a DigitaService ILink Object.
   *
   * @param link the ILink Data containing the descriptor of the link
   */
  openLink(link: ILink): void {
    this.loggerService.log('[LinkService]::openLink', link);
    if (link && link.href) {
      // at this point we know we have a link but don't yet know how to handle it
      switch (link.type) {
        // we will assume if nothing is provided or if the value is wrong, that it is `internal`
        default:
          this.linkRouter(link);
          break;

        // if the link is defined as `external-self` we will directly use the window
        case 'external-self':
          this.linkWindowSelf(link);
          break;

        // if the link is defined as `external-blank` we will open tab
        case 'external-blank':
          this.linkWindowBlank(link);
          break;

        // if the link is defined as `external-parent` we will open the link in the parent
        case 'external-parent':
          this.linkWindowParent(link);
          break;

        // if the link is defined as `external-top` we will open the link in the parent
        case 'external-top':
          this.linkWindowTop(link);
          break;

        // if the link is defined as 'social' then it needs significant processing
        case 'social':
          this.linkSocial(link);
          break;
      }
    } else {
      this.loggerService.warn('[LinkService] openLink - No link supplied');
    }
  }

  /**
   * Navigates with the internal Angular Router
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkRouter(link: ILink): void {
    this.loggerService.log('[LinkService]::linkRouter', link);

    const navigationExtras: NavigationExtras = {
      // prevents URL / Address Bar / Location updates
      skipLocationChange: environment.skipLocationChange,

      // enforce that we do not support arrays in query parameters.  Identical params will be merged.
      queryParamsHandling: 'merge',
    };

    // navigate to the path
    this.router.navigateByUrl(link.href, navigationExtras);
  }

  /**
   * Navigates with the window.location property.
   *
   * You can use `reload' as a URL which will refresh the page.
   *
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkWindowSelf(link: ILink): void {
    this.loggerService.log('[LinkService]::linkWindowSelf', link);
    if (link.href === RELOAD_PAGE) {
      this.refresh();
    } else {
      window.location.href = link.href;
    }
  }

  /**
   *  Navigates with window.open using '_blank'
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkWindowBlank(link: ILink): void {
    this.loggerService.log('[LinkService]::linkWindowBlank', link);
    window.open(link.href, '_blank');
  }

  /**
   * Navigates with window.open using '_parent'
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkWindowParent(link: ILink): void {
    this.loggerService.log('[LinkService]::linkWindowParent', link);
    window.open(link.href, '_parent');
  }

  /**
   * Navigates with window.open using `_top`
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkWindowTop(link: ILink): void {
    this.loggerService.log('[LinkService]::linkWindowTop', link);
    window.open(link.href, '_top');
  }

  /**
   * Navigates with the Social Sharer in a featured window
   * @param link the ILink data descriptor containing the absolute URL href property
   */
  private linkSocial(link: ILink): void {
    // always needed
    const localScreenWidth = window.screen.width;
    const localScreenHeight = window.screen.height;

    // the feature settings are the constants by default.
    let width = SOCIAL_SHARING_FEATURES.WIDTH;
    let height = SOCIAL_SHARING_FEATURES.HEIGHT;

    // if the width is bigger than the screen, the new width is the screen width
    if (width > localScreenWidth) {
      width = localScreenWidth;
    }

    // if the height is bigger than screen, the new height is the screen height
    if (height > localScreenHeight) {
      height = localScreenHeight;
    }

    // if the height is less than constant then they new height is dynamic at 80% height;
    if (height < SOCIAL_SHARING_FEATURES.HEIGHT) {
      height = Math.floor(localScreenHeight * 0.8);
    }

    // we use the final size to get the top and left positions
    const left = Math.floor(window.screen.width / 2 - width / 2);
    const top = Math.floor(window.screen.height / 2 - height / 2);

    // set a feature array of properties
    const stringPropertiesArray: string[] = [];
    stringPropertiesArray.push(`width=${width}`);
    stringPropertiesArray.push(`height=${height}`);
    stringPropertiesArray.push(`left=${left}`);
    stringPropertiesArray.push(`top=${top}`);

    // construct a single string from the features settings
    let featureString = '';
    stringPropertiesArray.forEach((value) => {
      featureString += value + ',';
    });
    featureString = featureString.slice(0, -1); // remove the last `,`

    // finally open a new window
    window.open(link.href, '_blank', featureString);
  }

  /**
   * Refreshes the whole Application
   */
  refresh() {
    window.location.reload();
  }
}
