<!-- If a configuration exists-->
<div *ngIf="dialogMediaLockQuery.configured$ | async">
  <!-- The ng-container is not rendered but structural only to hold the async pipe -->
  <ng-container *ngIf="dialogMediaLockQuery.header$ | async as header">
    <h2 mat-dialog-title *ngIf="header">{{ header }}</h2>
  </ng-container>

  <!-- Set the content -->
  <mat-dialog-content>
    <!-- The ng-container is not rendered but structural only to hold the async pipe -->
    <ng-container *ngIf="dialogMediaLockQuery.lock$ | async as lock">
      <app-media-lockable
        *ngIf="lock"
        class="lock"
        [config]="lock"
        (unlocked)="mediaLockUnlocked()"
        (error)="mediaLockError()"
      ></app-media-lockable>
    </ng-container>
  </mat-dialog-content>

  <!-- Set the Actions -->
  <ng-container *ngIf="dialogMediaLockQuery.footer$ | async as footer">
    <mat-dialog-actions [fxLayout]="footer.layout || 'row'" [fxLayoutAlign]="footer.layoutAlign || 'center center'">
      <!-- The ng-container is not rendered but structural only to hold the async pipe -->
      <ng-container *ngIf="dialogMediaLockQuery.button$ | async as button">
        <app-button
          *ngIf="button"
          class="ui-button"
          @dialogMediaLockButtonAnimation
          [config]="button"
          (click)="onNegativeClick()"
        ></app-button>
      </ng-container>
    </mat-dialog-actions>
  </ng-container>
</div>
