import { ContainerFactoryArray, ContainerFactoryItem } from '../../containers/container/container.factory';

/**
 * Re-exports `ContainerFactoryArray` as `ScreenFactoryArray`.
 * It serves as a factory function to create and render an array of screen components
 * based on a given configuration. This function is typically used to dynamically
 * generate a set of components that constitute a complete screen.
 */
export const ScreenFactoryArray = ContainerFactoryArray;

/**
 * Re-exports `ContainerFactoryItem` as `ScreenFactoryItem`.
 * This function is used for creating and rendering individual screen components
 * from a single configuration item. It's useful when needing to dynamically
 * add or configure components on a screen one at a time.
 */
export const ScreenFactoryItem = ContainerFactoryItem;
