import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DigitaServiceInputs } from 'src/app/app-global-callbacks';

export function createInitialState(): Partial<DigitaServiceInputs> {
  return {};
}

/**
 * The AppConfigStore contains a full configuration object which was collected
 * from the environment at the start of the application.
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'config' })
export class AppConfigStore extends Store<DigitaServiceInputs> {
  constructor() {
    super(createInitialState());
  }

  /**
   * Initialize the Configuration Store.  This collects all data at the start
   * of the application
   */
  initialize(configuration: Partial<DigitaServiceInputs> = {}) {
    // override the state with any provided configuration
    this.update(configuration);
  }
}
