import { Injectable } from '@angular/core';
import { Query, combineQueries } from '@datorama/akita';
import { filter, map } from 'rxjs';
import { PluginTextEntityModel } from './plugin-text-entity.model';
import { PluginTextEntityStore } from './plugin-text-entity.store';

@Injectable()
export class PluginTextEntityQuery extends Query<PluginTextEntityModel> {
  /**
   * Is the screen configured?
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  /**
   * The text to display.
   */
  private _text = this.select((state) => state.text);

  /**
   * The text to display.
   */
  text$ = combineQueries([this._configured$, this._text]).pipe(
    map(([_, text]) => {
      return text;
    })
  );

  /**
   * The x position of the text entity
   */
  private _positionX$ = this.select((state) => state.positionX);

  /**
   * The y position of the text entity
   */
  private _positionY$ = this.select((state) => state.positionY);

  /**
   * The position information
   */
  position$ = combineQueries([this._configured$, this._positionX$, this._positionY$]).pipe(
    map(([_, x, y]) => {
      return { x, y };
    })
  );

  /**
   * The type of animation.
   */
  private _type$ = this.select((state) => state.type);

  /**
   * The display duration of the text entity.
   */
  private _displayDuration$ = this.select((state) => state.displayDuration);

  /**
   * The enter animation settings.
   */
  private _enter$ = this.select((state) => state.enter);

  /**
   * The exit animation settings.
   */
  private _exit$ = this.select((state) => state.exit);

  /**
   * The animation data.
   */
  animationData$ = combineQueries([this._configured$, this._type$, this._displayDuration$, this._enter$, this._exit$]).pipe(
    map(([_, type, displayDuration, enter, exit]) => {
      return { type, displayDuration, enter, exit };
    })
  );

  /**
   * Occurs when the animation has completed
   */
  complete$ = this.select((state) => state.complete).pipe(filter((complete) => complete));

  /**
   * Constructor
   */
  constructor(protected override readonly store: PluginTextEntityStore) {
    super(store);
  }
}
