<!-- 
    When a Social Component is displaying a list of Sharing Buttons the following template is used.
-->
<div *ngIf="(type$ | async) !== 'homepage' && (buttons$ | async)" fxLayout="row wrap" fxLayoutGap="4px grid" fxLayoutAlign="center center">
  <app-social-sharing-button *ngFor="let item of buttons$ | async" [config]="item" [type]="type$ | async"></app-social-sharing-button>
</div>

<!-- 
    When a Social Component is displaying a list of Homepage Buttons the following template is used.
-->
<div
  *ngIf="(type$ | async) === 'homepage' && (buttons$ | async)"
  fxLayout="row wrap"
  fxLayoutGap="4px grid"
  fxLayoutAlign="space-between stretch"
>
  <app-social-sharing-button
    *ngFor="let item of buttons$ | async"
    [config]="item"
    [type]="type$ | async"
    fxFlex="auto"
  ></app-social-sharing-button>
</div>
