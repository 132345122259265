import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { IPlugin, IPluginHeartbeatOptions } from 'src/app/api/modules/core/dynamic/plugin/components/IPlugin';
import { DEFAULT_PLUGIN_COMPONENT_HEIGHT } from 'src/app/app-constants';
import { createDigitaServiceError } from 'src/app/app-error';
import { PluginModel } from './plugin.model';

export function createInitialState(): PluginModel {
  return {
    configured: false,
    ready: false,
    src: undefined,
    databox: undefined,
    heartbeat: undefined,
    heartbeatScore: 0,
    onReadyAPI: undefined,
    onErrorAPI: undefined,
    onEventAPI: undefined,
    onCompleteAPI: undefined,
    fullWidth: false,
    interactionEnabled: false,
    contentHeight: DEFAULT_PLUGIN_COMPONENT_HEIGHT,
    effect: {
      startTime: 0,
      duration: 0,
    },
  };
}

/**
 * The PluginStore is used for each {@link PluginComponent}.
 */
@Injectable()
export class PluginStore extends Store<PluginModel> {
  /**
   * Constructor.
   */
  constructor() {
    super(createInitialState(), {
      name: `plugin-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Initialize the store with JSON configuration.
   *
   * @param configuration - the incoming configuration json
   */
  applyConfiguration(configuration: IPlugin) {
    // if there is configration then that is a issue
    if (!configuration) {
      throw createDigitaServiceError(`Plugin`, `initialize`, `No configuration provided but this is required.`, `config`);
    }

    // source
    const src = configuration.src;
    if (!configuration.src) {
      throw createDigitaServiceError(`Plugin`, `initialize`, `No "src" property provided but this is required.`, `config`);
    }

    // databox
    let databox: IDataboxContainer | undefined = undefined;
    if (configuration.databox) {
      databox = cloneDeep(configuration.databox);
    }

    // heartbeat
    let heartbeat: IPluginHeartbeatOptions | undefined = undefined;
    if (configuration.heartbeat) {
      // the heartbeat api
      const heartbeatAPI = configuration.heartbeat.api;
      if (!heartbeatAPI || heartbeatAPI.length === 0) {
        throw createDigitaServiceError(`Plugin`, `initialize`, `The heartbeat object was set but no heartbeat.api was provided.`, `config`);
      }

      // if the heartbeat throttle time is not set or is less than 1000 then set it to 1000
      let heartbeatThrottleTimeMS = configuration.heartbeat.throttleTimeMS;
      if (isNaN(heartbeatThrottleTimeMS) || heartbeatThrottleTimeMS < 1000) {
        heartbeatThrottleTimeMS = 1000;
      }

      // set the heartbeat
      heartbeat = {
        api: heartbeatAPI,
        throttleTimeMS: heartbeatThrottleTimeMS,
      };
    }

    // on ready API
    let onReadyAPI: string | undefined = undefined;
    if (configuration.onReadyAPI) {
      onReadyAPI = configuration.onReadyAPI;
    }

    // on error API
    let onErrorAPI: string | undefined = undefined;
    if (configuration.onErrorAPI) {
      onErrorAPI = configuration.onErrorAPI;
    }

    // on event API
    let onEventAPI: string | undefined = undefined;
    if (configuration.onEventAPI) {
      onEventAPI = configuration.onEventAPI;
    }

    // on complete API
    let onCompleteAPI: string | undefined = undefined;
    if (configuration.onCompleteAPI) {
      onCompleteAPI = configuration.onCompleteAPI;
    }

    // fullwidth
    let fullWidth = false;
    if (configuration.fullWidth === true) {
      fullWidth = true;
    }

    this.update((draft) => {
      draft.configured = true;
      draft.src = src;
      draft.databox = databox;
      draft.heartbeat = heartbeat;
      draft.onReadyAPI = onReadyAPI;
      draft.onErrorAPI = onErrorAPI;
      draft.onEventAPI = onEventAPI;
      draft.onCompleteAPI = onCompleteAPI;
      draft.fullWidth = fullWidth;
    });
  }

  /**
   * Occurs when the IFrame has loaded it's contents.
   */
  applyReady() {
    this.update((draft) => {
      draft.ready = true;
    });
  }

  /**
   * The height as a number value dispatched from the plugin.
   *
   * @param height - the height numeric value
   */
  applyContentHeight(height: number) {
    this.update((draft) => {
      draft.contentHeight = Math.ceil(height);
    });
  }

  /**
   * Is the Iframe Interactive?
   *
   * @param flag - true if the iframe is interactive or false otherwise
   */
  applyFrameInteractive(flag = false) {
    this.update((draft) => {
      draft.interactionEnabled = flag;
    });
  }

  /**
   * Set the latest score obtained from the plugin. The data is optionally used within the heartbeat method.
   *
   * @param score - the latest score from the plugin.
   */
  applyHeartbeatScore(score: number) {
    this.update((draft) => {
      draft.heartbeatScore = score;
    });
  }

  /**
   * Update a delay to be used before the onCompleteAPI is called. This is applied when an Effect is used.
   *
   * @param delay - the delay in milliseconds
   */
  applyEffectDelay(delay = 0) {
    this.update((draft) => {
      draft.effect = {
        startTime: performance.now(),
        duration: delay,
      };
    });
  }
}
