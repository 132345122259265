import { IFrameEventCreateDialogImage } from '@digitaservice/utils/@types';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';

export const GenerateImage = (config: string | IFrameEventCreateDialogImage): IImage => {
  // the default image
  const defaults: IImage = {
    selector: 'app-image',
    src: undefined,
    link: undefined,
    width: 0,
    height: 0,
  };

  const unionGuard = (value: string | IFrameEventCreateDialogImage): value is IFrameEventCreateDialogImage => {
    return typeof value !== 'string';
  };

  if (unionGuard(config)) {
    return {
      ...defaults,
      ...config,
    };
  } else {
    return {
      ...defaults,
      src: config,
    };
  }
};
