import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../material/material.module';
import { SafePipesModule } from '../safe-pipes/safe-pipes.module';
import { FaIconComponent } from './components/fa-icon/fa-icon.component';
import { IconStateComponent } from './components/icon-state/icon-state.component';
import { FaIconNamePipe } from './pipes/fa-icon-name.pipe';
import { IconService } from './services/icon.service';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, SafePipesModule, MaterialModule],
  providers: [IconService],
  declarations: [FaIconComponent, IconStateComponent, FaIconNamePipe],
  exports: [FontAwesomeModule, FaIconComponent, IconStateComponent, FaIconNamePipe],
})
export class IconsModule {}
