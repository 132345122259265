import { ICoreComponent } from '../ICoreComponent';
import { IButton } from './IButton';
import { IMedia } from './IMedia';

/**
 * The type of Locked Media.
 *
 * Used with {@link IMediaLock} type property.
 */
export enum MediaLockType {
  /**
   * Time Based.
   */
  Time = 'time',
  /**
   * Percentage Based.
   */
  Percent = 'percent',
  /**
   * End of Media.
   */
  End = 'end',
}

/**
 * Describes a Locked Media Object where by the user is forced to watch a specific duration
 * of media before being able to continue.
 *
 * Note: This is not to be implemented directly but is used as a dependency of {@link IMediaLock} and {@link IMediaLockDialog}.
 *
 * Belongs to the {@link CoreModule}.
 */
export interface IMediaLockable {
  /**
   * The Media to Use.
   *
   * This is required.
   */
  media: IMedia;

  /**
   * The type of Locked Media. It can be:
   *
   *    1. "time": Used with the `target` property to force a specific value of time in seconds.
   *    2. "percentage": Used with the `target` property to force watching media for a specific percentage of playback between 0 and 100.
   *    3. "end": The user is forced to consume the entire media until the end. The target property will be ignored.
   *
   * By default this is "end".
   *
   * This is optional.
   */
  type?: MediaLockType;

  /**
   * The target value to use.
   *
   *    1. For "time" this is the number of seconds to watch.
   *    2. For "percent" this is the percentage of playback to watch between 0 and 100.
   *    3. For "end" this is ignored.
   *
   * By default this is undefined since by default `type: "end"`.
   *
   * This is required unless `type: "end"`
   */
  target?: number;

  /**
   * Should the Progress Display of a Media Lock be hidden?
   *
   * By default this is false.
   *
   * This is optional.
   */
  hideProgress?: boolean;
}

/**
 * Implements a LockedMediaComponent. This is intended to be displayed on a screen of it's own.
 *
 * See also {@link IMediaLockDialog} for using a very similar component in a dialog.
 *
 * Belongs to the {@link CoreModule}.
 */
export interface IMediaLock extends ICoreComponent {
  /**
   * The selector is required.
   */
  selector: `app-media-lock`;

  /**
   * The Media Lock Component.
   *
   * This is required.
   */
  lock: IMediaLockable;

  /**
   * The Button to Display when the Media becomes unlocked.
   *
   * This should be a `type: "link"` button containing the URL to proceed to.
   *
   * This is required.
   */
  button: IButton;
}
