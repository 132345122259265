import { cloneDeep } from 'lodash-es';
import { CountdownFormat, ICountdownLabels } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { CountdownConverted, CountdownSections } from './countdown.model';

const secondsToDHMS = (seconds: number) => {
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return { d, h, m, s } as CountdownConverted;
};

const secondsToHMS = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return { h, m, s } as CountdownConverted;
};

const secondsToMS = (seconds: number) => {
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return { m, s } as CountdownConverted;
};

const secondsToX = (seconds: number, format: CountdownFormat) => {
  switch (format) {
    case CountdownFormat.DAYS:
      return secondsToDHMS(seconds);
    case CountdownFormat.HOURS:
      return secondsToHMS(seconds);
    case CountdownFormat.MINUTES:
      return secondsToMS(seconds);
    case CountdownFormat.SECONDS:
      return { s: Math.floor(seconds) } as CountdownConverted;
  }
};

const processTimeReminaing = (startTime: number, endTime: number, initTime: number) => {
  // get the time now
  const now = Math.floor(Date.now() / 1000);

  // determine the amount of time passed between now and the initial time
  const timePassed = now - initTime;

  // add the time passed to the start time to get the current time
  const currentTime = startTime + timePassed;

  // determine the amount of time remaining between the current time and the end time
  let timeRemaining = endTime - currentTime;

  // if the time remaining is less than 0 then set it to 0
  if (timeRemaining < 0) {
    timeRemaining = 0;
  }

  // return the time remaining
  return timeRemaining;
};

export const secondsToCountdown = (
  startTime: number,
  endTime: number,
  initTime: number,
  format: CountdownFormat,
  data: CountdownSections,
  labels?: ICountdownLabels,
  firstRun = false
) => {
  const seconds = processTimeReminaing(startTime, endTime, initTime);

  const converted = secondsToX(seconds, format);

  // clone the existing data so that it can be mutated
  const newData = cloneDeep(data);
  newData.remainingSeconds = seconds;

  // during a first run, the data property is in a default state and it needs to be determined
  if (firstRun) {
    // if there are days
    if (format === CountdownFormat.DAYS) {
      // then enable the days segment
      newData.days.enabled = true;
      // set the days segment to the converted value as a number
      newData.days.prev.asNumber = newData.days.next.asNumber = converted.d;
      // set the days segment to the converted value as a string introducing padding if required so that format is 00 if less than 10
      newData.days.prev.asString = newData.days.next.asString = Array.from(converted.d.toString().padStart(2, '0'));
      // if there are labels
      if (labels) {
        // if the number of days is 1 then use the singular label
        if (converted.d === 1) {
          newData.days.label = labels.day;
        } else {
          // otherwise use the plural label
          newData.days.label = labels.days;
        }
      }
    }

    // if there are days or hours
    if (format === CountdownFormat.DAYS || format === CountdownFormat.HOURS) {
      // then enable the hours segment
      newData.hours.enabled = true;
      // set the hours segment to the converted value as a number
      newData.hours.prev.asNumber = newData.hours.next.asNumber = converted.h;
      // set the hours segment to the converted value as a string introducing padding if required so that format is 00 if less than 10
      newData.hours.prev.asString = newData.hours.next.asString = Array.from(converted.h.toString().padStart(2, '0'));
      // if there are labels
      if (labels) {
        // if the number of hours is 1 then use the singular label
        if (converted.h === 1) {
          newData.hours.label = labels.hour;
        } else {
          // otherwise use the plural label
          newData.hours.label = labels.hours;
        }
      }
    }

    // if there are days or hours or minutes
    if (format === CountdownFormat.DAYS || format === CountdownFormat.HOURS || format === CountdownFormat.MINUTES) {
      // then enable the minutes segment
      newData.minutes.enabled = true;
      // set the minutes segment to the converted value as a number
      newData.minutes.prev.asNumber = newData.minutes.next.asNumber = converted.m;
      // set the minutes segment to the converted value as a string introducing padding if required so that format is 00 if less than 10
      newData.minutes.prev.asString = newData.minutes.next.asString = Array.from(converted.m.toString().padStart(2, '0'));
      // if there are labels
      if (labels) {
        // if the number of minutes is 1 then use the singular label
        if (converted.m === 1) {
          newData.minutes.label = labels.minute;
        } else {
          // otherwise use the plural label
          newData.minutes.label = labels.minutes;
        }
      }
    }

    // if there are days or hours or minutes or seconds
    if (
      format === CountdownFormat.DAYS ||
      format === CountdownFormat.HOURS ||
      format === CountdownFormat.MINUTES ||
      format === CountdownFormat.SECONDS
    ) {
      // then enable the seconds segment
      newData.seconds.enabled = true;
      // set the seconds segment to the converted value as a number
      newData.seconds.prev.asNumber = newData.seconds.next.asNumber = converted.s;
      // set the seconds segment to the converted value as a string introducing padding if required so that format is 00 if less than 10
      newData.seconds.prev.asString = newData.seconds.next.asString = Array.from(converted.s.toString().padStart(2, '0'));
      // if there are labels
      if (labels) {
        // if the number of seconds is 1 then use the singular label
        if (converted.s === 1) {
          newData.seconds.label = labels.second;
        } else {
          // otherwise use the plural label
          newData.seconds.label = labels.seconds;
        }
      }
    }
  } else {
    // if this is not the first run

    // if days are enabled
    if (newData.days.enabled) {
      // clone the current data to the previous data
      newData.days.prev = cloneDeep(newData.days.next);
      // set the next days segment to the converted value as a number
      newData.days.next.asNumber = converted.d;
      // set the next days segment to the converted value as a string using original padding
      newData.days.next.asString = Array.from(converted.d.toString().padStart(newData.days.prev.asString.length, '0'));
      // if there are labels
      if (labels) {
        // if the number of days is 1 then use the singular label
        if (converted.d === 1) {
          newData.days.label = labels.day;
        } else {
          // otherwise use the plural label
          newData.days.label = labels.days;
        }
      }
    }

    // if hours are enabled
    if (newData.hours.enabled) {
      // clone the current data to the previous data
      newData.hours.prev = cloneDeep(newData.hours.next);
      // set the next hours segment to the converted value as a number
      newData.hours.next.asNumber = converted.h;
      // set the next hours segment to the converted value as a string using original padding
      newData.hours.next.asString = Array.from(converted.h.toString().padStart(newData.hours.prev.asString.length, '0'));
      // if there are labels
      if (labels) {
        // if the number of hours is 1 then use the singular label
        if (converted.h === 1) {
          newData.hours.label = labels.hour;
        } else {
          // otherwise use the plural label
          newData.hours.label = labels.hours;
        }
      }
    }

    // if minutes are enabled
    if (newData.minutes.enabled) {
      // clone the current data to the previous data
      newData.minutes.prev = cloneDeep(newData.minutes.next);
      // set the next minutes segment to the converted value as a number
      newData.minutes.next.asNumber = converted.m;
      // set the next minutes segment to the converted value as a string using original padding
      newData.minutes.next.asString = Array.from(converted.m.toString().padStart(newData.minutes.prev.asString.length, '0'));
      // if there are labels
      if (labels) {
        // if the number of minutes is 1 then use the singular label
        if (converted.m === 1) {
          newData.minutes.label = labels.minute;
        } else {
          // otherwise use the plural label
          newData.minutes.label = labels.minutes;
        }
      }
    }

    // if seconds are enabled
    if (newData.seconds.enabled) {
      // clone the current data to the previous data
      newData.seconds.prev = cloneDeep(newData.seconds.next);
      // set the next seconds segment to the converted value as a number
      newData.seconds.next.asNumber = converted.s;
      // set the next seconds segment to the converted value as a string using original padding
      newData.seconds.next.asString = Array.from(converted.s.toString().padStart(newData.seconds.prev.asString.length, '0'));
      // if there are labels
      if (labels) {
        // if the number of seconds is 1 then use the singular label
        if (converted.s === 1) {
          newData.seconds.label = labels.second;
        } else {
          // otherwise use the plural label
          newData.seconds.label = labels.seconds;
        }
      }
    }
  }

  return newData;
};
