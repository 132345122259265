import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ShellBackgroundModel } from './shell-background.model';
import { ShellBackgroundStore } from './shell-background.store';

/**
 * The ShellBackgroundQuery reactively updates when new background configurations come from the initialize/screen.
 */
@Injectable({
  providedIn: 'root',
})
export class ShellBackgroundQuery extends Query<ShellBackgroundModel> {
  systems$ = this.select((store) => store.systems);
  constructor(protected override readonly store: ShellBackgroundStore) {
    super(store);
  }
}
