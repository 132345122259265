import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { from, Subscription, take } from 'rxjs';
import { MediaQuery } from '../media.query';
import { MediaService } from '../media.service';

/**
 * The Media Vimeo Provider Component.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is not standalone and belongs within the {@link MediaComponent}.
 *
 * See {@link https://developer.vimeo.com/api/guides/start}
 */
@Component({
  selector: 'app-media-vimeo',
  templateUrl: './media-vimeo.component.html',
  styleUrls: ['./media-vimeo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaVimeoComponent implements AfterViewInit, OnDestroy {
  /**
   * A reference to the media HTMLDivElement.
   */
  @ViewChild('media') private mediaElement: ElementRef<HTMLDivElement>;

  /**
   * Holds a reference to the vimeo player
   */
  private player?: Vimeo.Player;

  /**
   * Holds all subscriptions for teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Holds the configuration subscription specifically.
   */
  private configurationSubscription?: Subscription;

  /**
   * Holds the duration subscription specifically.
   */
  private durationSubscription?: Subscription;

  /**
   * Holds the current time subscription specifically.
   */
  private currentTimeSubscription?: Subscription;

  /**
   * Constructor.
   *
   * @param mediaQuery - a reference to the media query.
   * @param mediaService - a reference to the media service.
   */
  constructor(public readonly mediaQuery: MediaQuery, private readonly mediaService: MediaService) {}

  /////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  /////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen out for when the youtube configuration becomes available
    this.configurationSubscription = this.mediaQuery.vimeoSettings$.pipe(take(1)).subscribe((data) => {
      this.createPlayer(data);
    });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();
    this.player?.destroy();
    this.durationSubscription?.unsubscribe();
    this.currentTimeSubscription?.unsubscribe();
  }

  /**
   * Create the player.
   *
   * @param options - the vimeo options
   */
  private createPlayer(options: Vimeo.Options) {
    this.subscriptions?.unsubscribe();
    this.subscriptions = new Subscription();

    this.player?.destroy();

    this.configurationSubscription?.unsubscribe();
    this.durationSubscription?.unsubscribe();
    this.currentTimeSubscription?.unsubscribe();

    // create the player
    this.player = new Vimeo.Player(this.mediaElement.nativeElement, options);

    // listen to events on the player
    this.player.on('loaded', () => {
      // once the player is ready we will find the total time of the video
      if (this.player) {
        this.updateDuration();
      }

      // inform the store that the video is ready
      this.mediaService.applyReady();
    });

    this.player.on('ended', () => {
      this.videoEnded();
    });

    this.player.on('play', () => {
      this.videoPlaying();
    });

    this.player.on('pause', () => {
      this.videoPaused();
    });

    this.player.on('error', (event) => {
      this.onError(event);
    });

    this.player.on('timeupdate', (event) => {
      this.updateCurrentTime(event);
    });

    // create the integration
    this.createPlayerIntegration();
  }

  /**
   * Integrates the player with the MediaService of the application.
   */
  private createPlayerIntegration() {
    // listen for play events.
    const play = this.mediaService.play$.subscribe(() => {
      this.player?.play();
    });
    this.subscriptions.add(play);

    // listen for pause events.
    const pause = this.mediaService.pause$.subscribe(() => {
      this.player?.pause();
    });
    this.subscriptions.add(pause);

    // seek to the current time
    const seekTo = this.mediaService.seekTo$.subscribe((data) => {
      if (data) {
        this.player?.setCurrentTime(data.seconds);
        if (data.play) {
          this.player?.play();
        } else {
          this.player?.pause();
        }
      }
    });
    this.subscriptions.add(seekTo);

    // mute the media
    const mute = this.mediaService.mute$.subscribe(() => {
      this.player?.setMuted(true);
    });
    this.subscriptions.add(mute);

    // unMute the media
    const unMute = this.mediaService.unMute$.subscribe(() => {
      this.player?.setMuted(false);
    });
    this.subscriptions.add(unMute);

    // set the volume
    const volume = this.mediaService.volume$.subscribe((volume) => {
      if (typeof volume === 'number' && volume >= 0 && volume <= 100) {
        this.player?.setVolume(volume / 100);
      }
    });
    this.subscriptions.add(volume);
  }

  /**
   * The Video has ended.
   */
  private videoEnded() {
    if (this.player) {
      this.currentTimeSubscription?.unsubscribe();

      this.currentTimeSubscription = from(this.player.getCurrentTime()).subscribe((duration) => {
        this.mediaService.applyCurrentTime(duration);
        this.currentTimeSubscription?.unsubscribe();
      });
    }
    this.mediaService.applyEnded();
  }

  /**
   * Occurs when the video is playing.
   */
  private videoPlaying() {
    this.mediaService.applyPlaying();
  }

  /**
   * Occurs when the video is paused.
   */
  private videoPaused() {
    this.mediaService.applyPaused();
  }

  /////////////////////////////////////////////////////////////////////
  // UTILS
  /////////////////////////////////////////////////////////////////////

  /**
   * Retrieve the current time from the player and save it to the store.
   */
  private updateCurrentTime(event: Vimeo.TimeEvent) {
    this.currentTimeSubscription?.unsubscribe();
    if (this.player) {
      if (event) {
        this.mediaService.applyCurrentTime(Math.floor(event.seconds));
      } else {
        this.currentTimeSubscription = from(this.player.getCurrentTime())
          .pipe(take(1))
          .subscribe((currentTime) => {
            this.mediaService.applyCurrentTime(Math.floor(currentTime));
            this.currentTimeSubscription?.unsubscribe();
          });
      }
    }
  }

  /**
   * Retrieve the total duration from the player and save it to the store.
   */
  private updateDuration(event?: Vimeo.TimeEvent) {
    this.durationSubscription?.unsubscribe();
    if (this.player) {
      if (event) {
        this.mediaService.applyDuration(Math.floor(event.duration));
      } else {
        this.durationSubscription = from(this.player.getDuration())
          .pipe(take(1))
          .subscribe((duration) => {
            this.mediaService.applyDuration(Math.floor(duration));
            this.durationSubscription?.unsubscribe();
          });
      }
    }
  }

  /**
   * Occurs when an error occurs.
   *
   * @param event - the event
   */
  private onError(event: Vimeo.Error) {
    this.mediaService.applyError(event?.message);
  }

  /**
   * Occurs on pointer up from the touch div when a Vimeo has no controls.
   */
  controlZonePointerUp() {
    if (this.player) {
      this.player.getPaused().then((paused) => {
        if (paused) {
          this.player.play();
        } else {
          this.player.pause();
        }
      });
    }
  }
}
