import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Animates the Paused and Play States
 */
export const mediaLockButtonAnimation = trigger('mediaLockButtonAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(-30px)', opacity: 0 }),
    animate('1s cubic-bezier(0.33, 1, 0.68, 1)', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);
