import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MediaManagerModel } from './media-manager.model';
import { MediaManagerStore } from './media-manager.store';

/**
 * Contains the Media Manager Query.
 *
 * This is used with {@link MediaManagerService} to emit it's state.
 */
@Injectable({
  providedIn: 'root',
})
export class MediaManagerQuery extends Query<MediaManagerModel> {
  ////////////////////////////////////////////////////////////////////
  // ACTIVE PLAYBACK
  ////////////////////////////////////////////////////////////////////

  /**
   * All Media (both streaming providers and native) have unique system IDs.
   *
   * Each time a media plays, it's unique ID is set (or removed) globally so that
   * any media which is not the active media can be paused.
   */
  activeMediaID$ = this.select((state) => state.activeMediaID);

  /**
   * Constructor
   */
  constructor(protected override readonly store: MediaManagerStore) {
    super(store);
  }
}
