import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

function createInitialState(): object {
  return {};
}
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'credential' })
export class CredentialStore extends Store<Record<string, unknown>> {
  constructor() {
    super(createInitialState());
  }

  /**
   * Initialize the Configuration Store.  This collects all data at the start
   * of the application
   */
  applyCredential(credential: Record<string, unknown>) {
    // override the state with any provided configuration
    if (credential) {
      // Uses `_setState` instead of this.update unlike all other stores.
      // See https://drimify.atlassian.net/browse/APP-658
      // See https://github.com/salesforce/akita/issues/661
      this._setState(credential);
    }
  }
}
