import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { cloneDeep, uniqueId } from 'lodash-es';
import { Subscription, take } from 'rxjs';
import { IShellBackgroundEntity } from 'src/app/api/modules/core/components/abstract/IShellBackgroundEntity';
import { ShellBackgroundEntityModel } from '../components/abstract/shell-background/shell-background-entity/shell-background-entity.model';
import { ShellBackgroundStore } from '../components/abstract/shell-background/shell-background.store';
import { ImageLoaderService } from './image-loader.service';

/**
 * The ShellBackgroundService takes an optional {@link IShellBackgroundEntity} configuration from the server
 * and converts it into a validated {@link ShellBackgroundModel} if applicable.
 */
@Injectable({
  providedIn: 'root',
})
export class ShellBackgroundService {
  /**
   * Constructor
   */
  constructor(
    private readonly shellBackgroundStore: ShellBackgroundStore,
    private readonly imageLoaderService: ImageLoaderService,
    private readonly loggerService: LoggerService
  ) {}

  /**
   * Every time a new configuration is applied to set a background, any existing subscription is cancelled.
   */
  private subscription: Subscription | null;

  /**
   * An {@link IScreen} may provided the following inputs:
   *
   * - `undefined` - nothing will happen
   * - `null` - will destroy all backgrounds
   * - An {@link IShellBackgroundEntity} which will configure the background.
   *
   * Should an {@link IShellBackgroundEntity} be provided with an image, the image will be preloaded.
   */
  applyConfiguration(configuration?: IShellBackgroundEntity | null) {
    // if the configuration is undefined, then othing happens and the method if skipped
    if (configuration === undefined) {
      return;
    }

    // since not undefined, any existing in-flight image preloaders are destroyed.
    this.subscription?.unsubscribe();
    this.subscription = null;

    // if the configuration is null
    if (configuration === null) {
      this.applyConfigurationToStore(null);
      return;
    }

    // if the configuration is provided
    if (configuration) {
      // a new model is created
      const model: ShellBackgroundEntityModel = {
        id: uniqueId('shell-background-entity-'),
        hasColor: false,
        color: 'transparent',
        colorOpacity: 1,
        hasImage: false,
        image: undefined,
        imageOpacity: 1,
        hasVideo: false,
        video: [],
        videoOpacity: 1,
      };

      // color and opacity
      if (configuration.color?.length > 0) {
        model.hasColor = true;
        model.color = configuration.color;
        if (typeof configuration.colorOpacity === 'number') {
          // you can't have an opacity of 0
          if (configuration.colorOpacity === 0) {
            this.loggerService.warn("Background Color Opacity is '0' and has been disabled");
            model.hasColor = false;
            model.color = undefined;
          }

          // you can't have an opacity greater than 1 or less than 0
          else if (configuration.colorOpacity >= 0 && configuration.colorOpacity <= 1) {
            model.colorOpacity = configuration.colorOpacity;
          } else {
            this.loggerService.warn("Background Color Opacity must be greater than '0' or less than '1");
          }
        }
      }

      // video and opacity
      if (configuration.video?.length > 0) {
        model.hasVideo = true;
        model.video = cloneDeep(configuration.video);

        // check that the video entry is valid, if it's not then video cannot be used.
        configuration.video.forEach((video) => {
          if (!video.src || !video.type) {
            model.hasVideo = false;
            model.video = [];
            this.loggerService.warn('Background Video is invalid and has been disabled');
          }
        });

        if (typeof configuration.videoOpacity === 'number') {
          // you can't have an opacity of 0
          if (configuration.videoOpacity === 0) {
            model.hasVideo = false;
            model.video = [];
            this.loggerService.warn("Background Video Opacity is '0' and has been disabled");
          }

          // you can't have an opacity greater than 1 or less than 0
          if (configuration.videoOpacity >= 0 && configuration.videoOpacity <= 1) {
            model.videoOpacity = configuration.videoOpacity;
          } else {
            this.loggerService.warn("Background Video Opacity must be greater than '0' or less than '1");
          }
        }
      }

      // image and opacity
      if (configuration.image?.length > 0) {
        model.hasImage = true;
        model.image = `url("${configuration.image}")`;
        if (typeof configuration.imageOpacity === 'number') {
          // you can't have an opacity of 0
          if (configuration.imageOpacity === 0) {
            model.hasImage = false;
            model.image = undefined;
            this.loggerService.warn("Background Image Opacity is '0' and has been disabled");
          }

          // you can't have an opacity greater than 1 or less than 0
          if (configuration.imageOpacity >= 0 && configuration.imageOpacity <= 1) {
            model.imageOpacity = configuration.imageOpacity;
          } else {
            this.loggerService.warn("Background Image Opacity must be greater than '0' or less than '1");
          }
        }

        // preload image to cache
        this.subscription = this.imageLoaderService
          .loadImagesToCache([configuration.image])
          .pipe(take(1))
          .subscribe({
            complete: () => {
              this.applyConfigurationToStore(model);
            },
            error: () => {
              // we can safely ignore errors because this will just persist the existing background.
            },
          });
      } else {
        this.applyConfigurationToStore(model);
      }
    }
  }

  /**
   * Applies the processed configuration to the store.
   *
   * @param model - the model to apply.
   */
  private applyConfigurationToStore(model: ShellBackgroundEntityModel | null) {
    this.shellBackgroundStore.applyConfiguration(model);
  }
}
