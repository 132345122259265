import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { IShellLayout } from 'src/app/api/modules/core/components/abstract/IShellLayout';
import { DEFAULT_SHELL_LAYOUT } from 'src/app/app-constants';

/**
 * The Layout Service
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  /**
   * Processes the Application Layout.
   */
  validateProcessApplicationLayout(configuration: IShellLayout): Required<IShellLayout> {
    const layout = cloneDeep(DEFAULT_SHELL_LAYOUT);

    // application layout
    if (configuration) {
      if (configuration.mobile) {
        if (configuration.mobile.grow >= 0) {
          layout.mobile.grow = configuration.mobile.grow;
        }
        if (configuration.mobile.shrink >= 0) {
          layout.mobile.shrink = configuration.mobile.shrink;
        }
        if (configuration.mobile.basis?.length > 0) {
          layout.mobile.basis = configuration.mobile.basis;
        }
      }
      if (configuration.desktop) {
        if (configuration.desktop.grow >= 0) {
          layout.desktop.grow = configuration.desktop.grow;
        }
        if (configuration.desktop.shrink >= 0) {
          layout.desktop.shrink = configuration.desktop.shrink;
        }
        if (configuration.desktop.basis?.length > 0) {
          layout.desktop.basis = configuration.desktop.basis;
        }
      }

      if (configuration.layout) {
        if (configuration.layout === 'row' || configuration.layout === 'row-reverse') {
          layout.layout = configuration.layout;
        }
      }

      if (configuration.layoutAlign) {
        layout.layoutAlign = configuration.layoutAlign;
      }
    }

    return layout;
  }
}
