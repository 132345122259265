import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { combineQueries, Query } from '@datorama/akita';
import { delay, EMPTY, filter, of, skipWhile, switchMap } from 'rxjs';
import { MediaLockableModel } from './media-lockable.model';
import { MediaLockableStore } from './media-lockable.store';

/**
 * The Query used for an {@link MediaLockableComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockableQuery extends Query<MediaLockableModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the Media Ready?
   */
  private _mediaReady$ = this.select((state) => state.mediaReady);

  /**
   * Is the Component Configured?
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.select((state) => state.configured);

  /**
   * Has the Media Been Configured.
   */
  configured$ = combineQueries([this._mediaReady$, this._configured$]).pipe(
    skipWhile((configured) => !configured),
    delay(500)
  );

  /**
   * Once the Media has stopped initializing it becomes initialized.
   */
  initialized$ = this.select((state) => state.initialized);

  /**
   * Has an error occurred?
   */
  error$ = this.select((state) => state.error);

  /**
   * Has the media been unlocked?
   */
  unlocked$ = this.select((state) => state.unlocked);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MEDIA
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * What is the Media Configuration?
   */
  private _media$ = this.select((state) => state.media);

  /**
   * The Media Configuration becomes avilaible once the Media has been configured.
   */
  media$ = combineQueries([this.configured$, this._media$]).pipe(
    switchMap(([isConfigured, media]) => {
      if (isConfigured) {
        return of(media);
      }
      return EMPTY;
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // PROGRESS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the progress bar explicitly been hidden?
   */
  private _hideProgress$ = this.select((state) => state.hideProgress);

  /**
   * Should progress be displayed?
   */
  shouldDisplayProgress$ = this._hideProgress$.pipe(
    switchMap((hideProgress) => {
      if (!hideProgress) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * What is the progress of the unlock?
   *
   * This isn't to be confused with the progress of the video.
   */
  progress$ = this.select((state) => state.progress);

  /**
   * Is the media in portrait mode?
   */
  private _isMediaPortrait$ = this.select((state) => state.mediaIsPortrait);

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  limitPortraitWidthOnDesktop$ = combineQueries([this._mediaObserver$, this._isMediaPortrait$]).pipe(
    switchMap(([changes, isMediaPortrait]) => {
      // if the media is not portrait then this doesn't apply
      if (!isMediaPortrait) {
        return of(false);
      }

      // get the first change
      const targetChange = changes[0];

      // if the media alias is not xs then we want to limit the width
      if (targetChange.mqAlias !== 'xs') {
        return of(true);
      }

      return of(false);
    })
  );

  /**
   * Constructor.
   */
  constructor(protected override readonly store: MediaLockableStore, protected readonly mediaObserver: MediaObserver) {
    super(store);
  }
}
