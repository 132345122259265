import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IDataboxProgressionNumeric } from 'src/app/api/modules/core/dynamic/databoxes/progression-numeric/IDataboxProgressionNumeric';
import { DataboxProgressionNumericModel } from './databox-progression-numeric.model';

export function createInitialState(): DataboxProgressionNumericModel {
  return {
    selector: 'app-databox-progression-numeric',
    template: '%CURRENT%',
    templateIdle: '-',
    output: '-',
    totalIsUsed: false,
  };
}

/**
 * Manages the State of a {@link DataboxProgressionNumericComponent}.
 */
@Injectable()
export class DataboxProgressionNumericStore extends Store<DataboxProgressionNumericModel> {
  /**
   * The CURRENT String Template variable which is replaced by the users actual progression.
   */
  private readonly CURRENT = '%CURRENT%';
  /**
   * The TOTAL String Template variable which is replaced by the total amount of levels.
   */
  private readonly TOTAL = '%TOTAL%';

  /**
   * Constructor
   */
  constructor(private readonly logger: LoggerService) {
    super(createInitialState(), {
      producerFn: produce,
      name: `databox-progression-numeric-${guid()}`,
    });
  }

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxProgressionNumeric>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // the template is used to process the numeric values into a formatted score string.
    let template = `${this.CURRENT}`;
    let totalIsUsed = false;
    if (configuration.template) {
      // all templates must use %CURRENT% substrings.
      if (configuration.template.includes(this.CURRENT)) {
        template = configuration.template;
      } else {
        this.logger.warn(
          `[DataboxScore] applyInitialize - The 'template' must contain a substring of "${this.CURRENT}". The default will be used.`
        );
      }

      // the template may optionally contain a %TOTAL% substring.
      if (configuration.template.includes(this.TOTAL)) {
        totalIsUsed = true;
      }
    }

    // template idle is shown when no acceptable values have been provided
    // or as the default state or during a reset.
    let templateIdle = `-`;
    if (configuration.templateIdle) {
      templateIdle = configuration.templateIdle;
    }

    // the output should be the templateIdle as no values are incoming yet.
    const output = templateIdle;

    // update the store
    this.update((draft) => {
      draft.template = template;
      draft.templateIdle = templateIdle;
      draft.output = output;
      draft.totalIsUsed = totalIsUsed;
    });
  }

  /**
   * Updates the component with the users progress.
   *
   * @param current - the current progress of the user
   * @param total - the total amount of progress possible, this is optional.
   */
  applyUpdate(current: number, total?: number): void {
    // get the configured data.
    const { template, totalIsUsed } = this.getValue();

    // if there is no current then abort.
    if (typeof current !== 'number') {
      this.logger.warn(`[DataboxProgressionNumeric] applyUpdate - Could not update databox as no "current" value was provided.`);
      return;
    }

    // if there is no total yet that is expected
    if (totalIsUsed && typeof total !== 'number') {
      this.logger.warn(
        `[DataboxProgressionNumeric] applyUpdate - Could not update databox as no "total" value was provided but is expected within the configured template.`
      );
      return;
    }

    // take a copy of the template.
    let finalOutput = template.slice();

    // replace the %CURRENT% substring with the current value.
    finalOutput = finalOutput.replace(this.CURRENT, current.toString());

    // if the total is used do some processing
    if (totalIsUsed) {
      // the total value cannot be less than 1.
      if (total < 1) {
        total = 1;
      }

      // replace the %TOTAL% substring with the total value.
      finalOutput = finalOutput.replace(this.TOTAL, total.toString());
    }

    // update the store
    this.update((draft) => {
      draft.output = finalOutput;
    });
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    this.update((draft) => {
      draft.output = draft.templateIdle;
    });
  }
}
