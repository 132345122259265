<div *ngIf="config?.link; else templateNoLink">
  <app-abstract-link *ngIf="config?.link" [config]="config?.link">
    <ng-container *ngTemplateOutlet="templateNoLink"></ng-container>
  </app-abstract-link>
</div>

<ng-template #templateNoLink>
  <div class="wrapper">
    <app-image *ngIf="config?.image" [config]="config?.image"></app-image>
    <span *ngIf="config?.text" class="mat-caption footer-branding-text">{{ config.text }}</span>
  </div>
</ng-template>
