import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { TextAnimationTypes } from '@digitaservice/utils';
import { produce } from 'immer';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { PluginTextEntityModel } from './plugin-text-entity.model';

/**
 * Creates the initial state for the PluginTextEntity with default settings.
 * @returns A PluginTextEntityModel with default properties.
 */
function createInitialState(): PluginTextEntityModel {
  return {
    configured: false,
    complete: false,
    text: '',
    type: TextAnimationTypes.slideInOutFromLeft,
    positionX: '50%',
    positionY: '50%',
    displayDuration: 1000,
    enter: undefined,
    exit: undefined,
  };
}

/**
 * The `PluginTextEntityStore` manages the state of a text entity within a plugin.
 *
 * It allows for initializing the entity with specific configurations and marking the
 * completion of its associated animations.
 */
@Injectable()
export class PluginTextEntityStore extends Store<PluginTextEntityModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `plugin-text-entity-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store's state with an optional configuration object.
   * Merges the default state with the provided configuration, updating only the properties that are set.
   *
   * @param configuration An optional configuration object that conforms to IPluginTextEntity.
   */
  applyInitialize(configuration?: IPluginTextEntity) {
    const defaultConfig = this.getValue();

    // text
    let text = defaultConfig.text;
    if (configuration?.text) {
      text = configuration.text;
    }

    // display duration
    let displayDuration = defaultConfig.displayDuration;
    if (configuration?.displayDuration) {
      displayDuration = configuration.displayDuration;
    }

    // position x
    let positionX = defaultConfig.positionX;
    if (configuration?.positionX) {
      positionX = configuration.positionX;
    }

    // position y
    let positionY = defaultConfig.positionY;
    if (configuration?.positionY) {
      positionY = configuration.positionY;
    }

    // type
    let type = defaultConfig.type;
    if (configuration?.type) {
      type = configuration.type;
    }

    // enter
    let enter = defaultConfig.enter;
    if (configuration?.enter) {
      enter = configuration.enter;
    }

    // exit
    let exit = defaultConfig.exit;
    if (configuration?.exit) {
      exit = configuration.exit;
    }

    this.update((draft) => {
      draft.configured = true;
      draft.displayDuration = displayDuration;
      draft.text = text;
      draft.positionX = positionX;
      draft.positionY = positionY;
      draft.type = type;
      draft.enter = enter;
      draft.exit = exit;
    });
  }

  /**
   * Marks the text animation as complete by setting the `complete` state property to true.
   */
  applyComplete() {
    this.update((draft) => {
      draft.complete = true;
    });
  }
}
