import { Injectable } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { combineQueries, Query } from '@datorama/akita';
import { delay, EMPTY, of, switchMap } from 'rxjs';
import { DialogMediaLockModel } from './dialog-media-lock.model';
import { DialogMediaLockStore } from './dialog-media-lock.store';

/**
 * The Query used for a {@link DialogMediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogMediaLockQuery extends Query<DialogMediaLockModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.select((state) => state.configured);

  /**
   * The Header Configuration.
   */
  header$ = this.select((state) => state.header);

  /**
   * What is the Media Lock Configuration?
   */
  lock$ = this.select((state) => state.lock);

  /**
   * Has an error occurred?
   */
  error$ = this.select((state) => state.error);

  /**
   * Is the Media Unlocked?
   */
  private _unlocked$ = this.select((state) => state.unlocked).pipe(delay(500));

  /**
   * The Footer Configuration.
   */
  footer$ = this.select((state) => state.footer);

  /**
   * The Footer Button Configuration becomes avilaible once the Media has been unlocked.
   *
   * Assuming there is no errors.
   */
  button$ = combineQueries([this._unlocked$, this.error$, this.footer$]).pipe(
    switchMap(([unlocked, error, footer]) => {
      if (unlocked || error) {
        return of(footer.negativeButton);
      }
      return EMPTY;
    })
  );

  /**
   * Constructor.
   */
  constructor(protected override readonly store: DialogMediaLockStore, protected readonly mediaObserver: MediaObserver) {
    super(store);
  }
}
