import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { ShellBackgroundEntityModel } from './shell-background-entity/shell-background-entity.model';
import { ShellBackgroundModel } from './shell-background.model';

/**
 * Creates the initial state of the Shell Background Store.
 *
 * By default an application has no background set until initialize or screens provid it.
 */
export function createInitialState(): ShellBackgroundModel {
  return {
    systems: [],
  };
}

/**
 * The ShellBackgroundStore is responsible for managing the state of backgrounds within the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ShellBackgroundStore extends Store<ShellBackgroundModel> {
  constructor() {
    super(createInitialState(), {
      name: 'shell-background',
      producerFn: produce,
    });
  }

  /**
   * Applies the configuration.
   *
   * @param configuration - the configuration to apply
   */
  applyConfiguration(configuration?: ShellBackgroundEntityModel | null) {
    // if the configuration is specifically undefined, nothing
    // changes on the background because it persists.
    if (configuration === undefined) {
      return;
    }

    const { systems } = this.getValue();

    // clone the existing systems
    let newSystems = cloneDeep(systems);

    // if the configuration is specifically null, all systems are disabled
    if (configuration === null) {
      newSystems = [];
    }

    // if there is a configuration, it is added to the systems
    if (configuration) {
      newSystems.push(configuration);
    }

    // update the store
    this.update((draft) => {
      draft.systems = newSystems;
    });
  }

  /**
   * Occurs once a background has successfully animated into place.
   */
  applySuccessfulBackgroundAddition() {
    const { systems } = this.getValue();

    // clone the existing systems
    const newSystems = cloneDeep(systems);

    // remove the first system
    if (newSystems?.length > 1) {
      newSystems.shift();
    }

    //update the store
    this.update((draft) => {
      draft.systems = newSystems;
    });
  }
}
