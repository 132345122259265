import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs';
import { ILeaderboard } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboard';
import { ILeaderboardDataRequest } from 'src/app/api/modules/core/dynamic/leaderboard/network/ILeaderboardDataRequest';
import { ILeaderboardDataResponse } from 'src/app/api/modules/core/dynamic/leaderboard/network/ILeaderboardDataResponse';
import { DigitaServiceAPIService } from '../../../services/network/digitaservice-api.service';
import { LeaderboardStore } from './leaderboard.store';

/**
 * The Leaderboard Service is used with the {@link LeaderboardComponent}.
 */
@Injectable()
export class LeaderboardService {
  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly digitaServiceAPIService: DigitaServiceAPIService,
    private readonly leaderboardStore: LeaderboardStore
  ) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: Partial<ILeaderboard>) {
    this.leaderboardStore.applyInitialize(configuration);
  }

  /**
   * Is the Leaderboard currently animating?
   *
   * @param isAnimating - true if animating, false otherwise.
   */
  applyIsAnimating(isAnimating: boolean) {
    this.leaderboardStore.applyIsAnimating(isAnimating);
  }

  /**
   * Is the Leaderboard in Debug Mode?
   *
   * @param isDebug - true if debug mode is enabled, false otherwise.
   */
  applyIsDebug(isDebug: boolean) {
    this.leaderboardStore.applyIsDebug(isDebug);
  }

  /**
   * Applies a the latest selected filter.
   *
   * @param filters - the filters to apply
   */
  applyUpdateFilters(filters?: Record<string, string>) {
    this.leaderboardStore.applyUpdateFilters(filters);
  }

  /**
   * Create an API request.
   *
   * @param interval - the interval to use for the polling
   * @param onDataAPI - the end point to call for data
   * @param filters - the optional filters
   */
  createAPIRequest(interval: number, onDataAPI: string, filters?: Record<string, string>) {
    // construct the payload
    const payload: ILeaderboardDataRequest = {
      filters,
    };

    this.loggerService.log('[LeaderboardService] createAPIRequest', payload);

    return this.digitaServiceAPIService.schedule<ILeaderboardDataResponse>(interval, onDataAPI, payload).pipe(
      take(1),
      tap((response) => {
        this.applyLeaderboardUpdateData(response);
      })
    );
  }

  /**
   * Updates the score with the leaderboard polling response.
   *
   * Separated to use with debugging.
   *
   * @param response - the information returned from the polling request.
   */
  applyLeaderboardUpdateData(response: ILeaderboardDataResponse) {
    this.loggerService.log('[LeaderboardService] applyLeaderboardUpdate', response);
    this.leaderboardStore.applyLeaderboardUpdateData(response);
  }
}
