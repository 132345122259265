import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { createDigitaServiceError } from 'src/app/app-error';
import { DialogContentModel } from './dialog-content.model';

/**
 * Creates the initial state of an media store.
 */
function createInitialState(): DialogContentModel {
  return {
    configured: false,
    header: undefined,
    content: undefined,
    footer: undefined,
    settings: {},
  };
}

/**
 * The Store used for a {@link DialogContentComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogContentStore extends Store<DialogContentModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `dialog-content-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IDialogContent) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`DialogContent`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // the header
    const header = configuration.header;

    // the content
    const content = configuration.content;

    // the footer
    const footer = configuration.footer;

    // the settings
    const settings = configuration.settings;

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.header = header;
      draft.content = content;
      draft.footer = footer;
      draft.settings = settings;
    });
  }
}
