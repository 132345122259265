import { Injectable } from '@angular/core';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { ImageLoaderService } from '../../../services/image-loader.service';
import { ImageStore } from './image.store';

/**
 * The Image Service is used by each instance of an {@link ImageComponent}.
 */
@Injectable()
export class ImageService {
  /**
   * Constructor
   */
  constructor(private readonly imageLoaderService: ImageLoaderService, private readonly imageStore: ImageStore) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize the Image Store from an {@link IImage} json configuration.
   *
   * @param configuration - the imagine configuration provided by the server.
   */
  initialize(configuration?: IImage) {
    this.imageStore.applyInitialize(configuration);
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE LOADING
  ////////////////////////////////////////////////////////////////////

  imageLoadingRequest(src: string) {
    return this.imageLoaderService.loadImagesToBlob([src]);
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE DATA
  ////////////////////////////////////////////////////////////////////

  imageData(imageData: string) {
    this.imageStore.applyImageData(imageData);
  }

  ////////////////////////////////////////////////////////////////////
  // ERROR
  ////////////////////////////////////////////////////////////////////

  error() {
    this.imageStore.applyError();
  }
}
