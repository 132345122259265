import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { IFooter } from 'src/app/api/modules/core/components/static/IFooter';
import { FooterModel } from './footer.model';

export function createInitialState(): FooterModel {
  return {
    configure: null,
    exists: false,
    hidden: null,
    appLayoutClass: '',
  };
}
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'footer' })
export class FooterStore extends Store<FooterModel> {
  constructor() {
    super(createInitialState(), { producerFn: produce });
  }

  /**
   * Configures the Footer State.
   */
  applyConfiguration(configuration?: IFooter) {
    this.update((draft) => {
      // if there is a configure property, use it to set the state of the footer
      if (configuration?.configure) {
        draft.configure = configuration.configure;
      }

      // if there is a hidden property set it otherwise all hidden is cancelled
      if (configuration?.hidden) {
        draft.hidden = configuration?.hidden;
      } else {
        draft.hidden = false;
      }

      // if the draft has a configuration
      if (draft.configure) {
        // count how many active items so that the app can be informed of optimised padding
        let activeCount = 0;

        if (draft.configure.author) {
          draft.exists = true;
          if (draft.hidden) {
            if (draft.hidden === true || draft.hidden.author === true) {
              // this is not an active item
            } else {
              activeCount++;
            }
          } else {
            activeCount++;
          }
        }

        if (draft.configure.branding) {
          if (draft.hidden) {
            if (draft.hidden === true || draft.hidden.branding === true) {
              // this is not an active item
            } else {
              activeCount++;
            }
          } else {
            activeCount++;
          }
        }
        if (draft.configure.sharing) {
          if (draft.hidden) {
            if (draft.hidden === true || draft.hidden.sharing === true) {
              // this is not an active item
            } else {
              activeCount++;
            }
          } else {
            activeCount++;
          }
        }

        if (draft.configure.termsConditions) {
          if (draft.hidden) {
            if (draft.hidden === true || draft.hidden.termsConditions === true) {
              // this is not an active item
            } else {
              activeCount++;
            }
          } else {
            activeCount++;
          }
        }

        // depending on how many items, the correct class is specified
        switch (activeCount) {
          default:
            draft.exists = true;
            draft.appLayoutClass = 'footer-3';
            break;
          case 0:
            draft.exists = false;
            draft.appLayoutClass = '';
            break;
          case 1:
            draft.exists = true;
            draft.appLayoutClass = 'footer-1';
            break;
          case 2:
            draft.exists = true;
            draft.appLayoutClass = 'footer-2';
            break;
        }
      } else {
        draft.exists = false;
        draft.configure = null;
        draft.hidden = true;
        draft.appLayoutClass = '';
      }

      return draft;
    });
  }
}
