import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { cloneDeep, uniqueId } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IShellFGEffect } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffect';
import { IShellFGEffectsConfetti } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfetti';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';
import { ShellEffectsService } from '../../../services/effects/shell-effects.service';

/**
 * The Shell Background is the main Component responsible for managing background subsystems.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-shell-fg-effects-container',
  templateUrl: './shell-fg-effects-container.component.html',
  styleUrls: ['./shell-fg-effects-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellFGEffectsContainerComponent implements AfterViewInit, OnDestroy {
  /**
   * Holds all active confetti effects.
   */
  private confettiSource = new BehaviorSubject<Record<string, IShellFGEffectsConfetti>>({});
  confetti$ = this.confettiSource.asObservable();

  /**
   * Holds all active confettis effects.
   */
  private confettisSource = new BehaviorSubject<Record<string, IShellFGEffectsConfettis>>({});
  confettis$ = this.confettisSource.asObservable();

  /**
   * Contains all subscriptions for teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Contains the background and overlay logic.
   */
  constructor(public readonly service: ShellEffectsService, private readonly cd: ChangeDetectorRef) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen out for the confetti service
    const confettiSub = this.service.foreground.confetti$.subscribe((config) => {
      // create a uniqud ID.
      const id = uniqueId('confetti');
      // clone the config
      const clonedConfig = cloneDeep(config);
      // set the effect ID
      clonedConfig.effectID = id;
      // clone the existing confetti record
      const confettiRecord = this.confettiSource.value;
      // add the effect to the confetti record
      confettiRecord[id] = clonedConfig;
      // update the confetti record
      this.confettiSource.next(confettiRecord);
    });
    this.subscriptions.add(confettiSub);

    // listen out for the confettis service
    const confettisSub = this.service.foreground.confettis$.subscribe((config) => {
      // create a uniqud ID.
      const id = uniqueId('confettis');
      // clone the config
      const clonedConfig = cloneDeep(config);
      // set the effect ID
      clonedConfig.effectID = id;
      // clone the existing confetti record
      const confettisRecord = this.confettisSource.value;
      // add the effect to the confetti record
      confettisRecord[id] = clonedConfig;
      // update the confetti record
      this.confettisSource.next(confettisRecord);
    });
    this.subscriptions.add(confettisSub);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.confettiSource?.complete();
    this.confettisSource?.complete();
  }

  /**
   * Removes a completed confetti effect from the record.
   *
   * @param effectID - the ID of the effect that was complete.
   */
  confettiComplete(effectID: string) {
    // clone the existing confetti record
    const confettiRecord = this.confettiSource.value;

    // remove the effect from the confetti record
    delete confettiRecord[effectID];

    // update the confetti record
    this.confettiSource.next(confettiRecord);
  }

  /**
   * Removes a completed confettis effect from the record.
   *
   * @param effectID - the ID of the effect that was complete.
   */
  confettisComplete(effectID: string) {
    // clone the existing confetti record
    const confettisRecord = this.confettisSource.value;

    // remove the effect from the confetti record
    delete confettisRecord[effectID];

    // update the confetti record
    this.confettisSource.next(confettisRecord);
  }

  /**
   * Track by function for the confetti effects.
   */
  trackByFn(index: number, item: { key: string; value: IShellFGEffect }) {
    return item.value.effectID;
  }
}
