import { Injectable } from '@angular/core';
import { IFrameEventError, IFrameEventReady } from '@digitaservice/utils';
import { IPlugin } from 'src/app/api/modules/core/dynamic/plugin/components/IPlugin';
import { IPluginResponse } from 'src/app/api/modules/core/dynamic/plugin/network/IPluginResponse';
import { IResponse } from 'src/app/api/modules/core/network/IResponse';
import { DigitaServiceAPIService } from 'src/app/modules/core/services/network/digitaservice-api.service';
import { PluginStore } from './plugin.store';

/**
 * The PluginService is used for each {@link PluginComponent}.
 */
@Injectable()
export class PluginService {
  /**
   * Constructor.
   */
  constructor(private readonly digitaServiceAPI: DigitaServiceAPIService, private readonly pluginStore: PluginStore) {}

  /**
   * Initialize the store with JSON configuration.
   *
   * @param configuration - the incoming configuration json
   */
  applyConfiguration(configuration: IPlugin) {
    this.pluginStore.applyConfiguration(configuration);
  }

  /**
   * Occurs when the IFrame has loaded it's contents.
   */
  applyReady() {
    this.pluginStore.applyReady();
  }

  /**
   * The height as a number value dispatched from the plugin.
   *
   * @param height - the height numeric value
   */
  applyContentHeight(height: number) {
    this.pluginStore.applyContentHeight(height);
  }

  /**
   * Is the Iframe Interactive?
   *
   * @param flag - true if the iframe is interactive or false otherwise
   */
  applyFrameInteractive(flag: boolean) {
    this.pluginStore.applyFrameInteractive(flag);
  }

  /**
   * Updates the latest score value from the plugin.
   *
   * @param score - the latest score from the plugin.
   */
  applyHeartbeatScore(score: number) {
    this.pluginStore.applyHeartbeatScore(score);
  }

  /**
   * Update a delay to be used before the onCompleteAPI is called. This is applied when an Effect is used.
   *
   * @param delay - the delay in milliseconds
   */
  applyEffectDelay(delay: number = 0) {
    this.pluginStore.applyEffectDelay(delay);
  }

  /**
   * Fires an onReadyAPI event.
   *
   * @param api - the API end point if it is available.
   * @param event - the event that is being dispatched.
   */
  requestOnReadyAPI(api: string, event: IFrameEventReady) {
    return this.digitaServiceAPI.data<IPluginResponse>(api, event);
  }

  /**
   * Fires an onErrorAPI event.
   *
   * @param api - the API end point if it is available.
   * @param event - the event that is being dispatched.
   */
  requestOnErrorAPI(api: string, event: IFrameEventError) {
    return this.digitaServiceAPI.data<IPluginResponse>(api, event);
  }

  /**
   * Fires an onEventAPI event.
   *
   * @param api - the API end point if it is available.
   * @param event - the event that is being dispatched.
   */
  requestOnEventAPI(api: string, event: object) {
    return this.digitaServiceAPI.data<IPluginResponse>(api, event);
  }

  /**
   * Fires an onCompleteAPI event.
   *
   * @param api - the API end point if it is available.
   * @param event - the event that is being dispatched.
   */
  requestOnCompleteAPI(api: string, event: object) {
    return this.digitaServiceAPI.data<IPluginResponse>(api, event, true);
  }

  /**
   * Fires an onHeartbeatAPI request.
   *
   * @param api
   * @param score
   * @returns
   */
  requestOnHeartbeatAPI(api: string, score: number) {
    return this.digitaServiceAPI.data<IResponse>(
      api,
      {
        score,
      },
      true
    );
  }
}
