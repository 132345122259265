import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita'; 
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { DataboxLivesIconStore } from './databox-lives-icon.store';

/**
 * Qureries the State of an instance of {@link DataboxLivesIconStore}.
 */
@Injectable()
export class DataboxLivesIconQuery extends Query<Required<IDataboxLivesIcon>> {
  /**
   * What icon has been configured?
   */
  icon$ = this.select((state) => state.icon);

  /**
   * How many lives are remaining?
   */
  lives$ = this.select((state) => state.lives);

  /**
   * Constructor
   */
  constructor(protected override readonly store: DataboxLivesIconStore) {
    super(store);
  }
}
