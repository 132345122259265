import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { EMPTY, of, switchMap } from 'rxjs';
import { LeaderboardModel } from './leaderboard.model';
import { LeaderboardStore } from './leaderboard.store';

/**
 * Queries the State of an instance of {@link LeaderboardStore}.
 */
@Injectable()
export class LeaderboardQuery extends Query<LeaderboardModel> {
  /**
   * Constructor
   */
  constructor(protected override readonly store: LeaderboardStore) {
    super(store);
  }

  /**
   * Is the leaderboard loading data?
   */
  private _isLoading$ = this.select((state) => state.isLoading);

  /**
   * Retrieve the entries data for the leaderboard.
   */
  entries$ = this.select((state) => state.entries);

  /**
   * Show the loading indicator if the system is loading and there are no entries.
   */
  showLoadingIndicator$ = combineQueries([this._isLoading$, this.entries$]).pipe(
    switchMap(([isLoading, entries]) => {
      if (isLoading && (!entries || entries.length === 0)) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Show the leaderboard.
   */
  showLeaderboard$ = this.showLoadingIndicator$.pipe(
    switchMap((showLoadingIndicator) => {
      if (showLoadingIndicator) {
        return of(false);
      } else {
        return of(true);
      }
    })
  );

  /**
   * The filters property.
   */
  filters$ = this.select((state) => state.filters);

  /**
   * The active filters property.
   */
  activeFilters$ = this.select((state) => state.activeFilters);

  /**
   * Does the system have filters?
   */
  hasFilters$ = this.filters$.pipe(
    switchMap((filters) => {
      if (filters?.length > 1) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * What is the Leaderboard Title should it exist?
   */
  title$ = this.select((state) => state.title);

  /**
   * Does the Leaderboard have a Title?
   */
  hasTitle$ = this.title$.pipe(
    switchMap((title) => {
      if (title) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Retrieve the headers data for the leaderboard.
   */
  headers$ = this.select((state) => state.headers);

  // get the schedule
  private schedule$ = this.select((state) => state.schedule);

  // get the debug mode
  private isDebug$ = this.select((state) => state.isDebug);

  // belongs to user color
  belongsToUserColor$ = this.select((state) => state.belongsToUserColor);

  // the change highlight color
  changeHighlightColor$ = this.select((state) => state.changeHighlightColor);

  /**
   * Combine the schedule, debug mode, and animation state.
   */
  scheduleSettings$ = combineQueries([this.schedule$, this.isDebug$, this.activeFilters$]).pipe(
    switchMap(([schedule, isDebug, filters]) => {
      if (!schedule || isDebug) {
        return EMPTY;
      }

      if (!schedule.onDataAPI) {
        return EMPTY;
      }

      const onDataAPI = schedule.onDataAPI;
      let onInterval = schedule.interval;
      if (typeof onInterval !== 'number') {
        onInterval = 1000;
      }
      if (onInterval < 0) {
        onInterval = 1000;
      }
      if (onInterval < 1000) {
        onInterval = 1000;
      }

      return of({
        filters,
        onDataAPI,
        onInterval,
      });
    })
  );
}
