<app-shell-fg-effects-confetti
  *ngFor="let confetti of confetti$ | async | keyvalue; trackBy: trackByFn"
  [config]="confetti.value"
  (complete)="confettiComplete($event)"
></app-shell-fg-effects-confetti>

<app-shell-fg-effects-confettis
  *ngFor="let confettis of confettis$ | async | keyvalue; trackBy: trackByFn"
  [config]="confettis.value"
  (complete)="confettisComplete($event)"
></app-shell-fg-effects-confettis>
