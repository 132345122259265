<!-- Link Types use an <a> tag to navigate -->
<ng-container *ngIf="(buttonQuery.type$ | async) === 'link'" [ngSwitch]="buttonQuery.style$ | async">
  <ng-container *ngSwitchCase="'mat-button'">
    <a
      mat-button
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-raised-button'">
    <a
      mat-raised-button
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-flat-button'">
    <a
      mat-flat-button
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-stroked-button'">
    <a
      mat-stroked-button
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-icon-button'">
    <a
      mat-icon-button
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-fab'">
    <a
      mat-fab
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [extended]="buttonQuery.extended$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-mini-fab'">
    <a
      mat-mini-fab
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </a>
  </ng-container>
</ng-container>

<!-- Button Types use a <button> tag to execute local JS -->
<ng-container *ngIf="(buttonQuery.type$ | async) !== 'link'" [ngSwitch]="buttonQuery.style$ | async">
  <ng-container *ngSwitchCase="'mat-button'">
    <button
      mat-button
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-raised-button'">
    <button
      mat-raised-button
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-flat-button'">
    <button
      mat-flat-button
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-stroked-button'">
    <button
      mat-stroked-button
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-icon-button'">
    <button
      mat-icon-button
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-fab'">
    <button
      mat-fab
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [extended]="buttonQuery.extended$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mat-mini-fab'">
    <button
      mat-mini-fab
      [attr.form]="buttonQuery.form$ | async"
      [color]="buttonQuery.color$ | async"
      [class.mat-button-textured]="buttonQuery.isTextured$ | async"
      (click)="onClick($event)"
      [disabled]="buttonQuery.disabled$ | async"
      [tabIndex]="buttonQuery.tabIndex$ | async"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
</ng-container>

<!-- All Buttons have the same inner content -->
<ng-template #buttonContentTemplate>
  <mat-icon
    *ngIf="buttonQuery.hasLeftAlignedIcon$ | async"
    [svgIcon]="buttonQuery.iconName$ | async"
    [class.mat-icon-left]="buttonQuery.hasLabel$ | async"
  ></mat-icon>
  <ng-container *ngIf="buttonQuery.hasLabel$ | async">
    {{ buttonQuery.label$ | async }}
  </ng-container>
  <mat-icon
    *ngIf="buttonQuery.hasRightAlignedIcon$ | async"
    [svgIcon]="buttonQuery.iconName$ | async"
    [class.mat-icon-right]="buttonQuery.hasLabel$ | async"
    iconPositionEnd
  ></mat-icon>
</ng-template>
