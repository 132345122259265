import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-list-snackbar',
  templateUrl: './list-snackbar.component.html',
  styleUrls: ['./list-snackbar.component.scss'],
})
/**
 * The ListSnackbarComponent is used to display a multi-line bullet point message to the user in a snackbar.
 */
export class ListSnackbarComponent {
  /**
   * Constructor
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public snackBarRef: MatSnackBarRef<ListSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    console.log(data);
  }

  /**
   * Occurs when an Action Button (optional) has been clicked.
   *
   * @param event - the native mouse event
   */
  onDismiss() {
    this.snackBarRef?.dismiss();
  }
}
