import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Animates the media opacity when ready.
 *
 * - "ready" = occurs when the media is ready to play
 */
export const mediaReadyAnimation = trigger('mediaReadyAnimation', [
  state('true', style({ opacity: '1' })),
  state('false', style({ opacity: '0' })),
  transition('* <=> true', animate(100)),
  transition('* <=> false', animate(0)),
]);

/**
 * Animates the Paused and Play States
 */
export const mediaStateAnimation = trigger('mediaStateAnimation', [
  transition(':enter', [
    style({ transform: 'scale(3.8)', opacity: 0.75, display: 'block' }),
    animate('1s cubic-bezier(0.33, 1, 0.68, 1)', style({ transform: 'scale(6)', opacity: 0.0 })),
  ]),
]);
