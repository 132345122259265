import { ChangeDetectionStrategy, Component } from '@angular/core';
import { mediaStateAnimation } from '../media.animations';
import { MediaQuery } from '../media.query';

/**
 * The Media UI.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is not standalone and belongs within the {@link MediaComponent}.
 */
@Component({
  selector: 'app-media-ui',
  templateUrl: './media-ui.component.html',
  styleUrls: ['./media-ui.component.scss'],
  animations: [mediaStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUIComponent {
  /**
   * Constructor.
   *
   * @param mediaQuery - a reference to the media query.
   */
  constructor(public readonly mediaQuery: MediaQuery) {}
}
