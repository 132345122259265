<fa-icon
  *ngIf="faIconQuery.initialized$ | async"
  [size]="faIconQuery.size$ | async"
  [fixedWidth]="faIconQuery.fixedWidth$ | async"
  [rotate]="faIconQuery.rotate$ | async"
  [flip]="faIconQuery.flip$ | async"
  [spin]="faIconQuery.spin$ | async"
  [pulse]="faIconQuery.pulse$ | async"
  [style.color]="faIconQuery.color$ | async"
  [icon]="faIconQuery.name$ | async | faIconName"
  [mask]="faIconQuery.mask$ | async"
  [transform]="faIconQuery.transform$ | async"
></fa-icon>
