import { Injectable } from '@angular/core';
import { IShellEffects } from 'src/app/api/modules/core/components/effects/IShellEffects';
import { ESTIMATED_CONFETTI_EFFECT_DURATION } from '../../components/abstract/shell-fg-effects-container/effects/shell-fg-effects-confetti/shell-fg-effects-confetti.store';
import { ESTIMATED_CONFETTIS_EFFECT_DURATION } from '../../components/abstract/shell-fg-effects-container/effects/shell-fg-effects-confettis/shell-fg-effects-confettis.store';
import { ShellBGEffects } from './ShellBGEffects';
import { ShellFGEffects } from './ShellFGEffects';

/**
 * The ShellEffects Service provides the configuration for both
 * foreground and background effects.
 *
 * It belones to {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class ShellEffectsService {
  /**
   * Contains forground effects.
   */
  foreground = new ShellFGEffects();

  /**
   * Contains background effects.
   */
  background = new ShellBGEffects();

  /**
   * The durations for the foreground effects.
   *
   * This is estimated as techniquely the effects depend on their configuration.
   */
  private foregroundEffectsDurations = {
    confetti: ESTIMATED_CONFETTI_EFFECT_DURATION,
    confettis: ESTIMATED_CONFETTIS_EFFECT_DURATION,
  };

  /**
   * Create an effect.
   *
   * @param config - The configuration for the effects.
   * @returns the estimated effect duration in milliseconds.
   */
  effect(config?: IShellEffects) {
    // if there is no configuration then do nothing.
    if (!config) {
      return 0;
    }

    let estimatedEffectDelay = 0;
    let estimatedEffectDuration = 0;

    // if there is is a foreground configuration then pass it to the foreground effects.
    if (config.fg) {
      // process through the effects
      for (let i = 0; i < config.fg.length; i++) {
        // get the target effect
        const target = config.fg[i];

        // find the largest delay
        if (target.delayMS > estimatedEffectDelay) {
          estimatedEffectDelay = target.delayMS;
        }

        switch (target.effectName) {
          case 'confetti':
            if (this.foregroundEffectsDurations.confetti > estimatedEffectDuration) {
              estimatedEffectDuration = this.foregroundEffectsDurations.confetti;
            }
            break;
          case 'confettis':
            if (this.foregroundEffectsDurations.confettis > estimatedEffectDuration) {
              estimatedEffectDuration = this.foregroundEffectsDurations.confettis;
            }
        }
      }
      this.foreground.effect(config.fg);
    }

    return estimatedEffectDuration + estimatedEffectDelay;
  }
}
