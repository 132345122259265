import { Injectable } from '@angular/core';
import { IScreen } from 'src/app/api/modules/core/components/screen/IScreen';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { ScreenStore } from './screen.store';

@Injectable()
export class ScreenService<Configuration extends IScreen = IScreen> {
  /**
   * Constructor
   */
  constructor(private readonly store: ScreenStore<Configuration>) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize the Image Store from an {@link IImage} json configuration.
   *
   * @param configuration - the imagine configuration provided by the server.
   */
  initialize(configuration?: Configuration) {
    this.store.applyInitialize(configuration);
  }
}
