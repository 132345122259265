import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { MediaChange } from '@ngbracket/ngx-layout';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { createDigitaServiceError } from 'src/app/app-error';
import { IButtonContainer } from '../../../../../api/modules/core/dynamic/containers/IButtonContainer';
import { ButtonContainerModel } from './button-container.model';

const DEFAULT_BUTTON_CONTAINER_SETTINGS: ButtonContainerModel = {
  configured: false,
  layout: 'column',
  layoutAlign: 'start center',
  isStacked: true,
  mediaAlias: null,
  componentArray: [],
  buttonSizing: undefined,
  preferredButtonSizing: undefined,
  preferredLayout: undefined,
  buttonCount: 0,
};

function createInitialState(): ButtonContainerModel {
  return DEFAULT_BUTTON_CONTAINER_SETTINGS;
}

@Injectable()
export class ButtonContainerStore extends Store<ButtonContainerModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), {
      name: `button-container-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Initiailize a button store
   */
  initialize(configuration?: IButtonContainer) {
    // if there is no configuration then that is an issue
    if (!configuration) {
      throw createDigitaServiceError('ButtonContainer', 'initialize', 'You must supply a configuration to a button container.', 'config');
    }

    // if there are no buttons for the button container than that is an issue
    if (!configuration.componentArray || configuration.componentArray.length === 0) {
      throw createDigitaServiceError(
        'ButtonContainer',
        'initialize',
        'You must supply atleast one button to a button container.',
        'config'
      );
    }

    // check that all objects in the component array have a selector of type "app-button" or that is an error
    for (let i = 0; i < configuration.componentArray.length; i++) {
      const component = configuration.componentArray[i];

      if (component.selector !== 'app-button') {
        throw createDigitaServiceError(
          'ButtonContainer',
          'initialize',
          'All components in the component array must be of `"selector": "app-button"`',
          'config'
        );
      }
    }

    // preferred layout
    let preferredLayout: 'column' | 'row' | 'row-reverse' | undefined = undefined;
    if (configuration.layout) {
      preferredLayout = configuration.layout;
    }

    // preferred button sizing
    let preferredButtonSizing: 'fixedSize' | 'fullSize' | undefined = undefined;
    if (configuration.buttonSizing) {
      preferredButtonSizing = configuration.buttonSizing;
    }

    // component array
    let componentArray: IButton[] = [];
    if (configuration.componentArray) {
      componentArray = cloneDeep(configuration.componentArray);
    }

    // update
    this.update((draft) => {
      draft.configured = true;
      draft.preferredButtonSizing = preferredButtonSizing;
      draft.preferredLayout = preferredLayout;
      draft.componentArray = componentArray;
      draft.buttonCount = configuration.componentArray.length;
    });

    // process
    this.processState();
  }

  /**
   * Initiailize a button store
   */
  mediaChange(change: MediaChange) {
    // update
    this.update((draft) => {
      draft.mediaAlias = change.mqAlias;
    });

    // process
    this.processState();
  }

  /**
   * Takes the current configuration and uses that information to process the state
   */
  private processState() {
    let layout: 'row' | 'row-reverse' | 'column' = 'column';
    let layoutAlign = 'start center';
    let isStacked = true;
    let buttonSizing: 'fixedSize' | 'fullSize' | undefined = undefined;
    let validRow = false;

    const { preferredButtonSizing, preferredLayout, buttonCount, mediaAlias } = this.getValue();

    if (mediaAlias) {
      validRow = false;

      // first we will try to select the configurations preferred choice if it is a row or row-reverse
      if (preferredLayout === 'row' || preferredLayout === 'row-reverse') {
        // the user wants a row or row-reverse, but has more than 2 buttons, it cannot be a row and must remain a column
        if (buttonCount < 3) {
          // we should deal with responsiveness here too since you cannot have a row on small screens
          if (mediaAlias !== 'xs') {
            layout = preferredLayout;
            layoutAlign = 'space-between center';
            isStacked = false;
            validRow = true;
          }
        }
      }

      // if no valid row was created then we must have a column.
      if (validRow === false) {
        // if there is 1 button it has a different layout
        if (buttonCount === 1) {
          isStacked = false;
          buttonSizing = preferredButtonSizing;
        }
      }
    }

    // update
    this.update((draft) => {
      draft.layout = layout;
      draft.isStacked = isStacked;
      draft.layoutAlign = layoutAlign;
      draft.buttonSizing = buttonSizing;
    });
  }
}
