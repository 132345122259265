import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { IFrameEventCreateTextData } from '@digitaservice/utils';
import { uniqueId } from 'lodash-es';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { PluginTextQuery } from './plugin-text.query';
import { PluginTextService } from './plugin-text.service';
import { PluginTextStore } from './plugin-text.store';

@Component({
  selector: 'app-plugin-text',
  templateUrl: './plugin-text.component.html',
  styleUrls: ['./plugin-text.component.scss'],
  providers: [PluginTextStore, PluginTextQuery, PluginTextService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PluginTextComponent implements OnDestroy {
  /**
   * The Directive marked to place content dynamically
   */
  @ViewChild(DynamicContentDirective, { static: true }) contentContainer: DynamicContentDirective;

  /**
   * This is emitted when a plugin text entity is about to be destroyed.
   *
   * It includes any ID that was provided when added.
   */
  @Output() childDestroyed = new EventEmitter<string>();

  /**
   * The Plugin Text Component is a container for holding Plugin Text Entities and
   * managing their lifespans.
   */
  constructor(private readonly service: PluginTextService, public readonly query: PluginTextQuery) {}

  /**
   * Spawns a new Plugin Text Entity in the container which will immediately start animating.
   *
   * @param config - the configuration for the plugin text entity
   * @param id - an optional ID
   */
  add(config: IFrameEventCreateTextData, id: string = uniqueId('plugin-text-')) {
    this.service.add(id, config);
  }

  /**
   * Clears all text elements
   */
  clear() {
    this.service.clear();
  }

  /**
   * Emitted by the Template when a {@link PluginTextEntityComponent} has completed it's animation.
   *
   * @param id - the ID of the entity to remove
   */
  entityCompleted(id: string) {
    // remove the entity from the store
    this.service.remove(id);
    // emit the event
    this.childDestroyed.emit(id);
  }

  /**
   * Lifecycle Override
   */
  ngOnDestroy() {
    this.clear();
    this.childDestroyed?.complete();
  }

  /**
   * Trackby
   */
  trackById(index: number, item: KeyValue<string, IPluginTextEntity>) {
    return item.key; // or any unique property of your items
  }
}
