import { IFrameEventCreateDialogMedia } from '@digitaservice/utils/@types';
import { GenerateMedia } from '../generators/media.generator';

/**
 * Plugins recieve a PostMessage format which must be converted into a Media API format.
 *
 * @param incoming - the simple incoming string
 */
export const ConvertPluginToMedia = (incoming?: IFrameEventCreateDialogMedia) => {
  if (incoming) {
    const media = GenerateMedia(incoming);
    return media;
  }
  return null;
};
