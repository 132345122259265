import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IIconState } from 'src/app/api/modules/icons/IIconState';
import { IconStateModel } from './icon-state.model';

function createInitialState(): IconStateModel {
  return {
    states: [],
    state: undefined,
    stateName: 'default',
    initialized: false,
  };
}

@Injectable()
export class IconStateStore extends Store<IconStateModel> {
  constructor() {
    super(createInitialState(), {
      name: `icon-state-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Initiailize the Icon State.
   *
   * @param configuration - the configuration of the state as provided by the server.
   */
  initialize(configuration: IIconState) {
    const { stateName } = this.getValue();

    // if there is no configuration then return
    if (!configuration && !configuration.states) {
      return;
    }

    // clone the configuration states
    const states = cloneDeep(configuration.states);

    // the default state
    const newStateName = stateName || 'default';

    // find the default state
    const state = states.find((state) => state.state === newStateName);

    // update the store
    this.update((draft) => {
      draft.states = states;
      draft.stateName = newStateName;
      draft.state = state;
      draft.initialized = true;
    });
  }

  /**
   * Selects the state.
   *
   * @param name - the name of the state to select
   */
  state(name: string) {
    const { states } = this.getValue();

    // the new state
    let stateName = name;
    if (typeof stateName !== 'string') {
      stateName = 'default';
    }

    // find the new state
    const state = states.find((state) => state.state === stateName);

    // update the store
    this.update((draft) => {
      draft.stateName = stateName;
      draft.state = state;
    });
  }
}
