import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DataboxProgressionNumericModel } from './databox-progression-numeric.model';
import { DataboxProgressionNumericStore } from './databox-progression-numeric.store';

/**
 * Qureries the State of an instance of {@link DataboxProgressionNumericStore}.
 */
@Injectable()
export class DataboxProgressionNumericQuery extends Query<DataboxProgressionNumericModel> {
  /**
   * The Final Output State.
   */
  output$ = this.select((state) => state.output);

  /**
   * Constructor
   */
  constructor(protected override readonly store: DataboxProgressionNumericStore) {
    super(store);
  }
}
