import { animate, animation, group, keyframes, style } from '@angular/animations';
import { ANIMATION_DELAY, ANIMATION_DURATION } from '../AnimationConsts';
import { IAnimationOptions } from '../IAnimationOptions';

export const bounceInAnimation = (options: IAnimationOptions) => {
  return animation([
    style({
      opacity: 0
    }),
    group([
      animate(
        `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
        keyframes([
          style({ transform: 'scale3d(0.3, 0.3, 0.3)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0 }),
          style({ transform: 'scale3d(1.1, 1.1, 1.1)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0.2 }),
          style({ transform: 'scale3d(0.9, 0.9, 0.9)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0.4 }),
          style({ transform: 'scale3d(1.03, 1.03, 1.03)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0.6 }),
          style({ transform: 'scale3d(0.97, 0.97, 0.97)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0.8 }),
          style({ transform: 'scale3d(1, 1, 1)', easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 1 })
        ])
      ),
      animate(
        `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
        keyframes([
          style({ visibility: 'visible', opacity: 1, easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0 }),
          style({ opacity: 1, easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 0.6 }),
          style({ opacity: 1, easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)', offset: 1 })
        ])
      )
    ])
  ]);
};

export const bounceOutAnimation = (options: IAnimationOptions) => {
  return animation([
    style({
      opacity: 1
    }),
    group([
      animate(
        `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
        keyframes([
          style({ transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 0 }),
          style({ transform: 'scale3d(0.9, 0.9, 0.9)', easing: 'ease', offset: 0.2 }),
          style({ transform: 'scale3d(1.1, 1.1, 1.1)', easing: 'ease', offset: 0.5 }),
          style({ transform: 'scale3d(1.1, 1.1, 1.1)', easing: 'ease', offset: 0.55 }),
          style({ transform: 'scale3d(0.3, 0.3, 0.3)', easing: 'ease', offset: 1 })
        ])
      ),
      animate(
        `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
        keyframes([
          style({ opacity: 1, easing: 'ease', offset: 0 }),
          style({ opacity: 1, easing: 'ease', offset: 0.55 }),
          style({ opacity: 0, easing: 'ease', offset: 1 })
        ])
      )
    ])
  ]);
};
