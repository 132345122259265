export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '5.0.0',
    name: '@digitaservice/app',
    versionDate: '2024-04-23T10:22:38.237Z',
    gitCommitHash: '457bb718',
    versionLong: '5.0.0-457bb718',
};
export default versions;
