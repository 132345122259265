import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { CountdownFormat, ICountdown, ICountdownLabels } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { ICountdownResponseData } from 'src/app/api/modules/core/dynamic/components/countdown/network/response/ICountdownResponseData';
import { createDigitaServiceError } from 'src/app/app-error';
import { CountdownModel } from './countdown.model';
import { secondsToCountdown } from './countdown.utils';

function createInitialState(): CountdownModel {
  return {
    configured: false,
    startTime: 0,
    endTime: 0,
    format: CountdownFormat.DAYS,
    onCompleteAPI: undefined,
    labels: undefined,
    initTime: 0,
    onCompleteResponse: false,
    destinationDelay: 0,
    destination: undefined,
    data: {
      remainingSeconds: 0,
      days: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      hours: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      minutes: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      seconds: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
    },
  };
}
/**
 * Manages the State of a {@link CountdownComponent}.
 */
@Injectable()
export class CountdownStore extends Store<CountdownModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), {
      name: `countdown-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: ICountdown) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`Countdown`, `initialize`, `No configuration provided.`, `config`);
    }

    // format
    let format: CountdownFormat | undefined = undefined;
    if (configuration.format) {
      switch (configuration.format) {
        case CountdownFormat.DAYS:
        case CountdownFormat.HOURS:
        case CountdownFormat.MINUTES:
        case CountdownFormat.SECONDS:
          format = configuration.format;
          break;
      }
    }
    if (!format) {
      throw createDigitaServiceError(
        `Countdown`,
        `initialize`,
        `No "format" provided. This should be "days" "hours" "minutes" or "seconds".`,
        `config`
      );
    }

    // labels
    let labels: ICountdownLabels | undefined = undefined;
    if (configuration.labels) {
      const { day, days, hour, hours, minute, minutes, second, seconds } = configuration.labels;

      [day, days, hour, hours, minute, minutes, second, seconds].forEach((label) => {
        if (typeof label !== 'string' || label.length === 0) {
          throw createDigitaServiceError(
            'Countdown',
            'initialize',
            `The "label.${label}" property must be provided otherwise no labels should be used.`,
            'config'
          );
        }
      });
    }
    labels = cloneDeep(configuration.labels);

    // start time
    const startTime = configuration.startTime;
    if (typeof startTime !== 'number' || startTime < 0) {
      throw createDigitaServiceError(
        'Countdown',
        'initialize',
        `The "startTime" property must be provided and be a number greater than 0.`,
        'config'
      );
    }

    // end time
    const endTime = configuration.endTime;
    if (typeof endTime !== 'number' || endTime < 0) {
      throw createDigitaServiceError(
        'Countdown',
        'initialize',
        `The "endTime" property must be provided and be a number greater than 0.`,
        'config'
      );
    }

    // init time
    const initTime = Math.floor(Date.now() / 1000);

    // on countdown complete API
    // Note that this can be empty if the countdown is for display purposes only.
    const onCompleteAPI = configuration.onCompleteAPI;

    // get the original data
    const { data } = this.getValue();
    // make the original conversion
    const originalConversion = secondsToCountdown(startTime, endTime, initTime, format, data, labels, true);

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.format = format;
      draft.labels = labels;
      draft.startTime = startTime;
      draft.endTime = endTime;
      draft.initTime = initTime;
      draft.data = originalConversion;
      draft.onCompleteAPI = onCompleteAPI;
    });
  }

  /**
   * Applies a 1 second tick to the store.
   */
  applyTick() {
    const { startTime, endTime, initTime, format, data, labels } = this.getValue();
    const newData = secondsToCountdown(startTime, endTime, initTime, format, data, labels, false);

    this.update((draft) => {
      draft.data = newData;
    });
  }

  /**
   * Apply the response from an API call to the store.
   *
   * @param response - the response from the server
   */
  applyCountdownCompleteResponse(response: ICountdownResponseData) {
    // if there is no response
    if (!response) {
      // do nothing
      return;
    }

    const destinationDelay = response.destinationDelay || 0;
    const destination = response.destination;

    if (!destination) {
      throw createDigitaServiceError(
        'Countdown',
        'response',
        `The "destination" property must be provided as part of the Countdown API Response.`,
        'config'
      );
    }

    this.update((draft) => {
      draft.onCompleteResponse = true;
      draft.destinationDelay = destinationDelay;
      draft.destination = destination;
    });
  }
}
