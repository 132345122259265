import { IFrameEventCreateDialogImage } from '@digitaservice/utils/@types';
import { GenerateImage } from '../generators/image.generator';

/**
 * Plugins recieve a PostMessage format which must be converted into a Image API format.
 *
 * @param incoming - the simple incoming string
 */
export const ConvertPluginToImage = (incoming: string | IFrameEventCreateDialogImage) => {
  if (incoming) {
    return GenerateImage(incoming);
  }

  return null;
};
