<div class="mat-mdc-table-scroller">
  <table *ngIf="config" class="mat-mdc-table">
    <thead *ngIf="config.headers">
      <tr>
        <th *ngFor="let cell of config.headers">
          <div class="cell-flex">
            <span *ngIf="cell" class="mat-body-2"> {{ cell }}</span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="config.rows">
      <tr *ngFor="let row of config.rows">
        <td *ngFor="let cell of row">
          <div class="cell-flex">
            <span *ngIf="cell" class="mat-body-2"> {{ cell }}</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="config.footers">
      <tr>
        <td *ngFor="let cell of config.footers">
          <div class="cell-flex">
            <span *ngIf="cell" class="mat-body-2"> {{ cell }}</span>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
