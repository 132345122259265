import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import confetti from 'canvas-confetti';
import { Subscription, forkJoin, from, switchMap } from 'rxjs';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';
import { ShellFGEffectsConfettisQuery } from './shell-fg-effects-confettis.query';
import { ShellFGEffectsConfettisService } from './shell-fg-effects-confettis.service';
import { ShellFGEffectsConfettisStore } from './shell-fg-effects-confettis.store';

/**
 * The Shell Background is the main Component responsible for managing background subsystems.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-shell-fg-effects-confettis',
  templateUrl: './shell-fg-effects-confettis.component.html',
  styleUrls: ['./shell-fg-effects-confettis.component.scss'],
  providers: [ShellFGEffectsConfettisService, ShellFGEffectsConfettisQuery, ShellFGEffectsConfettisStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellFGEffectsConfettisComponent implements AfterViewInit, OnDestroy {
  /**
   * The configuration for the confettis effect
   */
  private _config: IShellFGEffectsConfettis | undefined = undefined;
  @Input() set config(config: IShellFGEffectsConfettis) {
    this._config = config;
    this.service.applyInitialize(config);
  }
  get config() {
    return this._config;
  }

  /**
   * Occurs when the effect is complete.
   *
   * Emits the effectID.
   */
  @Output() complete = new EventEmitter<string>();

  /**
   * Holds the subscription for teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Constructor
   */
  constructor(private readonly service: ShellFGEffectsConfettisService, public readonly query: ShellFGEffectsConfettisQuery) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // apply the configuration and create the confetti effect
    const confettiSub = this.query.confettisConfig$
      .pipe(
        switchMap((config) => {
          return forkJoin([from(confetti(config.left)), from(confetti(config.right))]);
        })
      )
      .subscribe(() => {
        this.service.applyComplete();
      });
    this.subscriptions.add(confettiSub);

    // listen for complete
    const completeSub = this.query.completeConfig$.subscribe((effectID) => {
      this.complete.next(effectID);
      this.complete.complete();
    });
    this.subscriptions.add(completeSub);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.complete?.complete();
  }
}
