import { ComponentRef, ViewContainerRef } from '@angular/core';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { IMediaLock } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { IPromoCode } from 'src/app/api/modules/core/dynamic/components/IPromoCode';
import { ISocialSharing } from 'src/app/api/modules/core/dynamic/components/ISocialSharing';
import { ITable } from 'src/app/api/modules/core/dynamic/components/ITable';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { ICountdown } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { ILeaderboard } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboard';
import { IPlugin } from 'src/app/api/modules/core/dynamic/plugin/components/IPlugin';
import { IAbstractComponent } from 'src/app/api/modules/shared/IAbstractComponent';
import { createDigitaServiceError } from 'src/app/app-error';
import { createButtonComponent } from '../../components/button/button.factory';
import { CountdownComponent } from '../../components/countdown/countdown.component';
import { ImageComponent } from '../../components/image/image.component';
import { LeaderboardComponent } from '../../components/leaderboard/leaderboard.component';
import { MediaLockComponent } from '../../components/media-lock/media-lock.component';
import { MediaComponent } from '../../components/media/media.component';
import { PromoCodeComponent } from '../../components/promo-code/promo-code.component';
import { SocialSharingComponent } from '../../components/social-sharing/social-sharing.component';
import { TableComponent } from '../../components/table/table.component';
import { createTextRichComponent } from '../../components/text-rich/text-rich.factory';
import { PluginComponent } from '../../plugin/plugin/plugin.component';
import { createButtonContainerComponent } from '../button-container/button-container.factory';
import { ContainerComponent } from './container.component';

/**
 * This function serves as a factory for creating an array of dynamic components based on the configurations provided.
 * It iterates over each configuration object, creates the corresponding component, and collects them into an array.
 *
 * @param viewContainerRef A reference to the container where the dynamic components will be injected.
 * @param components An array of configuration objects that conform to IAbstractComponent. Each object includes the necessary information to create and configure a dynamic component.
 * @returns An array of ComponentRef<any> which are references to the created dynamic components.
 */
export const ContainerFactoryArray = (viewContainerRef: ViewContainerRef, components: IAbstractComponent[]) => {
  // all of the dynamic components that are created are stored in this array and returned
  const componentRefs: ComponentRef<any>[] = [];

  // go through each of the component configurations
  for (let i = 0; i < components.length; i++) {
    // the config
    const config = components[i];
    // the component reference
    const componentRef = ContainerFactoryItem(viewContainerRef, config);
    // add the component reference to the array
    componentRefs.push(componentRef);
  }
  // return the component references
  return componentRefs;
};

/**
 * Creates a single dynamic component based on the provided configuration. The function checks the selector property
 * of the configuration and creates the corresponding component. If the selector is not recognized, it throws an error.
 *
 * @param viewContainerRef A reference to the container where the dynamic component will be injected.
 * @param config An object that conforms to IAbstractComponent which includes the selector and other configuration needed to create and configure the component.
 * @returns A ComponentRef<any> which is a reference to the created dynamic component.
 * @throws Will throw an error if the selector is undefined or does not match any recognized component type.
 */
export const ContainerFactoryItem = (viewContainerRef: ViewContainerRef, config: IAbstractComponent) => {
  switch (config.selector) {
    default: {
      if (config.selector) {
        throw createDigitaServiceError(
          `Container`,
          `Factory`,
          `The selector (${config.selector}) is not a valid dynamic component.`,
          `config`
        );
      } else {
        throw createDigitaServiceError(
          `Container`,
          `Factory`,
          `The selector ( undefined ) is not a valid dynamic component. You must supply a selector.`,
          `config`
        );
      }
    }
    case 'app-text-rich': {
      const componentReference = createTextRichComponent(viewContainerRef, config as ITextRich);
      return componentReference;
    }
    case 'app-social-sharing': {
      const componentReference = viewContainerRef.createComponent(SocialSharingComponent);
      const component = componentReference.instance;
      component.config = config as ISocialSharing;
      component.detectChanges();
      return componentReference;
    }
    case 'app-image': {
      const componentReference = viewContainerRef.createComponent(ImageComponent);
      const component = componentReference.instance;
      component.config = config as IImage;
      return componentReference;
    }
    case 'app-button': {
      const componentReference = createButtonComponent(viewContainerRef, config as IButton);
      return componentReference;
    }
    case 'app-button-container': {
      const componentReference = createButtonContainerComponent(viewContainerRef, config as IButtonContainer);
      return componentReference;
    }
    case 'app-media': {
      const componentReference = viewContainerRef.createComponent(MediaComponent);
      const component = componentReference.instance;
      component.config = config as IMedia;
      return componentReference;
    }
    case 'app-media-lock': {
      const componentReference = viewContainerRef.createComponent(MediaLockComponent);
      const component = componentReference.instance;
      component.config = config as IMediaLock;
      return componentReference;
    }
    case 'app-container': {
      const componentReference = viewContainerRef.createComponent(ContainerComponent);
      const component = componentReference.instance;
      component.config = config as ICoreContainer;
      return componentReference;
    }
    case 'app-plugin': {
      const componentReference = viewContainerRef.createComponent(PluginComponent);
      const component = componentReference.instance;
      component.config = config as IPlugin;
      return componentReference;
    }
    case 'app-leaderboard': {
      const componentReference = viewContainerRef.createComponent(LeaderboardComponent);
      const component = componentReference.instance;
      component.config = config as ILeaderboard;
      return componentReference;
    }
    case 'app-table': {
      const componentReference = viewContainerRef.createComponent(TableComponent);
      const component = componentReference.instance;
      component.config = config as ITable;
      return componentReference;
    }
    case 'app-promo-code': {
      const componentReference = viewContainerRef.createComponent(PromoCodeComponent);
      const component = componentReference.instance;
      component.config = config as IPromoCode;
      return componentReference;
    }
    case 'app-countdown': {
      const componentReference = viewContainerRef.createComponent(CountdownComponent);
      const component = componentReference.instance;
      component.config = config as ICountdown;
      return componentReference;
    }
  }
};
