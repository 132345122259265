import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IconStateModel } from './icon-state.model';
import { IconStateStore } from './icon-state.store';

@Injectable()
export class IconStateQuery extends Query<IconStateModel> {
  state$ = this.select((state) => state.state);
  stateName$ = this.select((state) => state.stateName);
  initialized$ = this.select((state) => state.initialized);

  constructor(protected override readonly store: IconStateStore) {
    super(store);
  }
}
