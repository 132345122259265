import { AnimationBuilder, AnimationFactory } from '@angular/animations';
import { Injectable } from '@angular/core';
import { IAnimationCompoundOptions } from './IAnimationCompoundOptions';
import { bounceInOutCompoundAnimation } from './compounds/bounce-compound.animation';
import { fadeInOutCompoundAnimation } from './compounds/fade-compound.animation';
import { rollInOutCompoundAnimation } from './compounds/roll-compound.animation';
import {
  slideInOutFromBottomCompoundAnimation,
  slideInOutFromBottomReversedCompoundAnimation,
  slideInOutFromLeftCompoundAnimation,
  slideInOutFromLeftReversedCompoundAnimation,
  slideInOutFromRightCompoundAnimation,
  slideInOutFromRightReversedCompoundAnimation,
  slideInOutFromTopCompoundAnimation,
  slideInOutFromTopReversedCompoundAnimation,
} from './compounds/slide-compound.animation';
import { zoomInOutCompoundAnimation, zoomInOutTowardsCompoundAnimation } from './compounds/zoom-compound.animation';
import { IAnimationFadeTo, IAnimationResizeHeightTo, fadeToAnimation, resizeHeightToAnimation } from './parts/to.animation';

@Injectable({
  providedIn: 'root',
})
export class AnimationBuilderService {
  /**
   * The Animation Builder Service create programmatic Angular Animations
   * @param animationBuilder - reference to the animation builder
   */
  constructor(private animationBuilder: AnimationBuilder) {}

  /**
   * Constructs a player for an Animation Meta
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param animationFactory - the animation factory data to construct a player for
   */
  private construct(element: HTMLElement, autoplay = true, animationFactory: AnimationFactory) {
    const player = animationFactory.create(element);
    if (autoplay) {
      player.play();
    }
    return player;
  }

  /**
   * Creates a bounce in, bounce out sequence.
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  bounceInOut(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(bounceInOutCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a fade in-out sequence
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  fadeInOut(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(fadeInOutCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the left that exits to the right
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromLeft(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromLeftCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the right that exits to the left
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromRight(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromRightCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the top that exits to the bottom
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromTop(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromTopCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the bottom that exits to the top
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromBottom(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromBottomCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the left that exits to the left
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromLeftReversed(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromLeftReversedCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the right that exits to the right
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromRightReversed(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromRightReversedCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the top that exits to the top
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromTopReversed(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromTopReversedCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a Slide in from the bottom that exits to the bottom
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  slideInOutFromBottomReversed(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(slideInOutFromBottomReversedCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a fading small zoom in to small zoom out
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  zoomInOut(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(zoomInOutCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a fading small zoom in, to big zoom out
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  zoomInOutTowards(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(zoomInOutTowardsCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Creates a fading roll in and roll out
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  rollInOut(element: HTMLElement, autoplay = true, options: Partial<IAnimationCompoundOptions> = {}) {
    const anim = this.animationBuilder.build(rollInOutCompoundAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Changes an elements height from its current height to a target height
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  resizeHeightTo(element: HTMLElement, autoplay = true, options: Partial<IAnimationResizeHeightTo> = {}) {
    const anim = this.animationBuilder.build(resizeHeightToAnimation(options));
    return this.construct(element, autoplay, anim);
  }

  /**
   * Fades an element from its current opacity to a target opacity
   *
   * @param element - the element to operate on
   * @param autoplay - should the animation autoplay?
   * @param options - the options for the transition
   */
  fadeTo(element: HTMLElement, autoplay = true, options: Partial<IAnimationFadeTo> = {}) {
    const anim = this.animationBuilder.build(fadeToAnimation(options));
    return this.construct(element, autoplay, anim);
  }
}
