import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { delay, EMPTY, filter, of, switchMap, take } from 'rxjs';
import { MediaManagerQuery } from '../../../services/media-manager/media-manager.query';
import { MediaModel } from './media.model';
import { MediaStore } from './media.store';
import { MediaTypes } from './media.types';

/**
 * The Query used for an {@link MediaComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaQuery extends Query<MediaModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured. This means a configuration has been passed to the
   * component and has been successfully parsed and validated.
   */
  private _configured$ = this.select((state) => state.configured).pipe(delay(100));

  /**
   * What is the type of the Media?
   */
  private _type$ = this.select((state) => state.type);

  /**
   * Once the Media has been configured, we can get the type from the configuration as it
   * would have been set.
   */
  configuredType$ = this._configured$.pipe(
    filter((configured) => configured),
    switchMap(() => {
      return this._type$;
    })
  );

  /**
   * Has an error occurred?
   */
  error$ = this.select((state) => state.error);

  /**
   * What is the reason for the error?
   */
  errorReason$ = this.select((state) => state.errorReason);

  /**
   * The Error Icon to display if things go wrong.
   */
  errorIcon$ = this.select((state) => state.errorIcon);

  /**
   * Once the Media has stopped initializing it becomes initialized.
   */
  initialized$ = this.select((state) => state.initialized);

  /**
   * The system is initializing when any of the following is true:
   *
   *    1. The Media is not configured with a JSON configuration.
   *    2. There is an error
   */
  initializing$ = combineQueries([this.initialized$, this.error$]).pipe(
    switchMap(([initialized, error]) => {
      if (initialized || error) {
        return of(false);
      } else {
        return of(true);
      }
    })
  );

  /**
   * This is true then the system is initializing and an error occurs.
   */
  initializingError$ = combineQueries([this.initialized$, this.error$]).pipe(
    switchMap(([initialized, error]) => {
      if (!initialized && error) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // PLAYBACK STATUS
  //////////////////////////////////////////////////////////////////////////////////

  // is the media ready to play?
  private _isReady$ = this.select((state) => state.ready);

  /**
   * Is the player currently ready? This is the case if:
   *
   *    1. The media is initialized
   *    2. The media is ready
   */
  ready$ = combineQueries([this.initialized$, this._isReady$, this.error$]).pipe(
    switchMap(([initialized, ready, error]) => {
      if (initialized && ready) {
        if (!error) {
          return of(true);
        }
      }
      return EMPTY;
    }),
    // the system can only be ready once.
    take(1)
  );

  /**
   * The Media ID of this media. This is used with {@link MediaManagerService} to
   * determine which media is currently playing globally within the app.
   */
  private _mediaID$ = this.select((state) => state.mediaID);

  /**
   * Is the media currently playing?
   */
  private _mediaPlaying$ = this.select((state) => state.playing);

  /**
   * Is the media currently playing? This is true if:
   *
   *   1. The media is initialized and contains an ID
   *   2. The media is playing
   *
   * It returns both pieces of data.
   */
  playing$ = combineQueries([this._mediaID$, this._mediaPlaying$]).pipe(
    switchMap(([mediaID, playing]) => {
      return of({
        mediaID,
        playing,
      });
    })
  );

  /**
   * Has the media ended?
   */
  ended$ = this.select((state) => state.ended);

  /**
   * Is the media currently paused?
   */
  private _paused$ = this.select((state) => state.paused);

  /**
   * Is the media currently paused?
   *
   * Note that this
   */
  paused$ = combineQueries([this.ended$, this._paused$]).pipe(
    switchMap(([ended, paused]) => {
      // if the media is paused
      if (paused) {
        // and if the media is not ended then yes it's paused
        // if the media has ended, then the pause animation state
        // in media-ui should not be displayed.
        if (!ended) {
          return of(true);
        }
      }
      return of(false);
    })
  );

  /**
   * The Duration of the media in seconds.
   *
   * (How long the total length of the media is)
   */
  duration$ = this.select((state) => state.duration);

  /**
   * The Current Time of the media in seconds.
   *
   * (How much of the video length has passed).
   */
  currentTime$ = this.select((state) => state.currentTime);

  /**
   * Return the progress of playback as a percentage.
   */
  progress$ = combineQueries([this.currentTime$, this.duration$]).pipe(
    switchMap(([currentTime, duration]) => {
      if (currentTime && duration > 0) {
        return of(Math.round((currentTime / duration) * 100));
      }
      return of(0);
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // CONFIGURED OPTIONS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media configured with controls?
   */
  private _controls$ = this.select((state) => state.controls);

  /**
   * Does the media have controls? This is used specifically by the {@link MediaVimeoComponent}
   * to apply a small div on top of the video to allow the user to click on it to play or pause
   * the media.
   */
  controls$ = combineQueries([this._isReady$, this._controls$]).pipe(
    switchMap(([ready, controls]) => {
      if (ready && controls) {
        return of(true);
      }
      return of(false);
    })
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MEDIA DETAILS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Have details been obtained? This is primary used for streaming providers such as
   * YouTube and Vimeo. Because these services require an API call to obtain the details
   * of the media. This is also used for Native Video and Audio but in those cases the
   * value is always true since the data for those is provided upfront during configuration.
   */
  private _detailsObtained$ = this.select((state) => state.detailsObtained);

  /**
   * The Poster of the Media. This is optional.
   */
  private _poster = this.select((state) => state.poster);

  /**
   * The Width of the Media.
   */
  private _width$ = this.select((state) => state.width);

  /**
   * The Height of the Media.
   */
  private _height$ = this.select((state) => state.height);

  //////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE CONDITIONALS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the Media intersecting with the DOM?
   */
  private _intersecting$ = this.select((state) => state.intersecting);

  /**
   * Has the Media previously intersected with the DOM?
   */
  private _hasIntersectedPreviously$ = this.select((state) => state.hasIntersectedPreviously);

  /**
   * Should media be rendered? This occurs if both the following is true:
   *
   *    1. The media is initialized.
   *    2. The media has previously intersected with the DOM.
   */
  private _shouldMediaRender$ = combineQueries([this.initialized$, this._hasIntersectedPreviously$]).pipe(
    switchMap(([initialized, hasIntersectedPreviously]) => {
      if (initialized && hasIntersectedPreviously) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should the YouTube player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a YouTube video.
   *    2. The media should be rendered.
   */
  shouldRenderYouTube$ = combineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.youtube && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should the Vimeo player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a Vimeo video.
   *    2. The media should be rendered.
   */
  shouldRenderVimeo$ = combineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.vimeo && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should the Native Video player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a native Video.
   *    2. The media should be rendered.
   */
  shouldRenderVideo$ = combineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.video && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should the Audio player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a Native Audio.
   *    2. The media should be rendered.
   */
  shouldRenderAudio$ = combineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.audio && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * What is the aspect ratio of the Media? This occurs when the width and height are both provided
   * otherwise 0 is returned.
   */
  aspectRatio$ = combineQueries([this._width$, this._height$]).pipe(
    switchMap(([width, height]) => {
      if (typeof width === 'number' && typeof height === 'number') {
        if (width > 0 && height > 0) {
          return of(width / height);
        }
      }
      return of(0);
    })
  );

  /**
   * Determine if the Media is Portrait. This is known when:
   *
   *   1. The Media Details have been obtained.
   *   2. The Media Height is greater than the Media Width.
   */
  isMediaPortrait$ = combineQueries([this._detailsObtained$, this._width$, this._height$]).pipe(
    switchMap(([detailsObtained, width, height]) => {
      if (detailsObtained) {
        if (height > width) {
          return of(true);
        }
      }
      return of(false);
    })
  );

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  limitPortraitWidthOnDesktop$ = combineQueries([this._mediaObserver$, this.isMediaPortrait$]).pipe(
    switchMap(([changes, isMediaPortrait]) => {
      // if the media is not portrait then this doesn't apply
      if (!isMediaPortrait) {
        return of(false);
      }

      // get the first change
      const targetChange = changes[0];

      // if the media alias is not xs then we want to limit the width
      if (targetChange.mqAlias !== 'xs') {
        return of(true);
      }

      return of(false);
    })
  );

  // a poster (if available) can only be shown when no errors exist.
  private _shouldPosterBeDisplayed = combineQueries([this.error$, this._poster]);

  /**
   * Is there a poster for this media?
   */
  hasPoster$ = this._detailsObtained$.pipe(
    filter((details) => details),
    switchMap(() => {
      return this._shouldPosterBeDisplayed;
    }),
    switchMap(([error, poster]) => {
      if (!error && poster) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * The Poster Image of the Media. Returned as a CSS background image.
   */
  poster$ = this.hasPoster$.pipe(
    filter((hasPoster) => hasPoster),
    switchMap(() => {
      return this._poster;
    }),
    switchMap((poster) => {
      return of(`url("${poster}")`);
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // YOUTUBE PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The YouTube Settings configured for the Media.
   */
  private _youTubeSettings$ = this.select((state) => state.youtube);

  /**
   * If the Media is YouTube, return the YouTube Settings.
   */
  youtubeSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.youtube) {
        return this._youTubeSettings$;
      }
      return EMPTY;
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // VIMEO PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The Vimeo Settings configured for the Media.
   */
  private _vimeoSettings$ = this.select((state) => state.vimeo);

  /**
   * If the Media is Vimeo, return the Vimeo Settings.
   */
  vimeoSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.vimeo) {
        return this._vimeoSettings$;
      }
      return EMPTY;
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // NATIVE PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The Native Video Settings configured for the Media.
   */
  private _videoSettings$ = this.select((state) => state.video);

  /**
   * If the Media Video is Native, return the Native Settings.
   */
  videoSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.video) {
        return this._videoSettings$;
      }
      return EMPTY;
    })
  );

  /**
   * The Native Audio Settings configured for the Media.
   */
  private _audioSettings$ = this.select((state) => state.audio);

  /**
   * If the Media Audio is Native, return the Native Settings.
   */
  audioSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.audio) {
        return this._audioSettings$;
      }
      return EMPTY;
    })
  );

  /**
   * If it's a Audio Source is there is a poster?
   */
  audioPoster$ = this.audioSettings$.pipe(
    switchMap((settings) => {
      if (settings?.audioposter) {
        return of(settings.audioposter);
      }
      return EMPTY;
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // MEDIA UI
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media interactive which means the user can interact with it?
   */
  private _interaction$ = this.select((state) => state.interaction);

  /**
   * Have interactions been disabled? The opposite of interactive.
   */
  interactionDisabled$ = this._interaction$.pipe(
    switchMap((interaction) => {
      if (interaction) {
        return of(false);
      }
      return of(true);
    })
  );

  /**
   * Should drimify custom UI be displayed?
   */
  private _displayUIOverlay$ = this.select((state) => state.displayUIOverlay);

  /**
   * Does the size of the component meet the minimum requirements to display the UI? For example
   * this prevents a thin audio player from being intersected by the UI.
   */
  private _displayUIMinimumSize$ = combineQueries([this._width$, this._height$]).pipe(
    switchMap(([width, height]) => {
      if (width > 179 && height > 179) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should the Media UI be displayed? This is never the case for YouTube which has it's own UI
   * at all times.
   */
  displayUIOverlay$ = combineQueries([this._displayUIOverlay$, this._displayUIMinimumSize$]).pipe(
    switchMap(([displayUIOverlay, displayUIMinimumSize]) => {
      if (displayUIOverlay && displayUIMinimumSize) {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Has the media previously played?
   */
  private _hasMediaPlayed$ = this.select((state) => state.hasMediaPlayed);

  /**
   * Should the Media UI Play Button be displayed?
   */
  displayUIPlayButton$ = combineQueries([
    this.error$,
    this._isReady$,
    this.displayUIOverlay$,
    this._hasMediaPlayed$,
    this.interactionDisabled$,
  ]).pipe(
    switchMap(([error, isReady, displayUIOverlay, hasMediaPlayed, interactionDisabled]) => {
      if (isReady && displayUIOverlay && !hasMediaPlayed) {
        if (!error && !interactionDisabled) {
          return of(true);
        }
      }
      return of(false);
    })
  );

  /**
   * Should the Media UI enable a click zone to play the media?
   */
  displayUIControlZone$ = combineQueries([this.error$, this._isReady$, this.displayUIOverlay$, this.controls$, this._interaction$]).pipe(
    switchMap(([error, isReady, displayUIOverlay, controls, interaction]) => {
      if (!error && isReady && displayUIOverlay) {
        if (!controls && interaction) {
          return of(true);
        }
      }
      return of(false);
    })
  );

  //////////////////////////////////////////////////////////////////////////////////
  // MEDIA MANAGER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * By default, media will pause when the media is no longer intersecting, this
   * can be overridden with this property.
   */
  private preventPauseOnViewportExit$ = this.select((state) => state.preventPauseOnViewportExit);

  /**
   * When a Media Item is no longer intersecting with the viewport, should that media
   * be paused?
   */
  shouldPauseWhenNoLongerIntersecting = combineQueries([this.preventPauseOnViewportExit$, this._intersecting$]).pipe(
    switchMap(([preventMediaPauseWhenNotIntersecting, intersecting]) => {
      // if the media is intersecting
      if (intersecting === true) {
        // then it shouldn't pause
        return of(false);
      } else {
        // if the media is not intersecting
        // and specifically if the default behavior has been disabled
        if (preventMediaPauseWhenNotIntersecting === true) {
          // then no it shouldn't be paused.
          return of(false);
        }
      }

      // otherwise it should.
      return of(true);
    })
  );

  /**
   * Pause the Media if it is not the active Media.
   */
  pauseAsNotActiveMedia$ = combineQueries([this._isReady$, this.playing$, this.mediaManagerQuery.activeMediaID$]).pipe(
    switchMap(([ready, playing, activeMediaID]) => {
      // if the media is ready and playing but the active media is a different media
      if (ready && playing?.playing && playing.mediaID !== activeMediaID) {
        // then the media should be paused
        return of(true);
      }
      return of(false);
    }),
    filter((pause) => pause)
  );

  /**
   * Constructor.
   */
  constructor(
    protected override readonly store: MediaStore,
    private readonly mediaManagerQuery: MediaManagerQuery,
    protected readonly mediaObserver: MediaObserver
  ) {
    super(store);
  }
}
