import { Component } from '@angular/core';
import { ApplicationQuery } from '../../../services/application/application.query';

@Component({
  selector: 'app-shell-loader',
  templateUrl: './shell-loader.component.html',
  styleUrls: ['./shell-loader.component.scss'],
})
export class ShellLoaderComponent {
  /**
   * The Shell Loader Component is the main loading indicator
   */
  constructor(public readonly appQuery: ApplicationQuery) {}
}
