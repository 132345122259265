import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { TextRichQuery } from './text-rich.query';
import { TextRichService } from './text-rich.service';
import { TextRichStore } from './text-rich.store';

/**
 * The Text Rich Component is used to bind raw HTMLDomStrings to a Component.
 */
@Component({
  selector: 'app-text-rich',
  templateUrl: './text-rich.component.html',
  styleUrls: ['./text-rich.component.scss'],
  providers: [TextRichService, TextRichQuery, TextRichStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextRichComponent {
  /**
   * The configuration required for this object to function
   */
  private _config: ITextRich;
  @Input() set config(configuration: ITextRich) {
    this._config = configuration;
    this.service.applyConfiguration(configuration);
  }
  get config(): ITextRich {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(private readonly service: TextRichService, public readonly query: TextRichQuery) {}
}
