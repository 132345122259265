import { Injectable } from '@angular/core';
import { IMediaLock } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { MediaLockStore } from './media-lock.store';

/**
 * The Media Lock Service is responsible for managing the state of the {@link MediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockService {
  /**
   * Constructor
   */
  constructor(private readonly store: MediaLockStore) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: IMediaLock) {
    this.store.applyConfiguration(configuration);
  }

  /**
   * The system has encountered an error.
   */
  applyError() {
    this.store.applyError();
  }

  /**
   * The system has been unlocked.
   */
  applyUnlock() {
    this.store.applyUnlock();
  }
}
