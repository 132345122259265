import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { dialogMediaLockButtonAnimation } from './dialog-media-lock.animations';
import { DialogMediaLockQuery } from './dialog-media-lock.query';
import { DialogMediaLockService } from './dialog-media-lock.service';
import { DialogMediaLockStore } from './dialog-media-lock.store';

/**
 * Dialog Media Lock is a special kind of Dialog used to contain a {@link MediaLockable} component.
 *
 * They have a title, a MediaLockable, and a button.
 *
 * See {@link IDialogMediaLock}
 */
@Component({
  selector: 'app-dialog-media-lock',
  templateUrl: './dialog-media-lock.component.html',
  styleUrls: ['./dialog-media-lock.component.scss'],
  providers: [DialogMediaLockService, DialogMediaLockQuery, DialogMediaLockStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialogMediaLockButtonAnimation],
})
export class DialogMediaLockComponent {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IDialogMediaLock;
  @Input() set config(configuration: IDialogMediaLock) {
    this._config = configuration;
    this.dialogMediaLockService.applyConfiguration(configuration);
  }
  get config(): IDialogMediaLock {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(
    private dialogRef: MatDialogRef<DialogMediaLockComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IDialogMediaLock,
    public readonly dialogMediaLockQuery: DialogMediaLockQuery,
    private readonly dialogMediaLockService: DialogMediaLockService
  ) {
    this.config = data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs from the Media Lock when the Media is Unlocked.
   */
  mediaLockUnlocked() {
    this.dialogMediaLockService.applyUnlock();
  }

  /**
   * Occurs from the Media Lock when the Media has experienced an error.
   */
  mediaLockError() {
    this.dialogMediaLockService.applyError();
  }

  /**
   * Occurs when the user clicks on the optional negative button.
   */
  onNegativeClick() {
    this.dialogRef.close(false);
  }

  /**
   * Occurs when the user clicks on the optional positive button.
   */
  onPositiveClick() {
    // Not used in this component.
    // this.dialogRef.close(true);
  }
}
