import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IDataboxScore } from 'src/app/api/modules/core/dynamic/databoxes/score/IDataboxScore';
import { DataboxScoreModel } from './databox-score.model';

export function createInitialState(): DataboxScoreModel {
  return {
    selector: 'app-databox-score',
    template: '%SCORE%',
    templateSingular: '%SCORE%',
    templateIdle: '-',
    output: '-',
  };
}

/**
 * Manages the State of a {@link DataboxScoreComponent}.
 */
@Injectable()
export class DataboxScoreStore extends Store<DataboxScoreModel> {
  /**
   * The Score String Template variable which is replaced by the users actual score.
   */
  private readonly SCORE = '%SCORE%';

  /**
   * Constructor
   */
  constructor(private readonly logger: LoggerService) {
    super(createInitialState(), {
      producerFn: produce,
      name: `databox-score-${guid()}`,
    });
  }

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxScore>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // the template is used to process the numeric values into a formatted score string.
    let template = `${this.SCORE}`;
    if (configuration.template) {
      if (configuration.template.includes(this.SCORE)) {
        template = configuration.template;
      } else {
        this.logger.warn(
          `[DataboxScore] applyInitialize - The 'template' must contain a substring of "${this.SCORE}". The default will be used.`
        );
      }
    }

    // the templateSingular is used to process the numeric values into a formatted score string.
    let templateSingular = `${this.SCORE}`;
    if (configuration.templateSingular) {
      if (configuration.templateSingular.includes(this.SCORE)) {
        templateSingular = configuration.templateSingular;
      } else {
        this.logger.warn(
          `[DataboxScore] applyInitialize - The 'templateSingular' must contain a substring of "${this.SCORE}". The default will be used.`
        );
      }
    }

    // template idle is shown when no acceptable values have been provided
    // or as the default state or during a reset.
    let templateIdle = `-`;
    if (configuration.templateIdle) {
      templateIdle = configuration.templateIdle;
    }

    // the output should be the templateIdle as no values are incoming yet.
    const output = templateIdle;

    // update the store
    this.update((draft) => {
      draft.template = template;
      draft.templateSingular = templateSingular;
      draft.templateIdle = templateIdle;
      draft.output = output;
    });
  }

  /**
   * Splice the data into the template
   *
   * @param score - The score of the user.
   */
  applyUpdate(score?: number): void {
    // you can't update the score if there is no value provided
    if (typeof score !== 'number') {
      return;
    }

    // get the configured data
    const { template, templateSingular } = this.getValue();

    // the final output
    let finalOutput = '';

    // if there is a score of 1
    if (score === 1) {
      // then the singular template is used.
      finalOutput = templateSingular.slice();
    } else {
      // otherwise the template is used.
      finalOutput = template.slice();
    }

    // the final output is the chosen template with the score replaced.
    finalOutput = finalOutput.replace(this.SCORE, score.toString());

    // update the store
    this.update((draft) => {
      draft.output = finalOutput;
    });
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    this.update((draft) => {
      draft.output = draft.templateIdle;
    });
  }
}
