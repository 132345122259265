// angular
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// ngxs
import { IconsModule } from '../icons/icons.module';
import { MaterialModule } from '../material/material.module';
// pipes
import { SafePipesModule } from '../safe-pipes/safe-pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AbstractLinkComponent } from './components/abstract/abstract-link/abstract-link.component';
import { ShellBackgroundEntityComponent } from './components/abstract/shell-background/shell-background-entity/shell-background-entity.component';
import { ShellBackgroundComponent } from './components/abstract/shell-background/shell-background.component';
import { ShellLoaderComponent } from './components/abstract/shell-loader/shell-loader.component';
import { ShellOutletContainerComponent } from './components/abstract/shell-outlet-container/shell-outlet-container.component';
import { FooterAuthorComponent } from './components/static/footer-author/footer-author.component';
import { FooterBrandingComponent } from './components/static/footer-branding/footer-branding.component';
import { FooterComponent } from './components/static/footer/footer.component';
import { DialogContentComponent } from './dialogs/dialog-content/dialog-content.component';
import { ListSnackbarComponent } from './snackbar/list-snackbar/list-snackbar.component';
// components
import { ReactiveFormsModule } from '@angular/forms';
import { ShellFGEffectsConfettiComponent } from './components/abstract/shell-fg-effects-container/effects/shell-fg-effects-confetti/shell-fg-effects-confetti.component';
import { ShellFGEffectsConfettisComponent } from './components/abstract/shell-fg-effects-container/effects/shell-fg-effects-confettis/shell-fg-effects-confettis.component';
import { ShellFGEffectsContainerComponent } from './components/abstract/shell-fg-effects-container/shell-fg-effects-container.component';
import { DevPanelComponent } from './components/static/dev-panel/dev-panel.component';
import { MediaLockableComponent } from './components/static/media-lockable/media-lockable.component';
import { DialogMediaLockComponent } from './dialogs/dialog-media-lock/dialog-media-lock.component';
import { DomIntersectionObserverDirective } from './directives/dom-intersection-observer.directive';
import { ButtonComponent } from './dynamic/components/button/button.component';
import { CountdownSectionComponent } from './dynamic/components/countdown/countdown-section/countdown-section.component';
import { CountdownComponent } from './dynamic/components/countdown/countdown.component';
import { ImageComponent } from './dynamic/components/image/image.component';
import { LeaderboardEntryComponent } from './dynamic/components/leaderboard/leaderboard-entry.component';
import { LeaderboardComponent } from './dynamic/components/leaderboard/leaderboard.component';
import { MediaLockComponent } from './dynamic/components/media-lock/media-lock.component';
import { MediaNativeAudioComponent } from './dynamic/components/media/media-native-audio/media-native-audio.component';
import { MediaNativeVideoComponent } from './dynamic/components/media/media-native-video/media-native-video.component';
import { MediaUIComponent } from './dynamic/components/media/media-ui/media-ui.component';
import { MediaVimeoComponent } from './dynamic/components/media/media-vimeo/media-vimeo.component';
import { MediaYouTubeComponent } from './dynamic/components/media/media-youtube/media-youtube.component';
import { MediaComponent } from './dynamic/components/media/media.component';
import { PromoCodeComponent } from './dynamic/components/promo-code/promo-code.component';
import { SocialSharingButtonComponent } from './dynamic/components/social-sharing-button/social-sharing-button.component';
import { SocialSharingComponent } from './dynamic/components/social-sharing/social-sharing.component';
import { TableComponent } from './dynamic/components/table/table.component';
import { TextRichComponent } from './dynamic/components/text-rich/text-rich.component';
import { ButtonContainerTemplateComponent } from './dynamic/containers/button-container-template/button-container-template.component';
import { ButtonContainerComponent } from './dynamic/containers/button-container/button-container.component';
import { ContainerComponent } from './dynamic/containers/container/container.component';
import { DataboxAttemptsIconComponent } from './dynamic/databoxes/components/databox-attempts-icon/databox-attempts-icon.component';
import { DataboxContainerComponent } from './dynamic/databoxes/components/databox-container/databox-container.component';
import { DataboxHighScoreComponent } from './dynamic/databoxes/components/databox-highscore/databox-highscore.component';
import { DataboxLivesIconComponent } from './dynamic/databoxes/components/databox-lives-icon/databox-lives-icon.component';
import { DataboxProgressionIconComponent } from './dynamic/databoxes/components/databox-progression-icon/databox-progression-icon.component';
import { DataboxProgressionNumericComponent } from './dynamic/databoxes/components/databox-progression-numeric/databox-progression-numeric.component';
import { DataboxScoreComponent } from './dynamic/databoxes/components/databox-score/databox-score.component';
import { DataboxTimerComponent } from './dynamic/databoxes/components/databox-timer/databox-timer.component';
import { PluginTextEntityComponent } from './dynamic/plugin/plugin-text-entity/plugin-text-entity.component';
import { PluginTextComponent } from './dynamic/plugin/plugin-text/plugin-text.component';
import { PluginComponent } from './dynamic/plugin/plugin/plugin.component';
import { ScreenComponent } from './dynamic/screens/screen/screen.component';

@NgModule({
  imports: [
    CommonModule,
    SafePipesModule,
    SharedModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule,
    MaterialModule,
    IconsModule,
    ClipboardModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AbstractLinkComponent,
    ButtonComponent,
    FooterComponent,
    FooterAuthorComponent,
    FooterBrandingComponent,
    ImageComponent,
    ScreenComponent,
    SocialSharingComponent,
    SocialSharingButtonComponent,
    TextRichComponent,
    ContainerComponent,
    ShellOutletContainerComponent,
    ShellBackgroundComponent,
    ShellFGEffectsContainerComponent,
    ShellFGEffectsConfettiComponent,
    ShellFGEffectsConfettisComponent,
    ShellLoaderComponent,
    PluginComponent,
    DialogContentComponent,
    PluginTextComponent,
    PluginTextEntityComponent,
    DataboxContainerComponent,
    ButtonContainerComponent,
    ButtonContainerTemplateComponent,
    ShellBackgroundEntityComponent,
    PromoCodeComponent,
    CountdownComponent,
    CountdownSectionComponent,

    // leaderboard
    LeaderboardComponent,
    LeaderboardEntryComponent,

    // databoxes
    DataboxScoreComponent,
    DataboxHighScoreComponent,
    DataboxTimerComponent,
    DataboxProgressionNumericComponent,
    DataboxProgressionIconComponent,
    DataboxLivesIconComponent,
    DataboxAttemptsIconComponent,
    ButtonContainerComponent,
    TableComponent,

    // Snackbars
    ListSnackbarComponent,

    // media
    MediaComponent,
    MediaVimeoComponent,
    MediaYouTubeComponent,
    MediaNativeVideoComponent,
    MediaNativeAudioComponent,
    MediaUIComponent,
    MediaLockableComponent,
    MediaLockComponent,
    DialogMediaLockComponent,

    // Directives
    DomIntersectionObserverDirective,

    // DEV Panel
    DevPanelComponent,
  ],
  exports: [
    AbstractLinkComponent,
    ShellBackgroundComponent,
    ShellFGEffectsContainerComponent,
    ShellFGEffectsConfettiComponent,
    ShellFGEffectsConfettisComponent,
    ShellLoaderComponent,
    ShellOutletContainerComponent,
    ButtonComponent,
    ButtonContainerComponent,
    FooterComponent,
    FooterAuthorComponent,
    FooterBrandingComponent,
    ImageComponent,
    ScreenComponent,
    SocialSharingComponent,
    SocialSharingButtonComponent,
    TextRichComponent,
    ContainerComponent,
    PluginComponent,
    PluginTextComponent,
    PluginTextEntityComponent,
    DialogContentComponent,
    DataboxContainerComponent,
    ButtonContainerTemplateComponent,
    ShellBackgroundEntityComponent,
    PromoCodeComponent,
    CountdownComponent,
    CountdownSectionComponent,

    // leaderboard
    LeaderboardComponent,
    LeaderboardEntryComponent,

    // databoxes
    DataboxScoreComponent,
    DataboxHighScoreComponent,
    DataboxTimerComponent,
    DataboxProgressionNumericComponent,
    DataboxProgressionIconComponent,
    DataboxLivesIconComponent,
    DataboxAttemptsIconComponent,

    // Snackbars
    ListSnackbarComponent,

    // media
    MediaComponent,
    MediaVimeoComponent,
    MediaYouTubeComponent,
    MediaNativeVideoComponent,
    MediaNativeAudioComponent,
    MediaUIComponent,
    MediaLockableComponent,
    MediaLockComponent,
    DialogMediaLockComponent,

    // Directives
    DomIntersectionObserverDirective,

    // DEV Panel
    DevPanelComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
