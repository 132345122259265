import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { DialogContentQuery } from './dialog-content.query';
import { DialogContentService } from './dialog-content.service';
import { DialogContentStore } from './dialog-content.store';

/**
 * Content Dialogs are used generically
 *
 * They have a title, a `Container` and a positive and negative optional button.
 *
 * See [IDialogContent]{@link IDialogContent}
 */
@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  providers: [DialogContentStore, DialogContentService, DialogContentQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IDialogContent;
  @Input() set config(configuration: IDialogContent) {
    this._config = configuration;
    this.dialogContentService.applyConfiguration(configuration);
  }
  get config(): IDialogContent {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(
    private dialogRef: MatDialogRef<DialogContentComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IDialogContent,
    private readonly dialogContentService: DialogContentService,
    public readonly dialogContentQuery: DialogContentQuery
  ) {
    this.config = data;
  }

  /**
   * Occurs when the user clicks on the optional negative button.
   */
  onNegativeClick() {
    this.dialogRef.close(false);
  }

  /**
   * Occurs when the user clicks on the optional positive button.
   */
  onPositiveClick() {
    this.dialogRef.close(true);
  }
}
