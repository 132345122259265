import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { ISocialSharingButton } from 'src/app/api/modules/core/dynamic/components/ISocialSharingButton';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';
import { SnackbarService } from '../../../services/snackbar.service';

/**
 * The SocialSharingButtonComponent binds the URLs/Platform/Icon/Styles for a specific social network provider.
 *
 * This is done to reduce the end bundle size and to simplify the JSON data.
 */
@Component({
  selector: 'app-social-sharing-button',
  templateUrl: './social-sharing-button.component.html',
  styleUrls: ['./social-sharing-button.component.scss'],
})
export class SocialSharingButtonComponent implements OnChanges {
  /**
   * The Input Config as supplied by JSON
   */
  @Input() config: ISocialSharingButton;

  /**
   * Set by the Social Sharing component that this button belongs to
   * depending on what type of group is being created.
   *
   * The default is "share"
   */
  @Input() type: 'share' | 'homepage' = 'share';

  /**
   * A private list of objects which combine an icon and style to the selected platformID
   */
  private platforms: Record<
    string,
    {
      style: string;
      icon: IFaIcon;
    }
  > = {
    blogger: {
      style: 'blogger',
      icon: {
        name: 'fab:blogger-b',
      },
    },
    buffer: {
      style: 'buffer',
      icon: {
        name: 'fab:buffer',
      },
    },
    copy: {
      style: 'copy',
      icon: {
        name: 'fas:copy',
      },
    },
    diaspora: {
      style: 'diaspora',
      icon: {
        name: 'fab:diaspora',
      },
    },
    digg: {
      style: 'digg',
      icon: {
        name: 'fab:digg',
      },
    },
    facebook: {
      style: 'facebook',
      icon: {
        name: 'fab:facebook-f',
      },
    },
    'facebook-messenger': {
      style: 'facebook-messenger',
      icon: {
        name: 'fab:facebook-messenger',
      },
    },
    flipboard: {
      style: 'flipboard',
      icon: {
        name: 'fab:flipboard',
      },
    },
    instagram: {
      style: 'instagram',
      icon: {
        name: 'fab:instagram',
      },
    },
    line: {
      style: 'line',
      icon: {
        name: 'fab:line',
      },
    },
    linkedin: {
      style: 'linkedin',
      icon: {
        name: 'fab:linkedin-in',
      },
    },
    pinterest: {
      style: 'pinterest',
      icon: {
        name: 'fab:pinterest-p',
      },
    },
    pocket: {
      style: 'pocket',
      icon: {
        name: 'fab:get-pocket',
      },
    },
    qq: {
      style: 'qq',
      icon: {
        name: 'fab:qq',
      },
    },
    reddit: {
      style: 'reddit',
      icon: {
        name: 'fab:reddit-alien',
      },
    },
    renren: {
      style: 'renren',
      icon: {
        name: 'fab:renren',
      },
    },
    sinaweibo: {
      style: 'sinaweibo',
      icon: {
        name: 'fab:weibo',
      },
    },
    skype: {
      style: 'skype',
      icon: {
        name: 'fab:skype',
      },
    },
    sms: {
      style: 'sms',
      icon: {
        name: 'fas:comment-sms',
      },
    },
    telegram: {
      style: 'telegram',
      icon: {
        name: 'fab:telegram',
      },
    },
    tiktok: {
      style: 'tiktok',
      icon: {
        name: 'fab:tiktok',
      },
    },
    tumblr: {
      style: 'tumblr',
      icon: {
        name: 'fab:tumblr',
      },
    },
    twitch: {
      style: 'twitch',
      icon: {
        name: 'fab:twitch',
      },
    },
    twitter: {
      style: 'twitter',
      icon: {
        name: 'fab:x-twitter',
      },
    },
    viber: {
      style: 'viber',
      icon: {
        name: 'fab:viber',
      },
    },
    vk: {
      style: 'vk',
      icon: {
        name: 'fab:vk',
      },
    },
    whatsapp: {
      style: 'whatsapp',
      icon: {
        name: 'fab:whatsapp',
      },
    },
    xing: {
      style: 'xing',
      icon: {
        name: 'fab:xing',
      },
    },
    youtube: {
      style: 'youtube',
      icon: {
        name: 'fab:youtube',
      },
    },
  };

  private styleSource = new BehaviorSubject<string | null>(null);
  style$ = this.styleSource.asObservable().pipe(distinctUntilChanged());

  private iconSource = new BehaviorSubject<IFaIcon | null>(null);
  icon$ = this.iconSource.asObservable().pipe(distinctUntilChanged());

  private linkSource = new BehaviorSubject<ILink | null>(null);
  link$ = this.linkSource.asObservable().pipe(distinctUntilChanged());

  /**
   * Constructor
   */
  constructor(private readonly snackbarService: SnackbarService, private readonly clipboard: Clipboard) {}

  /**
   * Occurs when the component is clicked.
   *
   * @param e - the event dispatched from an abstract link
   */
  onClicked(e: PointerEvent) {
    if (this.config && this.config.link && this.config.link.href) {
      if (this.config.platform === 'copy') {
        this.handleCopyButton(e);
      }
    }
  }

  /**
   * Handles the Copy Button functionaliy.
   * @param e - the event dispatched
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleCopyButton(e: PointerEvent) {
    const pending = this.clipboard.beginCopy(this.config.link.href);
    let remainingAttempts = 5;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
        if (this.config.snackbar) {
          this.snackbarService.open(this.config.snackbar);
        }
      }
    };
    attempt();
  }

  /**
   * Lifecycle Hook
   *
   * When the @Input changes, find the accomanying style and icon and make it available to the template
   *
   * @param changes - simple changes arrising from the Input data changing.
   */
  ngOnChanges(changes?: SimpleChanges) {
    const currentConfig: ISocialSharingButton = changes['config']?.currentValue;
    const targetPlatform = this.platforms[currentConfig?.platform];

    if (targetPlatform) {
      this.iconSource.next(targetPlatform.icon);
      this.styleSource.next(targetPlatform.style);
    }

    if (currentConfig?.link) {
      this.linkSource.next(currentConfig.link);
    }
  }
}
