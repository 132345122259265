import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { combineQueries, Query } from '@datorama/akita';
import { filter, of, switchMap } from 'rxjs';
import { ImageModel } from './image.model';
import { ImageStore } from './image.store';

@Injectable()
export class ImageQuery extends Query<ImageModel> {
  /**
   * Does the image have a ghost?
   */
  hasGhost$ = this.select((state) => state.hasGhost);

  /**
   * Setup occurs before loading once a configuration has been processed.
   */
  isSetup$ = this.select((state) => state.isSetup);

  /**
   * Has the image loaded?
   */
  isLoaded$ = this.select((state) => state.isLoaded);

  /**
   * The border radius.
   */
  borderRadius$ = this.select((state) => state.borderRadius);

  /**
   * How does the image responsively behave?
   */
  flex$ = this.select((state) => state.flex);

  /**
   * What is the link if any?
   */
  link$ = this.select((state) => state.link);

  /**
   * Is there an error?
   */
  hasError$ = this.select((state) => state.hasError);

  /**
   * The width of the image as a pixel value.
   */
  width$ = this.select((state) => state.width);

  /**
   * The height of the image as a pixel value.
   */
  height$ = this.select((state) => state.height);

  /**
   * The Source of the Image URI.
   */
  src$ = this.select((state) => state.src);

  /**
   * The image data of the loaded image.
   */
  imageData$ = this.select((state) => state.imageData);

  /**
   * The Error Icon to display if things go wrong.
   */
  errorIcon$ = this.select((state) => state.errorIcon);

  /**
   * Aspect of the Ghost.
   */
  aspect$ = this.select((state) => state.aspect);

  /**
   * The wrapped width.
   */
  wrapperWidth$ = this.select((state) => state.wrapperWidth);

  /**
   * The Width of the image as a number.
   */
  private _widthValue$ = this.select((state) => state.widthValue);

  /**
   * The Height of the image as a number.
   */
  private _heightValue$ = this.select((state) => state.heightValue);

  /**
   * Determine if the Media is Portrait.
   */
  isPortrait$ = combineQueries([this._widthValue$, this._heightValue$]).pipe(
    switchMap(([width, height]) => {
      if (width === 0 || height === 0) {
        return of(false);
      }

      if (height > width) {
        return of(true);
      }

      return of(false);
    })
  );

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  limitPortraitWidthOnDesktop$ = combineQueries([this._mediaObserver$, this.isPortrait$]).pipe(
    switchMap(([changes, isMediaPortrait]) => {
      // if the media is not portrait then this doesn't apply
      if (!isMediaPortrait) {
        return of(false);
      }

      // get the first change
      const targetChange = changes[0];

      // if the media alias is not xs then we want to limit the width
      if (targetChange.mqAlias !== 'xs') {
        return of(true);
      }

      return of(false);
    })
  );

  /**
   * Constructor
   */
  constructor(protected override readonly store: ImageStore, protected readonly mediaObserver: MediaObserver) {
    super(store);
  }
}
