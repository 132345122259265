import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { asyncScheduler, of } from 'rxjs';
import { delay, filter, map, switchMap, throttleTime } from 'rxjs/operators';
import { PluginModel } from './plugin.model';
import { PluginStore } from './plugin.store';

/**
 * The PluginQuery is used for each {@link PluginComponent}.
 */
@Injectable()
export class PluginQuery extends Query<PluginModel> {
  /**
   * Constructor
   */
  constructor(protected override readonly store: PluginStore) {
    super(store);
  }

  ///////////////////////////////////////////////////////////
  // CORE
  ///////////////////////////////////////////////////////////

  /**
   * Has the system been configured?
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  /**
   * Has the Plugin loaded and is ready for use?
   */
  private _ready$ = this.select((state) => state.ready);

  /**
   * Is interactions enabled on the iframe?
   */
  interactionEnabled$ = this.select((state) => state.interactionEnabled);

  /**
   * The source of the Iframe.
   */
  private _src$ = this.select((state) => state.src);

  /**
   * Should the plugin be full width?
   */
  private _fullWidth$ = this.select((state) => state.fullWidth);

  /**
   * The content height of the plugin as a number value.
   */
  private _contentHeight$ = this.select((state) => state.contentHeight);

  /**
   * The effects configuration.
   */
  private _effects = this.select((state) => state.effect);

  /**
   * The remaining duration of the effect.
   *
   * Will return zero if the time has expired.
   */
  private _effectsDuration$ = this._effects.pipe(
    map((effect) => {
      if (effect.duration === 0) {
        return 0;
      }

      const currentTime = performance.now();
      const elapsedTime = currentTime - effect.startTime;
      const remainingTime = effect.duration - elapsedTime;
      return remainingTime > 0 ? Math.floor(remainingTime) : 0;
    })
  );

  /**
   * Contains all of the plugin state configuration, mostly for configuring the template.
   */
  templateData$ = combineQueries([
    this._configured$,
    this._ready$,
    this._contentHeight$,
    this._src$,
    this._fullWidth$,
    this.interactionEnabled$,
  ]).pipe(
    map(([configured, ready, contentHeight, src, fullWidth, interactionEnabled]) => {
      return {
        configured,
        ready,
        displayLoading: !ready,
        displayContent: ready,
        contentHeight: `${contentHeight}px`,
        src,
        fullWidth,
        interactionEnabled,
      };
    })
  );

  ///////////////////////////////////////////////////////////
  // DATABOX
  ///////////////////////////////////////////////////////////

  /**
   * The Databox Configuration.
   */
  databox$ = this.select((state) => state.databox);

  ///////////////////////////////////////////////////////////
  // HEARTBEAT
  ///////////////////////////////////////////////////////////

  /**
   * The Heartbeat Configuration.
   */
  private _heartbeatConfig$ = this.select((state) => state.heartbeat);

  /**
   * The Heartbeat Score.
   */
  private _heartbeatScore$ = this.select((state) => state.heartbeatScore);

  /**
   * The Heartbeat Query combines and emits the heartbeat configuration and the heartbeat score.
   */
  heartbeat$ = this._heartbeatConfig$.pipe(
    // Only proceed if heartbeatConfig is truthy
    filter((heartbeatConfig) => !!heartbeatConfig),
    // Map to heartbeatScore observable
    switchMap((heartbeatConfig) =>
      this._heartbeatScore$.pipe(
        // Throttle based on the configuration
        throttleTime(heartbeatConfig.throttleTimeMS, asyncScheduler, { leading: true, trailing: false }),
        // Filter out scores that are 0 or less
        filter((heartbeatScore) => heartbeatScore > 0),
        // Map to the required object structure
        map((heartbeatScore) => ({
          heartbeatAPI: heartbeatConfig.api,
          heartbeatScore,
        }))
      )
    )
  );

  ///////////////////////////////////////////////////////////
  // ON READY API
  ///////////////////////////////////////////////////////////

  /**
   * The onReadyAPI end Point.
   */
  onReadyAPI$ = this.select((state) => state.onReadyAPI).pipe(filter(Boolean));

  ///////////////////////////////////////////////////////////
  // ON Error API
  ///////////////////////////////////////////////////////////

  /**
   * The onErrorAPI end Point.
   */
  onErrorAPI$ = this.select((state) => state.onErrorAPI).pipe(filter(Boolean));

  ///////////////////////////////////////////////////////////
  // ON Event API
  ///////////////////////////////////////////////////////////

  /**
   * The onEventAPI end Point.
   */
  onEventAPI$ = this.select((state) => state.onEventAPI).pipe(filter(Boolean));

  ///////////////////////////////////////////////////////////
  // ON Complete API
  ///////////////////////////////////////////////////////////

  /**
   * The onCompleteAPI end Point.
   */
  private _onCompleteAPI$ = this.select((state) => state.onCompleteAPI);

  /**
   * Returns the onCompleteAPI end Point after the effects duration has expired.
   */
  onCompleteAPI$ = combineQueries([this._onCompleteAPI$, this._effectsDuration$]).pipe(
    switchMap(([onCompleteAPI, effectsDuration]) => {
      return of(onCompleteAPI).pipe(delay(effectsDuration));
    })
  );
}
