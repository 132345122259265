import { LoggerService } from '@angular-ru/cdk/logger';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { imageGhostExitAnimation } from './image.animation';
import { ImageQuery } from './image.query';
import { ImageService } from './image.service';
import { ImageStore } from './image.store';

/**
 * The Image Component is the primary way in which Images within the application are used.
 */
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  animations: [imageGhostExitAnimation],
  providers: [ImageService, ImageQuery, ImageStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnDestroy, AfterViewInit {
  /**
   * The Image Fade is controlled programatically.
   */
  @ViewChildren('img') images: QueryList<HTMLImageElement>;

  /**
   * The Config as provided to the element
   */
  private _config?: IImage;
  @Input() set config(configuration: IImage) {
    this._config = configuration;
    this.imageService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  // used to teardown the setup observable
  private setupSubscription?: Subscription;

  // used to teardown the loading observable
  private imageSubscription?: Subscription;

  // a reference to the animation player
  private imageAnimationPlayer?: AnimationPlayer;

  // stores the URL.createObjectURL() reference for teardown
  private imageBlob?: string;

  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly animationBuilder: AnimationBuilder,
    private readonly imageService: ImageService,
    public readonly imageQuery: ImageQuery
  ) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // The image children subscription will listen to the component DOM
    // seeking for when there is an HTMLImageElement. As soon as it finds
    // it, it is animated and the subscription is destroyed.
    this.imageSubscription = this.images.changes.subscribe((changes) => {
      // is there an image on the DOM?
      if (changes?.first?.nativeElement) {
        // If so we ditch this subscription as it's done it's job
        this.imageSubscription?.unsubscribe();

        const imageAnimationFactory = this.animationBuilder.build([
          // animate(duration delay ease)
          animate('400ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1)', style({ opacity: 1 })),
        ]);

        this.imageAnimationPlayer = imageAnimationFactory.create(changes.first.nativeElement);
        this.imageAnimationPlayer.play();
      }
    });

    // listen for when setup is complete
    this.setupSubscription = this.imageQuery.isSetup$
      .pipe(
        switchMap((isSetup) => {
          if (isSetup) {
            return this.imageQuery.src$;
          } else {
            return EMPTY;
          }
        }),
        switchMap((src) => {
          return this.imageService.imageLoadingRequest(src);
        }),
        take(1)
      )
      .subscribe({
        // with a successful response
        next: (response) => {
          // we no longer need the subscription
          this.setupSubscription?.unsubscribe();

          // get the response blob that was loaded
          const loadedBlob = response[0];

          // create an image object from the blob
          const createdBlob = URL.createObjectURL(loadedBlob);

          // keep a reference to this because it must be freed when the component
          // is destroyed
          this.imageBlob = createdBlob;

          // apply the loaded data to the store
          this.imageService.imageData(createdBlob);

          // loading is now complete
        },
        error: (error: Error) => {
          // we no longer need the subscription
          this.setupSubscription?.unsubscribe();

          // log the error
          this.loggerService.error(error.message);

          // loading has failed
          this.imageService.error();
        },
      });
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.setupSubscription?.unsubscribe();
    this.imageSubscription?.unsubscribe();
    this.imageAnimationPlayer?.destroy();

    // The image was loaded as a blob so it should be revoked to free memory
    if (this.imageBlob) {
      URL.revokeObjectURL(this.imageBlob);
    }
  }
}
