<!-- Container just to set flex layout as it's all I "know" :D -->
<div fxLayout="row" fxLayoutAlign="center center">
  <!-- The content part of the template is always shown and contains the list of errors which is an array -->
  <div *ngIf="data?.messages">
    <ul>
      <!-- Each error entry in the array gets it's own error list item -->
      <li *ngFor="let error of data?.messages">{{ error }}</li>
    </ul>
  </div>
  <!-- This part is only shown when a button exists which is optional -->
  <div *ngIf="data?.action?.label">
    <button mat-button (click)="onDismiss()">{{ data.action.label }}</button>
  </div>
</div>
