import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxProgressionIcon } from 'src/app/api/modules/core/dynamic/databoxes/progression-icon/IDataboxProgressionIcon';
import { DataboxProgressionIconQuery } from './databox-progression-icon.query';
import { DataboxProgressionIconStore } from './databox-progression-icon.store';

@Component({
  selector: 'app-databox-progression-icon',
  templateUrl: './databox-progression-icon.component.html',
  styleUrls: ['./databox-progression-icon.component.scss'],
  providers: [DataboxProgressionIconStore, DataboxProgressionIconQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxProgressionIconComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxProgressionIcon) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxProgressionIcon;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxProgressionIconStore, public readonly query: DataboxProgressionIconQuery) {}

  /**
   * Updates the component with the users progress
   *
   * @param current - the current progress of the user
   * @param total - the total amount of progress possible.
   */
  update(current: number, total?: number) {
    this.store.applyUpdate(current, total);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.reset();
  }
}
