import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { interval, of } from 'rxjs';
import { delay, first, switchMap } from 'rxjs/operators';
import { DEFAULT_LOADING_INDICATOR_DISPLAY_DELAY } from 'src/app/app-constants';
import { AppModel } from './application.model';
import { AppStore } from './application.store';

@Injectable({
  providedIn: 'root',
})
export class ApplicationQuery extends Query<AppModel> {
  ////////////////////////////////////////////////////////////////////
  // WIDGET
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Application running in a widget?
   */
  isInWidget$ = this.select((state) => state.isInWidget);

  /**
   * What type of widget is in use (if any)
   */
  widgetType$ = this.select((state) => state.widgetType);

  /**
   * Should {@link AppComponent} apply "widget-auto" class?
   *
   * This will be true if the application is running in a widget, and the widget is in "auto" layout mode.
   *
   * Otherwise this will be false.
   */
  shouldApplyWidgetAutoLayout$ = combineQueries([this.isInWidget$, this.widgetType$]).pipe(
    switchMap(([isInWidget, widgetType]) => {
      if (isInWidget && widgetType === 'auto') {
        return of(true);
      }
      return of(false);
    })
  );

  /**
   * Should {@link AppComponent} apply "widget-fixed" class?
   *
   * This will be true if the application is running in a widget, and the widget is in "fixed" layout mode.
   *
   * Otherwise this will be false.
   */
  shouldApplyWidgetFixedLayout$ = combineQueries([this.isInWidget$, this.widgetType$]).pipe(
    switchMap(([isInWidget, widgetType]) => {
      if (isInWidget && widgetType === 'fixed') {
        return of(true);
      }
      return of(false);
    })
  );

  ////////////////////////////////////////////////////////////////////
  // APPLICATION VIEWPORT RESIZING
  ////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the width of the viewport changes.
   */
  viewportWidth$ = this.select((state) => state.viewportWidth);

  /**
   * Occurs when the height of the viewport changes.
   */
  private viewportHeight$ = this.select((state) => state.viewportHeight);

  /**
   * Occurs when the width or height of the viewport changes.
   */
  viewportSize$ = combineQueries([this.viewportWidth$, this.viewportHeight$]).pipe(
    switchMap(([width, height]) => {
      return of({
        width,
        height,
      });
    })
  );

  /////////////////////////////////////////////////////////////////////////////////////////
  // Shell Primary Outlet Container
  /////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the Shell Layout changes.
   */
  shellLayout$ = this.select((state) => state.layout).pipe(
    switchMap((layout) => {
      return of(layout.layout);
    }),
    delay(0)
  );

  /**
   * Occurs when the Shell Layout Alignment changes.
   */
  shellLayoutAlign$ = this.select((state) => state.layout).pipe(
    switchMap((layout) => {
      return of(layout.layoutAlign);
    }),
    delay(0)
  );

  /**
   * Occurs when the XS Layout changes.
   */
  outletLayoutColumnXS$ = this.select((state) => state.layout).pipe(
    switchMap((layout) => {
      const target = layout.mobile;
      const grow = target.grow;
      const shrink = target.shrink;
      const basis = target.basis;
      return of(`${grow} ${shrink} ${basis}`);
    }),
    delay(0)
  );

  /**
   * Occurs when the GTXS Layout changes.
   */
  outletLayoutColumnGTXS$ = this.select((state) => state.layout).pipe(
    switchMap((layout) => {
      const target = layout.desktop;
      const grow = target.grow;
      const shrink = target.shrink;
      const basis = target.basis;
      return of(`${grow} ${shrink} ${basis}`);
    }),
    delay(0)
  );

  /////////////////////////////////////////////////////////////////////////////////////////
  // Misc
  /////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the loading indicator for the app changes state.
   */
  loadingIndicator$ = this.select((state) => state.loadingIndicator).pipe(
    // when the loading indicator changes
    switchMap((active) => {
      // if active
      if (active === true) {
        // create a interval delay
        return interval(DEFAULT_LOADING_INDICATOR_DISPLAY_DELAY).pipe(
          // we only need one
          first(),
          // return true
          switchMap(() => {
            return of(true);
          })
        );
      } else {
        // return false
        return of(false);
      }
    })
  );

  /**
   * The orientation of the page.
   */
  orientation$ = this.select((state) => state.orientation).pipe(delay(0));

  ////////////////////////////////////////////////////////////////////
  // First Interaction
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the first interaction occurred?
   */
  hasFirstInteraction$ = this.select((state) => state.hasFirstInteraction);

  ////////////////////////////////////////////////////////////////////
  // Window Unloading
  ////////////////////////////////////////////////////////////////////

  // prevent window unload dialog
  private preventWindowUnloadDialog$ = this.select((state) => state.preventWindowUnloadDialog);

  /**
   * Occurs when the application is about to exit.
   */
  preventAppUnloadDialog$ = combineQueries([this.preventWindowUnloadDialog$, this.isInWidget$]).pipe(
    switchMap(([preventWindowUnloadDialog, isInWidget]) => {
      if (preventWindowUnloadDialog || isInWidget) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );

  /**
   * Constructor
   */
  constructor(protected override readonly store: AppStore) {
    super(store);
  }
}
