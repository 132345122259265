import { Injectable } from '@angular/core';
import { IFooter } from 'src/app/api/modules/core/components/static/IFooter';
import { FooterStore } from './footer.store';

/**
 * The FooterService is used to publically update the {@link FooterStore}.
 */
@Injectable({
  providedIn: 'root',
})
export class FooterService {
  /**
   * Constructor
   */
  constructor(private readonly footerStore: FooterStore) {}

  /**
   * Initialize the Footer.
   *
   * @param configuration - the configuration
   */
  initialize(configuration?: IFooter) {
    this.applyConfiguration(configuration);
  }

  /**
   * Apply a configuration to the Footer.
   *
   * @param configuration - the configuration
   */
  applyConfiguration(configuration?: IFooter) {
    this.footerStore.applyConfiguration(configuration);
  }
}
