import { LoggerService } from '@angular-ru/cdk/logger';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ISocialSharing } from 'src/app/api/modules/core/dynamic/components/ISocialSharing';
import { ISocialSharingButton } from 'src/app/api/modules/core/dynamic/components/ISocialSharingButton';

/**
 * The Social Sharing Component is responsible for displaying a group of social buttons or links.
 *
 * It can behave in 2 ways:
 *
 * 1. It displays a row of `link` to homepages such as `https://twitter.com/Twitter`
 * 2. It displays a row of `share` buttons to actually post content.
 *
 * You cannot mix and match these within the same container.
 */
@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss'],
})
export class SocialSharingComponent implements OnChanges {
  /**
   * The Input Config as supplied by JSON
   */
  @Input() config: ISocialSharing;

  private typeSource = new BehaviorSubject<'homepage' | 'share' | null>(null);
  type$ = this.typeSource.asObservable().pipe(distinctUntilChanged());

  private buttonsSource = new BehaviorSubject<Array<ISocialSharingButton> | null>(null);
  buttons$ = this.buttonsSource.asObservable().pipe(distinctUntilChanged());

  /**
   * Constructor
   */
  constructor(private readonly deviceDetector: DeviceDetectorService, private readonly logger: LoggerService) {}

  /**
   * Lifecycle Hook
   */
  ngOnChanges(changes?: SimpleChanges) {
    this.detectChanges(changes);
  }

  /**
   * Used by Dynamic Component creation
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  detectChanges(changes?: SimpleChanges) {
    // there must be a config to proceed
    if (!this.config || !this.config.componentArray) {
      return;
    }

    // clone the sharing buttons with some validation and filters in place
    let filteredSharingData: ISocialSharingButton[] = cloneDeep(this.config.componentArray);

    //  some social entities only support homepages
    filteredSharingData = filteredSharingData.filter((shareData) => {
      switch (shareData.platform) {
        case 'twitch':
        case 'tiktok':
          if (this.config.type === 'share') {
            this.logger.warn(`[Social Sharing] - the ${shareData.platform} platform does not support content sharing.`);
            return false;
          }
      }
      return true;
    });

    // some social entries don't support desktop
    if (!this.deviceDetector.isMobile()) {
      filteredSharingData = filteredSharingData.filter((shareData) => {
        switch (shareData.platform) {
          case 'instagram':
          case 'sms':
          case 'viber':
          case 'facebook-messenger':
            // homepage types are always allowed though since they are just links
            if (this.config.type !== 'homepage') {
              return false;
            }
        }
        return true;
      });
    }

    this.typeSource.next(this.config.type);
    this.buttonsSource.next(filteredSharingData);
  }
}
