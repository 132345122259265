<!--
    The Layout of the div is determined by the configuration direction which is column by default.

    A Template is provided ready to inject with DigitaService Content. 
-->
<div
  class="container-flex"
  *ngIf="containerQuery.templateData$ | async as templateData"
  [fxLayout]="templateData.layout"
  [fxLayoutAlign]="templateData.layoutAlign"
>
  <ng-template appDynamicContent></ng-template>
</div>
