import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FaIconModel } from './fa-icon.model';
import { FaIconStore } from './fa-icon.store';

@Injectable()
export class FaIconQuery extends Query<FaIconModel> {
  size$ = this.select((state) => state.size);
  fixedWidth$ = this.select((state) => state.fixedWidth);
  rotate$ = this.select((state) => state.rotate);
  flip$ = this.select((state) => state.flip);
  spin$ = this.select((state) => state.spin);
  pulse$ = this.select((state) => state.pulse);
  color$ = this.select((state) => state.color);
  name$ = this.select((state) => state.name);
  mask$ = this.select((state) => state.mask);
  transform$ = this.select((state) => state.transform);
  state$ = this.select((state) => state.state);
  initialized$ = this.select((state) => state.initialized);

  constructor(protected override readonly store: FaIconStore) {
    super(store);
  }
}
