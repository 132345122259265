import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { of, switchMap } from 'rxjs';
import { TextRichModel } from './text-rich.model';
import { TextRichStore } from './text-rich.store';

/**
 * The Query used for a {@link TextRichComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class TextRichQuery extends Query<TextRichModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.select((state) => state.configured);

  /**
   * Get the configured type
   */
  private _type$ = this.select((state) => state.type);

  /**
   * Get the class name depending on the type.
   */
  classType$ = this._type$.pipe(
    switchMap((type) => {
      if (type === 'none') {
        return of('');
      }
      return of(type);
    })
  );

  /**
   * Get the data.
   */
  data$ = this.select((state) => state.data);

  /**
   * Constructor.
   */
  constructor(protected override readonly store: TextRichStore) {
    super(store);
  }
}
