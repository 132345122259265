import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Animates the Close Button of a {@link DialogMediaLockComponent}
 */
export const dialogMediaLockButtonAnimation = trigger('dialogMediaLockButtonAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(-30px)', opacity: 0 }),
    animate('2s cubic-bezier(0.33, 1, 0.68, 1)', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);
