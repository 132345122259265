import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PluginTextModel } from './plugin-text.model';
import { PluginTextStore } from './plugin-text.store';

@Injectable()
export class PluginTextQuery extends Query<PluginTextModel> {
  /**
   * Selects the entities from the store.
   */
  entities$ = this.select((state) => state.entities);

  /**
   * Constructor
   */
  constructor(protected override readonly store: PluginTextStore) {
    super(store);
  }
}
