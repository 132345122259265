// pro - fab - regular
import {
  faBloggerB,
  faBuffer,
  faDiaspora,
  faDigg,
  faFacebookF,
  faFacebookMessenger,
  faFlipboard,
  faGetPocket,
  faInstagram,
  faLine,
  faLinkedinIn,
  faPinterestP,
  faQq,
  faRedditAlien,
  faRenren,
  faSkype,
  faTelegram,
  faTiktok,
  faTumblr,
  faTwitch,
  faViber,
  faVk,
  faWeibo,
  faWhatsapp,
  faXing,
  faXTwitter,
  faYoutube,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';

/**
 * The Supported Icon Names used via JSON.
 */
export const FabList = [
  'fab:blogger-b',
  'fab:buffer',
  'fab:diaspora',
  'fab:digg',
  'fab:facebook-f',
  'fab:facebook-messenger',
  'fab:flipboard',
  'fab:get-pocket',
  'fab:instagram',
  'fab:line',
  'fab:linkedin-in',
  'fab:pinterest-p',
  'fab:qq',
  'fab:reddit-alien',
  'fab:renren',
  'fab:skype',
  'fab:telegram',
  'fab:tiktok',
  'fab:tumblr',
  'fab:twitch',
  'fab:viber',
  'fab:vk',
  'fab:weibo',
  'fab:whatsapp',
  'fab:xing',
  'fab:youtube',
  'fab:x-twitter',
] as const;

/**
 * Generated type from the SupportedIconNames.
 */
export type FabIcons = (typeof FabList)[number];

/**
 * All FontAwesome Regular Icons available for the system
 * to use with Material Design are defined here.
 */
export const FabIconDefinitions: IconDefinition[] = [
  faBloggerB,
  faBuffer,
  faDiaspora,
  faDigg,
  faFacebookF,
  faFacebookMessenger,
  faFlipboard,
  faGetPocket,
  faInstagram,
  faLine,
  faLinkedinIn,
  faPinterestP,
  faQq,
  faRedditAlien,
  faRenren,
  faSkype,
  faTelegram,
  faTiktok,
  faTumblr,
  faTwitch,
  faViber,
  faVk,
  faWeibo,
  faWhatsapp,
  faXing,
  faYoutube,
  faXTwitter,
];
