import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DataboxScoreModel } from './databox-score.model';
import { DataboxScoreStore } from './databox-score.store';

/**
 * Qureries the State of an instance of {@link DataboxScoreStore}.
 */
@Injectable()
export class DataboxScoreQuery extends Query<DataboxScoreModel> {
  /**
   * The Final Output State.
   */
  output$ = this.select((state) => state.output);

  /**
   * Constructor
   */
  constructor(protected override readonly store: DataboxScoreStore) {
    super(store);
  }
}
