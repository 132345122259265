<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <div class="button" *ngIf="mediaQuery.displayUIPlayButton$ | async">
    <mat-icon svgIcon="fas:circle-play" class="icon"></mat-icon>
  </div>
  <div class="state" @mediaStateAnimation *ngIf="(mediaQuery.playing$ | async)?.playing">
    <mat-icon class="state-icon" svgIcon="fas:circle-play"></mat-icon>
  </div>
  <div class="state" @mediaStateAnimation *ngIf="mediaQuery.paused$ | async">
    <mat-icon class="state-icon" svgIcon="fas:circle-pause"></mat-icon>
  </div>
</div>
