import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';
import { FaIconQuery } from './fa-icon.query';
import { FaIconStore } from './fa-icon.store';

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  styleUrls: ['./fa-icon.component.scss'],
  providers: [FaIconStore, FaIconQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaIconComponent {
  /**
   * The Config as provided to the element
   */
  @Input() set config(configuration: IFaIcon) {
    this.faIconStore.initialize(configuration);
  }

  constructor(private faIconStore: FaIconStore, public faIconQuery: FaIconQuery) {}
}
