import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { MediaManagerService } from '../../../services/media-manager/media-manager.service';
import { MediaStore } from './media.store';

/**
 * The Media Service is responsible for managing the state of the {@link MediaComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaService {
  private readonly playSource = new Subject<void>();
  private readonly pauseSource = new Subject<void>();
  private readonly seekToSource = new Subject<{
    seconds: number;
    allowSeekAhead: boolean;
    play: boolean;
  }>();
  private readonly muteSource = new Subject<void>();
  private readonly unMuteSource = new Subject<void>();
  private readonly volumeSource = new Subject<number>();

  /**
   * Emits when the Media has been requested to play.
   */
  play$ = this.playSource.asObservable();

  /**
   * Emits when the Media has been requested to pause.
   */
  pause$ = this.pauseSource.asObservable();

  /**
   * Emits when the media has been requested to seek to a specific time.
   */
  seekTo$ = this.seekToSource.asObservable();

  /**
   * Emits when the media has been requested to mute.
   */
  mute$ = this.muteSource.asObservable();

  /**
   * Emits when the media has been requested to unmute.
   */
  unMute$ = this.unMuteSource.asObservable();

  /**
   * Emits when the media has requested to change the volume.
   */
  volume$ = this.volumeSource.asObservable();

  /**
   * Constructor
   */
  constructor(private readonly store: MediaStore, private readonly mediaManager: MediaManagerService) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MEDIA CONTROLS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Play the media.
   */
  play() {
    this.playSource.next();
  }

  /**
   * Pause the media.
   */
  pause() {
    this.pauseSource.next();
  }

  /**
   * Seek to a position in the media.
   *
   * @param seconds - the position in seconds to seek to.
   * @param allowSeekAhead - if true then the player will make a new request to the server
   *  if the seconds requested are outside of the currently buffered range.
   * @param play - if true then the media will play after seeking to the position.
   */
  seekTo(seconds: number, allowSeekAhead: boolean, play: boolean) {
    this.seekToSource.next({
      seconds,
      allowSeekAhead,
      play,
    });
  }

  /**
   * Mute the media.
   */
  mute() {
    this.muteSource.next();
  }

  /**
   * UnMute the media.
   */
  unMute() {
    this.unMuteSource.next();
  }

  /**
   * Volume of the Media.
   *
   * @param volume - the volume to set the media to between 0 and 100.
   */
  volume(volume: number) {
    this.volumeSource.next(volume);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // YOUTUBE INTEGRATIONS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Attempt to load the YouTube Provider.
   */
  applyYouTubePlayerLoad() {
    return this.mediaManager.loadYouTubePlayer();
  }

  /**
   * Retrieve the details from the YouTube OEmbed service about the following VideoID.
   *
   * @param id - the ID of the YouTube Video to retrieve the details for.
   */
  retrieveYouTubeVideoDetails(id: string) {
    return this.mediaManager.retrieveYouTubeVideoDetails(id);
  }

  /**
   * Store the details retrieved from the YouTube OEmbed service.
   *
   * @param details - the details provided by the YouTube OEmbed service.
   */
  applyYouTubeVideoDetails(details: YT.OEmbed) {
    this.store.applyYouTubeVideoDetails(details);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // VIMEO INTEGRATIONS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Attempt to load the Vimeo Provider.
   */
  applyVimeoPlayerLoad() {
    return this.mediaManager.loadVimeoPlayer();
  }

  /**
   * Retrieve the details from the Vimep OEmbed service about the following VideoID.
   *
   * @param id - the ID of the Vimeo retrieve the details for.
   * @param url - the URL of the Vimeo retrieve the details for.
   */
  applyRetrieveVimeoVideoDetails(id?: number, url?: string) {
    return this.mediaManager.retrieveVimeoVideoDetails(id, url);
  }

  /**
   * Store the details retrieved from the Vimeo OEmbed service.
   *
   * @param details - the details provided by the Vimeo OEmbed service.
   */
  applyVimeoVideoDetails(details: Vimeo.Ombed) {
    this.store.applyVimeoVideoDetails(details);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // NATIVE INTEGRATIONS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Apply the Native Video Details.
   */
  applyNativeVideoDetails() {
    this.store.applyNativeVideoDetails();
  }

  /**
   * Apply the Native Audio Details.
   */
  applyNativeAudioDetails() {
    this.store.applyNativeAudioDetails();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: IMedia) {
    this.store.applyConfiguration(configuration);
  }

  /**
   * When the system has been configured, and loaded the required providers and video information (where applicable)
   * then the system is considered initialized and is ready to create the player.
   */
  applyInitialized() {
    this.store.applyInitialized();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The total duration of the Media in seconds
   *
   * @param duration - the duration of the Media in seconds
   */
  applyDuration(duration: number) {
    if (typeof duration === 'number') {
      this.store.applyDuration(duration);
    }
  }

  /**
   * The current time of the Media in seconds.
   *
   * @param currentTime - the current time of the Media in seconds
   */
  applyCurrentTime(currentTime: number) {
    if (typeof currentTime === 'number') {
      this.store.applyCurrentTime(currentTime);
    }
  }

  /**
   * The Media is ready to play.
   */
  applyReady() {
    this.store.applyReady();
  }

  /**
   * The Media is Playing.
   */
  applyPlaying() {
    this.store.applyPlaying();
  }

  /**
   * The Media is Paused.
   */
  applyPaused() {
    this.store.applyPaused();
  }

  /**
   * The Media has Ended.
   */
  applyEnded() {
    this.store.applyEnded();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The system has encountered an error.
   *
   * This can be because:
   *
   *   - the video source failed to load (e.g. youtube video id is invalid)
   *
   * @param reason - the reason for the error.
   * @param reason - the actual error message for logging.
   */
  applyError(reason?: string) {
    this.store.applyError(reason);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MISC
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media intersecting the viewport.
   *
   * @param isIntersecting - is the media intersecting the viewport
   */
  applyIsIntersecting(isIntersecting: boolean) {
    this.store.applyIsIntersecting(isIntersecting);
  }

  /**
   * The ID of the currently active Media.
   *
   * @param id - the ID of the currently active Media.
   */
  applyActiveMedia(id: string) {
    this.mediaManager.applyActiveMedia(id);
  }
}
