import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryBackoff } from 'backoff-rxjs';
import { from, Observable, throwError } from 'rxjs';
import { mergeMap, take, toArray } from 'rxjs/operators';

/**
 * The Image Loader Service will generate a bunch of off screen image elements in order to pre-load
 * images to disk. Note this won't work with cache disabled.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageLoaderService {
  private http: HttpClient;
  /**
   * Constructor
   *
   * @param http - uses angular http client
   */
  constructor(readonly handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  /**
   * Loads a bunch of images and return them.
   *
   * The order of the input array will be the order of the output array.
   *
   * The input is an array of strings representing URLs.
   * The output is an array of strings representing URLs.
   *
   * @param urls - an array of URLs
   */
  loadImagesToCache(urls: string[]) {
    // check the object exists
    if (!urls || !urls.length) {
      return throwError('[Image Loader Service] - You must provide an array of images when using the image loader.');
    }

    // check each object provided is valid
    let isValid = true;
    urls.map((targetURL) => {
      if (targetURL.length === 0) {
        isValid = false;
      }
    });

    // if valid, load all images
    if (isValid) {
      const loadImage = (imagePath: string) => {
        return new Observable<string>((observer) => {
          const img = new Image();
          img.src = imagePath;
          img.onload = () => {
            observer.next(img.src);
            observer.complete();
          };
          img.onerror = (err) => {
            observer.error(err);
          };
        }).pipe(
          retryBackoff({
            initialInterval: 500,
            maxRetries: 3,
            maxInterval: 5000,
          })
        );
      };

      return from(urls).pipe(mergeMap(loadImage), toArray(), take(1));
    } else {
      return throwError('[Image Loader Service] - The loader must be presented with valid URLs to load.');
    }
  }

  /**
   * Loads a bunch of images and return them.
   *
   * The order of the input array will be the order of the output array.
   *
   * The input is an array of strings representing URLs.
   * The output is an array of strings representing src data.
   *
   * @param urls - an array of URLs
   */
  loadImagesToBlob(urls: string[]) {
    // check the object exists
    if (!urls || !urls.length) {
      return throwError('[Image Loader Service] : loadImagesToCache - You must provide an array of images when using the image loader.');
    }

    // check each object provided is valid
    let isValid = true;
    urls.map((targetURL) => {
      if (targetURL.length === 0) {
        isValid = false;
      }
    });

    // if valid, load all images
    if (isValid) {
      const loadImage = (imagePath: string) => {
        return this.http
          .get(imagePath, {
            responseType: 'blob',
          })
          .pipe(
            retryBackoff({
              initialInterval: 500,
              maxRetries: 3,
              maxInterval: 5000,
            })
          );
      };

      // const requests = urls.map((targetURL) => {
      //   return this.http.get(targetURL, {
      //     responseType: 'blob',
      //   });
      // });

      return from(urls).pipe(mergeMap(loadImage), toArray(), take(1));
    } else {
      return throwError(() => {
        return new Error('[Image Loader Service] : loadImagesAsBlob- The loader must be presented with valid URLs to load.');
      });
    }
  }
}
