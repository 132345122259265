import { LoggerService } from '@angular-ru/cdk/logger';
import { isString } from '@angular-ru/cdk/string';
import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { ButtonStyleTypes, ButtonTypes, IButton, IButtonFeatureCopyToClipboard } from 'src/app/api/modules/core/dynamic/components/IButton';
import { IMatIcon } from 'src/app/api/modules/icons/mat/IMatIcon';
import { createDigitaServiceError } from 'src/app/app-error';
import { ButtonModel } from './button.model';

function createInitialState(): ButtonModel {
  return {
    hasLabel: false,
    label: undefined,
    type: 'button',
    style: 'mat-button',
    color: undefined,
    hasIcon: false,
    icon: undefined,
    link: undefined,
    hasClickToCloseFeature: false,
    hasCopyToClipboardFeature: false,
    hasPrintFeature: false,
    copyToClipboard: undefined,
    isTextured: undefined,
    hasDialog: false,
    dialog: undefined,
    disabled: false,
    belongsToForm: false,
    form: undefined,
    extended: false,
    tabIndex: undefined,
  };
}
/**
 * Manages the State of a {@link ButtonComponent}.
 */
@Injectable()
export class ButtonStore extends Store<ButtonModel> {
  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService) {
    super(createInitialState(), {
      name: `button-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: Partial<IButton>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`ButtonStore`, `applyInitialize`, `No configuration provided`, `config`);
    }

    // button label
    let hasLabel = false;
    let label: string | undefined = undefined;
    if (isString(configuration.label)) {
      hasLabel = true;
      label = configuration.label;
    }

    // button type
    let type: ButtonTypes = 'button';
    if (configuration.type === 'link') {
      if (configuration.link?.href) {
        type = 'link';
      } else {
        this.loggerService.warn(
          `[Button] - The configured button with 'type: "link"' was requested but no 'link' property was configured.`
        );
      }
    }

    // button style
    let style: ButtonStyleTypes = 'mat-button';
    switch (configuration.style) {
      case 'mat-fab':
      case 'mat-mini-fab':
      case 'mat-raised-button':
      case 'mat-flat-button':
      case 'mat-stroked-button':
      case 'mat-icon-button':
      case 'mat-button':
        style = configuration.style;
        break;
    }

    // extended (used for mat-fab to allow them to grow with a label)
    let extended = false;
    if (configuration.extended === true && style === 'mat-fab') {
      extended = true;
    }

    // button color
    let color: ThemePalette = undefined;
    // TODO Deprecated "basic" on Angular 15.
    if ((color as string) === 'basic') {
      this.loggerService.warn(
        `[Button] - The configured button uses 'color: "basic"' but this is deprecated and been converted to undefined`
      );
    }
    switch (configuration.color) {
      case 'primary':
      case 'accent':
      case 'warn':
        color = configuration.color;
        break;
    }

    // icon
    let hasIcon = false;
    let icon: IMatIcon | undefined = undefined;
    if (configuration.icon) {
      hasIcon = true;
      icon = cloneDeep(configuration.icon);
    }

    // link
    let link: ILink | undefined = undefined;
    if (type === 'link' && configuration.link) {
      link = cloneDeep(configuration.link);
    }

    // feature
    let hasCopyToClipboardFeature = false;
    let copyToClipboard: IButtonFeatureCopyToClipboard | undefined = undefined;
    let hasClickToCloseFeature = false;
    let hasPrintFeature = false;
    if (configuration.feature) {
      if (configuration.feature.copyToClipboard) {
        hasCopyToClipboardFeature = true;
        copyToClipboard = cloneDeep(configuration.feature.copyToClipboard);
      } else if (configuration.feature.clickToClose) {
        hasClickToCloseFeature = true;
      } else if (configuration.feature.print) {
        hasPrintFeature = true;
      }
    }

    // isTextured
    let isTextured = false;
    if (configuration.isTextured) {
      isTextured = configuration.isTextured;
    }

    // dialog
    let hasDialog = false;
    let dialog: IDialogContent | undefined = undefined;
    if (configuration.dialog) {
      hasDialog = true;
      dialog = cloneDeep(configuration.dialog);
    }

    // tab index
    let tabIndex: number | undefined = undefined;
    if (typeof configuration.tabIndex === 'number') {
      tabIndex = configuration.tabIndex;
    }

    this.update((draft) => {
      draft.hasLabel = hasLabel;
      draft.label = label;
      draft.type = type;
      draft.style = style;
      draft.color = color;
      draft.hasIcon = hasIcon;
      draft.icon = icon;
      draft.link = link;
      draft.hasClickToCloseFeature = hasClickToCloseFeature;
      draft.hasCopyToClipboardFeature = hasCopyToClipboardFeature;
      draft.copyToClipboard = copyToClipboard;
      draft.hasPrintFeature = hasPrintFeature;
      draft.isTextured = isTextured;
      draft.hasDialog = hasDialog;
      draft.dialog = dialog;
      draft.extended = extended;
      draft.tabIndex = tabIndex;
    });
  }

  /**
   * Is the button disabled?
   *
   * @param isDisabled - true if disabled, false otherwise.
   */
  applyDisabled(isDisabled: boolean) {
    this.update((draft) => {
      draft.disabled = isDisabled;
    });
  }

  /**
   * Does the button belong to a form?
   *
   * @param formName - the name of the form to which the button belongs.
   */
  applyForm(formName?: string) {
    let belongsToForm = false;
    if (formName && formName.length > 0) {
      belongsToForm = true;
    } else {
      formName = undefined;
    }

    this.update((draft) => {
      draft.belongsToForm = belongsToForm;
      draft.form = formName;
    });
  }
}
