import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IMediaLockable, MediaLockType } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { MediaLockableModel } from './media-lockable.model';

/**
 * Creates the initial state of Media Lockable Component.
 */
function createInitialState(): MediaLockableModel {
  return {
    configured: false,
    initialized: false,
    progress: 0,
    mediaReady: false,
    mediaDuration: 0,
    mediaCurrentTime: 0,
    mediaIsPortrait: false,
    unlocked: false,
    error: false,
    media: undefined,
    target: 0,
    hideProgress: false,
    type: MediaLockType.End,
  };
}

/**
 * The Store used for a {@link MediaLockableComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockableStore extends Store<MediaLockableModel> {
  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService) {
    super(createInitialState(), { name: `media-lockable-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMediaLockable) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(
        `MediaLockableStore`,
        `applyConfiguration`,
        `No configuration provided but this is required.`,
        `config`
      );
    }

    // if there is no media then that is an error
    const media = configuration.media;
    if (!media) {
      throw createDigitaServiceError(
        `MediaLockableStore`,
        `applyConfiguration`,
        `No "media" property provided but this is required.`,
        `config`
      );
    }

    // type
    let type: MediaLockType = MediaLockType.End;
    if (configuration.type) {
      switch (configuration.type) {
        default:
          break;
        case MediaLockType.Time:
          type = MediaLockType.Time;
          break;
        case MediaLockType.Percent:
          type = MediaLockType.Percent;
          break;
      }
    }

    // target
    let target: number | undefined = undefined;
    if (type === MediaLockType.Time || type === MediaLockType.Percent) {
      if (typeof configuration.target === 'number') {
        if (type === MediaLockType.Time) {
          target = configuration.target;
        } else if (type === MediaLockType.Percent) {
          target = configuration.target;
          if (target < 0) {
            target = 0;
          } else if (target > 100) {
            target = 100;
          }
        }
      } else {
        throw createDigitaServiceError(
          `MediaLockableStore`,
          `applyConfiguration`,
          `No "target" property provided but this is required because "type" property is set to ${type}.`,
          `config`
        );
      }
    }

    // hideProgress
    let hideProgress = false;
    if (configuration.hideProgress === true) {
      hideProgress = true;
    }

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.media = media;
      draft.target = target;
      draft.type = type;
      draft.hideProgress = hideProgress;
    });
  }

  /**
   * Occurs when the Media starts to play.
   */
  applyInitialize() {
    this.update((draft) => {
      draft.initialized = true;
    });
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.update((draft) => {
      draft.error = true;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // MEDIA STATE
  ////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the Media is ready to be played.
   */
  applyMediaIsReady() {
    this.update((draft) => {
      draft.mediaReady = true;
    });
  }

  /**
   * Apply the Duration of the Media.
   *
   * @param duration - the total duration of the media.
   */
  applyDuration(duration: number) {
    const { unlocked } = this.getValue();

    // if ended then do nothing
    if (unlocked) {
      return;
    }

    // otherwise update the media duration
    this.update((draft) => {
      draft.mediaDuration = duration;
    });

    // update the percentage
    this.updatePercentage();
  }

  /**
   * Apply the Current Time of the Media.
   *
   * @param currentTime - the current time of the media.
   */
  applyCurrentTime(currentTime: number) {
    const { unlocked } = this.getValue();

    // if ended then do nothing
    if (unlocked) {
      return;
    }

    // otherwise update the media current time
    this.update((draft) => {
      draft.mediaCurrentTime = currentTime;
    });

    // update the percentage
    this.updatePercentage();
  }

  /**
   * Calculates the percentage of the media lock and updates the store.
   */
  private updatePercentage() {
    const { initialized, type, target, mediaDuration, mediaCurrentTime } = this.getValue();

    // if not initialized then ignore
    if (!initialized || mediaDuration === 0) {
      return;
    }

    // calc
    let percentage = 0;
    switch (type) {
      default: {
        percentage = (mediaCurrentTime / mediaDuration) * 100;
        break;
      }
      case MediaLockType.Time: {
        percentage = (mediaCurrentTime / target) * 100;
        break;
      }
      case MediaLockType.Percent: {
        const durationPercentage = (mediaDuration / 100) * target;
        percentage = (mediaCurrentTime / durationPercentage) * 100;
        break;
      }
    }

    // truncate
    if (percentage > 100) {
      percentage = 100;
    } else if (percentage < 0) {
      percentage = 0;
    }

    // has the media lock ended?
    let unlocked = false;
    if (percentage === 100) {
      unlocked = true;
    }

    // update the store
    this.update((draft) => {
      draft.progress = percentage;
      draft.unlocked = unlocked;
    });
  }

  /**
   * Occurs if the media is in portrait mode or not.
   *
   * @param isPortrait - whether the media is in portrait mode.
   */
  applyMediaIsPortrait(isPortrait: boolean) {
    this.update((draft) => {
      draft.mediaIsPortrait = isPortrait;
    });
  }
}
