import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PromoCodeModel } from './promo-code.model';
import { PromoCodeStore } from './promo-code.store';

/**
 * The Query used for an {@link PromoCodeComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PromoCodeQuery extends Query<PromoCodeModel> {
  /**
   * The Text Property.
   */
  text$ = this.select((state) => state.text);

  /**
   * Constructor
   */
  constructor(protected override readonly store: PromoCodeStore) {
    super(store);
  }
}
