import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { GenerateFaIcon } from 'src/app/factories/generators/icon-fa.generator';

/**
 * A Built in Default State
 */
const DEFAULT_ICON = GenerateFaIcon({
  name: 'fas:heart',
  state: 'default',
});

export function createInitialState(): Required<IDataboxLivesIcon> {
  return {
    selector: 'app-databox-lives-icon',
    icon: DEFAULT_ICON,
    lives: 0,
  };
}
/**
 * Manages the State of a {@link DataboxLivesIconComponent}.
 */
@Injectable()
export class DataboxLivesIconStore extends Store<Required<IDataboxLivesIcon>> {
  constructor() {
    super(createInitialState(), {
      name: `databox-lives-icon-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Applies the incoming configuration to the model
   */
  applyInitialize(configuration?: Partial<IDataboxLivesIcon>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    let lives = 0;
    if (typeof configuration.lives === 'number' && configuration.lives >= 0) {
      lives = configuration.lives;
    }

    let icon = cloneDeep(DEFAULT_ICON);
    if (configuration.icon) {
      icon = cloneDeep(configuration.icon);
    }

    this.update((draft) => {
      draft.lives = lives;
      draft.icon = icon;
    });
  }

  /**
   * When the users lives updates
   */
  applyUpdate(livesRemaining: number) {
    if (typeof livesRemaining !== 'number' || livesRemaining < 0) {
      return;
    }

    this.update((draft) => {
      draft.lives = livesRemaining;
    });
  }
}
