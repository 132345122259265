import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxHighScore } from 'src/app/api/modules/core/dynamic/databoxes/highscore/IDataboxHighScore';
import { DataboxHighScoreQuery } from './databox-highscore.query';
import { DataboxHighScoreStore } from './databox-highscore.store';

@Component({
  selector: 'app-databox-highscore',
  templateUrl: './databox-highscore.component.html',
  styleUrls: ['./databox-highscore.component.scss'],
  providers: [DataboxHighScoreStore, DataboxHighScoreQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxHighScoreComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxHighScore) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxHighScore;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxHighScoreStore, public readonly query: DataboxHighScoreQuery) {}

  /**
   * Updates the component with the users score
   *
   * @param score - the score of the user
   */
  update(score?: number) {
    this.store.applyUpdate(score);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.applyReset();
  }
}
