import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { interval, Subscription, take } from 'rxjs';
import { IMediaYouTube } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { MediaQuery } from '../media.query';
import { MediaService } from '../media.service';

/**
 * The Media YouTube Provider Component.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is not standalone and belongs within the {@link MediaComponent}.
 *
 * See {@link https://developers.google.com/youtube/iframe_api_reference}
 */
@Component({
  selector: 'app-media-youtube',
  templateUrl: './media-youtube.component.html',
  styleUrls: ['./media-youtube.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaYouTubeComponent implements AfterViewInit, OnDestroy {
  /**
   * A reference to the media HTMLDivElement.
   */
  @ViewChild('media') private mediaElement: ElementRef<HTMLDivElement>;

  /**
   * Holds a reference to the youtube player
   */
  private player?: YT.Player;

  /**
   * Holds all subscriptions for teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Holds the timer subscription specifically.
   */
  private timerSubscription?: Subscription;

  /**
   * Holds the configuration subscription specifically.
   */
  private configurationSubscription?: Subscription;

  /**
   * Constructor.
   *
   * @param mediaQuery - a reference to the media query.
   * @param mediaService - a reference to the media service.
   */
  constructor(private readonly mediaQuery: MediaQuery, private readonly mediaService: MediaService) {}

  /////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  /////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen out for when the youtube configuration becomes available
    this.configurationSubscription = this.mediaQuery.youtubeSettings$.pipe(take(1)).subscribe((data) => {
      this.configurationSubscription?.unsubscribe();
      this.createPlayer(data);
    });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();
    this.timerSubscription?.unsubscribe();
    this.player?.destroy();
  }

  /////////////////////////////////////////////////////////////////////
  // PLAYER CREATION
  /////////////////////////////////////////////////////////////////////

  /**
   * Create the player.
   *
   * @param options - the youtube options
   */
  private createPlayer(options: IMediaYouTube) {
    this.subscriptions?.unsubscribe();
    this.subscriptions = new Subscription();

    this.player?.destroy();
    this.configurationSubscription?.unsubscribe();
    this.timerSubscription?.unsubscribe();

    // create the player
    this.player = new YT.Player(this.mediaElement.nativeElement, options);

    // listen to events on the player
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.player.addEventListener('onReady', (event: YT.PlayerEvent) => {
      // once the player is ready we will find the total time of the video
      if (this.player) {
        this.updateDuration();
      }

      // inform the store that the video is ready
      this.mediaService.applyReady();
    });

    this.player.addEventListener('onStateChange', (event: YT.OnStateChangeEvent) => {
      switch (event.data) {
        case YT.PlayerState.UNSTARTED:
          break;
        case YT.PlayerState.ENDED:
          this.videoEnded();
          break;
        case YT.PlayerState.PLAYING:
          this.videoPlaying();
          break;
        case YT.PlayerState.PAUSED:
          this.videoPaused();
          break;
      }
    });

    this.player.addEventListener('onError', (event: YT.OnErrorEvent) => {
      this.onError(event);
    });

    // create the integration
    this.createPlayerIntegration();
  }

  /**
   * Integrates the player with the MediaService of the application.
   */
  private createPlayerIntegration() {
    // listen for play events.
    const play = this.mediaService.play$.subscribe(() => {
      if (this.player?.playVideo) {
        this.player.playVideo();
      }
    });
    this.subscriptions.add(play);

    // listen for pause events.
    const pause = this.mediaService.pause$.subscribe(() => {
      if (this.player?.pauseVideo) {
        this.player.pauseVideo();
      }
    });
    this.subscriptions.add(pause);

    // seek to the current time
    const seekTo = this.mediaService.seekTo$.subscribe((data) => {
      if (data && this.player) {
        this.player.seekTo(data.seconds, data.allowSeekAhead);
        if (data.play) {
          if (this.player.playVideo) {
            this.player?.playVideo();
          }
        } else {
          if (this.player.pauseVideo) {
            this.player?.pauseVideo();
          }
        }
      }
    });
    this.subscriptions.add(seekTo);

    // mute the media
    const mute = this.mediaService.mute$.subscribe(() => {
      if (this.player?.mute) {
        this.player.mute();
      }
    });
    this.subscriptions.add(mute);

    // unMute the media
    const unMute = this.mediaService.unMute$.subscribe(() => {
      if (this.player?.unMute) {
        this.player.unMute();
      }
    });
    this.subscriptions.add(unMute);

    // set the volume
    const volume = this.mediaService.volume$.subscribe((volume) => {
      if (typeof volume === 'number' && volume >= 0 && volume <= 100) {
        if (this.player?.setVolume) {
          this.player.setVolume(volume);
        }
      }
    });
    this.subscriptions.add(volume);
  }

  /**
   * The Video has ended.
   */
  private videoEnded() {
    this.stopTimer(false);
    if (this.player) {
      const duration = Math.floor(this.player.getDuration());
      this.mediaService.applyCurrentTime(duration);
    }
    this.mediaService.applyEnded();
  }

  /**
   * Occurs when the video is playing.
   */
  private videoPlaying() {
    this.startTimer();
    this.mediaService.applyPlaying();
  }

  /**
   * Occurs when the video is paused.
   */
  private videoPaused() {
    this.stopTimer(true);
    this.mediaService.applyPaused();
  }

  /////////////////////////////////////////////////////////////////////
  // TIMERS
  /////////////////////////////////////////////////////////////////////

  /**
   * Start the playback timer.
   *
   * This will poll the video for currentTime Events.
   */
  private startTimer() {
    // stop any timer before starting a new one.
    this.stopTimer(true);

    // start a timer to check the current time of the video
    this.timerSubscription = interval(500).subscribe(() => {
      this.updateCurrentTime();
    });
  }

  /**
   * Stop the playback timer.
   *
   * @param updateCurrentTime - whether to update the current time of the video before stopping.
   */
  private stopTimer(updateCurrentTime: boolean) {
    // update the current time of the video before stopping.
    if (updateCurrentTime) {
      this.updateCurrentTime();
    }

    // stop the timer
    this.timerSubscription?.unsubscribe();
  }

  /////////////////////////////////////////////////////////////////////
  // UTILS
  /////////////////////////////////////////////////////////////////////

  /**
   * Retrieve the current time from the player and save it to the store.
   */
  private updateCurrentTime() {
    if (this.player) {
      const currentTime = Math.floor(this.player.getCurrentTime());
      this.mediaService.applyCurrentTime(currentTime);
    }
  }

  /**
   * Retrieve the total duration from the player and save it to the store.
   */
  private updateDuration() {
    if (this.player) {
      const duration = Math.floor(this.player.getDuration());
      this.mediaService.applyDuration(duration);
    }
  }

  /////////////////////////////////////////////////////////////////////
  // ERRORS
  /////////////////////////////////////////////////////////////////////

  /**
   * Occurs when an error occurs.
   *
   * @param event - the event
   */
  private onError(event: YT.OnErrorEvent) {
    this.mediaService.applyError(event.data?.toString());
  }
}
