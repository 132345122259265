import { AnimationPlayer } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TextAnimationTypes } from '@digitaservice/utils';
import { Subscription } from 'rxjs';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { AnimationBuilderService } from '../../../services/animations/animation-builder.service';
import { PluginTextEntityQuery } from './plugin-text-entity.query';
import { PluginTextEntityService } from './plugin-text-entity.service';
import { PluginTextEntityStore } from './plugin-text-entity.store';

@Component({
  selector: 'app-plugin-text-entity',
  templateUrl: './plugin-text-entity.component.html',
  styleUrls: ['./plugin-text-entity.component.scss'],
  providers: [PluginTextEntityService, PluginTextEntityQuery, PluginTextEntityStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PluginTextEntityComponent implements AfterViewInit, OnDestroy {
  /**
   * Host Binding for the left (x) position of text
   */
  @HostBinding('style.left')
  public x = '50%';

  /**
   * Host Binding for the top (y) position of text
   */
  @HostBinding('style.top')
  public y = '50%';

  /**
   * The Configuration powering this Component
   */
  private _config: IPluginTextEntity;
  @Input() set config(configuration: IPluginTextEntity) {
    this._config = configuration;
    this.service.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * The ID of the Plugin Text Entity
   */
  @Input() id: string;

  /**
   * Occurs when the animation has completed
   */
  @Output() animationCompleted = new EventEmitter<string>();

  /**
   * The Animation Player
   */
  private player?: AnimationPlayer;

  /**
   * Holds all subscriptions
   */
  private subscriptions = new Subscription();

  /**
   * Creates a new Plugin Text Entry Component
   */
  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly animationBuilderService: AnimationBuilderService,
    private readonly service: PluginTextEntityService,
    public readonly query: PluginTextEntityQuery,
    public cd: ChangeDetectorRef
  ) {}

  /**
   * Lifecycle Override
   *
   * As soon as the component is added to the dom, the animation should begin.
   *
   * As soon as the component ends it's animation, the animationComplete will be fired.
   */
  ngAfterViewInit() {
    // deals with positioning
    const positionSubscription = this.query.position$.subscribe((position) => {
      this.x = position.x;
      this.y = position.y;
    });
    this.subscriptions.add(positionSubscription);

    // deals with animation
    const animationDataSubscription = this.query.animationData$.subscribe((animationData) => {
      const targetElement = this.host.nativeElement;
      const autoPlay = false;

      switch (animationData.type) {
        default:
          this.player = this.animationBuilderService.fadeInOut(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.bounceInOut:
          this.player = this.animationBuilderService.bounceInOut(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromBottom:
          this.player = this.animationBuilderService.slideInOutFromBottom(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromBottomReversed:
          this.player = this.animationBuilderService.slideInOutFromBottomReversed(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromLeft:
          this.player = this.animationBuilderService.slideInOutFromLeft(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromLeftReversed:
          this.player = this.animationBuilderService.slideInOutFromLeftReversed(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromRight:
          this.player = this.animationBuilderService.slideInOutFromRight(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromRightReversed:
          this.player = this.animationBuilderService.slideInOutFromRightReversed(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromTop:
          this.player = this.animationBuilderService.slideInOutFromTop(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.slideInOutFromTopReversed:
          this.player = this.animationBuilderService.slideInOutFromTopReversed(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.zoomInOut:
          this.player = this.animationBuilderService.zoomInOut(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.zoomInOutTowards:
          this.player = this.animationBuilderService.zoomInOutTowards(targetElement, autoPlay, animationData);
          break;
        case TextAnimationTypes.rollInOut:
          this.player = this.animationBuilderService.rollInOut(targetElement, autoPlay, animationData);
          break;
      }

      this.player?.onDone(() => {
        this.service.applyComplete();
      });

      this.cd.markForCheck();
      this.player.play();
    });
    this.subscriptions.add(animationDataSubscription);

    // deals with completion
    const completeSubscription = this.query.complete$.subscribe(() => {
      this.animationCompleted.next(this.id);
    });
    this.subscriptions.add(completeSubscription);
  }

  /**
   * Lifecylce Hook
   */
  ngOnDestroy() {
    this.animationCompleted?.complete();
    this.player?.destroy();
    this.subscriptions?.unsubscribe();
  }
}
