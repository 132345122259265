import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { DialogMediaLockModel } from './dialog-media-lock.model';

/**
 * Creates the initial state of an media store.
 */
function createInitialState(): DialogMediaLockModel {
  return {
    configured: false,
    header: undefined,
    lock: undefined,
    footer: undefined,
    error: false,
    unlocked: false,
    settings: {},
  };
}

/**
 * The Store used for a {@link DialogMediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogMediaLockStore extends Store<DialogMediaLockModel> {
  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService) {
    super(createInitialState(), { name: `dialog-media-lock-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IDialogMediaLock) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`DialogMediaLock`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // the header
    const header = configuration.header;

    // if there is no lock then that is an error
    const lock = configuration.lock;
    if (!lock) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "lock" property provided but this is required.`,
        `config`
      );
    }

    // the footer
    const footer = configuration.footer;
    if (!footer) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "footer" property provided but this is required.`,
        `config`
      );
    }
    if (!footer.negativeButton) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "footer.negativeButton" property provided but this is required.`,
        `config`
      );
    }
    if (footer.positiveButton) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `Do not use "footer.positiveButton" property for this dialog type. Use "footer.negativeButton" instead.`,
        `config`
      );
    }

    // the settings
    const settings = configuration.settings;

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.header = header;
      draft.lock = lock;
      draft.footer = footer;
      draft.settings = settings;
    });
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.loggerService.error(`Media Error - Unlocked`);
    this.update((draft) => {
      draft.unlocked = true;
      draft.error = true;
    });
  }

  /**
   * Occurs when the Media Lock is unlocked.
   */
  applyUnlock() {
    this.update((draft) => {
      draft.unlocked = true;
    });
  }
}
