import { ViewContainerRef } from '@angular/core';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { TextRichComponent } from './text-rich.component';

/**
 * Dynamically creates a `TextRich` instance
 * @param destinationViewConterRef the container into which we want to inject the component
 * @param config the configuration required to construct a validcomponent
 */
export const createTextRichComponent = (viewContainerRef: ViewContainerRef, config: ITextRich) => {
  const componentReference = viewContainerRef.createComponent(TextRichComponent);
  const component = componentReference.instance;
  component.config = config;
  return componentReference;
};
