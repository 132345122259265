import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPromoCode } from 'src/app/api/modules/core/dynamic/components/IPromoCode';
import { PromoCodeQuery } from './promo-code.query';
import { PromoCodeStore } from './promo-code.store';

/**
 * The PromoCodeComponent is used to display a styized text representation of a promo code.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  providers: [PromoCodeStore, PromoCodeQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCodeComponent {
  /**
   * The Config as provided to the element
   */
  private _config?: IPromoCode;
  @Input() set config(configuration: IPromoCode) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(private readonly store: PromoCodeStore, public readonly query: PromoCodeQuery) {}
}
