import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxScore } from 'src/app/api/modules/core/dynamic/databoxes/score/IDataboxScore';
import { DataboxScoreQuery } from './databox-score.query';
import { DataboxScoreStore } from './databox-score.store';

@Component({
  selector: 'app-databox-score',
  templateUrl: './databox-score.component.html',
  styleUrls: ['./databox-score.component.scss'],
  providers: [DataboxScoreStore, DataboxScoreQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxScoreComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxScore) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxScore;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxScoreStore, public readonly query: DataboxScoreQuery) {}

  /**
   * Updates the component with the users score
   *
   * @param score - the score of the user
   */
  update(score?: number) {
    this.store.applyUpdate(score);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.applyReset();
  }
}
