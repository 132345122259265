<div
  class="mat-drawer-container mat-typography direct"
  [class.widget-auto]="(appQuery.shouldApplyWidgetAutoLayout$ | async) === true"
  [class.widget-fixed]="(appQuery.shouldApplyWidgetFixedLayout$ | async) === true"
  [ngClass]="footerQuery.appLayoutClass$ | async"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutAlign.gt-xs="start stretch"
>
  <app-shell-background></app-shell-background>
  <app-shell-outlet-container
    [fxLayout]="(appQuery.shellLayout$ | async) || 'row'"
    [fxLayoutAlign]="(appQuery.shellLayoutAlign$ | async) || 'center center'"
  ></app-shell-outlet-container>
  <app-shell-loader></app-shell-loader>
  <app-shell-fg-effects-container></app-shell-fg-effects-container>
  <app-footer fxLayout="column" fxLayoutAlign="end stretch" *ngIf="footerQuery.exists$ | async"></app-footer>
  <!--Dev Panel Dev Only-->
  <app-dev-panel *ngIf="showDevPanel"></app-dev-panel>
</div>
