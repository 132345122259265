import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimatedCounterDirective } from './directives/animated-counter/animated-counter.directive';
import { DynamicContentDirective } from './directives/dynamic-content/dynamic-content.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicContentDirective, AnimatedCounterDirective],
  exports: [DynamicContentDirective, AnimatedCounterDirective],
})
export class SharedModule {}
