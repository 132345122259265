import { animate, animateChild, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const leaderboardEntryBelongsToAnimation = trigger('leaderboardEntryBelongsToAnimation', [
  state(
    'true',
    style({
      backgroundColor: `{{backgroundColor}}`,
    }),
    { params: { backgroundColor: 'rgba(0, 0, 255, 0.5)' } }
  ),
  state(
    'false',
    style({
      backgroundColor: '*',
    })
  ),
  transition('* => true', [animate('500ms ease-out')]),
  transition('* => false', [animate('0ms')]),
]);

export const leaderboardEntryLifecycleAnimation = trigger('leaderboardEntryLifecycleAnimation', [
  transition('* <=> *', [
    group([
      // each time the binding value changes
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateX(-50px)' }),
          stagger('100ms', [animate('500ms 100ms cubic-bezier(0.33, 1, 0.68, 1)', style({ opacity: 1, transform: 'translateX(0)' }))]),
        ],
        { optional: true }
      ),
      query('@leaderboardEntryBelongsToAnimation', animateChild(), { optional: true }),

      // query(
      //   ':leave',
      //   [stagger('100ms', animate('500ms cubic-bezier(0.33, 1, 0.68, 1)', style({ opacity: 0, transform: 'translateX(50px)' })))],
      //   { optional: true }
      // ),
    ]),
  ]),
]);
