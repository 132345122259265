import { MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatProgressSpinnerDefaultOptions } from '@angular/material/progress-spinner';
import { IShellLayout } from './api/modules/core/components/abstract/IShellLayout';

/**
 * The Application has to be aware of the following static end points
 * in order to function.
 */
export enum IMPORTANT_API_PATHS {
  INITIALIZE = '/initialize',
}

////////////////////////////////////////////////////////////////////////
// Dialogs
////////////////////////////////////////////////////////////////////////

/**
 * All Dialogs are by default configured with these settings.
 *
 * It is used throughout the application.
 *
 * See {@link https://material.angular.io/components/dialog/overview#specifying-global-configuration-defaults}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DefaultDialogSettings<D = any> extends MatDialogConfig<D> {
  constructor(data?: D) {
    super();
    this.data = data;
    this.panelClass = ['mat-typography', 'app-dialog-custom'];
    this.width = '100%';
    this.maxWidth = '600px';
    this.hasBackdrop = true;
    this.autoFocus = 'dialog';
  }
}

/**
 * The Default Settings for all Mat Form Fields throughout the Application.
 */
export const DEFAULT_MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'dynamic',
};

/**
 * The Default Settings for all Mat Progress Spinners throughout the Application.
 */
export const DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS: MatProgressSpinnerDefaultOptions = {
  diameter: 32,
};

////////////////////////////////////////////////////////////////////////
// DigitaService API Service - HTTP Settings
////////////////////////////////////////////////////////////////////////

/**
 * The Width and Height of the Social Sharing popup displayed on Desktops
 *
 * Note that should the window.screen properties be less than this, the lesser is used.
 */
export const SOCIAL_SHARING_FEATURES = {
  WIDTH: 600,
  HEIGHT: 600,
};

/**
 * Standard API Retry Request Backoff settings
 */
export enum DIGITASERVICE_API_BACKOFF {
  /**
   * Used with DigitaService API Service. When a HTTP Request fails. This is exponential
   * so the first retry will happen 500ms after the first. Then 1000ms then 2000ms then 4000ms
   * until it reaches either `DIGITASERVICE_API_BACKOFF_MAX_INTERVAL` or `DIGITASERVICE_API_BACKOFF_ATTEMPTS` where
   * it will finally through an Error.
   */
  EXPO = 500,

  /**
   * What is the maximum possible time we will wait between retries to the DigitaService API regardless
   * of exponential backoff?
   */
  MAX_INTERVAL = 30000,

  /**
   * When making a standard DigitaService API Request {@link IRequest} / {@link IResponse} how many
   * attempts should there be before we give up and consider the application dead?
   */
  MAX_RETRIES = 10,
}

/**
 * Binary Retry Request Backoff settings
 */
export enum DIGITASERVICE_BINARY_BACKOFF {
  /**
   * Used with DigitaService API Service. When a HTTP Request fails. This is exponential
   * so the first retry will happen 500ms after the first. Then 1000ms then 2000ms then 4000ms
   * until it reaches either `DIGITASERVICE_API_BACKOFF_MAX_INTERVAL` or `DIGITASERVICE_API_BACKOFF_ATTEMPTS` where
   * it will finally through an Error.
   */
  EXPO = 500,

  /**
   * What is the maximum possible time we will wait between retries to the DigitaService API regardless
   * of exponential backoff?
   */
  MAX_INTERVAL = 30000,

  /**
   * When making an binary request how many attempts should there be before we give up and consider the application dead?
   */
  MAX_RETRIES = 3,
}

/**
 * We have a global loading indicator, how long do we wait before showing it when it is requested?
 *
 * In miliseconds
 */
export const DEFAULT_LOADING_INDICATOR_DISPLAY_DELAY = 1000;

/**
 * All Material Loading Spinners are this Diameter
 */
export const LOADING_SPINNER_DIAMETER = '50';

/**
 * The Default Height of Plugin Components when no other dimension
 * have been specified
 */
export const DEFAULT_PLUGIN_COMPONENT_HEIGHT = 400;

/**
 * Individual Screens may control the applications Flexbox Layout.
 *
 * See {@link IShellLayout}
 */
export const DEFAULT_SHELL_LAYOUT: Required<IShellLayout> = {
  mobile: {
    grow: 1,
    shrink: 0,
    basis: '300px',
  },
  desktop: {
    grow: 0,
    shrink: 0,
    basis: '600px',
  },
  layout: 'row',
  layoutAlign: 'center center',
};

/**
 * A Special URL used to refresh the existing app window.
 */
export const RELOAD_PAGE = 'reload';
