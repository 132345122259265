import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, defer, fromEvent, Observable, of } from 'rxjs';
import { filter, shareReplay, switchMap, take } from 'rxjs/operators';
import { MediaManagerStore } from './media-manager.store';

/**
 * The Media Manager Service.
 *
 * This service is responsible for managing media playback globally.
 *
 * It is responsible for:
 *
 * 1. streaming provider library loading such as youtube or vimeo.
 * 2. preventing multiple media playing at the same time.
 * 3. media screen intersections.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is global.
 */
@Injectable({
  providedIn: 'root',
})
export class MediaManagerService {
  /**
   * Has the YouTube Player Loaded?
   */
  private youTubePlayerAvailable = new BehaviorSubject(false);
  private youtubePlayerAvailable$ = this.youTubePlayerAvailable.asObservable();
  private youTubePlayerLoaded?: Observable<boolean>;

  /**
   * Has the Vimeo Player Loaded?
   */
  private vimeoPlayerLoaded?: Observable<boolean>;

  /**
   * Constructor
   */
  constructor(
    private readonly mediaManagerStore: MediaManagerStore,
    private readonly http: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly zone: NgZone
  ) {
    // The youtube API is global and required for when the player becomes available
    window.onYouTubeIframeAPIReady = () => {
      this.zone.run(() => {
        // this will emit
        this.youTubePlayerAvailable.next(true);
        // the callback is no longer required
        window.onYouTubeIframeAPIReady = undefined;
      });
    };
  }

  ////////////////////////////////////////////////////////////////////
  // MEDIA PROVIDER LIBRARY LOADERS
  ////////////////////////////////////////////////////////////////////

  /**
   * Attempt to load the YouTube Player API.
   *
   * @returns an Observable that will emit true if the YouTube Player API is loaded or false if the YouTube Player failed to load.
   */
  loadYouTubePlayer() {
    // return a deferred observable
    return defer(() => {
      // if the YouTube Player API has not been loaded
      if (!this.youTubePlayerLoaded) {
        // See https://developers.google.com/youtube/iframe_api_reference#Getting_Started
        const tag = this.document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        const firstScriptTag = this.document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // with the tag in place, listen for when it loads
        this.youTubePlayerLoaded = this.youtubePlayerAvailable$.pipe(
          // we only care about when it's loaded
          filter((loaded) => loaded),
          // this should be shared between all subscribers
          shareReplay(1)
        );
      }
      return this.youTubePlayerLoaded;
    });
  }

  /**
   * Attempt to load the Vimeo Player API.
   *
   * @returns an Observable that will emit true if the Vimeo Player API is loaded or false if the Vimeo Player failed to load.
   */
  loadVimeoPlayer() {
    // return a deferred observable
    return defer(() => {
      // if the Vimeo Player API has not been loaded
      if (!this.vimeoPlayerLoaded) {
        // See https://developer.vimeo.com/player/sdk/basics
        const tag = this.document.createElement('script');
        tag.src = 'https://player.vimeo.com/api/player.js';
        const firstScriptTag = this.document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // with the tag in place, listen for when it loads
        this.vimeoPlayerLoaded = fromEvent(tag, 'load').pipe(
          // we only care about when it's loaded
          switchMap(() => {
            return of(true);
          }),
          // this should be shared between all subscribers
          shareReplay(1)
        );
      }
      return this.vimeoPlayerLoaded;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // MEDIA PROVIDER OEMBED LOADERS
  ////////////////////////////////////////////////////////////////////

  /**
   * Returns information about a YouTube video from the oEmbed API.
   *
   * @param videoId - the video ID to request
   */
  retrieveYouTubeVideoDetails(videoId: string) {
    return this.http
      .get<YT.OEmbed>(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`, {
        responseType: 'json',
      })
      .pipe(take(1));
  }

  /**
   * Returns information about a Vimeo video from the oEmbed API.
   *
   * @param id - the video ID to request
   */
  retrieveVimeoVideoDetails(id?: number, url?: string) {
    if (typeof id === 'number') {
      return this.http
        .get<Vimeo.Ombed>(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`, {
          responseType: 'json',
        })
        .pipe(take(1));
    } else {
      return this.http
        .get<Vimeo.Ombed>(`https://vimeo.com/api/oembed.json?url=${url}`, {
          responseType: 'json',
        })
        .pipe(take(1));
    }
  }

  ////////////////////////////////////////////////////////////////////
  // MEDIA PLAYBACK MANAGEMENT
  ////////////////////////////////////////////////////////////////////

  /**
   * All Media (both streaming providers and native) have unique system IDs.
   *
   * Each time a media plays, it's unique ID is set (or removed) globally so that
   * any media which is not the active media can be paused.
   */
  applyActiveMedia(mediaID: string | null) {
    this.mediaManagerStore.applyActiveMediaID(mediaID);
  }
}
