import { Injectable } from '@angular/core';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ButtonStore } from './button.store';

/**
 * The Button Service is used with the {@link ButtonComponent}.
 */
@Injectable()
export class ButtonService {
  /**
   * Constructor
   */
  constructor(private readonly buttonStore: ButtonStore) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IButton) {
    this.buttonStore.applyInitialize(configuration);
  }

  /**
   * Is the Button disabled?
   *
   * @param isDisabled - true if disabled, false otherwise.
   */
  applyDisabled(isDisabled: boolean) {
    this.buttonStore.applyDisabled(isDisabled);
  }

  /**
   * Is the Button related to a form?
   *
   * @param formName - the name of the form this button belongs to.
   */
  applyForm(formName: string) {
    this.buttonStore.applyForm(formName);
  }
}
