import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { ILeaderboard, ILeaderboardFilter } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboard';
import { ILeaderboardCell } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardCell';
import { ILeaderboardDataResponse } from 'src/app/api/modules/core/dynamic/leaderboard/network/ILeaderboardDataResponse';
import { IPollingScheduleData } from 'src/app/api/modules/core/network/IPollingScheduleData';
import { createDigitaServiceError } from 'src/app/app-error';
import { LeaderboardModel } from './leaderboard.model';

const defaultBelongsToUserColor = 'rgba(0, 0, 255, 0.5)';
const defaultChangeHighlightColor = 'rgba(255,255,255,0.5)';

function createInitialState(): LeaderboardModel {
  return {
    selector: 'app-leaderboard',
    isLoading: true,
    belongsToUserColor: defaultBelongsToUserColor,
    changeHighlightColor: defaultChangeHighlightColor,
    headers: [],
    schedule: undefined,
    title: undefined,
    isAnimating: false,
    isDebug: false,
    entries: [],
    filters: [],
    activeFilters: {},
  };
}
/**
 * Manages the State of a {@link LeaderboardComponent}.
 */
@Injectable()
export class LeaderboardStore extends Store<LeaderboardModel> {
  constructor() {
    super(createInitialState(), {
      name: `leaderboard-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: Partial<ILeaderboard>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`Leaderboard`, `initialize`, `No configuration was provided but is required`, `config`);
    }

    // filters
    let filters: ILeaderboardFilter[] | undefined = undefined;
    const activeFilters: Record<string, string> = {};
    if (configuration.filters && configuration.filters.length > 0) {
      filters = cloneDeep(configuration.filters);
      for (let i = 0; i < filters.length; i++) {
        const targetFilter = filters[i];
        if (typeof targetFilter.key !== 'string' || targetFilter.key.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "key" property provided is not a string or is empty but a key is required for all filters.`,
            `config`
          );
        }
        if (!targetFilter.options || targetFilter.options.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "options" property provided is not an array or is empty but options are required for all filters.`,
            `config`
          );
        }
        if (!targetFilter.selected || targetFilter.selected.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "selected" property must be provided for each filter.`,
            `config`
          );
        }
        let foundSelected = false;
        for (let j = 0; j < targetFilter.options.length; j++) {
          const option = targetFilter.options[j];
          if (option.value === targetFilter.selected) {
            foundSelected = true;
            break;
          }
        }
        if (!foundSelected) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "selected" property provided is not in the list of options but must match one of their values.`,
            `config`
          );
        }

        activeFilters[targetFilter.key] = targetFilter.selected;
      }
    }

    // title
    let title: ITextRich | undefined = undefined;
    if (configuration.title) {
      title = cloneDeep(configuration.title);
    }

    // headers
    let headers: ILeaderboardCell[] = [];
    if (configuration.headers) {
      if (configuration.headers.length === 0) {
        throw createDigitaServiceError(
          `Leaderboard`,
          `applyInitialize`,
          `No headers were provided but at least one header is required.`,
          `config`
        );
      }
      headers = cloneDeep(configuration.headers);
    } else {
      throw createDigitaServiceError(`Leaderboard`, `applyInitialize`, `No headers were provided but are required.`, `config`);
    }

    // schedule
    let schedule: IPollingScheduleData;
    if (configuration.schedule) {
      schedule = cloneDeep(configuration.schedule);
      if (!schedule.interval) {
        schedule.interval = 0;
      }
      if (!schedule.onDataAPI) {
        throw createDigitaServiceError(
          `Leaderboard`,
          `applyInitialize`,
          `The "schedule.onDataAPI" property was not provided but is required.`,
          `config`
        );
      }
    }

    // belongs to user color
    let belongsToUserColor = defaultBelongsToUserColor;
    if (configuration.belongsToUserColor) {
      belongsToUserColor = cloneDeep(configuration.belongsToUserColor);
    }

    // change highlight color
    let changeHighlightColor = defaultChangeHighlightColor;
    if (configuration.changeHighlightColor) {
      changeHighlightColor = cloneDeep(configuration.changeHighlightColor);
    }

    // update the state
    this.update((draft) => {
      draft.filters = filters;
      draft.activeFilters = activeFilters;
      draft.title = title;
      draft.headers = headers;
      draft.schedule = schedule;
      draft.belongsToUserColor = belongsToUserColor;
      draft.changeHighlightColor = changeHighlightColor;
    });
  }

  /**
   * Is the Leaderboard currently animating?
   *
   * @param isAnimating - true if animating, false otherwise.
   */
  applyIsAnimating(isAnimating: boolean) {
    this.update((draft) => {
      draft.isAnimating = isAnimating;
    });
  }

  /**
   * Is the Leaderboard in Debug Mode?
   *
   * @param isDebug - true if debug mode is enabled, false otherwise.
   */
  applyIsDebug(isDebug: boolean) {
    this.update((draft) => {
      draft.isDebug = isDebug;
    });
  }

  /**
   * Applies a the latest selected filter.
   *
   * @param filters - the filters to apply
   */
  applyUpdateFilters(filters?: Record<string, string>) {
    const { schedule } = this.getValue();

    const newSchedule = cloneDeep(schedule);
    newSchedule.interval = 0;

    this.update((draft) => {
      draft.activeFilters = filters;
      draft.schedule = newSchedule;
    });
  }

  /**
   * Updates the leaderboard from the polling system.
   *
   * @param response - the information returned from the polling request.
   */
  applyLeaderboardUpdateData(response: ILeaderboardDataResponse) {
    // if there is no response then do nothing
    const { entries, title } = this.getValue();

    if (!response) {
      return;
    }

    // process the schedule
    let newSchedule: IPollingScheduleData | undefined = undefined;
    if (response.schedule) {
      if (typeof response.schedule.interval === 'number' && typeof response.schedule.onDataAPI === 'string') {
        newSchedule = cloneDeep(response.schedule);
      }
    }

    // process the entries but only if not animating
    let newEntries = cloneDeep(entries);
    if (response.data) {
      newEntries = cloneDeep(response.data);
    }

    // process the title
    let newTitle = cloneDeep(title);
    if (response.title) {
      newTitle = cloneDeep(response.title);
    }

    // update the store
    this.update((draft) => {
      draft.entries = newEntries;
      draft.schedule = newSchedule;
      draft.title = newTitle;
      draft.isLoading = false;
    });
  }
}
