import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { EMPTY, map, of, switchMap } from 'rxjs';
import { CountdownModel } from './countdown.model';
import { CountdownStore } from './countdown.store';

/**
 * Qureries the State of an instance of {@link CountdownStore}.
 */
@Injectable()
export class CountdownQuery extends Query<CountdownModel> {
  /**
   * Is the countdown configured. This means that a JSON configuration has been provided and processed without error.
   */
  configured$ = this.select((state) => state.configured);

  // the data containing the countdown information
  private _data$ = this.select((state) => state.data);

  /**
   * The days section.
   */
  days$ = this._data$.pipe(map((data) => data.days));

  /**
   * The hours section
   */
  hours$ = this._data$.pipe(map((data) => data.hours));

  /**
   * The minutes section
   */
  minutes$ = this._data$.pipe(map((data) => data.minutes));

  /**
   * The seconds section
   */
  seconds$ = this._data$.pipe(map((data) => data.seconds));

  /**
   * If the remaining seconds are more than zero then the timer is active
   * otherwise the timer is not active.
   */
  timerComplete$ = this._data$.pipe(
    switchMap((data) => {
      return of(data.remainingSeconds === 0);
    })
  );

  // the API end point for when the timer completes
  private _onCompleteAPI$ = this.select((state) => state.onCompleteAPI);

  /**
   * onTimerComplete API end point.
   *
   * Not this is optional and may not occur if the API is not configured.
   */
  onCompleteRequest$ = combineQueries([this._onCompleteAPI$, this.timerComplete$]).pipe(
    switchMap(([api, complete]) => {
      if (api && complete) {
        return of(api);
      } else {
        return EMPTY;
      }
    })
  );

  /**
   * Has the system recieved a complete response from the server?
   */
  private _onCompleteResponse$ = this.select((state) => state.onCompleteResponse);

  /**
   * What is the destination delay before navigating to the destination?
   */
  private _destinationDelay$ = this.select((state) => state.destinationDelay);

  /**
   * What is the final destination once the timer has completed?
   */
  private _destination$ = this.select((state) => state.destination);

  /**
   * The destination delay and destination provided after a successful API call.
   */
  onCountdownCompleteResponse$ = combineQueries([this._onCompleteResponse$, this._destinationDelay$, this._destination$]).pipe(
    switchMap(([response, delay, destination]) => {
      if (response === true) {
        return of({ delay, destination });
      } else {
        return EMPTY;
      }
    })
  );

  /**
   * Constructor
   */
  constructor(protected override readonly store: CountdownStore) {
    super(store);
  }
}
