import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IMediaLock } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { MediaLockModel } from './media-lock.model';

/**
 * Creates the initial state of an media store.
 */
function createInitialState(): MediaLockModel {
  return {
    configured: false,
    error: false,
    unlocked: false,
    lock: undefined,
    button: undefined,
  };
}

/**
 * The Store used for a {@link MediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockStore extends Store<MediaLockModel> {
  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService) {
    super(createInitialState(), { name: `media-lock-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMediaLock) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    // if there is no lock then that is an error
    const lock = configuration.lock;
    if (!lock) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No "lock" property provided but this is required.`, `config`);
    }

    // if there is no button then that is an error
    const button = configuration.button;
    if (!button) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No "button" property provided but this is required.`, `config`);
    }

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.button = button;
      draft.lock = lock;
    });
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.loggerService.error(`Media Error - Unlocked`);
    this.update((draft) => {
      draft.unlocked = true;
      draft.error = true;
    });
  }

  /**
   * Occurs when the Media Lock is unlocked.
   */
  applyUnlock() {
    this.update((draft) => {
      draft.unlocked = true;
    });
  }
}
