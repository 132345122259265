import { LoggerService } from '@angular-ru/cdk/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { createDigitaServiceError } from 'src/app/app-error';
import { MediaNativeAudioModel } from './media-native-audio/media-native-audio.model';
import { MediaNativeVideoModel } from './media-native-video/media-native-video.model';
import { MediaVimeoModel } from './media-vimeo/media-vimeo.model';
import { MediaYouTubeModel } from './media-youtube/media-youtube.model';
import { MediaModel } from './media.model';
import { MediaTypes } from './media.types';

/**
 * Creates the initial state of an media store.
 */
function createInitialState(): MediaModel {
  return {
    width: undefined,
    height: undefined,
    autoplay: false,
    controls: true,
    keyboard: false,
    loop: false,
    playsinline: false,
    poster: undefined,
    muted: false,
    pip: false,
    interaction: true,
    youtube: undefined,
    vimeo: undefined,
    video: undefined,
    audio: undefined,
    configured: false,
    initialized: false,
    intersecting: false,
    hasIntersectedPreviously: false,
    errorIcon: {
      name: 'far:triangle-exclamation',
      color: 'grey',
      size: '2x',
    },
    error: false,
    errorReason: undefined,
    detailsObtained: false,
    explicitPoster: false,
    explicitDimensions: false,
    type: MediaTypes.video,
    ready: false,
    playing: false,
    paused: false,
    ended: false,
    currentTime: 0,
    duration: 0,
    mediaID: 'media',
    hasMediaPlayed: false,
    displayUIOverlay: false,
    preventPauseOnViewportExit: false,
  };
}

/**
 * The Store used for an {@link MediaComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaStore extends Store<MediaModel> {
  /**
   * Constructor
   */
  constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly loggerService: LoggerService) {
    super(createInitialState(), { name: `media-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMedia) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`MediaStore`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // one of the following must be provided
    let mediaTypeKnown = false;

    // process the configuration depending on the type
    if (configuration.youtube) {
      this.applyInitializeYouTube(configuration);
      mediaTypeKnown = true;
    } else if (configuration.vimeo) {
      this.applyInitializeVimeo(configuration);
      mediaTypeKnown = true;
    } else if (configuration.video) {
      this.applyInitializeNativeVideo(configuration);
      mediaTypeKnown = true;
    } else if (configuration.audio) {
      this.applyInitializeNativeAudio(configuration);
      mediaTypeKnown = true;
    }

    // if the media type is not known then throw an error
    if (!mediaTypeKnown) {
      throw createDigitaServiceError(
        `MediaStore`,
        `applyInitialize`,
        `No "youtube", "vimeo", "video" or "audio" media type provided but one of these is required.`,
        `config`
      );
    }
  }

  /**
   * Initialize from a YouTube Configuration.
   *
   * @param configuration - the youtube based configuration
   */
  private applyInitializeYouTube(configuration: IMedia) {
    // if no youtube configuration then throw an error.
    if (!configuration?.youtube) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeYouTube',
        'No youtube configuration provided but this is required.',
        'config'
      );
    }

    // if no video id then throw an error.
    if (!configuration.youtube.videoId) {
      this.applyError('No YouTube "videoId" provided but this is required.');
      return;
    }

    // if the configuration comes with an explcit width and height then use that.
    // this allows the configuration to override the OEmbed Request width and height
    // which is useful for portrait videos.
    let explicitDimensions = false;
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (dimensionsAreValid) {
      explicitDimensions = true;
      width = configuration.width;
      height = configuration.height;
    }

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // clone the youtube configuration
    const clonedProviderConfig: MediaYouTubeModel = cloneDeep(configuration.youtube);

    // if there is no playerVars then create an empty object
    if (!clonedProviderConfig.playerVars) {
      clonedProviderConfig.playerVars = {};
    }

    // the width and height must be 100% for the YouTube Player
    clonedProviderConfig.width = '100%';
    clonedProviderConfig.height = '100%';

    // if the configuration for autoplay has been set, then display a warning
    if (clonedProviderConfig.playerVars.autoplay === 1 || clonedProviderConfig.playerVars.autoplay === 0) {
      this.warnTopLevelProperty('youtube.playerVars.autoplay', 'autoplay');
    }
    // set the youtube property using the top level provider
    let autoplay = false;
    if (configuration.autoplay === true) {
      clonedProviderConfig.playerVars.autoplay = 1;
      autoplay = true;
    } else {
      clonedProviderConfig.playerVars.autoplay = 0;
      autoplay = false;
    }

    // if the configuration for controls has been set, then display a warning
    if (clonedProviderConfig.playerVars.controls === 1 || clonedProviderConfig.playerVars.controls === 0) {
      this.warnTopLevelProperty('youtube.playerVars.controls', 'controls');
    }
    // set the youtube property using the top level provider
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      clonedProviderConfig.playerVars.controls = 0;
      controls = false;
    } else {
      clonedProviderConfig.playerVars.controls = 1;
      controls = true;
    }

    // if the configuration for disablekb has been set, then display a warning
    if (clonedProviderConfig.playerVars.disablekb === 1 || clonedProviderConfig.playerVars.disablekb === 0) {
      this.warnTopLevelProperty('youtube.playerVars.disablekb', 'keyboard');
    }
    // set the youtube property using the top level provider
    let keyboard = false;
    if (configuration.keyboard === true || interaction === false) {
      clonedProviderConfig.playerVars.disablekb = 0;
      keyboard = true;
    } else {
      clonedProviderConfig.playerVars.disablekb = 1;
      keyboard = false;
    }

    // force set the enablejsapi
    clonedProviderConfig.playerVars.enablejsapi = 1;

    // if the configuration for list is provided
    if (clonedProviderConfig.playerVars.list) {
      clonedProviderConfig.playerVars.list = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.list');
    }

    // if the configuration for listType is provided
    if (clonedProviderConfig.playerVars.listType) {
      clonedProviderConfig.playerVars.listType = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.listType');
    }

    // if the configuration for playlist is provided
    if (clonedProviderConfig.playerVars.playlist) {
      clonedProviderConfig.playerVars.playlist = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.playlist');
    }

    // if the configuration for loop is provided
    if (clonedProviderConfig.playerVars.loop === 1 || clonedProviderConfig.playerVars.loop === 0) {
      this.warnTopLevelProperty('youtube.playerVars.loop', 'loop');
    }

    // set the youtube property using the top level provider
    let loop = false;
    if (configuration.loop === true) {
      clonedProviderConfig.playerVars.loop = 1;
      clonedProviderConfig.playerVars.playlist = configuration.youtube.videoId;
      loop = true;
    } else {
      clonedProviderConfig.playerVars.loop = 0;
      loop = false;
    }

    // force set the origin
    clonedProviderConfig.playerVars.origin = this.document?.defaultView?.location?.host;

    // if the configuration for playsinline is provided
    if (clonedProviderConfig.playerVars.playsinline === 1 || clonedProviderConfig.playerVars.playsinline === 0) {
      this.warnTopLevelProperty('youtube.playerVars.playsinline', 'playsinline');
    }
    // set the youtube property using the top level provider
    let playsinline = false;
    if (configuration.playsinline === true) {
      clonedProviderConfig.playerVars.playsinline = 1;
      playsinline = true;
    } else {
      clonedProviderConfig.playerVars.playsinline = 0;
      playsinline = false;
    }

    // pip
    const pip = false;

    // if the configuration for mute is provided
    if (clonedProviderConfig.playerVars.mute === 1 || clonedProviderConfig.playerVars.mute === 0) {
      this.warnTopLevelProperty('youtube.playerVars.mute', 'muted');
    }
    // set the youtube property using the top level provider
    let muted = false;
    if (configuration.muted === true) {
      clonedProviderConfig.playerVars.mute = 1;
      muted = true;
    } else {
      clonedProviderConfig.playerVars.mute = 0;
      muted = false;
    }

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.update((draft) => {
      if (explicitDimensions) {
        draft.explicitDimensions = explicitDimensions;
        draft.width = width;
        draft.height = height;
      }
      if (explicitPoster) {
        draft.explicitPoster = explicitPoster;
        draft.poster = poster;
      }
      draft.autoplay = autoplay;
      draft.controls = controls;
      draft.keyboard = keyboard;
      draft.loop = loop;
      draft.playsinline = playsinline;
      draft.muted = muted;
      draft.interaction = interaction;
      draft.pip = pip;
      draft.type = MediaTypes.youtube;
      draft.configured = true;
      draft.youtube = clonedProviderConfig;
      draft.mediaID = this.storeName;
      draft.preventPauseOnViewportExit = preventPauseOnViewportExit;
      draft.displayUIOverlay = false; // youtube has its own
    });
  }

  /**
   * Initialize from a Vimeo Configuration.
   *
   * @param configuration - the vimeo based configuration
   */
  private applyInitializeVimeo(configuration: IMedia) {
    // if no vimeo configuration then throw an error.
    if (!configuration.vimeo) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeVimeo',
        'No Vimeo configuration provided but this is required.',
        'config'
      );
    }

    // if no video id OR url then throw an error.
    if (!configuration.vimeo.id && !configuration.vimeo.url) {
      this.applyError('No vimeo "id" or "url" provided but this is required.');
      return;
    }

    // if the configuration comes with an explcit width and height then use that.
    // this allows the configuration to override the OEmbed Request width and height
    // which is useful for portrait videos.
    let explicitDimensions = false;
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (dimensionsAreValid) {
      explicitDimensions = true;
      width = configuration.width;
      height = configuration.height;
    }

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // inject required parameters
    const clonedProviderConfig: MediaVimeoModel = cloneDeep(configuration.vimeo);

    // if the configuration for autoplay is provided
    if (clonedProviderConfig.autoplay === true || clonedProviderConfig.autoplay === false) {
      this.warnTopLevelProperty('vimeo.autoplay', 'autoplay');
    }
    let autoplay = false;
    if (configuration.autoplay === true) {
      clonedProviderConfig.autoplay = true;
      autoplay = true;
    } else {
      clonedProviderConfig.autoplay = false;
      autoplay = false;
    }

    // if the configuration for autopause is provided
    if (clonedProviderConfig.autopause === true || clonedProviderConfig.autopause === false) {
      clonedProviderConfig.autopause = undefined;
      this.warnDoNotUseProperty('vimeo.autopause');
    }

    // if the configuration for background is provided
    if (clonedProviderConfig.background === true || clonedProviderConfig.background === false) {
      clonedProviderConfig.background = undefined;
      this.warnDoNotUseProperty('vimeo.background');
    }

    // if the configuration for controls is provided
    if (clonedProviderConfig.controls === true || clonedProviderConfig.controls === false) {
      this.warnTopLevelProperty('vimeo.controls', 'controls');
    }
    // set the vimeo property using the top level provider
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      clonedProviderConfig.controls = false;
      controls = false;
    } else {
      clonedProviderConfig.controls = true;
      controls = true;
    }

    // if the width is provided
    if (clonedProviderConfig.width) {
      clonedProviderConfig.width = undefined;
      this.warnDoNotUseProperty('vimeo.width');
    }

    // if the height is provided
    if (clonedProviderConfig.height) {
      clonedProviderConfig.height = undefined;
      this.warnDoNotUseProperty('vimeo.height');
    }

    // if the configuration for keyboard is provided
    if (clonedProviderConfig.keyboard === true || clonedProviderConfig.keyboard === false) {
      this.warnTopLevelProperty('vimeo.keyboard', 'keyboard');
    }
    // set the vimeo property using the top level provider
    let keyboard = false;
    if (configuration.keyboard === true && interaction === true) {
      clonedProviderConfig.keyboard = true;
      keyboard = true;
    } else {
      clonedProviderConfig.keyboard = false;
      keyboard = false;
    }

    // if loop has been provided
    if (clonedProviderConfig.loop === true || clonedProviderConfig.loop === false) {
      this.warnTopLevelProperty('vimeo.loop', 'loop');
    }
    // set the vimeo property using the top level provider
    let loop = false;
    if (configuration.loop === true) {
      clonedProviderConfig.loop = true;
      loop = true;
    } else {
      clonedProviderConfig.loop = false;
      loop = false;
    }

    // if max width has been provided
    if (clonedProviderConfig.maxwidth) {
      clonedProviderConfig.maxwidth = undefined;
      this.warnDoNotUseProperty('vimeo.maxwidth');
    }

    // if max height has been provided
    if (clonedProviderConfig.maxheight) {
      clonedProviderConfig.maxheight = undefined;
      this.warnDoNotUseProperty('vimeo.maxheight');
    }

    // if the configuration for playsinline is provided
    if (clonedProviderConfig.playsinline === true || clonedProviderConfig.playsinline === false) {
      this.warnTopLevelProperty('vimeo.playsinline', 'playsinline');
    }
    // set the vimeo property using the top level provider
    let playsinline = false;
    if (configuration.playsinline === true) {
      clonedProviderConfig.playsinline = true;
      playsinline = true;
    } else {
      clonedProviderConfig.playsinline = false;
      playsinline = false;
    }

    // the responsive property is hardcoded
    clonedProviderConfig.responsive = true;

    // muted
    if (clonedProviderConfig.muted === true || clonedProviderConfig.muted === false) {
      this.warnTopLevelProperty('vimeo.muted', 'muted');
    }
    let muted = false;
    if (configuration.muted === true) {
      clonedProviderConfig.muted = true;
      muted = true;
    } else {
      clonedProviderConfig.muted = false;
      muted = false;
    }

    // pip
    if (clonedProviderConfig.pip === true || clonedProviderConfig.pip === false) {
      this.warnTopLevelProperty('vimeo.pip', 'pip');
    }
    let pip = false;
    if (configuration.pip === true) {
      clonedProviderConfig.pip = true;
      pip = true;
    } else {
      clonedProviderConfig.pip = false;
      pip = false;
    }

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.update((draft) => {
      if (explicitDimensions) {
        draft.explicitDimensions = explicitDimensions;
        draft.width = width;
        draft.height = height;
      }
      if (explicitPoster) {
        draft.explicitPoster = explicitPoster;
        draft.poster = poster;
      }
      draft.autoplay = autoplay;
      draft.controls = controls;
      draft.keyboard = keyboard;
      draft.loop = loop;
      draft.playsinline = playsinline;
      draft.muted = muted;
      draft.pip = pip;
      draft.interaction = interaction;
      draft.type = MediaTypes.vimeo;
      draft.configured = true;
      draft.vimeo = clonedProviderConfig;
      draft.mediaID = this.storeName;
      draft.preventPauseOnViewportExit = preventPauseOnViewportExit;
      draft.displayUIOverlay = true;
    });
  }

  /**
   * Initialize from a Video Configuration.
   *
   * @param configuration - the Video based configuration
   */
  private applyInitializeNativeVideo(configuration: IMedia) {
    // if no video configuration then throw an error.
    if (!configuration.video) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeVideo',
        'No video configuration provided but this is required.',
        'config'
      );
    }

    // if no sources then throw an error.
    if (!configuration.video.sources || configuration.video.sources.length === 0) {
      this.applyError('No media "sources" provided but this is required.');
      return;
    }

    // if no width and height then that is an error
    const explicitDimensions = true;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (!dimensionsAreValid) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeVideo',
        'No media "width" and "height" provided but this is required.',
        'config'
      );
    }
    const width = configuration.width;
    const height = configuration.height;

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    let preload = false;
    if (configuration.video.preload === true) {
      preload = true;
    }

    const sources = configuration.video.sources;

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // autoplay
    let autoplay = false;
    if (configuration.autoplay === true) {
      autoplay = true;
    }

    // controls
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      controls = false;
    }

    // keyboard
    const keyboard = false;

    // loop
    let loop = false;
    if (configuration.loop === true) {
      loop = true;
    }

    // plays inline
    let playsinline = false;
    if (configuration.playsinline === true) {
      playsinline = true;
    }

    // muted
    let muted = false;
    if (configuration.muted === true) {
      muted = true;
    }

    // pip
    let pip = true;
    if (configuration.pip === false) {
      pip = false;
    }

    // clone the video configuration
    const clonedProviderConfig: MediaNativeVideoModel = {
      preload,
      sources,
      autoplay,
      controls,
      loop,
      playsinline,
      muted,
      pip,
      poster,
    };

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.update((draft) => {
      if (explicitDimensions) {
        draft.explicitDimensions = explicitDimensions;
        draft.width = width;
        draft.height = height;
      }
      if (explicitPoster) {
        draft.explicitPoster = explicitPoster;
        draft.poster = poster;
      }
      draft.autoplay = autoplay;
      draft.controls = controls;
      draft.keyboard = keyboard;
      draft.loop = loop;
      draft.playsinline = playsinline;
      draft.muted = muted;
      draft.pip = pip;
      draft.interaction = interaction;
      draft.type = MediaTypes.video;
      draft.configured = true;
      draft.video = clonedProviderConfig;
      draft.mediaID = this.storeName;
      draft.preventPauseOnViewportExit = preventPauseOnViewportExit;
      draft.displayUIOverlay = true;
    });
  }

  /**
   * Initialize from a Audio Configuration.
   *
   * @param configuration - the Audio based configuration
   */
  private applyInitializeNativeAudio(configuration: IMedia) {
    // if no audio configuration then throw an error.
    if (!configuration.audio) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeAudio',
        'No Audio configuration provided but this is required.',
        'config'
      );
    }

    // if no sources then throw an error.
    if (!configuration.audio.sources || configuration.audio.sources.length === 0) {
      this.applyError('No media "sources" provided but this is required.');
      return;
    }

    // if no width and height then that is an error
    const explicitDimensions = true;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (!dimensionsAreValid) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeAudio',
        'No media "width" and "height" provided but this is required.',
        'config'
      );
    }
    const width = configuration.width;
    const height = configuration.height;

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    let preload = false;
    if (configuration.audio.preload === true) {
      preload = true;
    }

    let audioposter: undefined | string = undefined;
    if (configuration.audio.audioposter) {
      audioposter = configuration.audio.audioposter;
    }

    const sources = configuration.audio.sources;

    // autoplay
    let autoplay = false;
    if (configuration.autoplay === true) {
      autoplay = true;
    }

    // controls
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      controls = false;
    }

    // keyboard
    const keyboard = false;

    // loop
    let loop = false;
    if (configuration.loop === true) {
      loop = true;
    }

    // plays inline is not supported on audio
    const playsinline = false;

    // muted
    let muted = false;
    if (configuration.muted === true) {
      muted = true;
    }

    // pip is not supported on audio
    const pip = false;

    // clone the audio configuration
    const clonedProviderConfig: MediaNativeAudioModel = {
      preload,
      audioposter,
      sources,
      autoplay,
      controls,
      loop,
      muted,
    };

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.update((draft) => {
      if (explicitDimensions) {
        draft.explicitDimensions = explicitDimensions;
        draft.width = width;
        draft.height = height;
      }
      if (explicitPoster) {
        draft.explicitPoster = explicitPoster;
        draft.poster = poster;
      }
      draft.autoplay = autoplay;
      draft.controls = controls;
      draft.keyboard = keyboard;
      draft.loop = loop;
      draft.playsinline = playsinline;
      draft.muted = muted;
      draft.pip = pip;
      draft.interaction = interaction;
      draft.type = MediaTypes.audio;
      draft.configured = true;
      draft.audio = clonedProviderConfig;
      draft.mediaID = this.storeName;
      draft.preventPauseOnViewportExit = preventPauseOnViewportExit;
      draft.displayUIOverlay = true;
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The total duration of the video in seconds
   *
   * @param duration - the duration of the video in seconds
   */
  applyDuration(duration: number) {
    this.update((draft) => {
      draft.duration = duration;
    });
  }

  /**
   * The current time of the video in seconds.
   *
   * @param currentTime - the current time of the video in seconds
   */
  applyCurrentTime(currentTime: number) {
    this.update((draft) => {
      draft.currentTime = currentTime;
    });
  }

  /**
   * The video is ready to play.
   */
  applyReady() {
    this.update((draft) => {
      draft.ready = true;
    });
  }

  /**
   * The video is Playing.
   */
  applyPlaying() {
    this.update((draft) => {
      draft.playing = true;
      draft.paused = false;
      draft.ended = false;
      draft.hasMediaPlayed = true;
    });
  }

  /**
   * The video is Paused.
   */
  applyPaused() {
    this.update((draft) => {
      draft.playing = false;
      draft.paused = true;
      draft.ended = false;
    });
  }

  /**
   * The Video is Ended.
   */
  applyEnded() {
    const { loop } = this.getValue();
    if (!loop) {
      this.update((draft) => {
        draft.ended = true;
      });
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // INITIALIZED
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * When the system has been configured, and loaded the required providers and video information (where applicable)
   * then the system is considered initialized and is ready to create the player.
   */
  applyInitialized() {
    const { intersecting } = this.getValue();
    this.update((draft) => {
      draft.initialized = true;
      if (intersecting) {
        draft.hasIntersectedPreviously = true;
      }
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // VIDEO DETAILS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Once the video details have been obtained from the YouTube OEmbed Service, it will contain
   * the width and height of the video and the poster.
   *
   * If width, height or poster have been explicitly set, then the values from the OEmbed
   * service will be ignored.
   *
   * @param details - the details of the video
   */
  applyYouTubeVideoDetails(details: YT.OEmbed) {
    const { explicitDimensions, explicitPoster } = this.getValue();
    this.update((draft) => {
      draft.detailsObtained = true;
      if (!explicitPoster) {
        draft.poster = details.thumbnail_url;
      }
      if (!explicitDimensions) {
        draft.width = details.width;
        draft.height = details.height;
      }
    });
  }

  /**
   * Once the video details have been obtained from the Vimeo OEmbed Service, it will contain
   * the width and height of the video and the poster.
   *
   * If width, height or poster have been explicitly set, then the values from the OEmbed
   * service will be ignored.
   *
   * @param details - the details of the video
   */
  applyVimeoVideoDetails(details: Vimeo.Ombed) {
    const { explicitDimensions, explicitPoster } = this.getValue();
    this.update((draft) => {
      draft.detailsObtained = true;
      if (!explicitPoster) {
        draft.poster = details.thumbnail_url;
      }
      if (!explicitDimensions) {
        draft.width = details.width;
        draft.height = details.height;
      }
    });
  }

  /**
   * All Details for a Media Native Video Component have been obtained already.
   */
  applyNativeVideoDetails() {
    this.update((draft) => {
      draft.detailsObtained = true;
    });
  }

  /**
   * All Details for a Media Native Audio Component have been obtained already.
   */
  applyNativeAudioDetails() {
    this.update((draft) => {
      draft.detailsObtained = true;
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // ERROR
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The system has encountered an error.
   *
   * This can be because:
   *
   *   - the video source failed to load (e.g. youtube video id is invalid)
   *
   * @param reason - the reason for the error
   */
  applyError(reason?: string) {
    if (reason) {
      this.loggerService.error(`MediaComponent - ${reason}`);
    }

    const { width, height } = this.getValue();

    this.update((draft) => {
      draft.initialized = false;
      draft.error = true;
      draft.errorReason = 'Error';
      draft.width = width || 640;
      draft.height = height || 360;
    });
  }

  /**
   * Is the component intersecting the viewport?
   *
   * @param isVisible - is the component intersecting?
   */
  applyIsIntersecting(isVisible: boolean) {
    const { initialized } = this.getValue();

    this.update((draft) => {
      draft.intersecting = isVisible;
      if (isVisible && initialized) {
        draft.hasIntersectedPreviously = true;
      }
    });
  }

  /**
   * Utility
   */
  private warnTopLevelProperty(propertyName: string, topLevelPropertyName: string) {
    this.loggerService.warn(
      `MediaComponent - The ${propertyName} property should not be used. Instead use the top level ${topLevelPropertyName} property of the Media API. Your setting has been ignored.`
    );
  }

  /**
   * Utility
   */
  private warnDoNotUseProperty(propertyName: string) {
    this.loggerService.warn(
      `MediaComponent - The ${propertyName} property should not be used, it is either not supported or managed by the Media API. Your setting has been ignored.`
    );
  }
}
