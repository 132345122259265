import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxTimer } from 'src/app/api/modules/core/dynamic/databoxes/timer/IDataboxTimer';
import { DataboxTimerQuery } from './databox-timer.query';
import { DataboxTimerStore } from './databox-timer.store';

@Component({
  selector: 'app-databox-timer',
  templateUrl: './databox-timer.component.html',
  styleUrls: ['./databox-timer.component.scss'],
  providers: [DataboxTimerStore, DataboxTimerQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxTimerComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxTimer) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxTimer;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxTimerStore, public readonly query: DataboxTimerQuery) {}

  /**
   * Splice the data into the template
   *
   * @param seconds - the gametime in seconds which will be formatted to the template
   */
  update(seconds?: number) {
    this.store.applyUpdate(seconds);
  }

  /**
   * Reset the timer to defaults.
   */
  reset() {
    this.store.applyReset();
  }
}
