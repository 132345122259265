import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DataboxAttemptsIconModel } from './databox-attempts-icon.model';
import { DataboxAttemptsIconStore } from './databox-attempts-icon.store';

/**
 * Qureries the State of an instance of {@link DataboxAttemptsIconStore}.
 */
@Injectable()
export class DataboxAttemptsIconQuery extends Query<DataboxAttemptsIconModel> {
  /**
   * The Final Output State.
   */
  states$ = this.select((state) => state.states);

  /**
   * Constructor
   */
  constructor(protected override readonly store: DataboxAttemptsIconStore) {
    super(store);
  }
}
