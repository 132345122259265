import { Component, Input } from '@angular/core';
import { IFooterBranding } from 'src/app/api/modules/core/components/static/IFooterBranding';

@Component({
  selector: 'app-footer-branding',
  templateUrl: './footer-branding.component.html',
  styleUrls: ['./footer-branding.component.scss']
})
/**
 * The Footer Branding Component is used within a Footer.
 * It usually says "DigitaService by Drimlike" although the content is open to change
 */
export class FooterBrandingComponent {
  /**
   * The Configuration required for this component to function
   */
  @Input()
  config: IFooterBranding;
}
