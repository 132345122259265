import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { filter, interval, of, Subscription, switchMap, takeUntil, timer } from 'rxjs';
import { ICountdown } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { CountdownQuery } from './countdown.query';
import { CountdownService } from './countdown.service';
import { CountdownStore } from './countdown.store';

/**
 * The Countdown Component is used to display a stylized countdown timer.
 *
 * See {@link ICountdown}.
 */
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  providers: [CountdownService, CountdownStore, CountdownQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnDestroy, AfterViewInit {
  /**
   * The configuration powering this component.
   */
  private _config?: ICountdown;
  @Input() set config(configuration: ICountdown) {
    this._config = configuration;
    this.countdownService.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * The Subscription is used for all Observable Paths and stored for Teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Constructor
   */
  constructor(private readonly countdownService: CountdownService, public readonly countdownQuery: CountdownQuery) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // create the ticker which can only proceed if the countdown is configured
    const ticker = this.countdownQuery.configured$
      .pipe(
        // only proceed if the countdown is configured
        filter((configured) => configured),
        // create a timer that ticks every second
        switchMap(() => {
          return interval(1000).pipe(
            // the timer should repeat until the countdown is complete
            takeUntil(
              this.countdownQuery.timerComplete$.pipe(
                // the countdown must complete
                filter((complete) => complete)
              )
            )
          );
        })
      )
      .subscribe(() => {
        this.countdownService.applyTick();
      });
    this.subscriptions.add(ticker);

    // when the timer is complete
    const timerComplete = this.countdownQuery.timerComplete$
      .pipe(
        filter((complete) => complete),
        switchMap(() => {
          return this.countdownQuery.onCompleteRequest$;
        }),
        switchMap((onCompleteAPI) => {
          return this.countdownService.requestCountdownComplete(onCompleteAPI);
        })
      )
      .subscribe((response) => {
        // this is no longer required
        this.subscriptions.remove(timerComplete);

        // apply the response to the store
        this.countdownService.applyCountdownCompleteResponse(response);
      });
    this.subscriptions.add(timerComplete);

    // when/if a response has been received
    const countdownCompleteResponse = this.countdownQuery.onCountdownCompleteResponse$
      .pipe(
        switchMap((response) => {
          return timer(response.delay).pipe(
            switchMap(() => {
              return of(response.destination);
            })
          );
        })
      )
      .subscribe((destination) => {
        this.subscriptions.remove(countdownCompleteResponse);

        this.countdownService.navigateToDestination(destination);
      });
    this.subscriptions.add(countdownCompleteResponse);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    // kill the subscription
    this.subscriptions?.unsubscribe();
  }
}
