import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IIconState } from 'src/app/api/modules/icons/IIconState';
import { IconStateQuery } from './icon-state.query';
import { IconStateStore } from './icon-state.store';

@Component({
  selector: 'app-icon-state',
  templateUrl: './icon-state.component.html',
  styleUrls: ['./icon-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IconStateStore, IconStateQuery],
})
/**
 * IconState shows a single Icon from a list of icons as a state container.
 *
 * For example imagine you have an icon "lights-on" and "lights-off". These are
 * provided as states,  You can set what state is shown by the state properties of
 * the icon.
 *
 * ie: `iconState.setState('off') // will show the icon with the matching state "off".
 */
export class IconStateComponent {
  /**
   * The Config as provided to the element
   */
  @Input() set config(configuration: IIconState) {
    this.componentStore.initialize(configuration);
  }

  /**
   * The state to be in
   */
  @Input() set state(state: string) {
    this.componentStore.state(state);
  }

  /**
   * Constructor
   */
  constructor(private readonly componentStore: IconStateStore, public readonly componentQuery: IconStateQuery) {}
}
