import { Injectable } from '@angular/core';
import { Query, combineQueries } from '@datorama/akita';
import { filter, map } from 'rxjs';
import { DataboxContainerModel } from './databox-container.model';
import { DataboxContainerStore } from './databox-container.store';

/**
 * Query service for interacting with the DataboxContainerStore.
 * Provides observables to reactively manage and observe the state of the Databox container.
 * This includes the configuration status and the array of components within the container.
 */
@Injectable()
export class DataboxContainerQuery extends Query<DataboxContainerModel> {
  /**
   * Stream that emits when the screen is configured.
   * Filters the configured property of the state, emitting only when it is true.
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  ///////////////////////////////////////////////////////////////////////////
  // COMPONENTS
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Stream that emits the current array of components for the screen.
   * This observable allows subscribers to react to changes in the components array.
   */
  private _componentArray$ = this.select((state) => state.componentArray);

  ///////////////////////////////////////////////////////////////////////////
  // SCREEN CONFIGURATION
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Publicly exposed observable that combines the configured status and component array streams.
   * It emits the component array only when the screen is configured.
   * This is useful for consumers that need to react to changes in the component array
   * but only after the screen has been fully configured.
   */
  componentArray$ = combineQueries([this._configured$, this._componentArray$]).pipe(
    filter(([configured]) => configured),
    map(([_, componentArray]) => componentArray)
  );

  /**
   * Constructs a new DataboxContainerQuery instance.
   *
   * @param {DataboxContainerStore} store - The store that holds the state this query will manage.
   */
  constructor(protected override readonly store: DataboxContainerStore) {
    super(store);
  }
}
