import { GenerateTextRich } from '../generators/text-rich.generator';

/**
 * Plugins recieve a PostMessage format which must be converted into a TextRich API format.
 *
 * @param incoming - the simple incoming string
 */
export const ConvertPluginToTextRich = (incoming: string) => {
  if (incoming) {
    return GenerateTextRich({
      data: incoming,
    });
  }

  return null;
};
