<div class="container" *ngIf="mediaLockQuery.configured$ | async">
  <!-- The ng-container is not rendered but structural to hold the asyc pipe -->
  <ng-container *ngIf="mediaLockQuery.lock$ | async as lock">
    <app-media-lockable
      *ngIf="lock"
      class="lock"
      [config]="lock"
      (unlocked)="mediaLockUnlocked()"
      (error)="mediaLockError()"
    ></app-media-lockable>
  </ng-container>

  <div class="ui-container" fxLayout="row" fxLayoutAlign="center center">
    <!-- The ng-container is not rendered but structural to hold the asyc pipe -->
    <ng-container *ngIf="mediaLockQuery.button$ | async as button">
      <app-button *ngIf="button" class="ui-button" @mediaLockButtonAnimation [config]="button"></app-button>
    </ng-container>
  </div>
</div>
