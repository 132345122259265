import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScreenComponent } from './modules/core/dynamic/screens/screen/screen.component';
import { digitaServiceRouteResolver } from './modules/core/services/network/digitaservice-route-resolver';

/**
 * Top Level Routes
 */
const routes: Routes = [
  {
    path: 'content',
    children: [
      {
        path: 'dynamic',
        children: [
          {
            path: '**',
            component: ScreenComponent,
            resolve: {
              screen: digitaServiceRouteResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'plugin',
    children: [
      {
        path: '**',
        component: ScreenComponent,
        resolve: {
          screen: digitaServiceRouteResolver,
        },
      },
    ],
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: 'forms',
    children: [
      {
        path: 'dynamic',
        loadChildren: () => import('./modules/forms/dynamic-controls/dynamic-controls.module').then((m) => m.DynamicControlsModule),
      },
    ],
  },
  {
    path: 'ask',
    loadChildren: () => import('./modules/ask/ask.module').then((m) => m.AskModule),
  },
  {
    path: 'experiences',
    children: [
      {
        path: 'chatbot',
        loadChildren: () => import('./modules/experiences/chatbot/chatbot.module').then((m) => m.ChatbotModule),
      },
      {
        path: 'dynamic-path',
        loadChildren: () => import('./modules/experiences/dynamic-path/dynamic-path.module').then((m) => m.DynamicPathModule),
      },
      {
        path: 'media-contest',
        loadChildren: () => import('./modules/experiences/media-contest/media-contest.module').then((m) => m.MediaContestModule),
      },
    ],
  },
  {
    path: 'activities',
    children: [
      {
        path: 'team-picker',
        loadChildren: () => import('./modules/activities/team-picker/team-picker.module').then((m) => m.TeamPickerModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    // skip initial navigation when skipLocationChange is enabled.
    RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
