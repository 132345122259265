<div
  class="container"
  *ngIf="mediaLockableQuery.configured$ | async"
  [class.limit-portrait-width-on-desktop]="mediaLockableQuery.limitPortraitWidthOnDesktop$ | async"
>
  <ng-container *ngIf="mediaLockableQuery.media$ | async as media">
    <app-media
      *ngIf="media"
      class="media"
      [class.disable-pointer]="mediaLockableQuery.initialized$ | async"
      [config]="media"
      (ready)="onMediaReady()"
      (playing)="onMediaPlaying()"
      (currentTime)="onMediaCurrentTime($event)"
      (duration)="onMediaDuration($event)"
      (error)="onMediaError($event)"
      (isPortrait)="onMediaIsPortrait($event)"
    ></app-media>
  </ng-container>

  <!-- Display a Progress Bar representing how long the UI is locked for -->
  <mat-progress-bar
    *ngIf="mediaLockableQuery.shouldDisplayProgress$ | async"
    class="progress disable-pointer"
    [class.hide-progress]="(mediaLockableQuery.initialized$ | async) !== true"
    mode="determinate"
    [value]="mediaLockableQuery.progress$ | async"
  ></mat-progress-bar>
</div>
