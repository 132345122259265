import { Injectable } from '@angular/core';
import { Query, combineQueries } from '@datorama/akita';
import { filter, map } from 'rxjs';
import { IScreen } from 'src/app/api/modules/core/components/screen/IScreen';
import { ScreenModel } from './screen.model';
import { ScreenStore } from './screen.store';

@Injectable()
export class ScreenQuery<Configuration extends IScreen = IScreen> extends Query<ScreenModel<Configuration>> {
  /**
   * Is the screen configured?
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  /**
   * The screen data.
   */
  private _data$ = this.select((state) => state.data);

  ///////////////////////////////////////////////////////////////////////////
  // LOGGING
  ///////////////////////////////////////////////////////////////////////////

  /**
   * The unique identifier for this screen.
   */
  private _id$ = this.select((state) => state.id);

  ///////////////////////////////////////////////////////////////////////////
  // LAYOUT & APPERANCE
  ///////////////////////////////////////////////////////////////////////////

  /**
   * The screen data is provided when the screen is configured.
   */
  screenData$ = combineQueries([this._configured$, this._data$, this._id$]).pipe(
    map(([_, data, id]) => {
      return { ...data, id };
    })
  );

  /**
   * Constructor
   */
  constructor(protected override readonly store: ScreenStore<Configuration>) {
    super(store);
  }
}
