import { animate, group, query, style, transition, trigger } from '@angular/animations';

/**
 * Animates the Shell Subsystem.
 */
export const shellBackgroundAnimation = trigger('shellBackgroundAnimation', [
  transition('* <=> *', [
    group([
      query(':enter', [style({ opacity: 0 }), animate('500ms 0ms', style({ opacity: 1 }))], {
        optional: true,
      }),
      query(':leave', [style({ opacity: '*' }), animate('500ms 0ms', style({ opacity: 0 }))], {
        optional: true,
      }),
    ]),
  ]),
]);
