<div *ngIf="config" class="group" fxLayout="row" fxLayoutGap="4px">
  <ng-container *ngFor="let segment of config.next.asString; let i = index">
    <div class="segment">
      <div class="display">
        <div class="display-top">{{ config.next.asString[i] }}</div>
        <div class="display-bottom">{{ config.prev.asString[i] }}</div>
        <div class="overlay">
          <div class="overlay-top" @countdownFlipTopAnimation>{{ config.next.asString[i] }}</div>
          <div class="overlay-bottom" @countdownFlipBottomAnimation>{{ config.next.asString[i] }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="config?.label" class="label">{{ config.label }}</div>
