import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxAttemptsIcon } from 'src/app/api/modules/core/dynamic/databoxes/attempts-icon/IDataboxAttemptsIcon';
import { DataboxAttemptsIconQuery } from './databox-attempts-icon.query';
import { DataboxAttemptsIconStore } from './databox-attempts-icon.store';

@Component({
  selector: 'app-databox-attempts-icon',
  templateUrl: './databox-attempts-icon.component.html',
  styleUrls: ['./databox-attempts-icon.component.scss'],
  providers: [DataboxAttemptsIconStore, DataboxAttemptsIconQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxAttemptsIconComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxAttemptsIcon) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxAttemptsIcon;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxAttemptsIconStore, public readonly query: DataboxAttemptsIconQuery) {}

  /**
   * Updates the component
   *
   * @param stateList - an array of states to show
   */
  update(stateList: string[]) {
    this.store.applyUpdate(stateList);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.applyReset();
  }
}
