import { ISnackbar } from 'src/app/api/modules/core/components/snackbar/ISnackbar';

/**
 * Generates a JSON Object that can be used to construct a new Snackbar Component
 *
 * This is used primarily within Plugins to convert a IFrame Message into an Angular
 * Component.
 *
 * @param config - The Partial Configuration for a Snackbar
 */
export const GenerateSnackbar = (config: Partial<ISnackbar> = {}): ISnackbar => {
  const defaults: ISnackbar = {
    message: 'This is a sample snackbar',
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  };

  return {
    ...defaults,
    ...config,
  };
};
