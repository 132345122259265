import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DigitaServiceInputs } from 'src/app/app-global-callbacks';
import { AppConfigStore } from './app-config.store';

/**
 * The original config that was defined on the index or through URL parameters (the same object send to the initialize) is stored here.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigQuery extends Query<DigitaServiceInputs> {
  /**
   * Constructor
   */
  constructor(protected override readonly store: AppConfigStore) {
    super(store);
  }
}
