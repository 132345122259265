import { LoggerService } from '@angular-ru/cdk/logger';
import { Component, OnInit } from '@angular/core';
import { DigitaServiceError } from '@digitaservice/utils';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInitializeService } from './app-initialize.service';
import { ApplicationQuery } from './modules/core/services/application/application.query';
import { ApplicationService } from './modules/core/services/application/application.service';
import { FooterQuery } from './modules/core/services/footer/footer.query';
import { ThemeService } from './modules/core/services/theme.service';
import { IconService } from './modules/icons/services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Determine if we are in dev mode.
   */
  public showDevPanel = environment.showDevPanel;

  /**
   * App Entry Point
   */
  constructor(
    private readonly appService: ApplicationService,
    private readonly icon: IconService,
    private readonly initializeService: AppInitializeService,
    private readonly loggerService: LoggerService,
    private readonly themeService: ThemeService,
    public readonly appQuery: ApplicationQuery,
    public readonly footerQuery: FooterQuery
  ) {
    // registers gsap to handle scroll to plugin.
    // this replaces "scroll-behavior: 'smooth'" and allows
    // direct control of auto scrolling durations and easing.
    gsap.registerPlugin(ScrollToPlugin);
  }

  /**
   * Occurs when the Application has successfully bootstrapped.
   *
   * Once this step is reached, we will initialize with the server and expect
   * that to complete.
   */
  ngOnInit(): void {
    // DigitaService ERROR TEST
    // throw new DigitaServiceError('Native Error', 'https://freesvgdesigns.com/wp-content/uploads/2020/09/01458-500x500.png');

    this.loggerService.log('[App] initializing');
    this.initializeService
      .initialize()
      .pipe(take(1))
      .subscribe({
        next: ([configuration, initializeResponse]) => {
          this.loggerService.groupCollapsed('[App] Initialize Response');
          this.loggerService.log(initializeResponse);
          this.loggerService.close();

          this.appService.initialize(configuration, initializeResponse);

          // the server should be sending a valid object response
          if (!initializeResponse) {
            throw new DigitaServiceError(`[App] Initialization Error - The server did not respond with a valid configuration.`);
          }

          // creates the icon registry
          this.icon.initialize();

          // apply custom theming should any be provided.
          // note, if you want to force defaults for testing, pass a second parameter of true
          // this.themeService.applyTheme(initializeResponse.theme, true);
          this.themeService.applyTheme(initializeResponse.theme);

          // populate the stores with data in a cascade
          this.appService.registerReady(initializeResponse.lifecycleReady);
          this.loggerService.log('[App] initialized');

          // NATIVE Error
          // throw new Error('Angular Error Test');

          // navigate to the destination this will trigger one of the options abive
          this.appService.openLink(initializeResponse.destination);
        },
        error: (error: DigitaServiceError) => {
          if (error) {
            throw error;
          } else {
            throw new DigitaServiceError(`Initiailization Error - Unknown`);
          }
        },
      });
  }
}
