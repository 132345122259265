import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, switchMap, timer } from 'rxjs';
import { ShellFGEffectsConfettisModel } from './shell-fg-effects-confettis.model';
import { ShellFGEffectsConfettisStore } from './shell-fg-effects-confettis.store';

/**
 * Qureries the State of an instance of {@link ShellFGEffectsConfettisStore}.
 */
@Injectable()
export class ShellFGEffectsConfettisQuery extends Query<ShellFGEffectsConfettisModel> {
  /**
   * Constructor
   */
  constructor(protected override readonly store: ShellFGEffectsConfettisStore) {
    super(store);
  }

  /**
   * Is the effect ready (has the configuration been processed).
   */
  private ready$ = this.select((state) => state.ready);

  /**
   * The delay in milliseconds before the confetti effect starts.
   */
  private delayMS$ = this.select((state) => state.delayMS);

  /**
   * The ID of the effect.
   */
  private effectID$ = this.select((state) => state.effectID);

  /**
   * The options to use for the confetti effect.
   */
  private effect$ = this.select((state) => state.effect);

  /**
   * Is the effect complete?
   */
  private complete$ = this.select((state) => state.complete);

  /**
   * The configuration for the confetti effect.
   */
  confettisConfig$ = this.ready$.pipe(
    filter((ready) => ready),
    switchMap(() => this.delayMS$),
    switchMap((delayMS) => timer(delayMS)),
    switchMap(() => this.effect$)
  );

  /**
   * When the confetti is completed, this will emit the effectID.
   */
  completeConfig$ = this.complete$.pipe(
    filter((complete) => complete),
    switchMap(() => this.effectID$)
  );
}
