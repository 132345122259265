<div fxLayout="column" fxLayoutAlign="end stretch" #container class="mat-footer">
  <div
    *ngIf="
      (footerQuery.sharing$ | async) ||
      (footerQuery.author$ | async) ||
      (footerQuery.branding$ | async) ||
      (footerQuery.termsConditions$ | async)
    "
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="end stretch"
    fxLayoutAlign.gt-xs="space-between center"
  >
    <div fxFlex.gt-xs="32" fxFlex>
      <app-social-sharing
        *ngIf="(footerQuery.sharing$ | async) && (footerQuery.isSharingHidden$ | async) !== true"
        [config]="footerQuery.sharing$ | async"
      ></app-social-sharing>
    </div>
    <div fxFlex.gt-xs="32" fxFlex>
      <app-footer-author
        *ngIf="(footerQuery.author$ | async) && (footerQuery.isAuthorHidden$ | async) !== true"
        [config]="footerQuery.author$ | async"
      ></app-footer-author>
    </div>
    <div fxFlex.gt-xs="32" fxFlex>
      <app-footer-branding
        *ngIf="(footerQuery.branding$ | async) && (footerQuery.isBrandingHidden$ | async) !== true"
        [config]="footerQuery.branding$ | async"
      ></app-footer-branding>
    </div>
  </div>
  <div
    fxLayout="column"
    *ngIf="(footerQuery.termsConditions$ | async) && (footerQuery.isTnCHidden$ | async) !== true"
    fxLayoutAlign="space-between center"
  >
    <app-button fxFlex="100" [config]="footerQuery.termsConditions$ | async"></app-button>
  </div>
</div>
