import { animation, sequence, useAnimation } from '@angular/animations';
import { ANIMATION_DISPLAY_DURATION } from '../AnimationConsts';
import { IAnimationCompoundOptions } from '../IAnimationCompoundOptions';
import { zoomInAnimation, zoomOutAnimation } from '../parts/common.animation';

export const zoomInOutCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = zoomInAnimation(options.enter);
  const exit = zoomOutAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const zoomInOutTowardsCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = zoomInAnimation(options.enter);
  const exit = zoomOutAnimation({
    startScale: 1,
    endScale: 1.5,
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};
