import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { ApplicationQuery } from 'src/app/modules/core/services/application/application.query';
import { ShellBackgroundEntityModel } from './shell-background-entity.model';

/**
 * The ShellBackgroundEntity Component is used with the ShellBackground to
 * represent a change of Application Background.
 */
@Component({
  selector: 'app-shell-background-entity',
  templateUrl: './shell-background-entity.component.html',
  styleUrls: ['./shell-background-entity.component.scss'],
})
export class ShellBackgroundEntityComponent implements OnDestroy {
  /**
   * The configuration powering the component.
   */
  @Input() config: ShellBackgroundEntityModel;

  /**
   * The video element.
   */
  @ViewChild('video', { static: false }) videoElement: ElementRef<HTMLVideoElement>;

  /**
   * Occurs when the video fails to load.
   */
  private videoErrorSource = new BehaviorSubject(false);
  videoError$ = this.videoErrorSource.asObservable().pipe(distinctUntilChanged());

  /**
   * Constructor
   */
  constructor(public readonly domSanitizer: DomSanitizer, public readonly appQuery: ApplicationQuery) {}

  /**
   * Occurs when the video fails to load.
   */
  videoError() {
    this.videoErrorSource.next(true);
  }

  /**
   * Occurs when the video metadata has loaded.
   */
  videoLoadedMetaData() {
    const video = this.videoElement?.nativeElement;
    if (video) {
      video.disablePictureInPicture = true;
      video.autoplay = true;
      video.preload = 'auto';
      video.loop = true;
      video.muted = true;
      video.playsInline = true;
      video.controls = false;
      video.poster = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=';
      video.play();
    }
  }

  /**
   * Lifecycle.
   */
  ngOnDestroy() {
    this.videoErrorSource?.complete();
  }
}
