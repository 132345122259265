<div id="container" *ngIf="pluginQuery.templateData$ | async as templateData">
  <!-- The loading indicator is diplayed during loading. -->
  <div id="loading-container" *ngIf="templateData.displayLoading">
    <div id="loading" fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner id="loading-spinner"></mat-spinner>
    </div>
  </div>
  <!-- The content is displayed after loading.-->
  <div
    class="content"
    [@fadeIn]="templateData.displayContent ? 'visible' : 'hidden'"
    [style.position]="templateData.displayContent ? 'static' : 'relative'"
  >
    <app-databox-container #databox *ngIf="pluginQuery.databox$ | async" [config]="pluginQuery.databox$ | async"></app-databox-container>
    <div class="stack" [style.height]="templateData.contentHeight" [class.mobile-full-width]="templateData.fullWidth">
      <app-plugin-text #text></app-plugin-text>
      <iframe id="iframe" #iframe> </iframe>
    </div>
  </div>
</div>
