import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { MediaManagerModel } from './media-manager.model';

/**
 * Creates the initial state of this store.
 */
function createInitialState(): MediaManagerModel {
  return {
    activeMediaID: null,
  };
}

/**
 * Contains the Media Manager Store.
 *
 * This is used with {@link MediaManagerService} to emit it's state.
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'media-manager' })
export class MediaManagerStore extends Store<MediaManagerModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // ACTIVE PLAYBACK
  ////////////////////////////////////////////////////////////////////

  /**
   * All Media (both streaming providers and native) have unique system IDs.
   *
   * Each time a media plays, it's unique ID is set (or removed) globally so that
   * any media which is not the active media can be paused.
   */
  applyActiveMediaID(id: string | null) {
    this.update((draft) => {
      draft.activeMediaID = id;
    });
  }
}
