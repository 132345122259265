import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IAutoDestination } from 'src/app/api/modules/core/components/abstract/IAutoDestination';
import { IScreen } from 'src/app/api/modules/core/components/screen/IScreen';
import { IEnvironmentSettings } from 'src/app/api/modules/core/components/settings/IEnvironmentSettings';
import { IAbstractComponent } from 'src/app/api/modules/shared/IAbstractComponent';
import { createDigitaServiceError } from 'src/app/app-error';
import { ScreenModel } from './screen.model';

function createInitialState<Configuration extends IScreen = IScreen>(): ScreenModel<Configuration> {
  return {
    id: '',
    configured: false,
    data: {
      layout: 'column',
      layoutAlign: 'space-around center',
      appearance: {
        useCardThemeColor: false,
      },
      autoDestination: undefined,
      componentArray: [],
      componentObject: {},
      environment: {},
    },
  } as ScreenModel<Configuration>;
}

@Injectable()
export class ScreenStore<Configuration extends IScreen = IScreen> extends Store<ScreenModel<Configuration>> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `screen-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  applyInitialize(configuration?: Configuration) {
    if (!configuration) {
      throw createDigitaServiceError(`Screen`, `initialze`, `All screens required a configuration to be provided.`, `config`);
    }

    const defaultConfig = this.getValue();

    // layout
    let layout = defaultConfig.data.layout;
    if (configuration.layout) {
      layout = configuration.layout;
    }

    // layout align
    let layoutAlign = defaultConfig.data.layoutAlign;
    if (configuration.layoutAlign) {
      layoutAlign = configuration.layoutAlign;
    }

    // appearance
    const appearance = {
      useCardThemeColor: false,
    };
    if (configuration.appearance?.useCardThemeColor == true) {
      appearance.useCardThemeColor = true;
    }

    // auto destination
    let autoDestination: IAutoDestination | undefined = undefined;
    if (configuration.autoDestination) {
      autoDestination = configuration.autoDestination;
    }

    // component array
    let componentArray: IAbstractComponent[] = [];
    if (configuration.componentArray) {
      componentArray = cloneDeep(configuration.componentArray);
    }

    // component object
    let componentObject = {};
    if (configuration.componentObject) {
      componentObject = cloneDeep(configuration.componentObject);
    }

    // environment
    const environment: IEnvironmentSettings = {};
    if (configuration.environment) {
      // the environment background can be null as a valid value.
      if (configuration.environment.background) {
        environment.background = cloneDeep(configuration.environment.background);
      } else if (configuration.environment.background === null) {
        environment.background = null;
      }

      if (configuration.environment.fonts) {
        environment.fonts = cloneDeep(configuration.environment.fonts);
      }
      if (configuration.environment.footer) {
        environment.footer = cloneDeep(configuration.environment.footer);
      }
      if (configuration.environment.lifecycleComplete) {
        environment.lifecycleComplete = cloneDeep(configuration.environment.lifecycleComplete);
      }
      if (configuration.environment.lifecycleRouteChange) {
        environment.lifecycleRouteChange = cloneDeep(configuration.environment.lifecycleRouteChange);
      }
      if (configuration.environment.shell) {
        environment.shell = cloneDeep(configuration.environment.shell);
      }
      if (configuration.environment.theme) {
        environment.theme = cloneDeep(configuration.environment.theme);
      }
    }

    this.update((draft) => {
      draft.configured = true;
      draft.id = this.storeName;
      draft.data = {
        layout,
        layoutAlign,
        appearance,
        autoDestination,
        componentArray,
        componentObject,
        environment,
      } as Omit<Configuration, 'selector'>;
    });
  }
}
