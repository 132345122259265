<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZING: During initializing the media is being configured and attempting to load -->
<!-------------------------------------------------------------------------------------------->

<!-- During Initialize display a query loading bar as there is nothing to show -->
<mat-progress-bar class="loader disable-pointer" *ngIf="mediaQuery.initializing$ | async" mode="query"></mat-progress-bar>

<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZING ERROR: During initializing if an error occurs the component will never    -->
<!-- reach the initialized state                                                            -->
<!-------------------------------------------------------------------------------------------->

<!-- If an error occurs during initiailization, then a specific error duplication is shown. -->
<div
  class="container"
  *ngIf="mediaQuery.initializingError$ | async"
  [style.aspectRatio]="(mediaQuery.aspectRatio$ | async) || 0"
  [class.limit-portrait-width-on-desktop]="mediaQuery.limitPortraitWidthOnDesktop$ | async"
>
  <div class="error" *ngIf="mediaQuery.error$ | async">
    <div class="error-content" fxLayout="column" fxLayoutAlign="space-between center">
      <app-fa-icon class="error-icon" *ngIf="mediaQuery.errorIcon$ | async" [config]="mediaQuery.errorIcon$ | async"></app-fa-icon>
      <div *ngIf="mediaQuery.errorReason$ | async">{{ mediaQuery.errorReason$ | async }}</div>
    </div>
  </div>
</div>

<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZED: The media has been configured and managed to boot up.                     -->
<!-------------------------------------------------------------------------------------------->

<!-- Initialized -->
<div
  class="container"
  *ngIf="mediaQuery.initialized$ | async"
  [style.aspectRatio]="(mediaQuery.aspectRatio$ | async) || 0"
  [class.limit-portrait-width-on-desktop]="mediaQuery.limitPortraitWidthOnDesktop$ | async"
>
  <!-- Once details become available, the poster of the media can be displayed -->
  <div class="poster disable-pointer" *ngIf="mediaQuery.hasPoster$ | async" [style.backgroundImage]="mediaQuery.poster$ | async"></div>

  <div
    *ngIf="mediaQuery.initialized$ | async"
    class="provider-container"
    [class.disable-pointer]="mediaQuery.interactionDisabled$ | async"
    appDomIntersectionObserver
    (isIntersecting)="isIntersecting($event)"
    [isContinuous]="true"
  >
    <!-- Once all dependencies are met, the system becomes active as displays the correct player -->
    <app-media-youtube
      class="provider"
      *ngIf="mediaQuery.shouldRenderYouTube$ | async"
      [@mediaReadyAnimation]="(mediaQuery.ready$ | async) ? 'true' : 'false'"
    ></app-media-youtube>
    <app-media-vimeo
      class="provider"
      *ngIf="mediaQuery.shouldRenderVimeo$ | async"
      [@mediaReadyAnimation]="(mediaQuery.ready$ | async) ? 'true' : 'false'"
    ></app-media-vimeo>
    <app-media-native-video
      class="provider"
      *ngIf="mediaQuery.shouldRenderVideo$ | async"
      [@mediaReadyAnimation]="(mediaQuery.ready$ | async) ? 'true' : 'false'"
    >
    </app-media-native-video>
    <app-media-native-audio
      class="provider"
      *ngIf="mediaQuery.shouldRenderAudio$ | async"
      [@mediaReadyAnimation]="(mediaQuery.ready$ | async) ? 'true' : 'false'"
    >
    </app-media-native-audio>

    <!-- Contains overlaid UI for play button, and play and pause state animations -->
    <app-media-ui class="ui disable-pointer" *ngIf="mediaQuery.displayUIOverlay$ | async"></app-media-ui>
  </div>

  <!-- Errors can occur for any reason -->
  <div class="error" *ngIf="mediaQuery.error$ | async">
    <div class="error-content" fxLayout="column" fxLayoutAlign="space-between center">
      <app-fa-icon class="error-icon" *ngIf="mediaQuery.errorIcon$ | async" [config]="mediaQuery.errorIcon$ | async"></app-fa-icon>
      <div *ngIf="mediaQuery.errorReason$ | async">{{ mediaQuery.errorReason$ | async }}</div>
    </div>
  </div>
</div>
