import { Injectable } from '@angular/core';
import { Query, combineQueries } from '@datorama/akita';
import { filter, map } from 'rxjs';
import { ContainerModel } from './container.model';
import { ContainerStore } from './container.store';

/**
 * Service that extends the Akita Query class, providing reactive query functionality
 * for accessing and manipulating the state of a container within the application.
 * The ContainerQuery class exposes various Observables based on the container's state
 * which can be utilized by components or services to reactively update the UI or
 * perform other logic when the state changes.
 */
@Injectable()
export class ContainerQuery extends Query<ContainerModel> {
  /**
   * An Observable that emits a boolean value indicating whether the screen is configured.
   * It emits true if the screen's `configured` state property is true.
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  ///////////////////////////////////////////////////////////////////////////
  // LAYOUT & APPEARANCE
  ///////////////////////////////////////////////////////////////////////////

  /**
   * An Observable that emits the current value of the screen's layout from the state.
   */
  private _layout$ = this.select((state) => state.layout);

  /**
   * An Observable that emits the current value of the screen's layout alignment from the state.
   */
  private _layoutAlign$ = this.select((state) => state.layoutAlign);

  /**
   * A public Observable that combines the latest values of the screen's configured status,
   * layout, and layout alignment into a single object. It only emits when the screen is configured.
   */
  templateData$ = combineQueries([this._configured$, this._layout$, this._layoutAlign$]).pipe(
    map(([_, layout, layoutAlign]) => {
      return { layout, layoutAlign };
    })
  );

  ///////////////////////////////////////////////////////////////////////////
  // COMPONENTS
  ///////////////////////////////////////////////////////////////////////////

  /**
   * An Observable that emits the current array of components from the screen's state.
   */
  private _componentArray$ = this.select((state) => state.componentArray);

  ///////////////////////////////////////////////////////////////////////////
  // SCREEN CONFIGURATION
  ///////////////////////////////////////////////////////////////////////////

  /**
   * A public Observable that combines the latest values of the screen's configured status
   * and component array into a single object. It emits the `componentArray` only when the screen
   * is configured, ensuring that the components are only used when ready.
   */
  containerConfig$ = combineQueries([this._configured$, this._componentArray$]).pipe(
    filter(([configured]) => configured),
    map(([_, componentArray]) => {
      return { componentArray };
    })
  );

  /**
   * Constructs a new `ContainerQuery` instance, injecting the associated `ContainerStore`.
   * This setup binds the query service to the store, allowing it to listen and query the store's state.
   *
   * @param store The `ContainerStore` managing the state of container components.
   */
  constructor(protected override readonly store: ContainerStore) {
    super(store);
  }
}
