/**
 * The Default Distance covered by translation.
 *
 * The value is in pixels expressed as a number
 */
export const ANIMATION_TRANSLATION = 20;

/**
 * The Default Duration for any given animation expressed in ms
 */
export const ANIMATION_DURATION = 750;

/**
 * The Default Delay for any given animation expressed in ms
 */
export const ANIMATION_DELAY = 0;

/**
 * When In and Out Compound Animations occur, how much delay does
 * the user have to read between the completed animations?
 */
export const ANIMATION_DISPLAY_DURATION = 1750;
