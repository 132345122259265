import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IPromoCode } from 'src/app/api/modules/core/dynamic/components/IPromoCode';
import { createDigitaServiceError } from 'src/app/app-error';
import { PromoCodeModel } from './promo-code.model';

/**
 * Creates the initial state of an image's store.
 */
export function createInitialState(): PromoCodeModel {
  return {
    text: '',
  };
}

/**
 * The Store used for an {@link PromoCodeComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PromoCodeStore extends Store<PromoCodeModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `promo-code-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyInitialize(configuration?: IPromoCode) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`PromoCodeStore`, `applyInitialize`, `No configuration provided but this is required.`, `config`);
    }

    // text
    if (!configuration.text || configuration.text.length === 0) {
      throw createDigitaServiceError(`PromoCodeStore`, `applyInitialize`, `No "text" property provided but this is required.`, `config`);
    }

    // the text
    const text = configuration.text;

    // update the store
    this.update((draft) => {
      draft.text = text;
    });
  }
}
