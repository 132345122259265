import { Component } from '@angular/core';
import { FooterQuery } from '../../../services/footer/footer.query';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
/**
 * The Footer is a specialized container used for displaying specific items at the bottom of the screen.
 */
export class FooterComponent {
  /**
   * Constructor
   */
  constructor(public readonly footerQuery: FooterQuery) {}
}
