import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon'; 
import { FaIconModel } from './fa-icon.model';

export function createInitialState(): FaIconModel {
  return {
    size: undefined,
    fixedWidth: undefined,
    rotate: undefined,
    flip: undefined,
    spin: undefined,
    pulse: undefined,
    color: undefined,
    name: undefined,
    mask: undefined,
    transform: undefined,
    state: undefined,
    initialized: false,
  };
}
@Injectable()
export class FaIconStore extends Store<FaIconModel> {
  constructor() {
    super(createInitialState(), {
      name: `fa-icon-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Initiailize a button store
   */
  initialize(configuration: IFaIcon) {
    if (configuration) {
      this.update((draft) => {
        draft.size = configuration.size;
        draft.fixedWidth = configuration.fixedWidth;
        draft.rotate = configuration.rotate;
        draft.flip = configuration.flip;
        draft.spin = configuration.spin;
        draft.pulse = configuration.pulse;
        draft.color = configuration.color;
        draft.name =  configuration.name;
        draft.mask = configuration.mask;
        draft.transform = configuration.transform;
        draft.state = configuration.state;
        draft.initialized = true;
      });
    }
  }
}
