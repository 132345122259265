import { ViewContainerRef } from '@angular/core';
import { IButtonContainer } from '../../../../../api/modules/core/dynamic/containers/IButtonContainer';
import { ButtonContainerComponent } from './button-container.component';

/**
 * Dynamically creates a `ButtonContainerComponent` instance
 *
 * @param viewConterRef the container into which we want to inject the component
 * @param config the configuration required to construct a validcomponent
 */
export const createButtonContainerComponent = (viewContainerRef: ViewContainerRef, config: IButtonContainer) => {
  const componentReference = viewContainerRef.createComponent(ButtonContainerComponent);
  const component = componentReference.instance;
  component.config = config;
  return componentReference;
};
