import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { Options as ConfettiOptions } from 'canvas-confetti';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';
import { createDigitaServiceError } from 'src/app/app-error';
import { ShellFGEffectsConfettisModel } from './shell-fg-effects-confettis.model';

/**
 * The estimated duration of the confetti effect.
 *
 * This will change depending on the configuration.
 */
export const ESTIMATED_CONFETTIS_EFFECT_DURATION = 5000;

// the default options for confetti on the Left
const defaultOptionsLeft: ConfettiOptions = {
  angle: 45,
  colors: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'],
  decay: 0.9,
  disableForReducedMotion: false,
  drift: 1,
  gravity: 1,
  origin: {
    x: 0,
    y: 0.5,
  },
  particleCount: 50,
  scalar: 1,
  shapes: ['square', 'circle'],
  spread: 45,
  startVelocity: 60,
  ticks: 500,
  zIndex: 100,
};

// the default options for confetti on the Right
const defaultOptionsRight: ConfettiOptions = {
  angle: 135,
  colors: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'],
  decay: 0.9,
  disableForReducedMotion: false,
  drift: -1,
  gravity: 1,
  origin: {
    x: 1,
    y: 0.5,
  },
  particleCount: 50,
  scalar: 1,
  shapes: ['square', 'circle'],
  spread: 45,
  startVelocity: 60,
  ticks: 500,
  zIndex: 100,
};

function createInitialState(): ShellFGEffectsConfettisModel {
  return {
    ready: false,
    complete: false,
    delayMS: 0,
    effectID: '',
    effect: {
      left: defaultOptionsLeft,
      right: defaultOptionsRight,
    },
  };
}

/**
 * Manages the State of a {@link ShellFGEffectsConfettisComponent}.
 */
@Injectable()
export class ShellFGEffectsConfettisStore extends Store<ShellFGEffectsConfettisModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), {
      name: `shell-fg-effects-confettis-${guid()}`,
      producerFn: produce,
    });
  }

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IShellFGEffectsConfettis) {
    // if there is no configuration, then throw an error
    if (!configuration) {
      throw createDigitaServiceError(
        `ShellFGEffectsConfettis`,
        `initialize`,
        `The configuration is required but was not provided.`,
        `internal`
      );
    }

    // process the configurations
    const newOptionsLeft = cloneDeep(defaultOptionsLeft);
    this.processConfig(configuration.left, newOptionsLeft);

    const newOptionsRight = cloneDeep(defaultOptionsRight);
    this.processConfig(configuration.right, newOptionsRight);

    // extract the id
    const effectID = configuration.effectID;
    if (!effectID) {
      throw createDigitaServiceError(`ShellFGEffectsConfettis`, `initialize`, `The effectID is required but was not provided.`, `internal`);
    }

    // the delay in milliseconds before the confetti effect starts
    let delayMS = 0;
    if (typeof configuration.delayMS === 'number') {
      if (configuration.delayMS >= 0) {
        delayMS = configuration.delayMS;
      }
    }

    // updat the state
    this.update((draft) => {
      draft.ready = true;
      draft.delayMS = delayMS;
      draft.effectID = effectID;
      draft.effect = {
        left: newOptionsLeft,
        right: newOptionsRight,
      };
    });
  }

  /**
   * Occurs when the confettis effect has completed.
   */
  applyComplete() {
    this.update((draft) => {
      draft.complete = true;
    });
  }

  /**
   * Applies incoming configuration to a default options object.
   *
   * @param configuration - The configuration to process.
   * @param newOptions - The options to update.
   */
  private processConfig(configuration: ConfettiOptions, newOptions: ConfettiOptions) {
    if (!configuration) {
      return;
    }

    if (typeof configuration.angle === 'number') {
      newOptions.angle = configuration.angle;
    }

    if (configuration.colors && configuration.colors.length > 0) {
      newOptions.colors = configuration.colors;
    }

    if (typeof configuration.decay === 'number') {
      newOptions.decay = configuration.decay;
    }

    if (configuration.disableForReducedMotion === true) {
      newOptions.disableForReducedMotion = configuration.disableForReducedMotion;
    }

    if (typeof configuration.drift === 'number') {
      newOptions.drift = configuration.drift;
    }

    if (typeof configuration.gravity === 'number') {
      newOptions.gravity = configuration.gravity;
    }

    if (configuration.origin) {
      if (typeof configuration.origin.x === 'number') {
        if (configuration.origin.x >= 0 && configuration.origin.x <= 1) {
          newOptions.origin.x = configuration.origin.x;
        }
      }

      if (typeof configuration.origin.y === 'number') {
        if (configuration.origin.y >= 0 && configuration.origin.y <= 1) {
          newOptions.origin.y = configuration.origin.y;
        }
      }
    }

    if (typeof configuration.particleCount === 'number') {
      newOptions.particleCount = configuration.particleCount;
    }

    if (typeof configuration.scalar === 'number') {
      newOptions.scalar = configuration.scalar;
    }

    if (configuration.shapes && configuration.shapes.length > 0) {
      const validShapes = configuration.shapes
        .filter((shape): shape is 'square' | 'circle' | 'star' => typeof shape === 'string')
        .every((shape) => ['circle', 'square', 'star'].includes(shape));
      if (validShapes) {
        newOptions.shapes = configuration.shapes;
      }
    }

    if (typeof configuration.spread === 'number') {
      newOptions.spread = configuration.spread;
    }

    if (typeof configuration.startVelocity === 'number') {
      newOptions.startVelocity = configuration.startVelocity;
    }

    if (typeof configuration.ticks === 'number') {
      newOptions.ticks = configuration.ticks;
    }

    if (typeof configuration.zIndex === 'number') {
      newOptions.zIndex = configuration.zIndex;
    }
  }
}
