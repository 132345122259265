// This file runs a development build with full logs and consoles. It is the
// equivalent of a production build otherwise without minification.

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

import { IBuildEnvironment } from './IBuildEnvironment';
import versions from './version';

export const environment: IBuildEnvironment = {
  production: false,
  useWithCredentials: true,
  localDevelopment: false,
  showDevPanel: true,
  skipLocationChange: true,
  environmentName: 'dev',
  environmentVersions: versions,
};
