import { Injectable } from '@angular/core';
import { Query, combineQueries } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { ButtonContainerModel } from './button-container.model';
import { ButtonContainerStore } from './button-container.store';

@Injectable()
export class ButtonContainerQuery extends Query<ButtonContainerModel> {
  /**
   * Is the system configured?
   */
  private _configured$ = this.select((state) => state.configured).pipe(filter((configured) => configured));

  /**
   * The layout of the container is determined at runtime.
   */
  private layout$ = this.select((state) => state.layout);

  /**
   * The layout align of the container is determined at runtime.
   */
  private layoutAlign$ = this.select((state) => state.layoutAlign);

  /**
   * What sizing mode is applied to the buttons?
   */
  private _buttonSizing$ = this.select((state) => state.preferredButtonSizing);

  /**
   * Are buttons fixed size?
   */
  private _isFixedSize$ = this._buttonSizing$.pipe(map((sizingMode) => sizingMode === 'fixedSize'));

  /**
   * Are buttons full size?
   */
  private _isFullSize$ = this._buttonSizing$.pipe(map((sizingMode) => sizingMode === 'fullSize'));

  /**
   * Contains all layout data.
   */
  templateData$ = combineQueries([this._configured$, this.layout$, this.layoutAlign$, this._isFixedSize$, this._isFullSize$]).pipe(
    map(([_, layout, layoutAlign, isFixedSize, isFullSize]) => {
      return {
        layout,
        layoutAlign,
        isFixedSize,
        isFullSize,
      };
    })
  );

  /**
   * Is the buttons stacked in their containers?
   */
  isStacked$ = this.select((state) => state.isStacked);

  /**
   * The component array containing the buttons.
   */
  private _componentArray$ = this.select((state) => state.componentArray);

  /**
   * The component array.
   */
  componentArray$ = combineQueries([this._configured$, this._componentArray$]).pipe(
    map(([_, componentArray]) => {
      return componentArray;
    })
  );

  /**
   * Constructor.
   *
   * @param store - the button container store
   */
  constructor(protected override readonly store: ButtonContainerStore) {
    super(store);
  }
}
