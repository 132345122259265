import { Injectable } from '@angular/core';
import { IShellFGEffectsConfetti } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfetti';
import { ShellFGEffectsConfettiStore } from './shell-fg-effects-confetti.store';

/**
 * The ShellFGEffectsConfettiService is used for each {@link ShellFGEffectsConfettiComponent}.
 */
@Injectable()
export class ShellFGEffectsConfettiService {
  /**
   * Constructor
   */
  constructor(private readonly store: ShellFGEffectsConfettiStore) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IShellFGEffectsConfetti) {
    this.store.applyInitialize(configuration);
  }

  /**
   * Is the effect complete?
   */
  applyComplete() {
    this.store.applyComplete();
  }
}
