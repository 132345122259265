<!--
    The Layout of the div is determined by the configuration direction which is column by default.

    A Template is provided ready to inject with DigitaService Content. 
-->
<div
  id="container"
  *ngIf="screenQuery.screenData$ | async as screenData"
  [fxLayout]="screenData.layout"
  [fxLayoutAlign]="screenData.layoutAlign"
  [class.card-wrap]="screenData.appearance?.useCardThemeColor"
>
  <ng-template appDynamicContent></ng-template>
</div>
