import { LoggerService } from '@angular-ru/cdk/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import type {} from 'css-font-loading-module';
import { forkJoin, take, tap } from 'rxjs';
import { IDynamicFont } from 'src/app/api/modules/core/components/abstract/IDynamicFont';
import { IDynamicTheme } from 'src/app/api/modules/core/components/abstract/IDynamicTheme';
import { createDigitaServiceError } from 'src/app/app-error';

/**
 * An enum containing all CSS Variables which are possible in the application.
 */
enum DigitaServiceCSSVars {
  'themeBodyColor' = '--theme-body-color',
  'themeBackgroundColor' = '--theme-background-background',
  'themeRadius' = '--theme-radius',
  'themeFontBodyAlign' = '--theme-font-body-align',
  'themeFontHeadlineFamily' = '--theme-font-headline-family',
  'themeFontHeadlineWeight' = '--theme-font-headline-weight',
  'themeFontHeadlineSize' = '--theme-font-headline-size',
  'themeFontHeadlineColor' = '--theme-font-headline-color',
  'themeFontBodyFamily' = '--theme-font-body-family',
  'themeFontBodyWeight' = '--theme-font-body-weight',
  'themeFontBodySize' = '--theme-font-body-size',
  'themeFontButtonLabelColor' = '--theme-font-button-label-color',
  'themeFontButtonWeight' = '--theme-font-button-weight',
  'themeTexturedButtonURL' = '--theme-textured-button-url',
  'themeFormBackgroundOpacity' = '--theme-form-background-opacity',
  'themeFontPluginFamily' = '--theme-font-plugin-family',
  'themeFontPluginWeight' = '--theme-font-plugin-weight',
  'themeFontPluginSize' = '--theme-font-plugin-size',
  'themeFontPluginColor' = '--theme-font-plugin-color',
  'themeFontPluginStrokeColor' = '--theme-font-plugin-stroke-color',
  'themeChatbotBotBackgroundColor' = '--theme-chatbot-bot-background-color',
  'themeChatbotBotTextColor' = '--theme-chatbot-bot-text-color',
  'themeChatbotHumanBackgroundColor' = '--theme-chatbot-human-background-color',
  'themeChatbotHumanTextColor' = '--theme-chatbot-human-text-color',
}

/**
 * This Dark Theme is the official theme used in Drimify. It used used as both a reference,
 * and in the dev tool for switching between light and dark modes.
 *
 * In production, the server will set it's own theme.
 */
const DarkTheme: IDynamicTheme = {
  themeIsDark: true,
  themePrimaryColor: '#FFFFFF',
  themeAccentColor: '#FFFFFF',
  themeWarnColor: '#ff5722',
  themeFontHeadlineColor: '#FFFFFF',
  themeBodyColor: '#FFFFFF',
  themeFontHeadlineFamily: 'Roboto',
  themeFontHeadlineWeight: '700',
  themeFontBodyFamily: 'Roboto',
  themeFontBodyWeight: '400',
  themeFormBackgroundOpacity: '0.85',
  themeFontButtonLabelColor: '#121212',
  themeRadius: '8px 16px 8px 16px',
  themeFontHeadlineSize: '28px',
  themeFontBodySize: '16px',
  themeTexturedButtonURL: 'https://front.drimify.com/client/common/assets/button-texture/button-texture.jpg',
  themeBackgroundColor: '#121212',
  themeFontButtonWeight: '400',
  themeFontBodyAlign: 'center',
  themeFontPluginFamily: 'Roboto',
  themeFontPluginWeight: '900',
  themeFontPluginSize: '40px',
  themeFontPluginColor: '#fff',
  themeFontPluginStrokeColor: '#121212',
  themeChatbotBotBackgroundColor: '#121212',
  themeChatbotBotTextColor: '#FFFFFF',
  themeChatbotHumanBackgroundColor: '#FFFFFF',
  themeChatbotHumanTextColor: '#121212',
};

/**
 * This Light Theme is the official theme used in Drimify. It used used as both a reference,
 * and in the dev tool for switching between light and dark modes.
 *
 * In production the server will set it's own theme.
 */
const LightTheme: IDynamicTheme = {
  themeIsDark: false,
  themePrimaryColor: '#121212',
  themeAccentColor: '#121212',
  themeWarnColor: '#ff5722',
  themeFontHeadlineColor: '#121212',
  themeBodyColor: '#121212',
  themeFontHeadlineFamily: 'Roboto',
  themeFontHeadlineWeight: '700',
  themeFontBodyFamily: 'Roboto',
  themeFontBodyWeight: '400',
  themeFormBackgroundOpacity: '0.85',
  themeFontButtonLabelColor: '#FFFFFF',
  themeRadius: '8px 16px 8px 16px',
  themeFontHeadlineSize: '28px',
  themeFontBodySize: '16px',
  themeTexturedButtonURL: 'https://front.drimify.com/client/common/assets/button-texture/button-texture.jpg',
  themeBackgroundColor: '#FFFFFF',
  themeFontButtonWeight: '400',
  themeFontBodyAlign: 'center',
  themeFontPluginFamily: 'Roboto',
  themeFontPluginWeight: '900',
  themeFontPluginSize: '40px',
  themeFontPluginColor: '#FFFFFF',
  themeFontPluginStrokeColor: '#121212',
  themeChatbotBotBackgroundColor: '#FFFFFF',
  themeChatbotBotTextColor: '#121212',
  themeChatbotHumanBackgroundColor: '#121212',
  themeChatbotHumanTextColor: '#FFFFFF',
};

/**
 * The Theme Service configures the CSS Variables which are provided during the
 * initialize phase.
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   * Constructor
   */
  constructor(
    private readonly materialCssVarsService: MaterialCssVarsService,
    private readonly loggerService: LoggerService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  /**
   * Applies custom css variable theme values with the given configuration.
   *
   * @param styleData the configuration object
   * @param forceDefault you can force the configuration to be ignored and use defaults.
   */
  applyTheme(styleData: Partial<IDynamicTheme> = {}, forceDefaults = false) {
    this.loggerService.log('[ThemeService] applyTheme');

    if (!forceDefaults && styleData) {
      const styleService = this.materialCssVarsService;
      const documentStyle = document.documentElement.style;

      ////////////////////////////////////////////////////////////////////////////////////////
      // Direct Values
      ////////////////////////////////////////////////////////////////////////////////////////

      // material is dark or not?
      if (styleData.themeIsDark === true) {
        styleService.setDarkTheme(true);
      } else if (styleData.themeIsDark === false) {
        styleService.setDarkTheme(false);
      }

      // set the primary theme color used with material components "color" property.
      // when no primary color is provided, auto contrast is off.
      if (styleData.themePrimaryColor) {
        styleService.setPrimaryColor(styleData.themePrimaryColor);
      }
      // TODO Remove this, it was removed on 20/07/2022 because it didn't seem used anymore.
      //  else {
      //   styleService.setAutoContrastEnabled(false);
      // }

      // set the accent theme color used with material components "color" property.
      if (styleData.themeAccentColor) {
        styleService.setAccentColor(styleData.themeAccentColor);
      }

      // set the warn theme color used with material components "color" property.
      if (styleData.themeWarnColor) {
        styleService.setWarnColor(styleData.themeWarnColor);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Misc CSS Vars
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the material body color
      if (styleData.themeBodyColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeBodyColor, styleData.themeBodyColor);
      }

      // set the actual page background solid color (if you have no background image, you see this solid color)
      if (styleData.themeBackgroundColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeBackgroundColor, styleData.themeBackgroundColor);
      }

      // set the radius for rounded corners
      if (styleData.themeRadius) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeRadius, styleData.themeRadius);
      }

      // set the font alignment to the body
      if (styleData.themeFontBodyAlign) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontBodyAlign, styleData.themeFontBodyAlign);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Headline Font Setup  (Titles)
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the headline color
      if (styleData.themeFontHeadlineColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontHeadlineColor, styleData.themeFontHeadlineColor);
      }

      // set the headline family
      if (styleData.themeFontHeadlineFamily) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontHeadlineFamily, styleData.themeFontHeadlineFamily);
      }

      // set the headline weight
      if (styleData.themeFontHeadlineWeight) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontHeadlineWeight, styleData.themeFontHeadlineWeight);
      }

      // set the headline size
      if (styleData.themeFontHeadlineSize) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontHeadlineSize, styleData.themeFontHeadlineSize);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Body Font Setup (descriptions)
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the body color
      if (styleData.themeFontBodyFamily) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontBodyFamily, styleData.themeFontBodyFamily);
      }

      // set the body weight
      if (styleData.themeFontBodyWeight) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontBodyWeight, styleData.themeFontBodyWeight);
      }

      // set the body size
      if (styleData.themeFontBodySize) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontBodySize, styleData.themeFontBodySize);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Buttons
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the label color within a button
      if (styleData.themeFontButtonLabelColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontButtonLabelColor, styleData.themeFontButtonLabelColor);
      }

      // set the weight of text within a button
      if (styleData.themeFontButtonWeight) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontButtonWeight, styleData.themeFontButtonWeight);
      }

      // set the textured url for buttons who are `isTextured: true`
      if (styleData.themeTexturedButtonURL) {
        // wrap the value in `url()` if it's not already
        if (!styleData.themeTexturedButtonURL.startsWith('url')) {
          documentStyle.setProperty(DigitaServiceCSSVars.themeTexturedButtonURL, `url('${styleData.themeTexturedButtonURL}')`);
        } else {
          documentStyle.setProperty(DigitaServiceCSSVars.themeTexturedButtonURL, styleData.themeTexturedButtonURL);
        }
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Forms
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the background opacity for all forms
      if (styleData.themeFormBackgroundOpacity) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFormBackgroundOpacity, styleData.themeFormBackgroundOpacity);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Plugins
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the font family to use with Plugins (IFrame Apps)
      if (styleData.themeFontPluginFamily) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontPluginFamily, styleData.themeFontPluginFamily);
      }

      // set the font weight to use with Plugins (IFrame Apps)
      if (styleData.themeFontPluginWeight) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontPluginWeight, styleData.themeFontPluginWeight);
      }

      // set the font size to use with Plugins (IFrame Apps)
      if (styleData.themeFontPluginSize) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontPluginSize, styleData.themeFontPluginSize);
      }

      // set the font color to use with Plugins (IFrame Apps)
      if (styleData.themeFontPluginColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontPluginColor, styleData.themeFontPluginColor);
      }

      // set the font stroke (outline) color to use with Plugins (IFrame Apps)
      if (styleData.themeFontPluginStrokeColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeFontPluginStrokeColor, styleData.themeFontPluginStrokeColor);
      }

      ////////////////////////////////////////////////////////////////////////////////////////
      // Chatbot
      ////////////////////////////////////////////////////////////////////////////////////////

      // set the chatbot bot background color
      if (styleData.themeChatbotBotBackgroundColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeChatbotBotBackgroundColor, styleData.themeChatbotBotBackgroundColor);
      }

      // set the chatbot bot text color
      if (styleData.themeChatbotBotTextColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeChatbotBotTextColor, styleData.themeChatbotBotTextColor);
      }

      // set the chatbot human background color
      if (styleData.themeChatbotHumanBackgroundColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeChatbotHumanBackgroundColor, styleData.themeChatbotHumanBackgroundColor);
      }

      // set the chatbot human text color
      if (styleData.themeChatbotHumanTextColor) {
        documentStyle.setProperty(DigitaServiceCSSVars.themeChatbotHumanTextColor, styleData.themeChatbotHumanTextColor);
      }
    }
  }

  /**
   * Dynamically Load a Font into the System.
   *
   * @param fonts - the fonts to load.
   */
  loadFonts(fonts?: IDynamicFont[]) {
    if (!fonts || fonts.length === 0) {
      throw createDigitaServiceError('ThemeService', 'loadFonts', 'No fonts provided or the fonts array is empty.', 'config');
    }
    return this.loadFontsWithModern(fonts).pipe(
      take(1),
      tap((fonts) => {
        // for each of the fonts, add them to the document fonts.
        for (let i = 0; i < fonts.length; i++) {
          this.document.fonts.add(fonts[i]);
        }
      })
    );
  }

  /**
   * Processes the fonts {@link IDynamicFont} array and converts them into an Observable.
   *
   * @param fonts - the fonts to load.
   */
  private loadFontsWithModern(fonts: IDynamicFont[]) {
    // this.loggerService.log('[ThemeService]::loadFontsWithModern', fonts);

    const fontFaces: FontFace[] = [];
    for (let i = 0; i < fonts.length; i++) {
      const font = fonts[i];
      const fontFamily = font.fontFamily;
      if (!fontFamily) {
        throw createDigitaServiceError('ThemeService', 'loadFontsWithModern', 'fontFamily is required for all dynamic fonts.', 'config');
      }

      // add the font files which need to be loaded to an array for processing.
      const fontURIs: string[] = [];
      if (!font.fontURIs) {
        throw createDigitaServiceError('ThemeService', 'loadFontsWithModern', 'fontURIs is required for all dynamic fonts.', 'config');
      }
      if (!font.fontURIs.woff2 && !font.fontURIs.woff && !font.fontURIs.ttf) {
        throw createDigitaServiceError(
          'ThemeService',
          'loadFontsWithModern',
          'Atleast one fontURI is required for all dynamic fonts.',
          'config'
        );
      }

      if (font.fontURIs.woff2) {
        fontURIs.push(`url(${font.fontURIs.woff2}) format("woff2")`);
      }
      if (font.fontURIs.woff) {
        fontURIs.push(`url(${font.fontURIs.woff}) format("woff")`);
      }
      if (font.fontURIs.ttf) {
        fontURIs.push(`url(${font.fontURIs.ttf}) format("truetype")`);
      }

      // add between these strings into a final large loading string similar to loading multiple files with css @font-face
      // we end up with either
      // "url(font.woff) format(woff)"
      // or multiple separated by a comma
      // "url(font.woff) format(woff), url(font.woff2) format(woff2)"
      let fontURI = ``;
      for (let j = 0; j < fontURIs.length; j++) {
        if (j === fontURIs.length - 1) {
          fontURI += `${fontURIs[j]}`;
        } else {
          fontURI += `${fontURIs[j]}, `;
        }
      }

      // the FontFace can be created.
      const fontFace = new FontFace(fontFamily, fontURI, font.descriptors);
      fontFaces.push(fontFace);
    }

    // returns a forkJoin of all the fonts to be loaded as an observable.
    return forkJoin(fontFaces.map((fontFace) => fontFace.load()));
  }

  /**
   * Toggle Dark Mode. This is used by the debug tool only.
   */
  debugToolToggleDarkModel() {
    const isDarkTheme = this.materialCssVarsService.isDarkTheme;

    if (isDarkTheme) {
      this.applyTheme(LightTheme);
    } else {
      this.applyTheme(DarkTheme);
    }
  }
}
