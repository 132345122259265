/**
 * Logger
 */
import { LoggerLevel, LoggerModule } from '@angular-ru/cdk/logger';
/**
 * Angular
 */
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
/**
 * Font Awesome
 */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/**
 * Formly
 */
import { FormlyModule } from '@ngx-formly/core';
/**
 * Dynamic CSS Vars with IE11 Fallback
 * https://github.com/johannesjo/angular-material-css-vars
 */
import { MaterialCssVarsModule } from 'angular-material-css-vars';
/**
 * Digita Service
 */
import { environment } from '../environments/environment';
import { DefaultDialogSettings, DEFAULT_MAT_FORM_FIELD_OPTIONS, DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS } from './app-constants';
import { AppInitializeService } from './app-initialize.service';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.error-handler';
import { CoreModule } from './modules/core/core.module';
import { IconsModule } from './modules/icons/icons.module';
import { MaterialModule } from './modules/material/material.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoggerModule.forRoot({
      minLevel: environment.production ? LoggerLevel.OFF : LoggerLevel.ALL,
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    IconsModule,
    CoreModule.forRoot(),
    MaterialModule,
    MaterialCssVarsModule.forRoot({
      // all optional
      isAutoContrast: true,
      darkThemeClass: 'isDarkTheme',
      lightThemeClass: 'isLightTheme',
      // ...
    }),
    AppRoutingModule,
    FormlyModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule, // should always be last
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    /**
     * Default Settings for Dialogs App Wide
     */
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: new DefaultDialogSettings() },
    /**
     * Default Settings for Form Fields App Wide
     */
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: DEFAULT_MAT_FORM_FIELD_OPTIONS,
    },
    /**
     * Default Settings for Mat Progress Spinners App Wide
     */
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS,
    },
    AppInitializeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
