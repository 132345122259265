import { LoggerService } from '@angular-ru/cdk/logger';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  DigitaServiceError,
  FRAME_EVENT_TYPES,
  IFrameEventClose,
  IFrameEventComplete,
  IFrameEventError,
  IFrameEventFirstInteraction,
  IFrameEventReady,
  IFrameEventRouteChange,
} from '@digitaservice/utils';
import { combineLatest, fromEvent } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import {
  ILifecycleCompleteEvent,
  ILifecycleReadyEvent,
  ILifecycleRouteChangeEvent,
} from 'src/app/api/modules/core/components/abstract/ILifecycleEvents';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IShell } from 'src/app/api/modules/core/components/abstract/IShell';
import { ISystem } from 'src/app/api/modules/core/components/abstract/ISystem';
import { IShellSettings } from 'src/app/api/modules/core/components/settings/IShellSettings';
import { IInitializeResponse } from 'src/app/api/modules/core/network/IInitializeResponse';
import { DigitaServiceInputs } from 'src/app/app-global-callbacks';
import { environment } from 'src/environments/environment';
import { FooterService } from '../footer/footer.service';
import { LayoutService } from '../layout.service';
import { LinkService } from '../link.service';
import { ScrollingService } from '../scrolling.service';
import { ShellBackgroundService } from '../shell-background.service';
import { WidgetService } from '../widget.service';
import { ApplicationQuery } from './application.query';
import { AppStore } from './application.store';

/**
 * The Widget Service
 */
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  /**
   * The primary outlet container.
   */
  private primaryOutletContainer: HTMLDivElement;

  /**
   * A Reference to the window.
   */
  private window: Window;

  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly linkService: LinkService,
    private readonly widgetService: WidgetService,
    private readonly scrollingService: ScrollingService,
    private readonly footerService: FooterService,
    private readonly shellBackgroundService: ShellBackgroundService,
    private readonly layoutService: LayoutService,
    private readonly appStore: AppStore,
    private readonly appQuery: ApplicationQuery,
    private readonly viewportRuler: ViewportRuler,
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Bootstrap the application services.
   *
   * @param inputs - the environment captured configuration.
   * @param configuration - the response from initialize request.
   */
  initialize(inputs: DigitaServiceInputs, configuration: IInitializeResponse) {
    this.loggerService.log('[AppService] - initialize', inputs, configuration);

    // configure the services
    this.initializeWidget(inputs);
    this.initializeSystem(configuration.system);
    this.initializeShell(configuration.shell);
    this.footerService.initialize(configuration.footer);
    this.shellBackgroundService.applyConfiguration(configuration.background);

    // configure long lived subscriptions
    this.setupWindowUnloadTracking();
    this.setupViewportResizeTracking();
    this.setupOrientationChangeTracking();
    this.setupFirstInteractionTracking();

    // the system has been initialized
    this.appStore.applyInitialized();
  }

  private initializeWidget(inputs: DigitaServiceInputs) {
    // are we in a widget?
    const isInWidget = this.window.parent !== this.window;

    // what type of widget are we in?
    let widgetType: 'auto' | 'fixed' | null = null;
    if (isInWidget) {
      widgetType = inputs.widget === 'fixed' ? 'fixed' : 'auto';
    }

    // update the store
    this.appStore.applyWidgetConfiguration(isInWidget, widgetType);

    // initialize the widget service
    this.widgetService.initialize(isInWidget, widgetType);
  }

  private initializeSystem(configuration: ISystem) {
    // setup amp
    const amp = configuration?.amp === true ? true : false;
    if (amp) {
      this.window?.setInterval(() => {
        const height = this.document.body.scrollHeight;
        this.window?.parent?.postMessage(
          {
            sentinel: 'amp',
            type: 'embed-size',
            height,
          },
          '*'
        );
      }, 1500);
    }

    // prevent window unloading behavior
    const preventWindowUnloadDialog = configuration?.preventWindowUnloadDialog === true ? true : false;

    // update the store
    this.appStore.applySystemConfiguration(amp, preventWindowUnloadDialog);
  }

  private initializeShell(configuration: IShell) {
    this.configureShellLayout(configuration);
  }

  ////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////

  /**
   * Register the First Interaction.
   *
   * This includes touching anywhere in the application. This is
   * used primarily to enable "scrollToTop" which should only happen
   * after the iframe has focus.
   */
  registerFirstInteraction() {
    const { isInWidget, widgetType, hasFirstInteraction } = this.appStore.getValue();

    // if first interaction has not occurred
    if (!hasFirstInteraction) {
      this.loggerService.log('[ApplicationService] - First Interaction');

      // update the store
      this.appStore.applyFirstInteraction();

      // create a digitaservice event
      const event: IFrameEventFirstInteraction = {
        type: FRAME_EVENT_TYPES.FIRST_INTERACTION,
        data: undefined,
      };

      // emit that event to the globals
      if (this.window?.DigitaService?.onFirstInteraction) {
        this.window.DigitaService.onFirstInteraction();
      }

      // if in a widget
      if (isInWidget) {
        // inform the widget of this event
        this.widgetService.registerFirstInteraction(event, widgetType);
      }
    }
  }

  /**
   * Register the Application Changing Route.
   */
  registerRouteChange(routeChange: ILifecycleRouteChangeEvent) {
    const { isInWidget, widgetType } = this.appStore.getValue();

    this.loggerService.log('[ApplicationService] - Route Change', routeChange);

    // create a digitaservice event
    const event: IFrameEventRouteChange = {
      type: FRAME_EVENT_TYPES.ROUTE_CHANGE,
      data: routeChange.data,
    };

    // emit that event to the globals
    if (this.window?.DigitaService?.onRouteChange) {
      this.window.DigitaService.onRouteChange(event);
    }

    // if we are in a widget
    if (isInWidget) {
      // inform the widget of this event
      this.widgetService.registerRouteChange(event, widgetType);
    }
  }

  /**
   * Register the Application being Ready.
   *
   * This means "DigitaService App is ready to be used by the user and everything is loaded"
   */
  registerReady(ready: ILifecycleReadyEvent) {
    const { isInWidget, widgetType, hasBeenDeclaredReady } = this.appStore.getValue();

    // if ready hasn't already been delcared
    if (!hasBeenDeclaredReady) {
      this.loggerService.log('[ApplicationService] - Register Ready', ready);

      // update the store
      this.appStore.applyReady();

      // create a digitaservice event
      const event: IFrameEventReady = {
        type: FRAME_EVENT_TYPES.READY,
        data: ready.data,
      };

      // emit that event to the globals
      if (this.window?.DigitaService?.onReady) {
        this.window.DigitaService.onReady(event);
      }

      // if we are in a widget
      if (isInWidget) {
        // inform the widget of this event
        this.widgetService.registerReady(event, widgetType);
      }
    }
  }

  /**
   * Register the Application being Complete.
   *
   * This means "DigitaService App has come to an end and the user has finished the experience"
   */
  registerComplete(complete: ILifecycleCompleteEvent) {
    const { isInWidget, widgetType, hasBeenDeclaredComplete } = this.appStore.getValue();

    // if complete hasn't already been delcared
    if (!hasBeenDeclaredComplete) {
      this.loggerService.log('[ApplicationService] - Register Complete', complete);

      // update the store
      this.appStore.applyComplete();

      // create a digitaservice event
      const event: IFrameEventComplete = {
        type: FRAME_EVENT_TYPES.COMPLETE,
        data: complete.data,
      };

      // emit that event to the globals
      if (this.window?.DigitaService?.onComplete) {
        this.window.DigitaService.onComplete(event);
      }

      // if we are in a widget
      if (isInWidget) {
        // inform the widget of this event
        this.widgetService.registerComplete(event, widgetType);
      }
    }
  }

  /**
   * Register the Application being Closed.
   *
   * This means the user has interacted with a "close" button at the end of their experience on
   * the final screen.
   */
  registerClose() {
    const { isInWidget, widgetType, hasBeenDeclaredClosed } = this.appStore.getValue();

    // if close hasn't already been delcared
    if (!hasBeenDeclaredClosed) {
      this.loggerService.log('[ApplicationService] - Register Close.');

      // update the store
      this.appStore.applyClose();

      // create an event
      const event: IFrameEventClose = {
        type: FRAME_EVENT_TYPES.CLOSE,
      };

      // emit that event to the globals
      if (this.window?.DigitaService?.onClose) {
        this.window.DigitaService.onClose();
      }

      // if we are in a widget
      if (isInWidget) {
        // inform the widget of this event
        this.widgetService.registerClose(event, widgetType);
      }
    }
  }

  /**
   * Register the Application having a Fatal App Killing Error.
   */
  registerError(error: DigitaServiceError) {
    const { isInWidget, widgetType, hasFatalError } = this.appStore.getValue();

    // if error hasn't already been delcared
    if (!hasFatalError && error) {
      this.loggerService.error('[ApplicationService] - Fatal Error, Please contact support.', error);
      // update the store
      this.appStore.applyError(error);

      // create an event
      const event: IFrameEventError = {
        type: FRAME_EVENT_TYPES.ERROR,
        data: error,
      };

      // emit that event to the globals
      if (this.window?.DigitaService?.onError) {
        this.window.DigitaService.onError(event);
      }

      // if we are in a widget
      if (isInWidget) {
        // inform the widget of this event
        this.widgetService.registerError(event, widgetType);
      }

      // kill the loading bar
      this.setLoadingIndicator(false);
    }
  }

  ////////////////////////////////////////////////////////////////////
  // ORIENTATION
  ////////////////////////////////////////////////////////////////////

  /**
   * Setup Orentiation Change.
   */
  private setupOrientationChangeTracking() {
    // listen out for the breakpoint observer to find the orentiation of the app.
    this.breakpointObserver.observe(['(orientation: portrait)', '(orientation: landscape)']).subscribe((breakpointState) => {
      // the final orientation is landscape by default
      let orientation: 'landscape' | 'portrait' = 'landscape';

      if (breakpointState.breakpoints['(orientation: portrait)'] === true) {
        // if it is portrait
        orientation = 'portrait';
      } else if (breakpointState.breakpoints['(orientation: landscape)'] === true) {
        // if it is landscape
        orientation = 'landscape';
      }

      // update the store
      this.appStore.applyOrientation(orientation);
    });
  }

  ////////////////////////////////////////////////////////////////////
  // FIRST INTERACTION
  ////////////////////////////////////////////////////////////////////

  /**
   * Setup First Interaction Tracking.
   */
  private setupFirstInteractionTracking() {
    // any clicks on the window
    fromEvent(this.window, 'click')
      .pipe(
        // see if first interaction has occurred
        switchMap(() => {
          return this.appQuery.hasFirstInteraction$;
        }),
        // do this until the first interaction has occurred
        first((hasFirstInteraction) => hasFirstInteraction === true)
      )
      .subscribe(() => {
        this.registerFirstInteraction();
      });
  }

  ////////////////////////////////////////////////////////////////////
  // VIEWPORT RESIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Setup Listeners for Viewport Resize.
   */
  private setupViewportResizeTracking() {
    // app resize
    this.measureViewport();
    this.viewportRuler.change(50).subscribe(() => {
      this.measureViewport();
    });
  }

  /**
   * Occurs when the Viewport Resizes.
   */
  private measureViewport() {
    const { width, height } = this.viewportRuler.getViewportSize();
    this.appStore.applyViewportResize(width, height);
  }

  ////////////////////////////////////////////////////////////////////
  // LINKS AND ADDRESSES
  ////////////////////////////////////////////////////////////////////

  /**
   * All Links are processed here.
   *
   * @param link the ILink Data containing the descriptor of the link
   */
  openLink(link: ILink) {
    this.linkService.openLink(link);
  }

  /**
   * Get the window address
   */
  getAddress() {
    return this.window.location.href;
  }

  ////////////////////////////////////////////////////////////////////
  // Scrolling Mechanisms
  ////////////////////////////////////////////////////////////////////

  /**
   * Apply Scroll To Top to Scroll to the Top of the Page.
   *
   * This action will only work once a first interaction has occurred.
   *
   * @param duration - the duration of the scroll in seconds.
   * @param delay - the delay before the scroll occurs in seconds.
   * @param onComplete - a callback that occurs once scrolling has completed.
   * @param overrideFirstInteraction - forced the scroll to occur even if first interaction has not occurred. This can occur on Dynamic Path.
   */
  applyScrollToTop(duration?: number, delay = 0, onComplete?: () => void, overrideFirstInteraction?: boolean) {
    const { isInWidget, widgetType, hasFirstInteraction } = this.appStore.getValue();

    // if we are running in a widget
    if (isInWidget) {
      // to prevent hijacking the widget page scrolling we should wait for an interaction
      if (hasFirstInteraction || overrideFirstInteraction) {
        // now we can scroll to the top
        this.scrollingService.applyScrollToTop(duration, delay, onComplete);
        // and tell the widget about it
        this.widgetService.applyScrollToTop(widgetType);
      } else {
        this.loggerService.info('[ApplicationService]::applyScrollToTop - not available until first interaction.');
      }
    } else {
      // we are not running in a widget so go ahead
      this.scrollingService.applyScrollToTop(duration, delay, onComplete);
    }
  }

  /**
   * Apply Scroll To Bottom to Scroll to the Bottom of the Page.
   *
   * This action will only work once a first interaction has occurred.
   *
   * @param duration - the duration of the scroll in seconds.
   * @param delay - the delay before the scroll occurs in seconds.
   * @param onComplete - a callback that occurs once scrolling has completed.
   * @param overrideFirstInteraction - forced the scroll to occur even if first interaction has not occurred. This can occur on Dynamic Path.
   */
  applyScrollToBottom(duration?: number, delay = 0, onComplete?: () => void, overrideFirstInteraction?: boolean) {
    const { isInWidget, hasFirstInteraction } = this.appStore.getValue();

    // if we are running in a widget
    if (isInWidget) {
      // to prevent hijacking the widget page scrolling we should wait for an interaction
      if (hasFirstInteraction || overrideFirstInteraction) {
        // now we can scroll to the bottom
        this.scrollingService.applyScrollToBottom(duration, delay, onComplete);
      } else {
        this.loggerService.info('[ApplicationService]::applyScrollToBottom - not available until first interaction.');
      }
    } else {
      // we are not running in a widget so go ahead
      this.scrollingService.applyScrollToBottom(duration, delay, onComplete);
    }
  }

  /**
   * Scroll to an element on the page.
   *
   * @param element - the element to scroll to
   * @param duration - the duration in seconds
   * @param delay - the delay in seconds
   * @param location - can be 'center' or 'top' depending on if the element should scroll in the center of the page or the top
   * @param onlyScrollIfNotVisible - will only scroll if the element is not visible
   * @param onComplete - the callback once scrolling is completed.
   */
  applyScrollToElement(
    element: HTMLElement,
    duration = 0.75,
    delay = 0,
    location: 'center' | 'top' | 'bottom' = 'center',
    onlyScrollIfNotVisible = false,
    onComplete?: () => void
  ) {
    this.scrollingService.applyScrollToElement(element, duration, delay, location, onlyScrollIfNotVisible, onComplete);
  }

  ////////////////////////////////////////////////////////////////////
  // WINDOW UNLOAD
  ////////////////////////////////////////////////////////////////////

  /**
   * Uses the standard window beforeunload event to show a default dialog when navigating from the application.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
   *
   * Note:
   *
   *   - This behavior can be set during initialize under the `system.preventWindowUnloadDialog` property.
   *   - It is not used when the application is running within a widget since doing so impacts the parent page.
   *   - It is not used when in development mode for a smoother experience.
   */
  private setupWindowUnloadTracking() {
    // this dialog should not be used for local development as it is annoying
    if (environment.localDevelopment) {
      return;
    }

    const unloadEvent$ = fromEvent(this.window, 'beforeunload');
    const preventAppUnloadDialog$ = this.appQuery.preventAppUnloadDialog$;

    combineLatest([unloadEvent$, preventAppUnloadDialog$]).subscribe(([unloadEvent, preventAppUnloadDialog]) => {
      // if we are in a widget or if the app has been configured not to show the dialog
      if (preventAppUnloadDialog === true) {
        return;
      }

      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      unloadEvent.preventDefault();

      // Chrome requires returnValue to be set, it is normal that chrome uses a depreciated method.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unloadEvent.returnValue = '' as any;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // SHELL LAYOUT
  ////////////////////////////////////////////////////////////////////

  /**
   * Control the global loading bar.
   *
   * @param active - true to show the loader, false otherwise
   */
  setLoadingIndicator(active: boolean) {
    this.appStore.applyShellLoader(active);
  }

  /**
   * Applies the native outlet container element
   */
  setPrimaryOutletContainer(element: HTMLDivElement) {
    this.primaryOutletContainer = element;
  }

  /**
   * Apply Shell Layout. This effects the primary outlets layout.
   *
   * @param config - the incoming layout options
   */
  configureShellLayout(config: IShellSettings) {
    const { isInWidget, widgetType } = this.appStore.getValue();

    // validate the layout
    const layout = this.layoutService.validateProcessApplicationLayout(config.layout);

    // disable outlet grow?
    // this only applies if the app is in a widget and the widgetType is auto.
    let disableOutletGrow = config.disableOutletGrow === true ? true : false;
    if (isInWidget === false) {
      disableOutletGrow = true;
    }
    if (isInWidget && widgetType === 'fixed') {
      disableOutletGrow = true;
    }

    if (this.primaryOutletContainer) {
      // the primary outlet should return to height auto
      this.primaryOutletContainer.style.height = 'auto';

      if (disableOutletGrow === true) {
        // if outlet grow is disabled, the minimum height should be cancelled
        this.primaryOutletContainer.style.minHeight = '0';
      }
    }

    // update the store
    this.appStore.applyShellLayout(layout, disableOutletGrow);
  }

  /**
   * Occurs just before the application changes route.
   *
   * Prevents the Primary Outlet from collapsing during a transition.
   */
  registerPrepareForRouteTransition() {
    // if the primary outlet container has been registered.
    if (this.primaryOutletContainer) {
      // measure the element height
      const height = this.primaryOutletContainer?.getBoundingClientRect()?.height || 0;

      // force the element to be this height
      this.primaryOutletContainer.style.height = `${height}px`;
      this.primaryOutletContainer.style.minHeight = `${height}px`;
    }
  }
}
