import { Subject } from 'rxjs';
import { IShellFGEffectsConfetti } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfetti';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';

/**
 * A Class which emits events for the Foreground Effects.
 *
 * It is instantiated by {@link ShellEffectsService} and belongs to {@link CoreModule}.
 */
export class ShellFGEffects {
  /**
   * The confetti subject is the source of the confetti$ Observable.
   */
  private confettiSubject = new Subject<IShellFGEffectsConfetti>();

  /**
   * The confetti Observable is fire and forget.
   */
  confetti$ = this.confettiSubject.asObservable();

  /**
   * The confettis subject is the source of the confettis$ Observable.
   */
  private confettisSubject = new Subject<IShellFGEffectsConfettis>();

  /**
   * The confettis Observable is fire and forget.
   */
  confettis$ = this.confettisSubject.asObservable();

  /**
   * Provide the configuration for the effects.
   */
  effect(configs?: (IShellFGEffectsConfetti | IShellFGEffectsConfettis)[]) {
    if (configs) {
      configs.forEach((config) => {
        switch (config.effectName) {
          case 'confetti':
            this.confetti(config as IShellFGEffectsConfetti);
            break;
          case 'confettis':
            this.confettis(config as IShellFGEffectsConfettis);
            break;
        }
      });
    }
  }

  /**
   * Emit a new confetti event.
   *
   * @param config - The confetti configuration.
   */
  private confetti(config: IShellFGEffectsConfetti) {
    if (config && config.effectName === 'confetti') {
      this.confettiSubject.next(config);
    }
  }

  /**
   * Emite a new confettis event.
   *
   * @param config - The confettis configuration.
   */
  private confettis(config: IShellFGEffectsConfettis) {
    if (config && config.effectName === 'confettis') {
      this.confettisSubject.next(config);
    }
  }
}
