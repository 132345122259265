// pro - far - regular
import {
  faAt,
  faBars,
  faCircle,
  faCirclePlus,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faForward,
  faGift,
  faGripLines,
  faHashtag,
  faHeart,
  faPhone,
  faRightFromBracket,
  faSquareArrowUpRight,
  faSquareInfo,
  faStopwatch,
  faTriangleExclamation,
  faTrophyStar,
  faUser,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * The Supported Icon Names used via JSON.
 */
export const FarList = [
  'far:at',
  'far:bars',
  'far:circle',
  'far:circle-plus',
  'far:face-frown',
  'far:face-meh',
  'far:face-smile',
  'far:forward',
  'far:gift',
  'far:grip-lines',
  'far:hashtag',
  'far:heart',
  'far:phone',
  'far:right-from-bracket',
  'far:square-arrow-up-right',
  'far:square-info',
  'far:stopwatch',
  'far:triangle-exclamation',
  'far:trophy-star',
  'far:user',
] as const;

/**
 * Generated type from the SupportedIconNames.
 */
export type FarIcons = typeof FarList[number];

/**
 * All FontAwesome Regular Icons available for the system
 * to use with Material Design are defined here.
 */
export const FarIconDefinitions: IconDefinition[] = [
  faAt,
  faBars,
  faCircle,
  faCirclePlus,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faForward,
  faGift,
  faGripLines,
  faHashtag,
  faHeart,
  faPhone,
  faRightFromBracket,
  faSquareArrowUpRight,
  faSquareInfo,
  faStopwatch,
  faTriangleExclamation,
  faTrophyStar,
  faUser,
];
