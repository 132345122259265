import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { DataboxLivesIconQuery } from './databox-lives-icon.query';
import { DataboxLivesIconStore } from './databox-lives-icon.store';

@Component({
  selector: 'app-databox-lives-icon',
  templateUrl: './databox-lives-icon.component.html',
  styleUrls: ['./databox-lives-icon.component.scss'],
  providers: [DataboxLivesIconStore, DataboxLivesIconQuery],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxLivesIconComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxLivesIcon) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxLivesIcon;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxLivesIconStore, public readonly query: DataboxLivesIconQuery) {}

  /**
   * Updates the component with the users lives.
   *
   * @param livesRemaining - the amount of lives remaining.
   */
  update(livesRemaining: number) {
    this.store.applyUpdate(livesRemaining);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.applyUpdate(0);
  }
}
