import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { ITextRich, ITextRichTypes } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { createDigitaServiceError } from 'src/app/app-error';
import { TextRichModel } from './text-rich.model';

/**
 * Creates the initial state.
 */
function createInitialState(): TextRichModel {
  return {
    configured: false,
    data: '',
    type: 'mat-body-2',
  };
}

/**
 * The Store used for a {@link TextRichComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class TextRichStore extends Store<TextRichModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `text-rich-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ITextRich) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`TextRich`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    // the type
    let type: ITextRichTypes = 'mat-body-2';
    if (configuration.type) {
      switch (configuration.type) {
        case 'mat-body-2':
        case 'mat-body-2':
        case 'mat-caption':
        case 'mat-h1':
        case 'mat-h2':
        case 'mat-h3':
        case 'mat-h4':
        case 'mat-display-1':
        case 'mat-display-2':
        case 'mat-display-3':
        case 'mat-display-4':
        case 'mat-headline':
        case 'mat-title':
        case 'mat-subheading-1':
        case 'mat-subheading-2':
        case 'mat-body-strong':
        case 'mat-small':
        case 'none':
          type = configuration.type;
          break;
      }
    }

    // the data
    let data = '';
    if (typeof configuration.data === 'string') {
      data = configuration.data;
    }

    // update the store
    this.update((draft) => {
      draft.configured = true;
      draft.type = type;
      draft.data = data;
    });
  }
}
