import { animation, sequence, useAnimation } from '@angular/animations';
import { ANIMATION_DISPLAY_DURATION } from '../AnimationConsts';
import { IAnimationCompoundOptions } from '../IAnimationCompoundOptions';
import { fadeInAnimation, fadeOutAnimation } from '../parts/common.animation';

export const fadeInOutCompoundAnimation = (options: Partial<IAnimationCompoundOptions>) => {
  const enter = fadeInAnimation(options.enter);

  const exit = fadeOutAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};
