import { DigitaServiceError } from '@digitaservice/utils';

/**
 * Standardise Error Messages.
 *
 * @param system - the file or class where the error was raised.
 * @param method - the method where the error was raised.
 * @param message - the error message to display.
 * @param type - the type of error if it is internal or configuration.
 */
export const createDigitaServiceError = (system: string, method: string, message: string, type?: 'internal' | 'config') => {
  let errorMessage = `[${system}]::${method} - ${message}}`;
  if (type) {
    errorMessage = `[${system}]::${method} - ${message} - (${type})`;
  }
  return new DigitaServiceError(errorMessage);
};
