<!-- The main root level div is always displayed and is responsive -->
<div *ngIf="imageQuery.isSetup$ | async" [class.limit-portrait-width-on-desktop]="imageQuery.limitPortraitWidthOnDesktop$ | async">
  <div
    [@imageGhostExitAnimation]="imageQuery.isLoaded$ | async"
    [fxFlex]="imageQuery.flex$ | async"
    [class.ghost]="imageQuery.hasGhost$ | async"
    [style.border-radius]="imageQuery.borderRadius$ | async"
    [class.ghostless-error]="(imageQuery.hasGhost$ | async) !== true && (imageQuery.hasError$ | async)"
  >
    <div [style.padding-top]="imageQuery.aspect$ | async" [style.width]="imageQuery.wrapperWidth$ | async">
      <!-- We need to introduce a container here for if there is an error -->
      <ng-container *ngIf="imageQuery.hasError$ | async">
        <app-fa-icon [config]="imageQuery.errorIcon$ | async" class="ghost-icon"></app-fa-icon>
      </ng-container>

      <!--
        If there is no error, we can proceed.
        We have an optional abstract link that wraps the image, so we wrap it not.
    -->
      <ng-container *ngIf="(imageQuery.hasError$ | async) !== true">
        <app-abstract-link *ngIf="imageQuery.link$ | async; else imageTemplate" [config]="imageQuery.link$ | async">
          <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
        </app-abstract-link>
      </ng-container>
    </div>
  </div>
</div>

<!--
    The image is moved into a template because it may or may not
    be wrapped inside an abstract-link. 
    
    In either case, this template will be displayed. 
    
    It can only be shown once the image has preloaded.
-->
<ng-template #imageTemplate>
  <img
    #img
    *ngIf="(imageQuery.isLoaded$ | async) && (imageQuery.imageData$ | async)"
    [style.border-radius]="imageQuery.borderRadius$ | async"
    [attr.src]="imageQuery.imageData$ | async | safeUrl"
  />
</ng-template>
