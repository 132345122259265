<!-- The Control Div should always exist since it is used to pause/play the media -->
<div class="control" (pointerup)="controlZonePointerUp()">
  <!-- The Poster is Optional and will only be shown when the audio is playing -->
  <img
    class="poster"
    [class.hide-poster]="(mediaQuery.playing$ | async)?.playing !== true"
    *ngIf="mediaQuery.audioPoster$ | async"
    [src]="mediaQuery.audioPoster$ | async"
  />
</div>

<!-- The actual audio element responsible for file playback -->
<audio #media class="media" preload="auto"></audio>
