import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { createDigitaServiceError } from 'src/app/app-error';
import { ImageModel } from './image.model';

/**
 * Creates the initial state of an image's store.
 */
export function createInitialState(): ImageModel {
  return {
    errorIcon: {
      name: 'far:triangle-exclamation',
      color: 'grey',
      size: '2x',
    },
    flex: '0 0 auto',
    hasError: false,
    width: 'auto',
    height: 'auto',
    widthValue: 0,
    heightValue: 0,
    aspect: '0',
    imageData: undefined,
    src: '',
    borderRadius: undefined,
    fixedSize: undefined,
    link: undefined,
    wrapperWidth: '100%',
    hasGhost: false,
    isLoaded: false,
    isSetup: false,
  };
}

/**
 * The Store used for an {@link ImageComponent}.
 */
@Injectable()
export class ImageStore extends Store<ImageModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `image-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  applyInitialize(configuration?: IImage) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyInitialize`,
        `No configuration provided but all images must provide a configuration.`,
        `config`
      );
    }

    // SRC

    let src: string | undefined = undefined;
    // if there is no source then that is an error
    if (typeof configuration.src === 'string' && configuration.src.length > 0) {
      src = configuration.src;
    } else {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyInitialize`,
        `No "src" property provided but all images must provide a source.`,
        `config`
      );
    }

    // LINK

    let link: ILink | undefined = undefined;
    if (configuration.link) {
      link = cloneDeep(configuration.link);
    }

    // BORDER RADIUS

    let borderRadius = undefined;
    if (typeof configuration.borderRadius === 'string' && configuration.borderRadius.length > 0) {
      borderRadius = configuration.borderRadius;
    }

    // WRAPPER WIDTH

    let wrapperWidth = '100%';
    if (typeof configuration.wrapperWidth === 'number' && configuration.wrapperWidth > 0) {
      wrapperWidth = `${configuration.wrapperWidth}px`;
    }

    // IMAGE DIMENSIONS

    let hasImageDimensions = false;
    let imageWidth: number | undefined = undefined;
    let imageHeight: number | undefined = undefined;
    let imageWidthStyle = 'auto';
    let imageHeightStyle = 'auto';
    if (
      typeof configuration.width === 'number' &&
      configuration.width > 0 &&
      typeof configuration.height === 'number' &&
      configuration.height > 0
    ) {
      hasImageDimensions = true;
      imageWidth = configuration.width;
      imageHeight = configuration.height;
      imageWidthStyle = `${configuration.width}px`;
      imageHeightStyle = `${configuration.height}px`;
    }

    // FIXED SIZE

    let isFixedSize = false;
    if (configuration.fixedSize === true) {
      isFixedSize = true;
    }

    // PROCESSING

    let flex = '0 0 auto';
    let hasGhost = false;
    let aspectStyle = `0`;

    // See https://codepen.io/drimlike/pen/KKVJybZ
    // fixed size has different behavior from responsive images
    if (isFixedSize) {
      // note that the width and height may not always be provided and
      // if not provided, it should be auto.
      if (hasImageDimensions) {
        flex = `0 0 ${imageWidthStyle}`;
        hasGhost = true;
        aspectStyle = `calc(${imageHeight}/${imageWidth}*${wrapperWidth})`;
      } else {
        flex = `0 0 auto`;
        hasGhost = false;
        aspectStyle = `0`;
      }
    } else {
      // note that the width and height may not always be provided and
      // if not provided, it should be auto.
      if (hasImageDimensions) {
        flex = `0 1 ${imageWidthStyle}`;
        hasGhost = true;
        aspectStyle = `calc(${imageHeight}/${imageWidth}*${wrapperWidth})`;
      } else {
        flex = `0 1 auto`;
        hasGhost = false;
        aspectStyle = `0`;
      }
    }

    // UPDATE STORE

    this.update((draft) => {
      draft.src = src;
      draft.link = link;
      draft.borderRadius = borderRadius;
      draft.wrapperWidth = wrapperWidth;
      draft.width = imageWidthStyle;
      draft.height = imageHeightStyle;
      draft.widthValue = imageWidth;
      draft.heightValue = imageHeight;
      draft.fixedSize = isFixedSize;
      draft.flex = flex;
      draft.hasGhost = hasGhost;
      draft.aspect = aspectStyle;
      draft.isSetup = true;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE DATA
  ////////////////////////////////////////////////////////////////////

  applyImageData(imageData?: string) {
    // if there is no image data then that is a problem
    if (!imageData) {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyImageData`,
        `No image data provided but all images must provide image data.`,
        `internal`
      );
    }

    // UPDATE STORE

    this.update((draft) => {
      draft.imageData = imageData;
      draft.isLoaded = true;
    });
  }

  ////////////////////////////////////////////////////////////////////
  // APPLY ERROR
  ////////////////////////////////////////////////////////////////////

  applyError() {
    this.update((draft) => {
      draft.hasError = true;
    });
  }
}
