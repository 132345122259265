import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { DataboxContainerSupportedTypes, IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { DataboxContainerModel } from './databox-container.model';

const DEFAULT_DATABOX_CONTAINER_SETTINGS: DataboxContainerModel = {
  configured: false,
  componentArray: [],
};

/**
 * Creates the initial state for a DataboxContainerModel.
 * @returns The default initial state.
 */
function createInitialState(): DataboxContainerModel {
  return DEFAULT_DATABOX_CONTAINER_SETTINGS;
}

/**
 * The store used for managing {@link DataboxContainerComponent} state.
 */
@Injectable()
export class DataboxContainerStore extends Store<DataboxContainerModel> {
  /**
   * Constructor
   */
  constructor() {
    super(createInitialState(), { name: `databox-container-${guid()}`, producerFn: produce });
  }

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the DataboxContainerStore with a configuration.
   * @param configuration - The configuration for the DataboxContainer.
   * @throws Throws an error if no configuration is provided.
   */
  applyInitialize(configuration?: IDataboxContainer) {
    if (!configuration) {
      throw createDigitaServiceError(`DataboxContainer`, `initialze`, `All containers required a configuration to be provided.`, `config`);
    }

    // component array
    let componentArray: DataboxContainerSupportedTypes[] = [];
    if (configuration.componentArray) {
      componentArray = cloneDeep(configuration.componentArray);
    }

    this.update((draft) => {
      draft.configured = true;
      draft.componentArray = componentArray;
    });
  }
}
