import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxProgressionNumeric } from 'src/app/api/modules/core/dynamic/databoxes/progression-numeric/IDataboxProgressionNumeric';
import { DataboxProgressionNumericQuery } from './databox-progression-numeric.query';
import { DataboxProgressionNumericStore } from './databox-progression-numeric.store';

@Component({
  selector: 'app-databox-progression-numeric',
  templateUrl: './databox-progression-numeric.component.html',
  styleUrls: ['./databox-progression-numeric.component.scss'],
  providers: [DataboxProgressionNumericQuery, DataboxProgressionNumericStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataboxProgressionNumericComponent {
  /**
   * The Configuration powering the component.
   */
  @Input() set config(configuration: IDataboxProgressionNumeric) {
    this._config = configuration;
    this.store.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }
  private _config?: IDataboxProgressionNumeric;

  /**
   * Constructor
   */
  constructor(private readonly store: DataboxProgressionNumericStore, public readonly query: DataboxProgressionNumericQuery) {}

  /**
   * Updates the component with the users progress
   *
   * @param current - the current progress of the user
   * @param total - the total amount of progress possible.
   */
  update(current: number, total?: number) {
    this.store.applyUpdate(current, total);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.store.applyReset();
  }
}
