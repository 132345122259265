import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * In the Application a single Intersection Observer is used to observe all elements.
 *
 * Used in conjunction with {@link DomIntersectionObserverDirective} to trigger only when the element is in the viewport on the main document.
 */
@Injectable({
  providedIn: 'root',
})
export class DOMIntersectionObserverService {
  /**
   * The Intersection Observer.
   */
  private intersectionObserver?: IntersectionObserver;

  /**
   * The source for the entries observable.
   */
  private entriesSource = new Subject<IntersectionObserverEntry>();

  /**
   * The Entries Observable
   */
  entries$ = this.entriesSource.asObservable();

  /**
   * Constructor
   */
  constructor() {
    this.configure({
        threshold: 0.5,
    });
  }

  /**
   * Configure the Intersection Observer.
   *
   * @param options - the options to configure the observer with.
   */
  configure(options?: IntersectionObserverInit) {
    // if no new options are provided, then do nothing.
    if (!options) {
      return;
    }

    // disconnect the existing observer should it exist
    this.intersectionObserver?.disconnect();

    // create a new one with the specific options
    this.intersectionObserver = new IntersectionObserver((entries) => {
      for (let i = 0; i < entries.length; i++) {
        this.entriesSource.next(entries[i]);
      }
    }, options);
  }

  /**
   * Observe an element for intersection.
   *
   * @param element - the element to observe
   */
  observe(element: HTMLElement) {
    this.intersectionObserver?.observe(element);
  }

  /**
   * Unobserve an element for intersection.
   *
   * @param element - the element to unobserve.
   */
  unobserve(element: HTMLElement) {
    this.intersectionObserver?.unobserve(element);
  }
}
