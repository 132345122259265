import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CredentialStore } from './credential.store';

@Injectable({
  providedIn: 'root',
})
export class CredentialQuery extends Query<object> {
  constructor(protected override readonly store: CredentialStore) {
    super(store);
  }
}
