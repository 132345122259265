import { LoggerService } from '@angular-ru/cdk/logger';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FRAME_EVENT_TYPES } from '@digitaservice/utils';
import { cloneDeep, merge } from 'lodash-es';
import { Subscription, catchError, of, switchMap, take, tap, timeout } from 'rxjs';
import { IDynamicTheme } from 'src/app/api/modules/core/components/abstract/IDynamicTheme';
import { ILifecycleCompleteEvent, ILifecycleRouteChangeEvent } from 'src/app/api/modules/core/components/abstract/ILifecycleEvents';
import { IShellBackgroundEntity } from 'src/app/api/modules/core/components/abstract/IShellBackgroundEntity';
import { IScreen } from 'src/app/api/modules/core/components/screen/IScreen';
import { IShellSettings, ShellSettingsDefaults } from 'src/app/api/modules/core/components/settings/IShellSettings';
import { IFooter } from 'src/app/api/modules/core/components/static/IFooter';
import { createDigitaServiceError } from 'src/app/app-error';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { ApplicationService } from '../../../services/application/application.service';
import { ShellEffectsService } from '../../../services/effects/shell-effects.service';
import { FooterService } from '../../../services/footer/footer.service';
import { ShellBackgroundService } from '../../../services/shell-background.service';
import { ThemeService } from '../../../services/theme.service';
import { ScreenFactoryArray } from './screen.factory';
import { ScreenQuery } from './screen.query';
import { ScreenService } from './screen.service';
import { ScreenStore } from './screen.store';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
  providers: [ScreenQuery, ScreenService, ScreenStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenComponent<Configuration extends IScreen> implements AfterViewInit, OnDestroy {
  /**
   * The Directive marked to place content dynamically
   */
  @ViewChild(DynamicContentDirective, { static: false }) contentContainer: DynamicContentDirective;

  /**
   * The Screen Configuration
   */
  private _config: Configuration;
  @Input() set config(configuration: Configuration) {
    this._config = configuration;
    this.screenService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * All Screen Specific Subscriptions are held and destroyed here.
   */
  protected subscriptions = new Subscription();

  /**
   * All dynamically created components are held here.
   */
  protected dynamicComponentRefs: ComponentRef<any>[] = [];

  /**
   * All Routes in the DigitaService Application resolve to a Screen.
   *
   * See {@link IScreen}
   */
  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly appService: ApplicationService,
    protected readonly loggerService: LoggerService,
    protected readonly footerService: FooterService,
    protected readonly shellBackgroundService: ShellBackgroundService,
    protected readonly themeService: ThemeService,
    protected readonly effectService: ShellEffectsService,
    protected readonly screenService: ScreenService<Configuration>,
    public readonly screenQuery: ScreenQuery<Configuration>,
    protected readonly cd: ChangeDetectorRef
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle Hook
   *
   * Occurs when the Template is ready.
   */
  ngAfterViewInit() {
    // obtain the screen configuration from the route
    const routeSubscription = this.route.data
      .pipe(
        // we only need one of these
        take(1)
      )
      .subscribe((data) => {
        // extract the screen data from the route
        const configuration = data['screen'] as Configuration;
        // set the screen configuration
        this.config = configuration;
      });
    this.subscriptions.add(routeSubscription);

    // process the screen configuration
    const screenConfigurationSubscription = this.screenQuery.screenData$
      .pipe(
        // firstly log the screen configuration
        tap((screen) => {
          this.logScreen(screen.id);
        }),
        // configure the components
        tap((screen) => {
          // references
          const componentArray = screen.componentArray;
          const componentObject = screen.componentObject;
          const autoDestination = screen.autoDestination;

          // if the screen has a component array
          if (componentArray && Array.isArray(componentArray) && componentArray.length > 0) {
            // create the components
            this.dynamicComponentRefs = this.createComponentsFromArray(componentArray);
          } else if (componentObject && typeof componentObject === 'object' && Object.keys(componentObject).length > 0) {
            // create the components
            this.createComponentsFromObject(componentObject);
          } else {
            // if there is an auto destination then it's OK to have no components
            if (autoDestination && typeof autoDestination === 'object' && autoDestination.destination) {
              // this is OK
            } else {
              // otherwise throw an error since all screens require a screen configuration
              throw createDigitaServiceError(
                `Screen`,
                `initialze`,
                `Screens must be configured with either a "componentArray"  or "componentObject"`,
                `config`
              );
            }
          }
        }),
        // load any fonts
        switchMap((screen) => {
          // references
          const environment = screen.environment;
          const fonts = environment?.fonts;

          // if there are fonts to load
          if (fonts && Array.isArray(fonts) && fonts.length > 0) {
            // try to load the fonts
            return this.themeService.loadFonts(fonts).pipe(
              // we only need to do this once
              take(1),
              // if the fonts are loaded, then return the environment
              switchMap((fonts) => {
                this.loggerService.info('Loaded Fonts', fonts);
                return of(screen);
              }),
              // if fonts failt to load, we can ignore it and not kill the app over it
              catchError((err) => {
                this.loggerService.error('Failed to Load Fonts', err);
                return of(screen);
              })
            );
          } else {
            // otherwise just return the screen
            return of(screen);
          }
        }),
        // configure the environment
        tap((screen) => {
          // references
          const environment = screen.environment;
          this.configureEnvironment(environment);
        }),
        // if there is an auto destination then navigate to it
        switchMap((screen) => {
          // references
          const autoDestination = screen.autoDestination;
          const destination = autoDestination?.destination;
          const destinationDelay = autoDestination?.destinationDelay || 500;

          // if there is an auto destination
          if (destination) {
            return of(destination).pipe(
              timeout(destinationDelay),
              take(1),
              tap(() => {
                this.appService.openLink(destination);
              })
            );
          } else {
            // otherwise just return the screen
            return of(screen);
          }
        })
      )
      .subscribe(() => {
        // call change detection
        this.cd.detectChanges();

        // call setup complete
        this.setupComplete();
      });
    this.subscriptions.add(screenConfigurationSubscription);
  }

  /**
   * Occurs when the system has finished setting up the screen.
   *
   * @param config - the screen configuration
   */
  protected setupComplete() {
    // Not used in this base class
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    // destroy all components
    this.dynamicComponentRefs.forEach((item) => {
      item.destroy();
    });

    // clear the array
    this.dynamicComponentRefs = [];

    // unsubscribe from all subscriptions
    this.subscriptions.unsubscribe();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PROCESS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Log the screen configuration.
   *
   * @param id - the id of the screen
   */
  protected logScreen(id: string) {
    this.loggerService.groupCollapsed(`[Screen] ${id}`, () => {
      this.loggerService.log(this.config);
    });
  }

  /**
   * Creates Dynamic Components from an Array.
   *
   * Intended to be overridden.
   *
   * @param componentArray - the component array.
   */
  protected createComponentsFromArray(componentArray: Configuration['componentArray']): ComponentRef<any>[] {
    return ScreenFactoryArray(this.contentContainer.viewContainerRef, componentArray);
  }

  /**
   * Creates Dynamic Components from an Object.
   *
   * Intended to be overridden.
   *
   * @param componentObject - the component object.
   */
  protected createComponentsFromObject(componentObject: Configuration['componentObject']) {
    // not used for screens in this base class
  }

  /**
   * Configure the environment.
   *
   * @param environment - the environment configuration
   */
  private configureEnvironment(environment: Configuration['environment']) {
    this.configureEnvironmentBackground(environment?.background);
    this.configureEnvironmentFooter(environment?.footer);
    this.configureEnvironmentShell(environment?.shell);
    this.configureEnvironmentTheme(environment?.theme);
    this.configureEnvironmentLifeCycleRouteChange(environment?.lifecycleRouteChange);
    this.configureEnvironmentLifeCycleComplete(environment?.lifecycleComplete);
  }

  /**
   * Configure the applications background.
   *
   * @param background - the background configuration, or null, or undefined.
   */
  private configureEnvironmentBackground(background: IShellBackgroundEntity | null | undefined = undefined) {
    // supply the background to the shell backgrounf service.
    this.shellBackgroundService.applyConfiguration(background);
  }

  /**
   * Configures the application footer.
   *
   * @param footer - the footer configuration or undefined.
   */
  private configureEnvironmentFooter(footer: IFooter | undefined = undefined) {
    if (footer) {
      this.footerService.applyConfiguration(cloneDeep(footer));
    }
  }

  /**
   * Configures the application shell.
   *
   * @param shell - the shell settings to apply or undefined.
   */
  private configureEnvironmentShell(shell: IShellSettings | undefined = undefined) {
    // the default shell settings
    const shellSettings = cloneDeep(ShellSettingsDefaults);

    // if the shell was provided
    if (shell) {
      // merge the shell settings
      merge(shellSettings, cloneDeep(shell));
    }

    // if there are shell effects
    if (shellSettings.effects) {
      // apply the effects
      this.effectService.effect(shellSettings.effects);
    }

    // apply the optionally merged settings
    this.appService.configureShellLayout(shellSettings);

    // scroll to the top of the page if not disabled
    if (!shellSettings.disableScrollToTop) {
      this.appService.applyScrollToTop();
    }
  }

  /**
   * Allows you to change the theme of the application.
   *
   * @param theme - the IDynamic theme or undefined.
   */
  private configureEnvironmentTheme(theme: IDynamicTheme | undefined = undefined) {
    // if a theme has been provided
    if (theme) {
      // clone the object and apply the theme
      const clonedTheme = cloneDeep(theme);
      this.themeService.applyTheme(clonedTheme);
    }
  }

  /**
   * Configures the application lifecycle for route change events.
   *
   * This occurs on every screen, if a LifecycleRouteChangeEvent has not been provided then
   * an empty one will be created.
   *
   * @param lifeCycleRouteChange - the lifecycle route change configuration or undefined.
   */
  private configureEnvironmentLifeCycleRouteChange(lifeCycleRouteChange: ILifecycleRouteChangeEvent | undefined = undefined) {
    // a default lifecycle event which is used if one is not provided
    let finalLifeCycleRouteChange: ILifecycleRouteChangeEvent = {
      type: FRAME_EVENT_TYPES.ROUTE_CHANGE,
      data: undefined,
    };

    // duplicate the configuration if it is provided
    if (lifeCycleRouteChange) {
      finalLifeCycleRouteChange = cloneDeep(lifeCycleRouteChange);
    }

    // apply the event
    this.appService.registerRouteChange(finalLifeCycleRouteChange);
  }

  /**
   * Configures the application lifecycle complete event.
   *
   * If this is provided by the server, then it means the application has come to the end.
   *
   * @param lifecycleComplete - the event configuration or undefined.
   */
  private configureEnvironmentLifeCycleComplete(lifecycleComplete: ILifecycleCompleteEvent | undefined = undefined) {
    // if there is a lifecycle complete configuration then apply it.
    if (lifecycleComplete) {
      const lifecycleCompleteConfiguration = cloneDeep(lifecycleComplete);
      this.appService.registerComplete(lifecycleCompleteConfiguration);
    }
  }
}
