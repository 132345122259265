import { Injectable } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { combineQueries, Query } from '@datorama/akita';
import { delay, EMPTY, of, switchMap } from 'rxjs';
import { MediaLockModel } from './media-lock.model';
import { MediaLockStore } from './media-lock.store';

/**
 * The Query used for a {@link MediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockQuery extends Query<MediaLockModel> {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.select((state) => state.configured);

  /**
   * What is the Media Lock Configuration?
   */
  lock$ = this.select((state) => state.lock);

  /**
   * Has an error occurred?
   */
  error$ = this.select((state) => state.error);

  /**
   * Is the Media Unlocked?
   */
  private _unlocked$ = this.select((state) => state.unlocked).pipe(delay(500));

  /**
   * The Button Configuration.
   */
  private _button$ = this.select((state) => state.button);

  /**
   * The Button Configuration becomes avilaible once the Media has been unlocked.
   *
   * Assuming there is no errors.
   */
  button$ = combineQueries([this._unlocked$, this.error$, this._button$]).pipe(
    switchMap(([unlocked, error, button]) => {
      if (unlocked || error) {
        return of(button);
      }
      return EMPTY;
    })
  );

  /**
   * Constructor.
   */
  constructor(protected override readonly store: MediaLockStore, protected readonly mediaObserver: MediaObserver) {
    super(store);
  }
}
