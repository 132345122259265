import { Injectable } from '@angular/core';
import { guid, Store } from '@datorama/akita';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { IAbstractComponent } from 'src/app/api/modules/shared/IAbstractComponent';
import { createDigitaServiceError } from 'src/app/app-error';
import { ContainerModel } from './container.model';

// Define default settings for the container, used to initialize the store's state
const DEFAULT_CONTAINER_SETTINGS: ContainerModel = {
  configured: false,
  layout: 'column',
  layoutAlign: 'space-between center',
  componentArray: [],
  componentObject: {},
};

// Function to create the initial state of the container store
function createInitialState(): ContainerModel {
  return DEFAULT_CONTAINER_SETTINGS;
}

/**
 * Store for managing the state of a container component within the application.
 * Utilizes Akita's state management capabilities along with immer for immutable state updates,
 * and lodash for deep cloning complex objects.
 *
 * This store serves as the single source of truth for the state of container components,
 * including their configuration, layout, and the components they contain.
 */
@Injectable()
export class ContainerStore extends Store<ContainerModel> {
  /**
   * Initializes the store with a unique ID and the initial state.
   */
  constructor() {
    super(createInitialState(), { name: `container-${guid()}`, producerFn: produce });
  }

  /**
   * Applies the initial configuration to the container's state. The configuration
   * must be provided, otherwise, an error is thrown. The container's state is
   * updated to reflect the provided configuration, marking it as 'configured'.
   *
   * @param configuration The configuration object for the container, adhering to the ICoreContainer interface.
   * @throws Will throw an error if the configuration is not provided.
   */
  applyInitialize(configuration?: ICoreContainer) {
    if (!configuration) {
      throw createDigitaServiceError(`Container`, `initialze`, `All containers required a configuration to be provided.`, `config`);
    }

    // Determine the layout configuration, defaulting to the store's default if not provided
    const layout = configuration.layout || DEFAULT_CONTAINER_SETTINGS.layout;

    // Determine the layout alignment configuration, defaulting to the store's default if not provided
    const layoutAlign = configuration.layoutAlign || DEFAULT_CONTAINER_SETTINGS.layoutAlign;

    // Deep clone the component array if provided, otherwise default to an empty array
    const componentArray: IAbstractComponent[] = configuration.componentArray ? cloneDeep(configuration.componentArray) : [];

    // Update the store's state with the new configuration
    this.update((draft) => {
      draft.configured = true;
      draft.layout = layout;
      draft.layoutAlign = layoutAlign;
      draft.componentArray = componentArray;
    });
  }
}
